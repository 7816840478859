import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { UseFormMethods, Controller } from "react-hook-form";
import { Button } from "src/components/atoms/button";
import { Typography } from "src/components/atoms/typography";
import { InputField } from "src/components/molecules/input-field";

const ExerciseResults = styled.div`
    margin-bottom: 5vh;

    display: grid;
    row-gap: 2vh;
`;

const ExerciseResultsTitle = styled(Typography).attrs({ variant: "h4" })`
    text-transform: uppercase;
`;

const FeedbackTitle = styled(Typography).attrs({ variant: "h5" })``;

const FeedbackArea = styled.div`
    display: grid;
    row-gap: 2vh;
`;

const FeedbackFullProgress = styled(Button).attrs({ round: false })`
    width: 100%;
`;

const FeedbackPartialProgress = styled(Button).attrs({ round: false })`
    width: 100%;
`;

const NotesField = styled(InputField).attrs({
    label: "Observações",
    placeholder: "Observações",
    text: {
        align: "left",
    },
})``;

const CurrentDate = styled(Typography).attrs({ variant: "h5" })``;

interface ExerciseResultsBaseProps {
    control: UseFormMethods["control"];
}

export const ExerciseResultsBase: React.FC<ExerciseResultsBaseProps> = (
    props
) => {
    return (
        <ExerciseResults>
            <ExerciseResultsTitle>Dados do Exercício</ExerciseResultsTitle>
            {props.children}
            <FeedbackTitle>Como executou este exercício?</FeedbackTitle>
            <Controller
                render={({ value, onChange }) => (
                    <FeedbackArea>
                        <FeedbackFullProgress
                            color={value ? "primary" : "background"}
                            onClick={() => onChange(true)}
                        >
                            Como prescrito
                        </FeedbackFullProgress>
                        <FeedbackPartialProgress
                            color={value ? "background" : "primary"}
                            onClick={() => onChange(false)}
                        >
                            Modificado
                        </FeedbackPartialProgress>
                    </FeedbackArea>
                )}
                control={props.control}
                defaultValue={true}
                name="fullWorkout"
            />

            <Controller
                as={<NotesField />}
                control={props.control}
                defaultValue={""}
                name="notes"
            />
            <CurrentDate>{`Realizado em ${dayjs().format(
                "DD/MM/YYYY"
            )}`}</CurrentDate>
        </ExerciseResults>
    );
};

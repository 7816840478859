import React from "react";
import styled from "styled-components";
import { Typography } from "src/components/atoms/typography";

const EquipmentCardBase = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "title delete"
        "title edit";
    background: #f2f2f2;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    padding: 3vh 2vw;
    row-gap: 2vh;
    column-gap: 2vw;
    align-content: center;
    align-items: center;
`;

const EquipmentTitle = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})`
    grid-area: title;
`;

const EquipmentDelete = styled.img.attrs({
    src: "/assets/mdi_delete.png",
})`
    grid-area: delete;
    width: 18px;
    cursor: pointer;
`;

const EquipmentEdit = styled.img.attrs({
    src: "/assets/mdi_edit.png",
})`
    grid-area: edit;
    width: 18px;
    cursor: pointer;
`;

interface EquipmentCardProps {
    title: string;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

export const EquipmentCard: React.FC<EquipmentCardProps> = (props) => {
    return (
        <EquipmentCardBase>
            <EquipmentTitle>{props.title}</EquipmentTitle>
            <EquipmentDelete onClick={() => props.onDeleteClick()} />
            <EquipmentEdit onClick={() => props.onEditClick()} />
        </EquipmentCardBase>
    );
};

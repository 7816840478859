import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import { Typography } from "src/components/atoms/typography";
import { TextAreaField } from "src/components/molecules/textarea-field";
import { Workout } from "src/entities/workout";
import { Button } from "src/components/atoms/button";
import dayjs from "dayjs";

const Content = styled.form`
    display: grid;
    row-gap: 3vh;
    column-gap: 2vw;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "title title"
        ". ."
        "name date"
        "description description"
        ". buttons";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "title"
            "name"
            "date"
            "description"
            "buttons";
    }
`;

const Title = styled(Typography).attrs({ variant: "h3" })`
    grid-area: title;
`;

const NameField = styled(InputField).attrs({
    label: "Nome do Treino",
    placeholder: "Digite o nome do treino",
    text: { align: "left" },
})`
    grid-area: name;
`;

const DateField = styled(InputField).attrs({
    label: "Data do Agendamento",
    text: { align: "left" },
    validations: { mask: "99/99/9999" },
})`
    grid-area: date;
`;

const DescriptionField = styled(TextAreaField).attrs({
    label: "Descrição do Treino",
    placeholder: "Digite a descrição do treino",
})`
    grid-area: description;
`;

const ButtonsArea = styled.div`
    grid-area: buttons;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-end;
    column-gap: 2vw;
    grid-template-areas: "cancel submit";

    @media only screen and (max-width: 768px) {
        justify-content: stretch;
    }
`;

const ButtonCancel = styled(Button).attrs({
    color: "background",
    round: false,
})`
    width: 100%;

    grid-area: cancel;
`;

const ButtonNext = styled(Button).attrs({ round: false })`
    width: 100%;

    grid-area: submit;
`;

interface WorkoutBasicInfoProps {
    workout?: Partial<Workout>;
    scheduledDate?: string;
    onCancel: () => void;
    onSave: (updateData: Partial<Workout>) => Promise<boolean>;
    goToNextFrame: () => void;
}

export const WorkoutBasicInfo: React.FC<WorkoutBasicInfoProps> = (props) => {
    const { control, formState, handleSubmit } = useForm();

    const { isSubmitting } = formState;

    const onSubmit = async (values: any) => {
        const result = await props.onSave({
            ...values,
            date: values.date
                ? dayjs(values.date, "DD/MM/YYYY").format("YYYY-MM-DD")
                : undefined,
        });

        if (result) {
            props.goToNextFrame();
        }
    };

    return (
        <Content onSubmit={handleSubmit(onSubmit)}>
            <Title>Informações do Treino</Title>
            <Controller
                as={<NameField />}
                rules={{ required: true }}
                control={control}
                defaultValue={props.workout?.name ?? ""}
                name="name"
            />
            {props.scheduledDate && (
                <Controller
                    as={<DateField disabled={true} />}
                    rules={{ required: true }}
                    control={control}
                    defaultValue={dayjs(props.scheduledDate).format(
                        "DD/MM/YYYY"
                    )}
                    name="date"
                />
            )}
            <Controller
                as={<DescriptionField />}
                rules={{ required: true }}
                control={control}
                defaultValue={props.workout?.description ?? ""}
                name="description"
            />
            <ButtonsArea>
                {!isSubmitting && (
                    <ButtonCancel onClick={() => props.onCancel()}>
                        Cancelar
                    </ButtonCancel>
                )}
                <ButtonNext loading={isSubmitting} type="submit">
                    Salvar
                </ButtonNext>
            </ButtonsArea>
        </Content>
    );
};

import React from "react";
import styled from "styled-components";
import { UseFormMethods, Controller } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import { LoadTypeField } from "src/components/structures/admin-edit-workout/workout-exercises/shared-fields/load-type";
import { Typography } from "src/components/atoms/typography";
import { LoadValueField } from "src/components/structures/admin-edit-workout/workout-exercises/shared-fields/load-value";
import { LoadType, RealizarDentroMinuto } from "src/entities/workout-series";

const FrameBase = styled.div`
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1vw;
    grid-template-areas:
        "round-reps total-rounds load-type ."
        "props-title props-title props-title props-title"
        "load-value load-value . .";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        column-gap: 2vw;
        row-gap: 2vh;
        grid-template-areas:
            "round-reps total-rounds"
            "load-type ."
            "props-title props-title"
            "load-value load-value";
    }
`;

const RoundReps = styled(InputField).attrs({
    label: "Repetições/Rodada",
    type: "number",
    validations: { min: "1" },
})`
    grid-area: round-reps;
`;

const TotalRounds = styled(InputField).attrs({
    label: "Rodadas",
    type: "number",
    validations: { min: "1" },
})`
    grid-area: total-rounds;
`;

const PropsTitle = styled(Typography).attrs({
    variant: "h5",
})`
    grid-area: props-title;
    margin: 5vh 0 2vh;
    text-transform: uppercase;
    color: #4d4d4d;
`;

interface FrameRealizarDentroMinutoEditProps {
    workoutSeries?: RealizarDentroMinuto;
    control: UseFormMethods["control"];
    errors: UseFormMethods["errors"];
    watch: UseFormMethods["watch"];
}

export const FrameRealizarDentroMinutoEdit: React.FC<FrameRealizarDentroMinutoEditProps> = (
    props
) => {
    const loadType = React.useMemo<LoadType>(() => {
        if (props.watch("loadType")) {
            return props.watch("loadType");
        }

        if (props.workoutSeries?.rounds[0]?.load) {
            return props.workoutSeries?.rounds[0]?.load.type;
        }

        return "sem_carga_definida";
    }, [props]);

    return (
        <FrameBase>
            <Controller
                as={
                    <RoundReps
                        error={{
                            isInvalid: props.errors.roundReps,
                        }}
                    />
                }
                rules={{ required: true }}
                control={props.control}
                defaultValue={props.workoutSeries?.rounds[0]?.reps ?? 10}
                name="roundReps"
            />
            <Controller
                as={
                    <TotalRounds
                        error={{
                            isInvalid: props.errors.totalRounds,
                        }}
                    />
                }
                rules={{ required: true }}
                control={props.control}
                defaultValue={props.workoutSeries?.rounds?.length ?? 5}
                name="totalRounds"
            />
            <LoadTypeField
                control={props.control}
                defaultValue={props.workoutSeries?.rounds[0]?.load}
            />
            {loadType !== "sem_carga_definida" && (
                <PropsTitle>PARÂMETROS DAS SÉRIES</PropsTitle>
            )}
            {loadType !== "sem_carga_definida" && (
                <LoadValueField
                    errors={props.errors}
                    control={props.control}
                    watch={props.watch}
                    loadType={loadType}
                    defaultValue={props.workoutSeries?.rounds[0]?.load}
                />
            )}
        </FrameBase>
    );
};

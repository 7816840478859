import React from "react";
import { useHistory } from "react-router-dom";
import { HomeProfileTemplate } from "src/components/templates/home-profile";
import { Condo } from "src/entities/condo";
import { Customer } from "src/entities/customer";
import {
    awsAuthGetCurrentUser,
    awsAuthSwitchPassword,
} from "src/infrastructure/aws/aws-amplify";
import { awsCondoGetAllByCustomer } from "src/infrastructure/aws/aws-condo";

export const HomeProfilePage: React.FC = () => {
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(true);
    const [customer, setCustomer] = React.useState<Customer | undefined>();
    const [customerCondos, setCustomerCondos] = React.useState<Condo[]>([]);

    const redirectWorkouts = () => {
        history.push("/home/workouts");
    };

    React.useEffect(() => {
        const retrieveCustomer = async () => {
            const newCustomer = await awsAuthGetCurrentUser();

            if (!newCustomer) {
                setCustomer(undefined);
                setIsLoading(false);
                return;
            }

            const newCondos = await awsCondoGetAllByCustomer(newCustomer);

            setCustomer(newCustomer ?? undefined);
            setCustomerCondos(newCondos);
            setIsLoading(false);
        };

        retrieveCustomer();
    }, []);

    const updatePassword = async (
        oldPassword: string,
        newPassword: string
    ): Promise<boolean> => {
        return await awsAuthSwitchPassword(oldPassword, newPassword);
    };

    return (
        <HomeProfileTemplate
            isLoading={isLoading}
            customer={customer}
            customerCondos={customerCondos}
            onCancel={redirectWorkouts}
            updatePassword={updatePassword}
        />
    );
};

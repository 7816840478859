import React from "react";
import { Redirect, Route } from "react-router-dom";
import { awsAuthGetCurrentUser } from "src/infrastructure/aws/aws-amplify";
import { Customer } from "src/entities/customer";
import { LoadingPage } from "src/components/structures/shared/loading-page";

export const PrivateRoute = (props: any) => {
    const [loading, setLoading] = React.useState(true);
    const [user, setUser] = React.useState<Customer>();

    const { component: Component, path, ...rest } = props;

    React.useEffect(() => {
        const fetchData = async () => {
            const authUser = await awsAuthGetCurrentUser();

            if (authUser) {
                setUser(authUser);
            }

            setLoading(false);
        };
        fetchData();
    }, []);

    const redirectAuthenticated = (authUser: Customer, props: any) => {
        if (path.includes("admin") && authUser.type === "CUSTOMER") {
            return <Redirect to="/home" />;
        }

        if (authUser.status === "ACTIVE" || authUser.type === "ADMIN") {
            return <Component {...rest} />;
        }

        return <Redirect to="/cadastro" />;
    };

    const message = "Você precisa estar logado para acessar!";
    const redirectUrl = props.location.pathname.replace("/", "");

    return (
        <Route
            {...rest}
            render={() =>
                !!user ? (
                    redirectAuthenticated(user, props)
                ) : loading ? (
                    <LoadingPage />
                ) : (
                    <Redirect
                        to={`/login?mensagem=${message}&redirectUrl=${redirectUrl}`}
                    />
                )
            }
        />
    );
};

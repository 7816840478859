import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export interface ScreenAlertProps {
    open: boolean;
    handleClose: () => void;
    message: string;
    severity?: "error" | "success";
}

export const ScreenAlert: React.FC<ScreenAlertProps> = (props) => {
    return (
        <Snackbar
            open={props.open}
            autoHideDuration={6000}
            onClose={props.handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={props.handleClose}
                severity={props.severity ?? "success"}
            >
                {props.message}
            </MuiAlert>
        </Snackbar>
    );
};

import React from "react";
import styled from "styled-components";
import { WorkoutScheduleDay } from "src/components/structures/shared/workout-schedule-day";
import { ScheduledWorkout } from "src/entities/scheduled-workout";

interface CalendarWeekStructure {
    numberOfItems: number;
}

const CalendarWeekStructure = styled.div<CalendarWeekStructure>`
    grid-area: calendar;

    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.numberOfItems}, 1fr)`};
    grid-auto-flow: column;
    column-gap: 2vw;
    align-items: start;
`;

export interface WorkoutSheduleCalendarItem {
    date: Date;
    scheduledWorkouts: ScheduledWorkout[];
}

interface WorkoutScheduleCalendarProps {
    isLoading: boolean;
    items: WorkoutSheduleCalendarItem[];
    onSelectScheduledWorkout: (scheduledWorkout: ScheduledWorkout) => void;
}

export const WorkoutScheduleCalendar: React.FC<WorkoutScheduleCalendarProps> = (
    props
) => {
    return (
        <CalendarWeekStructure numberOfItems={props.items.length}>
            {props.items.map((item, index) => (
                <WorkoutScheduleDay
                    key={index}
                    isLoading={props.isLoading}
                    scheduledWorkouts={item.scheduledWorkouts}
                    selectedDate={item.date}
                    onClick={props.onSelectScheduledWorkout}
                />
            ))}
        </CalendarWeekStructure>
    );
};

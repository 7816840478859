import React from "react";
import styled from "styled-components";
import { UseFormMethods } from "react-hook-form";
import { ExerciseBaseCard } from "src/components/structures/home-workout-series/exercise-base-card";
import { Divider } from "src/components/structures/home-workout-series/divider";
import { ExerciseResultsBase } from "src/components/structures/home-workout-series/exercise-results-base";
import { SeriesXRepeticao } from "src/entities/workout-series";
import {
    getWorkoutSeriesTitleFormated,
    getWorkoutSeriesDescription,
} from "src/entities/workout-series/utils";
import { Exercise } from "src/entities/exercise";

const BaseSection = styled.div`
    display: grid;
    row-gap: 3vh;
`;

interface FrameSeriesXRepeticaoProps {
    control: UseFormMethods["control"];
    workoutSeries: SeriesXRepeticao;
    exhibitExercise: (exercise: Exercise) => void;
}

export const FrameSeriesXRepeticao: React.FC<FrameSeriesXRepeticaoProps> = (
    props
) => {
    return (
        <BaseSection>
            {props.workoutSeries.rounds.map((round, index) => (
                <ExerciseBaseCard
                    title={getWorkoutSeriesTitleFormated(props.workoutSeries)}
                    description={getWorkoutSeriesDescription(
                        props.workoutSeries,
                        index
                    )}
                    onClick={() =>
                        props.exhibitExercise(props.workoutSeries.exercise)
                    }
                />
            ))}
            <Divider />
            <ExerciseResultsBase control={props.control} />
        </BaseSection>
    );
};

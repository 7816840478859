import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { Typography } from "src/components/atoms/typography";
import { WorkoutSeriesResult } from "src/entities/workout-result";
import { WorkoutSeries } from "src/entities/workout-series";
import { getWorkoutSeriesTitleFormated } from "src/entities/workout-series/utils";

const CardBase = styled.div`
    background: #f5f5f5;
    border-radius: 6px;
    padding: 1vh 1vw;

    display: grid;
    row-gap: 1vh;
`;

const CardTitle = styled(Typography).attrs({
    variant: "h5",
})``;

const ResultItem = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})`
    span {
        font-weight: 700;
    }
`;

const FullWorkoutResult = styled(Typography).attrs({
    variant: "h5",
})`
    color: #73d800;
`;

const PartialWorkoutResult = styled(Typography).attrs({
    variant: "h5",
})`
    color: #ffa3a3;
`;

const ResultType = styled.div`
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1vw;
`;

const Icon = styled.img`
    height: 4vh;
`;

interface WorkoutSeriesResultCardProps {
    series: WorkoutSeries;
    seriesResult: WorkoutSeriesResult;
}

export const WorkoutSeriesResultCard: React.FC<WorkoutSeriesResultCardProps> = (
    props
) => {
    const getReps = (reps: number | number[]) => {
        return Array.isArray(reps) ? reps.join(" / ") : reps;
    };

    return (
        <CardBase>
            <CardTitle>
                {getWorkoutSeriesTitleFormated(props.series, true)}
            </CardTitle>
            <ResultType>
                <Icon
                    src={
                        props.seriesResult.fullWorkout
                            ? "/assets/mdi_paste_green.png"
                            : "/assets/mdi_paste_red.png"
                    }
                />
                {props.seriesResult.fullWorkout ? (
                    <FullWorkoutResult>Como prescrito</FullWorkoutResult>
                ) : (
                    <PartialWorkoutResult>Modificado</PartialWorkoutResult>
                )}
            </ResultType>
            {!!props.seriesResult.load && (
                <ResultItem>{`Carga Utilizada: ${props.seriesResult.load} Kg`}</ResultItem>
            )}
            {!!props.seriesResult.rounds && (
                <ResultItem>{`Séries Completas: ${props.seriesResult.rounds}`}</ResultItem>
            )}
            {!!props.seriesResult.reps && (
                <ResultItem>{`Repetições: ${getReps(
                    props.seriesResult.reps
                )}`}</ResultItem>
            )}
            {!!props.seriesResult.time && (
                <ResultItem>{`Tempo: ${dayjs(props.seriesResult.time).format(
                    "HH:mm:ss"
                )}`}</ResultItem>
            )}
            {!!props.seriesResult.notes && (
                <ResultItem>
                    <span>Observações:</span>
                    {` ${props.seriesResult.notes}`}
                </ResultItem>
            )}
        </CardBase>
    );
};

import React from "react";
import styled from "styled-components";
import { Button } from "src/components/atoms/button";
import { Typography } from "src/components/atoms/typography";

const Background = styled.div`
    background-color: rgba(0, 0, 0, 0.9);
    width: 100vw;
    padding: 1vh 10vw;
    display: grid;
    grid-template-columns: 8rem 1fr auto auto;
    grid-template-rows: 1fr;
    grid-template-areas: "logo . contact-button login-button";
    column-gap: 1vw;
    align-items: center;
`;

const Logo = styled.img.attrs({ src: "/assets/logo_white.png" })`
    grid-area: logo;
    width: 100%;

    @media only screen and (max-width: 768px) {
        max-width: 40vw;
    }
`;

const ContactButton = styled(Typography).attrs({
    variant: "h5",
    color: "white",
})`
    grid-area: contact-button;
    text-transform: uppercase;
    margin: 0 2vw;
    cursor: pointer;
`;

const SigninButton = styled(Button).attrs({
    color: "primary",
})`
    grid-area: login-button;
    padding: 2vh 2vw;
    min-height: unset;

    @media only screen and (max-width: 768px) {
        padding: 2vh 5vw;
    }
`;

interface AppBarProps {
    goToLogin: () => void;
    openContactForm: () => void;
}

export const AppBar: React.FC<AppBarProps> = (props) => {
    return (
        <Background>
            <Logo />
            <ContactButton
                onClick={() => props.openContactForm()}
                className="hide-mobile"
            >
                Contato
            </ContactButton>
            <SigninButton onClick={() => props.goToLogin()}>
                Entrar
            </SigninButton>
        </Background>
    );
};

import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { Button } from "src/components/atoms/button";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import {
    Table,
    TableAction,
    TableHeader,
} from "src/components/structures/shared/table";
import { AdminLayout } from "src/components/templates/layout/admin-layout";
import { Condo } from "src/entities/condo";
import styled from "styled-components";

const Content = styled.div`
    padding: 5vh 0;
    display: grid;
    row-gap: 2vh;
`;

const NewButton = styled(Button).attrs({
    round: false,
})`
    justify-self: flex-end;
`;

interface AdminCondosTemplateProps {
    isLoading: boolean;
    condos: Condo[];
    addNewCondo: () => void;
    editCondo: (condo?: Condo) => void;
    toggleCondoActive: (condo: Condo) => void;
}

export const AdminCondosTemplate: React.FC<AdminCondosTemplateProps> = (
    props
) => {
    const isMobile = useMediaQuery("(max-width:768px)");

    const tableHeaders: TableHeader<Condo>[] = [
        {
            title: "Condomínio",
            field: "name",
        },
        {
            title: "Código",
            field: "id",
        },
        {
            title: "Usuários",
            render: (condo) =>
                `${condo.customers?.length ?? 0}/${condo.userLimit ?? 0}`,
        },
        {
            title: "Contato",
            render: (condo) => `${condo.contactInfo?.name ?? ""}`,
        },
        {
            title: "Telefone",
            render: (condo) => `${condo.contactInfo?.phone ?? ""}`,
        },
        {
            title: "E-mail",
            render: (condo) => `${condo.contactInfo?.email ?? ""}`,
        },
        {
            title: "Status",
            field: "status",
        },
    ];

    const tableHeadersMobile: TableHeader<Condo>[] = [
        {
            title: "Condomínio",
            field: "name",
        },
    ];

    const tableActions: TableAction<Condo>[] = [
        {
            icon: "block",
            tooltip: "Tornar Ativo/Inativo",
            onClick: (condo) => props.toggleCondoActive(condo),
        },
    ];

    return (
        <AdminLayout title="Gerenciamento de Condomínios">
            {props.isLoading ? (
                <LoadingPage />
            ) : (
                <Content>
                    <Table
                        actions={tableActions}
                        data={props.condos}
                        header={isMobile ? tableHeadersMobile : tableHeaders}
                        onClick={props.editCondo}
                    />
                    <NewButton onClick={() => props.addNewCondo()}>
                        Novo Condomínio
                    </NewButton>
                </Content>
            )}
        </AdminLayout>
    );
};

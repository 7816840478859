import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { Button } from "src/components/atoms/button";
import { InputField } from "src/components/molecules/input-field";
import { Typography } from "src/components/atoms/typography";
import { Equipment } from "src/entities/equipment";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        background: "none",
        width: "90%",
        maxWidth: "600px",
    },
    overlay: {
        backgroundColor: "rgb(0, 0, 0, 0.3)",
    },
};

const ModalContent = styled.form`
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 8vh 5vw;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "title title"
        "name name"
        "back-button save-button";
    align-items: center;
    row-gap: 3vh;
    column-gap: 3vw;
`;

const Title = styled(Typography).attrs({
    variant: "h2",
})`
    grid-area: title;
`;

const NameField = styled(InputField).attrs({
    label: "Nome do equipamento",
    placeholder: "Digite o nome do equipamento",
})`
    grid-area: name;
`;

const ButtonBack = styled(Button).attrs({ color: "background", round: false })`
    grid-area: back-button;
    width: 100%;
`;

const ButtonSave = styled(Button).attrs({ round: false })`
    grid-area: save-button;
    width: 100%;
`;

interface EditEquipmentModalProps {
    isOpen: boolean;
    equipment?: Equipment;
    onClose: () => void;
    onSave: (equipment: Equipment) => Promise<boolean>;
}

export const EditEquipmentModal: React.FC<EditEquipmentModalProps> = (
    props
) => {
    Modal.setAppElement("#root");

    const { control, formState, handleSubmit } = useForm();

    const { isSubmitting } = formState;

    // const [errorMessage, setErrorMessage] = React.useState("");

    const createEquipment = async (values: any) => {
        const newEquipment: Equipment = {
            ...props.equipment,
            name: values.name,
        };

        const result = await props.onSave(newEquipment);

        if (result) {
            props.onClose();
            // setErrorMessage("Erro ao criar o equipamento");
        }
    };

    return (
        <Modal
            style={customStyles}
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <ModalContent onSubmit={handleSubmit(createEquipment)}>
                <Title>Novo Equipamento</Title>
                <Controller
                    as={<NameField />}
                    rules={{ required: true }}
                    control={control}
                    defaultValue={props.equipment?.name ?? ""}
                    name="name"
                />
                {isSubmitting === false && (
                    <ButtonBack
                        loading={isSubmitting}
                        onClick={() => props.onClose()}
                    >
                        Cancelar
                    </ButtonBack>
                )}
                <ButtonSave loading={isSubmitting} type="submit">
                    Salvar
                </ButtonSave>
            </ModalContent>
        </Modal>
    );
};

import React from "react";
import styled from "styled-components";
import { UseFormMethods, Controller } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import { LoadValueField } from "src/components/structures/admin-edit-workout/workout-exercises/shared-fields/load-value";
import { MorrerRepeticao } from "src/entities/workout-series";
import { getDateTimeInSeconds } from "src/entities/workout-series/utils";

const FrameBase = styled.div`
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1vw;
    row-gap: 2vh;
    grid-template-areas:
        "start-reps increment-reps round-time max-rounds"
        "load-value load-value . .";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        column-gap: 2vw;
        row-gap: 2vh;
        grid-template-areas:
            "start-reps increment-reps"
            "round-time max-rounds"
            "load-value load-value";
    }
`;

const StartReps = styled(InputField).attrs({
    label: "Nº de Repetições Iniciais",
    type: "number",
    validations: { min: "0" },
})`
    grid-area: start-reps;
`;

const IncrementReps = styled(InputField).attrs({
    label: "Incremento por Rodada",
    type: "number",
})`
    grid-area: increment-reps;
`;

const RoundTime = styled(InputField).attrs({
    label: "Tempo da Rodada (s)",
    type: "number",
    validations: { min: "0" },
})`
    grid-area: round-time;
`;

const MaxRounds = styled(InputField).attrs({
    label: "Máximo de Séries",
    type: "number",
    validations: { min: "0" },
})`
    grid-area: max-rounds;
`;

interface FrameMorrerRepeticaoEditProps {
    workoutSeries?: MorrerRepeticao;
    control: UseFormMethods["control"];
    errors: UseFormMethods["errors"];
    watch: UseFormMethods["watch"];
}

export const FrameMorrerRepeticaoEdit: React.FC<FrameMorrerRepeticaoEditProps> = (
    props
) => {
    const getDefaultIncrementReps = () => {
        if (
            props.workoutSeries?.rounds &&
            props.workoutSeries.rounds.length >= 2
        ) {
            return (
                (props.workoutSeries.rounds[1].reps ?? 10) -
                (props.workoutSeries.rounds[0].reps ?? 5)
            );
        }

        return 5;
    };

    return (
        <FrameBase>
            <Controller
                as={
                    <StartReps
                        error={{
                            isInvalid: props.errors.startReps,
                        }}
                    />
                }
                rules={{ required: true }}
                control={props.control}
                defaultValue={props.workoutSeries?.rounds[0]?.reps ?? 20}
                name="startReps"
            />
            <Controller
                as={
                    <IncrementReps
                        error={{
                            isInvalid: props.errors.incrementReps,
                        }}
                    />
                }
                rules={{ required: true }}
                control={props.control}
                defaultValue={getDefaultIncrementReps()}
                name="incrementReps"
            />
            <Controller
                as={
                    <RoundTime
                        error={{
                            isInvalid: props.errors.roundTime,
                        }}
                    />
                }
                rules={{ required: true }}
                control={props.control}
                defaultValue={
                    props.workoutSeries?.rounds[0]?.roundTime
                        ? getDateTimeInSeconds(
                              props.workoutSeries?.rounds[0]?.roundTime
                          )
                        : 60
                }
                name="roundTime"
            />
            <Controller
                as={
                    <MaxRounds
                        error={{
                            isInvalid: props.errors.maxRounds,
                        }}
                    />
                }
                rules={{ required: true }}
                control={props.control}
                defaultValue={props.workoutSeries?.rounds.length ?? 5}
                name="maxRounds"
            />
            <LoadValueField
                errors={props.errors}
                control={props.control}
                watch={props.watch}
                loadType="carga_por_sexo"
                defaultValue={props.workoutSeries?.rounds[0]?.load}
            />
        </FrameBase>
    );
};

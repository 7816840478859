import React from "react";
import { Button } from "src/components/atoms/button";
import { Typography } from "src/components/atoms/typography";
import { theme } from "src/style/theme";
import styled from "styled-components";

const Section = styled.div`
    width: 100vw;
    padding: 0 10vw;

    display: grid;

    @media only screen and (max-width: 768px) {
        padding: 0;

        justify-content: center;
    }
`;

const Title = styled(Typography).attrs({ variant: "h1" })`
    text-transform: uppercase;
    text-shadow: 2px -2px ${theme.colors.primary.light};
    justify-self: flex-end;

    @media only screen and (max-width: 768px) {
        margin: 0 10vw;
        text-align: center;
        justify-self: center;
    }
`;

const Divider = styled.div`
    margin: 3vh 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.7);
    width: 40vw;
    justify-self: flex-end;

    @media only screen and (max-width: 768px) {
        width: 80%;
        justify-self: center;
    }
`;

const IconsRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2vw;
    margin: 10vh 0;
    z-index: 15;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;

        row-gap: 5vh;
    }
`;

const Icon = styled.div`
    padding: 0 5vw;

    display: grid;
    justify-items: center;
    row-gap: 5vh;

    @media only screen and (max-width: 768px) {
        row-gap: 2vh;
    }
`;

const IconDescription = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})`
    text-align: center;
`;

const SignupButton = styled(Button)`
    justify-self: center;
`;

interface SectionWorkoutsProps {
    openContactForm: () => void;
}

export const SectionWorkouts: React.FC<SectionWorkoutsProps> = (props) => {
    return (
        <Section>
            <Title>Treinos por perfil</Title>
            <Divider />
            <IconsRow>
                <Icon>
                    <img
                        alt="icon-people"
                        src="/assets/people_icon.png"
                        width="104"
                        height="104"
                    />
                    <IconDescription>
                        Planos focados inteiramente no corpo através de
                        mobimentos cardiorrespiratório, funcionais e de
                        fortalecimento. Serão cinco treinos mensais criativos e
                        sem monotonia. Conforme seu desempenho os exercícios são
                        adaptados para aumentar o estímulo de seu corpo.
                    </IconDescription>
                </Icon>
                <Icon>
                    <img
                        alt="icon-elderly"
                        src="/assets/elderly_icon.png"
                        width="104"
                        height="104"
                    />
                    <IconDescription>
                        Planos focados ao público que necessita de um cuidado
                        especial, com movimentos simples e funcionais, que
                        ajudarão na melhora do equilíbrio, dores musculares e
                        manutenção de força para manutenção da autonomia. São
                        cinco treinos mensais e adaptações conforme desempenho.
                    </IconDescription>
                </Icon>
                <Icon>
                    <img
                        alt="icon-paper"
                        src="/assets/paper_icon.png"
                        width="104"
                        height="104"
                    />
                    <IconDescription>
                        Planos focados para você que tem objetivos específicos.
                        Você define a sua meta e nós programamos seus treinos de
                        acordo com a sua necessidade e acompanhamos seu
                        desempenho.
                    </IconDescription>
                </Icon>
            </IconsRow>
            <SignupButton onClick={() => props.openContactForm()}>
                Quero Fazer parte!
            </SignupButton>
        </Section>
    );
};

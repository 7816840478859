import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { Typography } from "src/components/atoms/typography";
import { HeaderAdmin } from "src/components/structures/admin-layout/header-admin";
import { SideMenuAdmin } from "src/components/structures/admin-layout/side-menu-admin";
import styled from "styled-components";

const BaseStructure = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: white;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "header"
            "content";
    }
`;

const Title = styled(Typography).attrs({ variant: "h3" })``;

const Content = styled.div`
    display: grid;
    align-content: flex-start;
    padding: 10vh 5vw;
`;

interface AdminLayoutProps {
    title: string;
}

export const AdminLayout: React.FC<AdminLayoutProps> = (props) => {
    const isMobile = useMediaQuery("(max-width:768px)");

    return (
        <BaseStructure>
            {isMobile ? <HeaderAdmin /> : <SideMenuAdmin />}

            <Content>
                <Title>{props.title}</Title>
                {props.children}
            </Content>
        </BaseStructure>
    );
};

import React from "react";
import { Button } from "src/components/atoms/button";
import { CondoEquipmentModal } from "src/components/structures/admin-edit-condo/condo-equipment-modal";
import { EquipmentCard } from "src/components/structures/admin-equipments/equipment-card";
import { CondoEquipment, Equipment } from "src/entities/equipment";
import styled from "styled-components";

const FrameBase = styled.div`
    display: grid;
    align-content: flex-start;
`;

const Content = styled.div`
    margin: 5vh 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 2vh;
    column-gap: 2vw;
`;

const AddEquipmentButton = styled(Button).attrs({
    color: "background",
    round: false,
})``;

interface EquipmentsInfoFrameProps {
    condoEquipments: CondoEquipment[];
    availableEquipments: Equipment[];
    onCancel: () => void;
    onAddEquipment: (equipment: CondoEquipment) => Promise<boolean>;
    onDeleteEquipment: (equipment: CondoEquipment) => Promise<boolean>;
}

export const EquipmentsInfoFrame: React.FC<EquipmentsInfoFrameProps> = (
    props
) => {
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const [equipmentToEdit, setEquipmentToEdit] = React.useState<
        CondoEquipment | undefined
    >(undefined);

    const addEquipment = async (equipment: CondoEquipment) => {
        await props.onAddEquipment(equipment);
    };

    const removeEquipment = async (equipment: CondoEquipment) => {
        await props.onAddEquipment(equipment);
    };

    return (
        <FrameBase>
            <AddEquipmentButton onClick={() => setEditModalOpen(true)}>
                Adicionar Equipamento
            </AddEquipmentButton>
            <Content>
                {props.condoEquipments.map((equipment, index) => (
                    <EquipmentCard
                        key={index}
                        title={`${equipment.name} (${equipment.notes})`}
                        onEditClick={() => setEquipmentToEdit(equipment)}
                        onDeleteClick={() => removeEquipment(equipment)}
                    />
                ))}
            </Content>
            <CondoEquipmentModal
                isOpen={editModalOpen}
                equipment={equipmentToEdit}
                equipments={props.availableEquipments}
                onClose={() => setEditModalOpen(false)}
                onSave={addEquipment}
            />
        </FrameBase>
    );
};

import React from "react";
import styled from "styled-components";
import { UseFormMethods, Controller } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import { LoadTypeField } from "src/components/structures/admin-edit-workout/workout-exercises/shared-fields/load-type";
import { Typography } from "src/components/atoms/typography";
import { LoadValueField } from "src/components/structures/admin-edit-workout/workout-exercises/shared-fields/load-value";
import { LoadType, Tempo } from "src/entities/workout-series";

const FrameBase = styled.div`
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1vw;
    grid-template-areas:
        "reps-number load-type . ."
        "props-title props-title props-title props-title"
        "load-value load-value . .";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        row-gap: 2vh;
        column-gap: 2vw;
        grid-template-areas:
            "reps-number load-type"
            "props-title props-title"
            "load-value load-value";
    }
`;

const RepsNumber = styled(InputField).attrs({
    label: "Repetições",
    type: "number",
    validations: { min: "1" },
})`
    grid-area: reps-number;
`;

const PropsTitle = styled(Typography).attrs({
    variant: "h5",
})`
    grid-area: props-title;
    margin: 5vh 0 2vh;
    text-transform: uppercase;
    color: #4d4d4d;
`;

interface FrameTempoEditProps {
    workoutSeries?: Tempo;
    control: UseFormMethods["control"];
    errors: UseFormMethods["errors"];
    watch: UseFormMethods["watch"];
}

export const FrameTempoEdit: React.FC<FrameTempoEditProps> = (props) => {
    const loadType = React.useMemo<LoadType>(() => props.watch("loadType"), [
        props,
    ]);

    return (
        <FrameBase>
            <Controller
                as={
                    <RepsNumber
                        error={{
                            isInvalid: props.errors.reps,
                        }}
                    />
                }
                rules={{ required: true }}
                control={props.control}
                defaultValue={props.workoutSeries?.rounds[0]?.reps ?? 1}
                name="reps"
            />
            <LoadTypeField
                control={props.control}
                defaultValue={props.workoutSeries?.rounds[0]?.load}
            />
            {loadType !== "sem_carga_definida" && (
                <PropsTitle>PARÂMETROS DAS SÉRIES</PropsTitle>
            )}
            {loadType !== "sem_carga_definida" && (
                <LoadValueField
                    errors={props.errors}
                    control={props.control}
                    watch={props.watch}
                    loadType={loadType}
                    defaultValue={props.workoutSeries?.rounds[0]?.load}
                />
            )}
        </FrameBase>
    );
};

import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { AdminEditExerciseTemplate } from "src/components/templates/admin-edit-exercise-template";
import { Exercise } from "src/entities/exercise";
import {
    awsExerciseCreate,
    awsExerciseGet,
    awsExerciseUpdate,
} from "src/infrastructure/aws/aws-exercise";

export const AdminEditExercisePage: React.FC = () => {
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(true);
    const [exercise, setExercise] = React.useState<Exercise | undefined>(
        undefined
    );

    const exerciseId = queryString.parse(useLocation().search)?.id ?? "";

    const onCancel = () => {
        history.push("/admin/exercicios");
    };

    const onSave = async (exercise: Exercise): Promise<boolean> => {
        const response = exercise.id
            ? await awsExerciseUpdate(exercise.id, exercise)
            : await awsExerciseCreate(exercise);

        return Boolean(response);
    };

    React.useEffect(() => {
        const retrieveExerciseData = async () => {
            const exerciseQs = Array.isArray(exerciseId)
                ? exerciseId[0]
                : exerciseId;

            const newExercise = await awsExerciseGet(exerciseQs);

            setExercise(newExercise ?? undefined);
            setIsLoading(false);
        };

        if (!!exerciseId) {
            retrieveExerciseData();
        } else {
            setIsLoading(false);
        }
    }, [exerciseId]);

    return (
        <AdminEditExerciseTemplate
            isLoading={isLoading}
            exercise={exercise}
            onCancel={onCancel}
            onSave={onSave}
        />
    );
};

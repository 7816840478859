import React from "react";
import { useHistory } from "react-router-dom";
import { awsAuthSignOut } from "src/infrastructure/aws/aws-amplify";

export const LogoutPage: React.FC = () => {
    const history = useHistory();

    React.useEffect(() => {
        const lougout = async () => {
            await awsAuthSignOut();

            history.push("/login");
        };

        lougout();
    }, [history]);

    return <div />;
};

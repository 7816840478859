import { FetchClient } from "src/infrastructure/http-client/fetch-client";
import { Exercise } from "src/entities/exercise";

const fetchClient = new FetchClient();

export const awsExerciseCreate = async (
    exercise: Exercise
): Promise<Exercise | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/exercise`;

        const response = await fetchClient.post<Exercise>(url, {
            ...exercise,
        });

        return response;
    } catch (error) {
        console.log("error while creating exercise", error);

        return null;
    }
};

export const awsExerciseUpdate = async (
    exerciseCode: string,
    updateData: Partial<Exercise>
): Promise<Exercise | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/exercise?id=${exerciseCode}`;

        const response = await fetchClient.patch<Exercise>(url, {
            ...updateData,
        });

        return response;
    } catch (error) {
        console.log("error while updating exercise", error);

        return null;
    }
};

export const awsExerciseGet = async (
    exerciseCode: string
): Promise<Exercise | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/exercise?id=${exerciseCode}`;

        const response = await fetchClient.get<Exercise>(url);

        return response;
    } catch (error) {
        console.log("error while getting Exercise", error);

        return null;
    }
};

export const awsExerciseDelete = async (
    exerciseCode: string
): Promise<boolean> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/exercise?id=${exerciseCode}`;

        await fetchClient.delete(url);

        return true;
    } catch (error) {
        console.log("error while deleting Exercise", error);

        return false;
    }
};

export const awsExerciseGetAll = async (): Promise<Exercise[]> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/exercises`;

        const response = await fetchClient.get<Exercise[]>(url);

        return response ?? [];
    } catch (error) {
        console.log("error while getting exercises", error);

        return [];
    }
};

import React from "react";
import { Button } from "src/components/atoms/button";
import { Typography } from "src/components/atoms/typography";
import { theme } from "src/style/theme";
import styled from "styled-components";

const Section = styled.div`
    width: 100vw;
`;

const UpperBackground = styled.div`
    width: 100vw;
    height: 20vh;
    background: url("/assets/background_about-us-sup.svg");
    background-color: transparent;
    z-index: 10;
    margin-top: -80px;
    position: relative;
    padding-top: 80px;
`;

const InfBackground = styled.div`
    width: 100vw;
    height: 12vw;
    background: url("/assets/background_about-us-sup.svg");
    background-color: transparent;
    z-index: 10;
    transform: scaleY(-1);
    position: relative;

    @media only screen and (max-width: 768px) {
        height: 25vw;
        background-position-y: -5vw;
    }
`;

const Content = styled.div`
    padding: 0 ${theme.padding.horizontal.normal};
    display: grid;
    position: relative;

    @media only screen and (max-width: 768px) {
        justify-items: center;
        justify-content: center;
    }
`;

const Title = styled(Typography).attrs({ variant: "h1" })`
    text-transform: uppercase;
    text-shadow: 2px -2px ${theme.colors.primary.light};

    @media only screen and (max-width: 768px) {
        text-align: center;
    }
`;

const Divider = styled.div`
    margin: 3vh 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.7);
    width: 40vw;

    @media only screen and (max-width: 768px) {
        width: 90%;
    }
`;

const Description = styled(Typography).attrs({
    variant: "h4",
    fontWeight: "400",
})`
    margin: 10vh 0;
    text-align: center;

    strong {
        font-weight: 700;
    }

    @media only screen and (max-width: 768px) {
        margin: 2vh 0;
    }
`;

const IconsRow = styled.div`
    margin-bottom: 10vh;
    z-index: 15;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2vw;

    @media only screen and (max-width: 768px) {
        margin-top: 5vh;
        grid-template-columns: 1fr;
        row-gap: 5vh;
    }
`;

const Icon = styled.div`
    display: grid;
    justify-items: center;
    row-gap: 5vh;

    @media only screen and (max-width: 768px) {
        row-gap: 2vh;
    }
`;

const IconDescription = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})`
    text-align: center;
`;

const Presentation = styled.div`
    position: relative;
    width: 100vw;
    min-height: 40vw;
    z-index: 1;
    margin-top: -80px;

    background: url("/assets/background-about-us-photo.png");
    background-repeat: no-repeat;
    background-size: cover;

    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 3vw;
    align-content: center;
    align-items: center;

    ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: -1;
    }

    @media only screen and (max-width: 768px) {
        padding: 10vh 5vw;

        grid-template-columns: 1fr;

        text-align: center;
    }
`;

const PresentationIcon = styled.img.attrs({
    src: "/assets/mdi_ondemand_video.png",
})`
    width: 100%;
`;

const SignupButton = styled(Button)`
    justify-self: center;
    margin-bottom: 5vh;
`;

interface SectionAboutUsProps {
    openContactForm: () => void;
}

export const SectionAboutUs: React.FC<SectionAboutUsProps> = (props) => {
    return (
        <Section>
            <UpperBackground />
            <Content>
                <Title>O que é treinado?</Title>
                <Divider />
                <Description>
                    <strong>TREINADO</strong> é um serviço de prescrição de
                    treinamentos físicos voltados para academias de condomínios.
                    Nosso objetivo é proporcionar treinos dinâmicos, eficazes e
                    com segurança necessária para melhorar seu condicionamento
                    físico com a comodidade de treinar em casa.
                </Description>
                <IconsRow>
                    <Icon>
                        <img
                            alt="icon-home"
                            src="/assets/home_icon.png"
                            width="104"
                            height="104"
                        />
                        <IconDescription>
                            A academia do condômínio é avaliada e os treinos são
                            desenvolvidos com base no espaço e materiais
                            disponíveis
                        </IconDescription>
                    </Icon>
                    <Icon>
                        <img
                            alt="icon-halter"
                            src="/assets/halter_icon.png"
                            width="104"
                            height="104"
                        />
                        <IconDescription>
                            Cada condômino recebe um usuário e senha no qual tem
                            acesso aos treinos dinâmicos exclusivos
                            desenvolvidas pela nossa equipe.
                        </IconDescription>
                    </Icon>
                    <Icon>
                        <img
                            alt="icon-yoga"
                            src="/assets/yoga_icon.png"
                            width="104"
                            height="104"
                        />
                        <IconDescription>
                            Você alcança o condicionamento físico desejado,
                            melhora sua qualidade de vida com a praticidade sem
                            precisar sair de casa
                        </IconDescription>
                    </Icon>
                </IconsRow>
                <SignupButton onClick={() => props.openContactForm()}>
                    Quero Fazer parte do treinado
                </SignupButton>
            </Content>
            <InfBackground />
            <Presentation>
                <Typography color="white" variant="h5" fontWeight="400">
                    TREINADO promove exercícios dinâmicos que que conduzem ao
                    condicionamento físico, potencializando a força, velocidade,
                    agilidade, mobilidade e flexibilidade.
                    <br />
                    <br />
                    Os movimentos são demonstrados através de VÍDEOS
                    acompanhados de uma descrição detalhada dos pontos de
                    performance de cada exercício e podem ser acessados via
                    qualquer dispositivo com acesso a internet.
                </Typography>
                <PresentationIcon className="hide-mobile" />
            </Presentation>
            <UpperBackground />
        </Section>
    );
};

import React from "react";
import ScrollToTop from "src/router/scroll-top";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { LandingPage } from "src/components/pages/landing-page";
import { DesignSystemPage } from "src/components/pages/design-system-page";
import { LoginPage } from "src/components/pages/login-page";
import { SignupPage } from "src/components/pages/signup-page";
import { AdminHomePage } from "src/components/pages/admin-home-page";
import { AdminCustomersPage } from "src/components/pages/admin-customers-page";
import { AdminExercisesPage } from "src/components/pages/admin-exercises-page";
import { AdminWorkoutsPage } from "src/components/pages/admin-workouts-page";
import { AdminEquipmentsPage } from "src/components/pages/admin-equipments-page";
import { AdminSchedulePage } from "src/components/pages/admin-schedule-page";
import { ForgotPasswordPage } from "src/components/pages/forgot-password-page";
import { AdminCondosPage } from "src/components/pages/admin-condos-page";
import { AdminEditCondoPage } from "src/components/pages/admin-edit-condo-page";
import { AdminEditExercisePage } from "src/components/pages/admin-edit-exercise-page";
import { AdminEditWorkoutPage } from "src/components/pages/admin-edit-workout-page";
import { HomeWorkoutsPage } from "src/components/pages/home-workouts-page";
import { HomeProfilePage } from "src/components/pages/home-profile-page";
import { HomeWorkoutPage } from "src/components/pages/home-workout-page";
import { HomeWorkoutSeriesPage } from "src/components/pages/home-workout-series-page";
import { PrivateRoute } from "src/router/private-route";
import { LogoutPage } from "src/components/pages/logout-page";
import { AdminScheduledWorkoutPage } from "src/components/pages/admin-scheduled-workout";
import { AdminResultsPage } from "src/components/pages/admin-results-page";

const history = createBrowserHistory();

export function AppRouter() {
    return (
        <Router history={history}>
            <ScrollToTop />
            <Switch>
                <Route path="/design-system">
                    <DesignSystemPage />
                </Route>
                <Route path="/login">
                    <LoginPage />
                </Route>
                <Route path="/logout">
                    <LogoutPage />
                </Route>
                <Route path="/esqueci-senha">
                    <ForgotPasswordPage />
                </Route>
                <PrivateRoute
                    path="/home/treinos"
                    component={HomeWorkoutsPage}
                />
                <PrivateRoute
                    path="/home/treino/:treinoId/exercicio/:exercicioId"
                    component={HomeWorkoutSeriesPage}
                />
                <PrivateRoute
                    path="/home/treino/:treinoId/"
                    component={HomeWorkoutPage}
                />
                <PrivateRoute path="/home/perfil" component={HomeProfilePage} />
                <PrivateRoute path="/home" component={HomeWorkoutsPage} />
                <PrivateRoute
                    path="/admin/clientes"
                    component={AdminCustomersPage}
                />
                <PrivateRoute
                    path="/admin/condominios"
                    component={AdminCondosPage}
                />
                <PrivateRoute
                    path="/admin/condominio"
                    component={AdminEditCondoPage}
                />
                <PrivateRoute
                    path="/admin/exercicios"
                    component={AdminExercisesPage}
                />
                <PrivateRoute
                    path="/admin/exercicio"
                    component={AdminEditExercisePage}
                />
                <PrivateRoute
                    path="/admin/treinos"
                    component={AdminWorkoutsPage}
                />
                <PrivateRoute
                    path="/admin/treino"
                    component={AdminEditWorkoutPage}
                />
                <PrivateRoute
                    path="/admin/equipamentos"
                    component={AdminEquipmentsPage}
                />
                <PrivateRoute
                    path="/admin/agenda/agendamento"
                    component={AdminScheduledWorkoutPage}
                />
                <PrivateRoute
                    path="/admin/agenda"
                    component={AdminSchedulePage}
                />
                <PrivateRoute
                    path="/admin/resultados"
                    component={AdminResultsPage}
                />
                <PrivateRoute path="/admin" component={AdminHomePage} />
                <Route path="/cadastro">
                    <SignupPage />
                </Route>
                <Route path="*">
                    <LandingPage />
                </Route>
            </Switch>
        </Router>
    );
}

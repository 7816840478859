import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { Button } from "src/components/atoms/button";
import { InputField } from "src/components/molecules/input-field";
import { Typography } from "src/components/atoms/typography";
import { SignupBaseFrame } from "src/components/structures/signup/signup-base-frame";

const Title = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "400",
})`
    text-align: center;
    cursor: pointer;
    user-select: none;
`;

const CondoCodeField = styled(InputField).attrs({
    label: "Código do Condomínio",
    placeholder: "Código do Condomínio",
    box: { light: true },
    text: { align: "left" },
})`
    width: 100%;
`;

const ButtonContinue = styled(Button).attrs({ round: false })`
    justify-self: flex-end;
    width: 50%;
    margin-top: 2vh;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface SignupCondoCodeProps {
    goToNextPage: () => void;
    selectCondo: (code: string) => Promise<boolean>;
}

export const SignupCondoCode: React.FC<SignupCondoCodeProps> = (props) => {
    const { control, formState, handleSubmit, errors, setError } = useForm();
    const { isSubmitting } = formState;

    const onSubmit = async (values: any) => {
        const condo = await props.selectCondo(values.condoCode);

        if (!condo) {
            setError("condoCode", {
                type: "manual",
                message: "Código inválido. Por favor, entre em contato conosco",
            });
        } else {
            props.goToNextPage();
        }
    };

    return (
        <SignupBaseFrame onSubmit={handleSubmit(onSubmit)}>
            <Title>
                Olá! Vamos auxiliar você a se cadastrar no TREINADO!
                <br />
                Insira abaixo o código do condomínio fornecido
            </Title>
            <Controller
                as={
                    <CondoCodeField
                        error={{
                            isInvalid: !!errors.condoCode,
                            message: errors.condoCode?.message,
                        }}
                    />
                }
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="condoCode"
            />
            <ButtonContinue loading={isSubmitting} type="submit">
                Avançar
            </ButtonContinue>
        </SignupBaseFrame>
    );
};

import { FetchClient } from "src/infrastructure/http-client/fetch-client";
import { ContactEmail } from "src/entities/email";

const fetchClient = new FetchClient();

export const awsEmailContact = async (
    email: ContactEmail
): Promise<boolean> => {
    try {
        const uri = `${process.env.REACT_APP_TREINADO_API_HOST}/email`;

        const response = await fetchClient.post(uri, {
            ...email,
        });

        if (response === null) {
            return false;
        }

        return true;
    } catch (error) {
        console.log("error while sending email", error);
        return false;
    }
};

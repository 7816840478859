import { FetchClient } from "src/infrastructure/http-client/fetch-client";
import { Equipment } from "src/entities/equipment";

const fetchClient = new FetchClient();

export const awsEquipmentCreate = async (
    equipment: Equipment
): Promise<Equipment | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/equipment`;

        const response = await fetchClient.post<Equipment>(url, {
            ...equipment,
        });

        return response;
    } catch (error) {
        console.log("error while creating equipment", error);

        return null;
    }
};

export const awsEquipmentUpdate = async (
    equipmentCode: string,
    updateData: Partial<Equipment>
): Promise<Equipment | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/equipment?id=${equipmentCode}`;

        const response = await fetchClient.patch<Equipment>(url, {
            ...updateData,
        });

        return response;
    } catch (error) {
        console.log("error while updating equipment", error);

        return null;
    }
};

export const awsEquipmentGet = async (
    equipmentCode: string
): Promise<Equipment | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/equipment?id=${equipmentCode}`;

        const response = await fetchClient.get<Equipment>(url);

        return response;
    } catch (error) {
        console.log("error while getting Equipment", error);

        return null;
    }
};

export const awsEquipmentDelete = async (
    equipmentCode: string
): Promise<boolean> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/equipment?id=${equipmentCode}`;

        await fetchClient.delete(url);

        return true;
    } catch (error) {
        console.log("error while deleting Equipment", error);

        return false;
    }
};

export const awsEquipmentGetAll = async (): Promise<Equipment[]> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/equipments`;

        const response = await fetchClient.get<Equipment[]>(url);

        return response ?? [];
    } catch (error) {
        console.log("error while getting equipments", error);

        return [];
    }
};

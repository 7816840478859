import React from "react";
import { useHistory } from "react-router-dom";
import { HomeWorkoutsTemplate } from "src/components/templates/home-workouts";
import { ScheduledWorkout } from "src/entities/scheduled-workout";
import { awsAuthGetCurrentUser } from "src/infrastructure/aws/aws-amplify";
import { awsCondoGetAllByCustomer } from "src/infrastructure/aws/aws-condo";
import { awsScheduledWorkoutQuery } from "src/infrastructure/aws/aws-scheduled-workout";

export const HomeWorkoutsPage: React.FC = () => {
    const history = useHistory();

    const getScheduledWorkouts = async (
        scheduledDate: Date
    ): Promise<ScheduledWorkout[]> => {
        const currentUser = await awsAuthGetCurrentUser();

        if (!currentUser) {
            console.log("Não há usuário logado");
            return [];
        }

        const condos = await awsCondoGetAllByCustomer(currentUser);

        if (condos.length === 0) {
            console.log("Não há condomínio para o usuário logado");
            return [];
        }

        return await awsScheduledWorkoutQuery(
            { startDate: scheduledDate },
            condos[0].id
        );
    };

    const editScheduledWorkout = async (scheduledWorkout: ScheduledWorkout) => {
        history.push(`/home/treino/${scheduledWorkout.id}`);
    };

    return (
        <HomeWorkoutsTemplate
            getScheduledWorkouts={getScheduledWorkouts}
            redirectScheduledWorkout={editScheduledWorkout}
        />
    );
};

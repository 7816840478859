import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { HomeLayout } from "src/components/templates/layout/home-layout";
import { Customer } from "src/entities/customer";
import { Typography } from "src/components/atoms/typography";
import { InputField } from "src/components/molecules/input-field";
import { Button } from "src/components/atoms/button";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import { Condo } from "src/entities/condo";
import dayjs from "dayjs";

const FormArea = styled.form`
    margin: 2vh 0;

    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "name name document"
        "email email dob"
        "condo condo apartment"
        ". . ."
        "old-password new-password ."
        ". buttons buttons";
    column-gap: 2vw;
    row-gap: 2vh;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "name"
            "document"
            "email"
            "dob"
            "condo"
            "apartment"
            "old-password"
            "new-password"
            "buttons";
    }
`;

const Title = styled(Typography).attrs({ variant: "h3" })``;

const NameField = styled(InputField).attrs({
    label: "Nome",
    text: { align: "left" },
    disabled: true,
})`
    grid-area: name;
`;

const DocumentField = styled(InputField).attrs({
    label: "CPF/CNPJ",
    text: { align: "left" },
    disabled: true,
})`
    grid-area: document;
`;

const EmailField = styled(InputField).attrs({
    label: "E-mail",
    text: { align: "left" },
    disabled: true,
})`
    grid-area: email;
`;

const DobField = styled(InputField).attrs({
    label: "Data de Nascimento",
    text: { align: "left" },
    disabled: true,
})`
    grid-area: dob;
`;

const CondoField = styled(InputField).attrs({
    label: "Condomínio",
    text: { align: "left" },
    disabled: true,
})`
    grid-area: condo;
`;

const ApartmentField = styled(InputField).attrs({
    label: "Apartamento",
    text: { align: "left" },
    disabled: true,
})`
    grid-area: apartment;
`;

const OldPasswordField = styled(InputField).attrs({
    label: "Senha antiga",
    text: { align: "left" },
    type: "password",
})`
    grid-area: old-password;
`;

const NewPasswordField = styled(InputField).attrs({
    label: "Nova senha",
    text: { align: "left" },
    type: "password",
})`
    grid-area: new-password;
`;

const ButtonsArea = styled.div`
    grid-area: buttons;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-end;
    column-gap: 2vw;
`;

const ButtonCancel = styled(Button).attrs({
    color: "background",
    round: false,
})``;

const ButtonNext = styled(Button).attrs({ round: false })``;

interface HomeProfileTemplateProps {
    isLoading: boolean;
    customer?: Customer;
    customerCondos: Condo[];
    onCancel: () => void;
    updatePassword: (
        oldPassword: string,
        newPassword: string
    ) => Promise<boolean>;
}

export const HomeProfileTemplate: React.FC<HomeProfileTemplateProps> = (
    props
) => {
    const apartmentZone = React.useMemo(() => {
        const apartmentStrings = [props.customer?.apartment];

        if (!!props.customer?.condoZone) {
            apartmentStrings.push(props.customer?.condoZone);
        }

        return apartmentStrings.join(" / ");
    }, [props.customer]);

    const { control, formState, handleSubmit } = useForm();

    const { isSubmitting } = formState;

    const onSubmit = async (values: any) => {
        props.updatePassword(values.oldPassword, values.newPassword);
    };

    return (
        <HomeLayout>
            <Title>Informações do Usuário</Title>
            {props.isLoading ? (
                <LoadingPage />
            ) : (
                <FormArea onSubmit={handleSubmit(onSubmit)}>
                    <NameField value={props.customer?.name} />
                    <DocumentField value={props.customer?.document} />
                    <EmailField value={props.customer?.email} />
                    <DobField
                        value={dayjs(props.customer?.birthDate)
                            .utc()
                            .format("DD/MM/YYYY")}
                    />
                    <CondoField
                        value={props.customerCondos
                            .map((condo) => condo.name)
                            .join(", ")}
                    />
                    <ApartmentField value={apartmentZone} />
                    <Controller
                        as={<OldPasswordField />}
                        rules={{ required: true }}
                        control={control}
                        defaultValue={""}
                        name="oldPassword"
                    />
                    <Controller
                        as={<NewPasswordField />}
                        rules={{ required: true }}
                        control={control}
                        defaultValue={""}
                        name="newPassword"
                    />
                    <ButtonsArea>
                        {isSubmitting === false && (
                            <ButtonCancel onClick={() => props.onCancel()}>
                                Retornar
                            </ButtonCancel>
                        )}
                        <ButtonNext type="submit" loading={isSubmitting}>
                            Salvar
                        </ButtonNext>
                    </ButtonsArea>
                </FormArea>
            )}
        </HomeLayout>
    );
};

import React, { CSSProperties } from "react";
import { Typography } from "src/components/atoms/typography";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import { theme } from "src/style/theme";
import styled from "styled-components";

const CardBase = styled.div`
    display: grid;
    padding: 3vh 3vw;
    align-items: center;
    justify-items: center;
    justify-content: center;
    max-width: 300px;
    border: 1px solid ${theme.colors.background.dark};
    border-radius: 8px;
`;

const CardTitle = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})``;

const CardValue = styled(Typography).attrs({
    variant: "h2",
})``;

interface ResultDisplayProps {
    isLoading: boolean;
    title: string;
    value: string;
    style: CSSProperties;
}

export const ResultDisplay: React.FC<ResultDisplayProps> = (props) => {
    return (
        <CardBase style={props.style}>
            <CardTitle>{props.title}</CardTitle>
            {props.isLoading ? (
                <LoadingPage size="sm" />
            ) : (
                <CardValue>{props.value}</CardValue>
            )}
        </CardBase>
    );
};

import React from "react";
import { WorkoutScheduleDay } from "src/components/structures/shared/workout-schedule-day";
import { HomeLayout } from "src/components/templates/layout/home-layout";
import { ScheduledWorkout } from "src/entities/scheduled-workout";

interface HomeWorkoutsTemplateProps {
    redirectScheduledWorkout: (scheduledWorkout: ScheduledWorkout) => void;
    getScheduledWorkouts: (scheduledDate: Date) => Promise<ScheduledWorkout[]>;
}

export const HomeWorkoutsTemplate: React.FC<HomeWorkoutsTemplateProps> = (
    props
) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [scheduledWorkouts, setScheduledWorkouts] = React.useState<
        ScheduledWorkout[]
    >([]);

    const getScheduledWorkouts = async (scheduledDate: Date) => {
        setIsLoading(true);

        const result = await props.getScheduledWorkouts(scheduledDate);

        setScheduledWorkouts(result);
        setIsLoading(false);
    };

    return (
        <HomeLayout>
            <WorkoutScheduleDay
                onClick={props.redirectScheduledWorkout}
                reloadScheduledWorkouts={getScheduledWorkouts}
                isLoading={isLoading}
                scheduledWorkouts={scheduledWorkouts}
            />
        </HomeLayout>
    );
};

import React from "react";
import styled from "styled-components";
import { theme } from "src/style/theme";
import { Typography } from "src/components/atoms/typography";
import { Button } from "src/components/atoms/button";
import { InputField } from "src/components/molecules/input-field";

const TemplateBase = styled.div`
    padding: 5vh 5vw;
    width: 100vw;
    height: 100vh;
`;

const Section = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 2vh;
    margin-bottom: 5vh;
`;

const ColorRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    column-gap: 1vw;
    justify-content: flex-start;
    align-items: center;
`;

const TypographySection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

const TypographyColumn = styled.div``;

const ButtonRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    column-gap: 1vw;
    justify-content: flex-start;
`;

const InputRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    column-gap: 1vw;
    justify-content: flex-start;
`;

interface ColorBoxProps {
    color: string;
}

const ColorBox = styled.div<ColorBoxProps>`
    width: min(8vh, 8vw);
    height: min(8vh, 8vw);
    background-color: ${(props) => props.color};
    border-radius: 5px;
    margin-right: 1vw;
`;

interface DesignSystemTemplateProps {}

export const DesignSystemTemplate: React.FC<DesignSystemTemplateProps> = () => {
    return (
        <TemplateBase>
            <Section>
                <Typography>Cores</Typography>
                <ColorRow>
                    <Typography variant="h3" fontWeight="400">
                        Primárias
                    </Typography>
                    <ColorBox color={theme.colors.primary.light} />
                    <ColorBox color={theme.colors.primary.dark} />
                </ColorRow>
                <ColorRow>
                    <Typography variant="h3" fontWeight="400">
                        Secundárias
                    </Typography>
                    <ColorBox color={theme.colors.secondary.light} />
                    <ColorBox color={theme.colors.secondary.dark} />
                </ColorRow>
                <ColorRow>
                    <Typography variant="h3" fontWeight="400">
                        Background
                    </Typography>
                    <ColorBox color={theme.colors.background.light} />
                    <ColorBox color={theme.colors.background.dark} />
                </ColorRow>
            </Section>
            <Section>
                <Typography>Tipografia</Typography>
                <TypographySection>
                    <TypographyColumn>
                        <Typography fontWeight="300">Raleway Light</Typography>
                        <Typography fontWeight="400">
                            Raleway Regular
                        </Typography>
                        <Typography fontWeight="700">Raleway Bold</Typography>
                        <Typography fontWeight="800">
                            Raleway ExtraBold
                        </Typography>
                    </TypographyColumn>
                    <TypographyColumn>
                        <Typography fontWeight="400" variant="h5">
                            15 px Typografia
                        </Typography>
                        <Typography fontWeight="400" variant="h4">
                            20 px Typografia
                        </Typography>
                        <Typography fontWeight="400" variant="h3">
                            25 px Typografia
                        </Typography>
                        <Typography fontWeight="400" variant="h2">
                            30 px Typografia
                        </Typography>
                        <Typography fontWeight="400" variant="h1">
                            35 px Typografia
                        </Typography>
                    </TypographyColumn>
                </TypographySection>
            </Section>
            <Section>
                <Typography>Botões</Typography>
                <ButtonRow>
                    <Button color="primary">Texto</Button>
                    <Button color="secondary">Texto</Button>
                    <Button>Texto</Button>
                </ButtonRow>
                <ButtonRow>
                    <Button color="primary" round={false}>
                        Texto
                    </Button>
                    <Button color="secondary" round={false}>
                        Texto
                    </Button>
                    <Button round={false}>Texto</Button>
                </ButtonRow>
            </Section>
            <Section>
                <Typography>Inputs</Typography>
                <InputRow>
                    <InputField label="teste-input-str" />
                    <InputField label="teste-input-num" type="number" />
                </InputRow>
            </Section>
        </TemplateBase>
    );
};

import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { ScheduledWorkout } from "src/entities/scheduled-workout";
import { ScheduledCard } from "src/components/structures/shared/scheduled-card";
import { LoadingPage } from "src/components/structures/shared/loading-page";

dayjs.locale("pt-br");
dayjs.extend(LocalizedFormat);

const BaseScheduledDay = styled.div`
    display: grid;
    row-gap: 2vh;

    @media only screen and (min-width: 768px) {
        row-gap: 1vh;
    }
`;

interface DateSelectorProps {
    disableDateSelect: boolean;
}

const DateSelectorArea = styled.div<DateSelectorProps>`
    padding: ${(props) => (props.disableDateSelect ? "2vh 0" : "3vh 5vw")};
    background-color: #2b2b2c;
    color: #fff;
    margin-bottom: 1vh;

    display: grid;
    grid-template-columns: ${(props) =>
        props.disableDateSelect ? "1fr" : "auto 1fr auto"};
    justify-items: center;
    align-content: center;
    align-items: center;

    @media only screen and (max-width: 768px) {
        margin-bottom: 0;
    }
`;

const DateSelectorIcon = styled.label`
    font-size: 1.5rem;

    cursor: pointer;
`;

const DateSelectorValue = styled.label`
    text-align: center;
    font-size: 0.8rem;
    font-weight: bold;

    @media only screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const WorkoutArea = styled.div`
    width: 100%;
    display: grid;
    row-gap: 2vh;
`;

interface WorkoutScheduleDayProps {
    isLoading: boolean;
    scheduledWorkouts: ScheduledWorkout[];
    selectedDate?: Date;
    reloadScheduledWorkouts?: (date: Date) => void;
    onClick: (scheduledWorkout: ScheduledWorkout) => void;
}

export const WorkoutScheduleDay: React.FC<WorkoutScheduleDayProps> = (
    props
) => {
    const [sortedItems, setSortedItems] = React.useState<ScheduledWorkout[]>(
        []
    );
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    React.useEffect(() => {
        const availableWorkouts = [...props.scheduledWorkouts];

        availableWorkouts.sort((a, b) => {
            if (a.createdAt && b.createdAt) {
                return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                );
            }

            return 0;
        });

        setSortedItems(availableWorkouts);
    }, [props.scheduledWorkouts]);

    React.useEffect(() => {
        setSelectedDate(props.selectedDate ?? new Date());
    }, [props.selectedDate]);

    React.useEffect(() => {
        const retrieveScheduledWorkouts = async () => {
            await props.reloadScheduledWorkouts?.(selectedDate);
        };

        retrieveScheduledWorkouts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    const updateDate = (incrementDays: number) => {
        const newDate = dayjs(selectedDate).add(incrementDays, "d");

        setSelectedDate(newDate.toDate());
    };

    const showSelectDateIcons = () => {
        return Boolean(props.reloadScheduledWorkouts);
    };

    const getSchedulesWorkoutCards = () => {
        return props.scheduledWorkouts.length === 0 ? (
            <ScheduledCard />
        ) : (
            sortedItems.map((scheduledWorkout, index) => (
                <ScheduledCard
                    onClick={props.onClick}
                    scheduledWorkout={scheduledWorkout}
                    key={index}
                />
            ))
        );
    };

    return (
        <BaseScheduledDay>
            <DateSelectorArea
                disableDateSelect={showSelectDateIcons() === false}
            >
                {showSelectDateIcons() && (
                    <DateSelectorIcon onClick={() => updateDate(-1)}>
                        {"<"}
                    </DateSelectorIcon>
                )}
                <DateSelectorValue>
                    {dayjs(selectedDate).format("dddd")}
                    <br />
                    {dayjs(selectedDate).format("L")}
                </DateSelectorValue>
                {showSelectDateIcons() && (
                    <DateSelectorIcon onClick={() => updateDate(1)}>
                        {">"}
                    </DateSelectorIcon>
                )}
            </DateSelectorArea>
            <WorkoutArea>
                {props.isLoading ? <LoadingPage /> : getSchedulesWorkoutCards()}
            </WorkoutArea>
        </BaseScheduledDay>
    );
};

import { FetchClient } from "src/infrastructure/http-client/fetch-client";
import { DashboardData } from "../../entities/dashboard-data";

const fetchClient = new FetchClient();

export const awsDashboardGet = async (): Promise<DashboardData | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/dashboard`;

        const response = await fetchClient.get<DashboardData>(url);

        return response;
    } catch (error) {
        console.log("error while getting Dashboard data", error);

        return null;
    }
};

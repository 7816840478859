import React from "react";
import styled from "styled-components";
import { Typography } from "src/components/atoms/typography";
import { Button } from "src/components/atoms/button";
import { theme } from "src/style/theme";
import { WorkoutSeriesCard } from "src/components/structures/admin-edit-workout/workout-exercises/workout-series-card";
import { Exercise } from "src/entities/exercise";
import { ModalNewSingleExercise } from "src/components/structures/admin-edit-workout/workout-exercises/modal-new-single-exercise";
import { ModalNewMultipleExercises } from "src/components/structures/admin-edit-workout/workout-exercises/modal-new-multiple-exercises";
import {
    WorkoutMultipleSeries,
    WorkoutMultipleSeriesType,
    WorkoutSeries,
    WorkoutSingleSeries,
    WorkoutSingleSeriesType,
} from "src/entities/workout-series";

const Content = styled.div`
    display: grid;
    row-gap: 3vh;
    column-gap: 2vw;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "title title"
        "exercises exercises"
        "one-movement multiple-movements"
        ". buttons";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "title"
            "exercises"
            "one-movement"
            "multiple-movements"
            "buttons";
    }
`;

const Title = styled(Typography).attrs({ variant: "h3" })`
    grid-area: title;
`;

const AddMovementButton = styled.div`
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 2vh;

    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.3px;

    color: rgba(0, 0, 0, 0.5);

    display: grid;
    row-gap: 2vh;
    justify-items: center;

    cursor: pointer;
`;

const AddOneMovementButton = styled(AddMovementButton)`
    grid-area: one-movement;
`;

const AddMultipleMovementButton = styled(AddMovementButton)`
    grid-area: multiple-movements;
`;

interface AudienceButton {
    backgroundColor: string;
}

const AudienceButton = styled.div<AudienceButton>`
    padding: 10vh 2vw;
    background: ${theme.colors.background.dark};
    border-radius: 22px;

    display: grid;
    align-content: center;
    justify-items: center;

    font-weight: 700;

    &.selected {
        background-color: ${theme.colors.primary.light};
    }
`;

const ButtonsArea = styled.div`
    grid-area: buttons;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    justify-content: flex-end;
    column-gap: 2vw;

    @media only screen and (max-width: 768px) {
        justify-content: stretch;
    }
`;

const ButtonCancel = styled(Button).attrs({
    color: "background",
    round: false,
})`
    width: 100%;
`;

const ButtonNext = styled(Button).attrs({ round: false })`
    width: 100%;
`;

const PlusIcon = styled.img.attrs({ src: "/assets/mdi_sum.png" })`
    width: 50px;
    height: 50px;
`;

const ExerciseArea = styled.div`
    grid-area: exercises;

    display: grid;
    row-gap: 2vh;
`;

interface WorkoutExercisesProps {
    workoutSeries: WorkoutSeries[];
    availableExercises: Exercise[];
    singleWorkoutTypes: WorkoutSingleSeriesType[];
    multipleWorkoutTypes: WorkoutMultipleSeriesType[];
    onCancel: () => void;
    goToNextFrame?: () => void;
    addWorkoutSeries: (workoutSeries: WorkoutSeries) => Promise<boolean>;
    removeWorkoutSeries: (workoutSeries: WorkoutSeries) => Promise<boolean>;
}

export const WorkoutExercises: React.FC<WorkoutExercisesProps> = (props) => {
    const [
        workoutSingleSeriesToEdit,
        setWorkoutSingleSeriesToEdit,
    ] = React.useState<WorkoutSingleSeries | undefined>(undefined);
    const [
        workoutMultipleSeriesToEdit,
        setWorkoutMultipleSeriesToEdit,
    ] = React.useState<WorkoutMultipleSeries | undefined>(undefined);
    const [
        modalAddSingleExerciseOpen,
        setModalAddSingleExerciseOpen,
    ] = React.useState(false);
    const [
        modalAddMultipleExerciseOpen,
        setModalAddMultipleExerciseOpen,
    ] = React.useState(false);

    const openModal = (modalType: "single" | "multiple") => {
        modalType === "single"
            ? setModalAddSingleExerciseOpen(true)
            : setModalAddMultipleExerciseOpen(true);
    };

    const closeModal = () => {
        setModalAddSingleExerciseOpen(false);
        setModalAddMultipleExerciseOpen(false);
        setWorkoutSingleSeriesToEdit(undefined);
        setWorkoutMultipleSeriesToEdit(undefined);
    };

    const editWorkoutSeries = (workoutSeries: WorkoutSeries) => {
        workoutSeries.style === "single"
            ? setWorkoutSingleSeriesToEdit(workoutSeries)
            : setWorkoutMultipleSeriesToEdit(workoutSeries);

        openModal(workoutSeries.style);
    };

    return (
        <Content>
            <Title>Exercícios</Title>
            <ExerciseArea>
                {props.workoutSeries.map((workoutSeries, index) => (
                    <WorkoutSeriesCard
                        key={index}
                        workoutSeries={workoutSeries}
                        onErase={() => {
                            props.removeWorkoutSeries(workoutSeries);
                        }}
                        onEdit={() => editWorkoutSeries(workoutSeries)}
                        onDuplicate={() =>
                            props.addWorkoutSeries({
                                ...workoutSeries,
                                id: undefined,
                            })
                        }
                    />
                ))}
            </ExerciseArea>
            <AddOneMovementButton onClick={() => openModal("single")}>
                <PlusIcon />
                UM ÚNICO MOVIMENTO
            </AddOneMovementButton>
            <AddMultipleMovementButton onClick={() => openModal("multiple")}>
                <PlusIcon />
                MÚLTIPLOS MOVIMENTOS
            </AddMultipleMovementButton>
            <ButtonsArea>
                <ButtonCancel onClick={() => props.onCancel()}>
                    Voltar
                </ButtonCancel>
                {props.goToNextFrame && (
                    <ButtonNext onClick={() => props.goToNextFrame?.()}>
                        Avançar
                    </ButtonNext>
                )}
            </ButtonsArea>
            <ModalNewSingleExercise
                workoutSeries={workoutSingleSeriesToEdit}
                availableExercises={props.availableExercises}
                workoutTypes={props.singleWorkoutTypes}
                isOpen={modalAddSingleExerciseOpen}
                onClose={closeModal}
                onSave={props.addWorkoutSeries}
            />
            <ModalNewMultipleExercises
                workoutSeries={
                    workoutMultipleSeriesToEdit as WorkoutMultipleSeries
                }
                availableExercises={props.availableExercises}
                workoutTypes={props.multipleWorkoutTypes}
                isOpen={modalAddMultipleExerciseOpen}
                onClose={closeModal}
                onSave={props.addWorkoutSeries}
            />
        </Content>
    );
};

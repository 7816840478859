import React from "react";
import DayPicker, { DayModifiers } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/pt-br";

import "react-day-picker/lib/style.css";
import "../../style/calendar.css";
import dayjs from "dayjs";

interface CalendarProps {
    className?: string;
    fetchSelectedDays?: (selectedDays: Date[]) => void;
}

export const Calendar: React.FC<CalendarProps> = (props) => {
    const [selectedDays, setSelectedDays] = React.useState<Date[]>([]);

    const handleDayClick = (day: Date, { selected }: DayModifiers) => {
        const updatedDays = [...selectedDays];

        if (selected) {
            const selectedIndex = selectedDays.findIndex((selectedDay) =>
                dayjs(selectedDay).isSame(day)
            );
            updatedDays.splice(selectedIndex, 1);
        } else {
            updatedDays.push(day);
        }

        setSelectedDays(updatedDays);
        props.fetchSelectedDays?.(updatedDays);
    };

    return (
        <DayPicker
            className={props.className}
            onDayClick={handleDayClick}
            selectedDays={selectedDays}
            localeUtils={MomentLocaleUtils}
            locale="pt-br"
        />
    );
};

import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { HomeWorkoutTemplate } from "src/components/templates/home-workout";
import { Workout } from "src/entities/workout";
import { WorkoutResult } from "src/entities/workout-result";
import { awsAuthGetCurrentUser } from "src/infrastructure/aws/aws-amplify";
import { awsScheduledWorkoutGet } from "src/infrastructure/aws/aws-scheduled-workout";

interface RouteParams {
    treinoId: string;
}

export const HomeWorkoutPage: React.FC = (props) => {
    const history = useHistory();
    const params = useParams<RouteParams>();

    const [isLoading, setIsLoading] = React.useState(true);
    const [workout, setWorkout] = React.useState<Workout>();
    const [result, setResult] = React.useState<WorkoutResult | undefined>();

    React.useEffect(() => {
        const retrieveScheduledWorkout = async () => {
            const currentUser = await awsAuthGetCurrentUser();

            if (!currentUser) {
                console.log("Não há usuário logado");
                return [];
            }

            const newScheduledWorkout = await awsScheduledWorkoutGet(
                params.treinoId
            );

            if (!newScheduledWorkout) {
                history.push("/home");
                return;
            }

            const availableWorkoutSeries = newScheduledWorkout.series;

            availableWorkoutSeries.sort((a, b) => {
                if (a.createdAt && b.createdAt) {
                    return (
                        new Date(a.createdAt).getTime() -
                        new Date(b.createdAt).getTime()
                    );
                }

                return 0;
            });

            const availableResults = newScheduledWorkout.results;

            const customerResults = availableResults?.find(
                (result) => result.customer.document === currentUser.document
            );

            setResult(customerResults);

            setWorkout({
                ...newScheduledWorkout,
                series: availableWorkoutSeries,
            });
            setIsLoading(false);
        };

        if (params.treinoId) {
            retrieveScheduledWorkout();
        }
    }, [history, params.treinoId]);

    const goToWorkoutSeries = (index: number) => {
        if (!workout) {
            return;
        }

        const workoutSeries = workout.series;

        history.push(
            `/home/treino/${params.treinoId}/exercicio/${workoutSeries[index].id}`
        );
    };

    const goBackToWorkouts = () => {
        history.push(`/home/treinos`);
    };

    return (
        <HomeWorkoutTemplate
            isLoading={isLoading}
            workout={workout}
            result={result}
            goToWorkoutSeries={goToWorkoutSeries}
            goBackToWorkouts={goBackToWorkouts}
        />
    );
};

import React from "react";
import styled from "styled-components";
import {
    Table,
    TableAction,
    TableHeader,
} from "src/components/structures/shared/table";
import { AdminLayout } from "src/components/templates/layout/admin-layout";
import { Exercise } from "src/entities/exercise";
import { Button } from "src/components/atoms/button";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import { useMediaQuery } from "@material-ui/core";

const Content = styled.div`
    padding: 5vh 0;
    display: grid;
    row-gap: 2vh;
`;

const NewButton = styled(Button).attrs({
    round: false,
})`
    justify-self: flex-end;
`;

interface AdminExercisesTemplateProps {
    isLoading: boolean;
    exercises: Exercise[];
    addNewExercise: () => void;
    editExercise: (exercise?: Exercise) => void;
    deleteExercise: (exercise: Exercise) => void;
}

export const AdminExercisesTemplate: React.FC<AdminExercisesTemplateProps> = (
    props
) => {
    const isMobile = useMediaQuery("(max-width:768px)");

    const tableHeadersMobile: TableHeader<Exercise>[] = [
        {
            title: "Nome",
            field: "name",
        },
    ];

    const tableHeaders: TableHeader<Exercise>[] = [
        {
            title: "Nome",
            field: "name",
        },
        {
            title: "Url do Youtube",
            field: "videoUrl",
        },
    ];

    const tableActions: TableAction<Exercise>[] = [
        {
            icon: "delete",
            tooltip: "Apagar",
            onClick: (exercise) => props.deleteExercise(exercise),
        },
    ];

    return (
        <AdminLayout title="Gerenciamento de Exercícios">
            {props.isLoading ? (
                <LoadingPage />
            ) : (
                <Content>
                    <Table
                        data={props.exercises}
                        header={isMobile ? tableHeadersMobile : tableHeaders}
                        onClick={props.editExercise}
                        actions={tableActions}
                    />
                    <NewButton onClick={() => props.addNewExercise()}>
                        Novo Exercício
                    </NewButton>
                </Content>
            )}
        </AdminLayout>
    );
};

import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { Button } from "src/components/atoms/button";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import {
    Table,
    TableAction,
    TableHeader,
} from "src/components/structures/shared/table";
import { AdminLayout } from "src/components/templates/layout/admin-layout";
import { Workout } from "src/entities/workout";
import { getWorkoutTypeFormated } from "src/entities/workout-series/utils";
import styled from "styled-components";

const Content = styled.div`
    padding: 5vh 0;
    display: grid;
    row-gap: 2vh;
`;

const NewButton = styled(Button).attrs({
    round: false,
})`
    justify-self: flex-end;
`;

interface AdminWorkoutsTemplateProps {
    isLoading: boolean;
    workouts: Workout[];
    addNewWorkout: () => void;
    editWorkout: (workout?: Workout) => void;
    deleteWorkout: (workout: Workout) => void;
}

export const AdminWorkoutsTemplate: React.FC<AdminWorkoutsTemplateProps> = (
    props
) => {
    const isMobile = useMediaQuery("(max-width:768px)");

    const tableHeadersMobile: TableHeader<Workout>[] = [
        {
            title: "Nome do Treino",
            field: "name",
        },
    ];

    const tableHeaders: TableHeader<Workout>[] = [
        {
            title: "Nome do Treino",
            field: "name",
        },
        {
            title: "Tipo",
            render: (workout) => getWorkoutTypeFormated(workout.type),
        },
    ];

    const tableActions: TableAction<Workout>[] = [
        {
            icon: "delete",
            tooltip: "Apagar",
            onClick: (workout) => props.deleteWorkout(workout),
        },
    ];

    return (
        <AdminLayout title="Gerenciamento de Treinos">
            {props.isLoading ? (
                <LoadingPage />
            ) : (
                <Content>
                    <Table
                        data={props.workouts}
                        header={isMobile ? tableHeadersMobile : tableHeaders}
                        actions={tableActions}
                        onClick={props.editWorkout}
                    />
                    <NewButton onClick={() => props.addNewWorkout()}>
                        Novo Treino
                    </NewButton>
                </Content>
            )}
        </AdminLayout>
    );
};

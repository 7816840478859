import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { times } from "lodash";
import { UseFormMethods, Controller } from "react-hook-form";
import { ExerciseBaseCard } from "src/components/structures/home-workout-series/exercise-base-card";
import { Divider } from "src/components/structures/home-workout-series/divider";
import { ExerciseResultsBase } from "src/components/structures/home-workout-series/exercise-results-base";
import { InputField } from "src/components/molecules/input-field";
import { getWorkoutExerciseDescription } from "src/entities/workout-series/utils";
import { Exercise } from "src/entities/exercise";
import { IntervaladoMultiplo } from "src/entities/workout-series";
import { Typography } from "src/components/atoms/typography";

const BaseSection = styled.div`
    display: grid;
    row-gap: 3vh;
`;

const ExerciseResult = styled(InputField).attrs({
    type: "number",
    validations: { min: "0" },
})`
    width: 100%;
`;

const ExerciseTotalResult = styled(InputField).attrs({
    label: "Total",
    type: "number",
    disabled: true,
    validations: { min: "0" },
})`
    width: 100%;
`;

const SectionArea = styled.div`
    display: grid;
    row-gap: 1vh;
`;

const ExerciseArea = styled.div`
    display: grid;
    row-gap: 1vh;
`;

const ExerciseResultArea = styled.div`
    margin-bottom: 2vh;

    display: grid;
    row-gap: 2vh;
    column-gap: 2vw;
    grid-template-columns: 1fr 1fr;
`;

const SectionTitle = styled(Typography).attrs({ variant: "h5" })``;

interface FrameIntervaladoMultiploProps {
    workoutSeries: IntervaladoMultiplo;
    control: UseFormMethods["control"];
    watch: UseFormMethods["watch"];
    exhibitExercise: (exercise: Exercise) => void;
}

export const FrameIntervaladoMultiplo: React.FC<FrameIntervaladoMultiploProps> = (
    props
) => {
    const section = props.workoutSeries.sections[0];

    const getTotalValue = (exerciseIndex: number) => {
        let totalValue = 0;
        for (let i = 0; i < (props.workoutSeries?.rounds ?? 0); i++) {
            totalValue += parseFloat(
                props.watch(`reps_${exerciseIndex}_${i}`) ?? 10
            );
        }

        return totalValue;
    };

    const getSectionTime = () => {
        const totalRounds = props.workoutSeries?.rounds ?? 0;
        const roundTime = dayjs(props.workoutSeries?.totalTime ?? 0).get(
            "second"
        );
        const breakTime = dayjs(section?.breakTime ?? 0).get("second");
        const totalTime = totalRounds * roundTime + breakTime;

        console.log(totalRounds, roundTime, breakTime, totalTime);

        return dayjs(0)
            .hour(0)
            .minute(0)
            .second(0)
            .add(totalTime, "second")
            .format("HH:mm:ss");
    };

    return (
        <BaseSection>
            <SectionArea>
                <SectionTitle>{`SÉRIE 01 - Tempo do Exercício: ${getSectionTime()}`}</SectionTitle>
                {section.exercises.map((exercise, exerciseIndex) => (
                    <ExerciseArea>
                        <ExerciseBaseCard
                            key={exerciseIndex}
                            title={exercise.exercise.name}
                            description={getWorkoutExerciseDescription(
                                exercise,
                                undefined,
                                {
                                    time: props.workoutSeries?.totalTime,
                                    total: props.workoutSeries?.rounds,
                                    breakTime: section?.breakTime,
                                }
                            )}
                            onClick={() =>
                                props.exhibitExercise(exercise.exercise)
                            }
                        />
                        <ExerciseResultArea>
                            {times(
                                props.workoutSeries?.rounds ?? 0,
                                (index) => (
                                    <Controller
                                        as={
                                            <ExerciseResult
                                                label={`Série ${index + 1}`}
                                            />
                                        }
                                        control={props.control}
                                        defaultValue={10}
                                        name={`reps_${exerciseIndex}_${index}`}
                                    />
                                )
                            )}
                            <ExerciseTotalResult
                                value={`${getTotalValue(exerciseIndex)}`}
                            />
                        </ExerciseResultArea>
                    </ExerciseArea>
                ))}
            </SectionArea>

            <Divider />
            <ExerciseResultsBase control={props.control} />
        </BaseSection>
    );
};

export class FetchClient {
    async get<T>(
        url: string,
        headers?: Record<string, string>
    ): Promise<T | null> {
        try {
            const response = await fetch(url, {
                method: "GET",
                headers,
            });

            if (!response.ok) {
                return null;
            }

            const body = await response.text();

            if (body.length === 0) {
                return JSON.parse(`{}`);
            }

            const bodyParsed = JSON.parse(body);

            return bodyParsed as T;
        } catch (error) {
            console.log("Request error", error);
            return null;
        }
    }

    async post<T>(
        url: string,
        data: any,
        headers?: Record<string, string>
    ): Promise<T | null> {
        try {
            const response = await fetch(url, {
                body: JSON.stringify(data),
                method: "POST",
                headers,
            });

            if (!response.ok) {
                return null;
            }

            const body = await response.text();

            if (body.length === 0) {
                return JSON.parse(`{}`);
            }

            const bodyParsed = JSON.parse(body);

            return bodyParsed as T;
        } catch (error) {
            console.log("Request error", error);
            return null;
        }
    }

    async delete(
        url: string,
        data?: any,
        headers?: Record<string, string>
    ): Promise<boolean> {
        try {
            const response = await fetch(url, {
                body: JSON.stringify(data),
                method: "DELETE",
                headers,
            });

            return await response.ok;
        } catch (error) {
            console.log("Request error", error);
            return false;
        }
    }

    async patch<T>(
        url: string,
        data: any,
        headers?: Record<string, string>
    ): Promise<T | null> {
        try {
            const response = await fetch(url, {
                body: JSON.stringify(data),
                method: "PATCH",
                headers,
            });

            if (!response.ok) {
                return null;
            }

            const body = await response.json();

            return body;
        } catch (error) {
            console.log("Request error", error);
            return null;
        }
    }
}

import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { Table, TableHeader } from "src/components/structures/shared/table";
import { Customer } from "src/entities/customer";

interface CustomersInfoFrameProps {
    customers: Customer[];
}

export const CustomersInfoFrame: React.FC<CustomersInfoFrameProps> = (
    props
) => {
    const isMobile = useMediaQuery("(max-width:768px)");

    const tableHeaders: TableHeader<Customer>[] = [
        {
            title: "Nome",
            field: "name",
        },
        {
            title: "CPF/CNPJ",
            field: "name",
        },
        {
            title: "e-mail",
            field: "name",
        },
        {
            title: "Ap/Bloco",
            render: (customer) =>
                customer.condoZone
                    ? `${customer.apartment}/${customer.condoZone}`
                    : customer.apartment,
        },
    ];

    const tableHeadersMobile: TableHeader<Customer>[] = [
        {
            title: "Nome",
            field: "name",
        },
        {
            title: "Ap/Bloco",
            render: (customer) =>
                customer.condoZone
                    ? `${customer.apartment}/${customer.condoZone}`
                    : customer.apartment,
        },
    ];

    return (
        <Table
            export={true}
            data={props.customers}
            header={isMobile ? tableHeadersMobile : tableHeaders}
        />
    );
};

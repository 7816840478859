import React from "react";
import { useHistory } from "react-router-dom";
import { AdminCondosTemplate } from "src/components/templates/admin-condos-template";
import { Condo } from "src/entities/condo";
import {
    awsCondoGetAll,
    awsCondoUpdate,
} from "src/infrastructure/aws/aws-condo";

export const AdminCondosPage: React.FC = () => {
    const history = useHistory();

    const [reload, setReload] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [condos, setCondos] = React.useState<Condo[]>([]);

    React.useEffect(() => {
        const retrieveCondos = async () => {
            const newCondos = await awsCondoGetAll();

            setCondos(newCondos);
            setIsLoading(false);
            setReload(false);
        };

        if (reload) {
            retrieveCondos();
        }
    }, [reload]);

    const addNewCondo = () => {
        history.push("/admin/condominio");
    };

    const editCondo = (condo?: Condo) => {
        history.push(`/admin/condominio?id=${condo?.id}`);
    };

    const toggleCondoActive = async (condo: Condo) => {
        setIsLoading(true);

        const status = condo.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";

        await awsCondoUpdate(condo.id, {
            ...condo,
            status,
        });

        setReload(true);
    };

    return (
        <AdminCondosTemplate
            isLoading={isLoading}
            condos={condos}
            addNewCondo={addNewCondo}
            editCondo={editCondo}
            toggleCondoActive={toggleCondoActive}
        />
    );
};

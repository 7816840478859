import React from "react";
import { ScheduledWorkout } from "src/entities/scheduled-workout";
import Modal from "react-modal";
import styled from "styled-components";
import {
    getWorkoutTypeColor,
    getWorkoutTypeFormated,
} from "src/entities/workout-series/utils";
import { Typography } from "src/components/atoms/typography";
import IntlMessageFormat from "intl-messageformat";
import { WorkoutSeriesShowcaseCard } from "src/components/structures/shared/workout-series-showcase-card";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        background: "none",
        width: "90%",
        height: "90%",
    },
    overlay: {
        backgroundColor: "rgb(0, 0, 0, 0.3)",
    },
};

const ModalContent = styled.form`
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 0;
    border-radius: 10px;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "title"
        "content";
    align-content: flex-start;
    column-gap: 1vw;
`;

interface WorkoutTitleProps {
    backgroundColor: string;
}

const WorkoutTitle = styled.div<WorkoutTitleProps>`
    grid-area: title;
    background-color: ${(props) => props.backgroundColor};
    padding: 2vh 3vw;

    text-align: center;

    display: grid;
    align-content: center;
    justify-items: center;
`;

const WorkoutContent = styled.div`
    grid-area: content;
    padding: 2vh 3vw;

    text-align: center;

    display: grid;
    align-content: center;
    justify-items: center;
`;

const Title = styled(Typography).attrs({ variant: "h4" })``;

const WorkoutName = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})``;

const WorkoutDescription = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})``;

const CloseButton = styled.i.attrs({
    className: "fas fa-times",
})`
    position: absolute;
    top: 5vh;
    right: 2.5vw;
    cursor: pointer;
    z-index: 99;
    opacity: 70%;

    @media only screen and (max-width: 768px) {
        top: 6vh;
        right: 5vw;
        font-size: 2rem;
    }
`;

const WorkoutQuantityArea = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.5vw;

    @media only screen and (max-width: 768px) {
        justify-content: center;
    }
`;

const WorkoutQuantityIcon = styled.img.attrs({ src: "/assets/gym_black.png" })`
    width: 21px;
    height: 21px;
`;

const WorkoutQuantity = styled(Typography).attrs({ variant: "h5" })`
    margin: 2vh 0;
`;

const WorkoutSeriesCardArea = styled.div`
    overflow-y: auto;
    margin: 2vh 0;

    justify-self: stretch;

    display: grid;
    row-gap: 2vh;
`;

const WorkoutActions = styled.div`
    margin: 1vh 0;

    justify-self: stretch;

    display: grid;
    column-gap: 2vh;
    justify-content: flex-end;
    grid-auto-flow: column;
`;

const EditIcon = styled.img.attrs({ src: "/assets/mdi_edit.png" })`
    height: 25px;
    cursor: pointer;
`;

const DeleteIcon = styled.img.attrs({ src: "/assets/mdi_delete.png" })`
    height: 25px;
    cursor: pointer;
`;

interface ModalScheduleProps {
    scheduleWorkout: ScheduledWorkout;
    isOpen: boolean;
    onClose: () => void;
    onEdit: () => void;
    onDelete: () => void;
}

export const ModalSchedule: React.FC<ModalScheduleProps> = (props) => {
    Modal.setAppElement("#root");

    const getQuantityText = () => {
        return new IntlMessageFormat(
            `{exercisesNumber, plural,
                =0 {Nenhuma séries}
                =1 {Uma série}
                other {# séries}
              }`,
            "pt-BR"
        ).format({
            exercisesNumber: props.scheduleWorkout.series.length,
        });
    };

    return (
        <Modal
            style={customStyles}
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
        >
            <ModalContent>
                <CloseButton onClick={() => props.onClose()} />
                <WorkoutTitle
                    backgroundColor={getWorkoutTypeColor(
                        props.scheduleWorkout.type
                    )}
                >
                    <Title>
                        {getWorkoutTypeFormated(props.scheduleWorkout.type)}
                    </Title>
                    <WorkoutName>{props.scheduleWorkout.name}</WorkoutName>
                </WorkoutTitle>
                <WorkoutContent>
                    <WorkoutQuantityArea>
                        <WorkoutQuantityIcon />
                        <WorkoutQuantity>{getQuantityText()}</WorkoutQuantity>
                    </WorkoutQuantityArea>
                    <WorkoutDescription>
                        {props.scheduleWorkout.description}
                    </WorkoutDescription>
                    <WorkoutSeriesCardArea>
                        {props.scheduleWorkout.series.map((workoutSeries) => (
                            <WorkoutSeriesShowcaseCard
                                workoutSeries={workoutSeries}
                            />
                        ))}
                    </WorkoutSeriesCardArea>
                    <WorkoutActions>
                        <EditIcon onClick={() => props.onEdit()} />
                        <DeleteIcon onClick={() => props.onDelete()} />
                    </WorkoutActions>
                </WorkoutContent>
            </ModalContent>
        </Modal>
    );
};

import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import {
    SignupInitialStep,
    SignupTemplate,
} from "src/components/templates/signup-template";
import { Condo } from "src/entities/condo";
import { Customer } from "src/entities/customer";
import {
    awsAuthSendVerificationEmail,
    awsAuthSignUp,
    awsAuthVerifyEmail,
} from "src/infrastructure/aws/aws-amplify";
import { awsCondoGet } from "src/infrastructure/aws/aws-condo";
import {
    awsCustomerCreate,
    awsCustomerGet,
    awsCustomerGetByEmail,
    awsCustomerUpdate,
} from "src/infrastructure/aws/aws-customer";

export const SignupPage: React.FC = () => {
    const history = useHistory();
    const customerEmail = queryString.parse(useLocation().search)?.email;
    const customerState = queryString.parse(useLocation().search)?.state;

    const [isLoading, setIsLoading] = React.useState(true);
    const [initialState, setInitialState] = React.useState<SignupInitialStep>(
        undefined
    );
    const [selectedCondo, setSelectedCondo] = React.useState<Condo | undefined>(
        undefined
    );
    const [customerInfo, setCustomerInfo] = React.useState<Partial<Customer>>(
        {}
    );

    React.useEffect(() => {
        const retrieveCustomer = async (
            email: string,
            state: SignupInitialStep
        ) => {
            const response = await awsCustomerGetByEmail(email);

            await awsAuthSendVerificationEmail(email);

            setInitialState(state);
            setCustomerInfo(response ?? {});
            setIsLoading(false);
        };

        if (!!customerEmail) {
            const customerEmailParsed = Array.isArray(customerEmail)
                ? customerEmail[0]
                : customerEmail;

            const customerStateParsed = Array.isArray(customerState)
                ? customerState[0]
                : customerState;

            retrieveCustomer(
                customerEmailParsed,
                customerStateParsed as SignupInitialStep
            );
        } else {
            setIsLoading(false);
        }
    }, [customerEmail, customerState]);

    const goToLogin = () => {
        history.push("/login");
    };

    const redirectOnFinish = () => {
        history.push("/login");
    };

    const checkCustomerExists = async (
        document: string,
        email: string
    ): Promise<boolean> => {
        const documentCustomer = await awsCustomerGet(document);
        const emailCustomer = await awsCustomerGetByEmail(email);

        return Boolean(documentCustomer) || Boolean(emailCustomer);
    };

    const updateCustomer = async (
        document: string,
        updateData: Partial<Customer>
    ) => {
        const response = await awsCustomerUpdate(document, updateData);

        return response !== null;
    };

    const signup = async (
        customer: Customer,
        password: string,
        condoId: string
    ): Promise<boolean> => {
        const newCustomer = await awsCustomerCreate(customer, condoId);

        if (!newCustomer) {
            return false;
        }

        const response = await awsAuthSignUp(customer, password);

        if (response === "CREATED") {
            return true;
        }

        return false;
    };

    const updateCustomerInfo = async (
        customerData: Partial<Customer>,
        password?: string
    ): Promise<boolean> => {
        const updatedCustomer: Partial<Customer> = {
            ...customerInfo,
            ...customerData,
        };

        setCustomerInfo(updatedCustomer);

        if (customerData.email && customerData.document) {
            const customerAlreadyExists = await checkCustomerExists(
                customerData.document,
                customerData.email
            );

            if (customerAlreadyExists) {
                return false;
            }
        }

        if (customerInfo.document && customerData.parq) {
            const response = await updateCustomer(
                customerInfo.document,
                updatedCustomer
            );

            if (!response) {
                return false;
            }
        }

        if (password) {
            const response = await signup(
                customerInfo as Customer,
                password,
                selectedCondo?.id ?? ""
            );

            if (!response) {
                return false;
            }
        }

        return true;
    };

    const acceptHealthCareWarning = async (): Promise<boolean> => {
        if (!customerInfo.parq || !customerInfo.document) {
            return false;
        }

        const updatedInfo = {
            parq: {
                ...customerInfo.parq,
                acceptedWithCondition: true,
            },
        };

        return await updateCustomer(customerInfo.document, updatedInfo);
    };

    const confirmEmail = async (code: string): Promise<boolean> => {
        if (!customerInfo.email) {
            return false;
        }

        const response = await awsAuthVerifyEmail(customerInfo.email, code);

        if (!response) {
            return false;
        }

        return true;
    };

    const updateSelectedCondo = async (condoCode: string) => {
        const response = await awsCondoGet(condoCode);

        setSelectedCondo(response ?? undefined);

        return response !== null;
    };

    return (
        <SignupTemplate
            isLoading={isLoading}
            goToLogin={goToLogin}
            initialState={initialState}
            updateCustomerInfo={updateCustomerInfo}
            redirectOnFinish={redirectOnFinish}
            acceptHealthCareWarning={acceptHealthCareWarning}
            confirmEmail={confirmEmail}
            updateSelectedCondo={updateSelectedCondo}
        />
    );
};

import React from "react";
import { useHistory } from "react-router-dom";
import { AdminExercisesTemplate } from "src/components/templates/admin-exercises-template";
import { Exercise } from "src/entities/exercise";
import {
    awsExerciseDelete,
    awsExerciseGetAll,
} from "src/infrastructure/aws/aws-exercise";

export const AdminExercisesPage: React.FC = () => {
    const history = useHistory();

    const [reload, setReload] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [exercises, setExercises] = React.useState<Exercise[]>([]);

    const addNewExercise = () => {
        history.push("/admin/exercicio");
    };

    const editExercise = (exercise?: Exercise) => {
        history.push(`/admin/exercicio?id=${exercise?.id}`);
    };

    const deleteExercise = async (exercise: Exercise) => {
        setIsLoading(true);
        await awsExerciseDelete(exercise.id);
        setReload(true);
    };

    React.useEffect(() => {
        if (reload) {
            retrieveExercises();
        }
    }, [reload]);

    const retrieveExercises = async () => {
        setIsLoading(true);

        const newExercises = await awsExerciseGetAll();

        setIsLoading(false);
        setReload(false);
        setExercises(newExercises);
    };

    return (
        <AdminExercisesTemplate
            isLoading={isLoading}
            exercises={exercises}
            addNewExercise={addNewExercise}
            editExercise={editExercise}
            deleteExercise={deleteExercise}
        />
    );
};

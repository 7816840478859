import { FetchClient } from "src/infrastructure/http-client/fetch-client";
import { CondoEntity } from "src/entities/condo-entity";

const fetchClient = new FetchClient();

export const awsCondoEntityCreate = async (
    condoEntity: CondoEntity
): Promise<CondoEntity | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/condo-entity`;

        const response = await fetchClient.post<CondoEntity>(url, condoEntity);

        return response;
    } catch (error) {
        console.log("error while creating condo entity", error);

        return null;
    }
};

export const awsCondoEntityUpdate = async (
    condoEntity: CondoEntity
): Promise<CondoEntity | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/condo-entity`;

        const response = await fetchClient.patch<CondoEntity>(url, condoEntity);

        return response;
    } catch (error) {
        console.log("error while updating condo", error);

        return null;
    }
};

export const awsCondoEntityDelete = async (
    request: CondoEntity
): Promise<boolean> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/condo-entity?condoId=${request.condoId}&entityId=${request.entity.id}&entityType=${request.entity.type}`;

        await fetchClient.delete(url);

        return true;
    } catch (error) {
        console.log("error while deleting Condo", error);

        return false;
    }
};

import React from "react";
import { AdminCustomersTemplate } from "src/components/templates/admin-customers-template";
import { Customer } from "src/entities/customer";
import {
    awsCustomerGetAll,
    awsCustomerUpdate,
} from "src/infrastructure/aws/aws-customer";
import { generateCustomerParq } from "src/infrastructure/pdf/customer-parq";

export const AdminCustomersPage: React.FC = () => {
    const [reload, setReload] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [customers, setCustomers] = React.useState<Customer[]>([]);

    React.useEffect(() => {
        const retrieveCustomers = async () => {
            const newCustomers = await awsCustomerGetAll();

            setCustomers(newCustomers);
            setIsLoading(false);
            setReload(false);
        };

        if (reload) {
            retrieveCustomers();
        }
    }, [reload]);

    const toggleCustomerActive = async (customer: Customer) => {
        setIsLoading(true);

        const status = customer.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";

        await awsCustomerUpdate(customer.document, {
            ...customer,
            status,
        });

        setReload(true);
    };

    return (
        <AdminCustomersTemplate
            customers={customers}
            isLoading={isLoading}
            toggleCustomerActive={toggleCustomerActive}
            getParq={(customer) => generateCustomerParq(customer)}
        />
    );
};

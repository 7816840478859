import React from "react";
import { AdminEquipmentsTemplate } from "src/components/templates/admin-equipments-template";
import { Equipment } from "src/entities/equipment";
import {
    awsEquipmentCreate,
    awsEquipmentDelete,
    awsEquipmentGetAll,
    awsEquipmentUpdate,
} from "src/infrastructure/aws/aws-equipment";

export const AdminEquipmentsPage: React.FC = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [equipments, setEquipments] = React.useState<Equipment[]>([]);

    React.useEffect(() => {
        retrieveEquipments();
    }, []);

    const retrieveEquipments = async () => {
        setIsLoading(true);

        const newEquipments = await awsEquipmentGetAll();

        setIsLoading(false);
        setEquipments(newEquipments);
    };

    const saveEquipment = async (equipment: Equipment): Promise<boolean> => {
        const response = equipment.id
            ? await awsEquipmentUpdate(equipment.id, equipment)
            : await awsEquipmentCreate(equipment);

        if (response) {
            await retrieveEquipments();
        }

        return Boolean(response);
    };

    const deleteEquipment = async (equipment: Equipment): Promise<boolean> => {
        const response = await awsEquipmentDelete(equipment.id ?? "");

        if (response) {
            await retrieveEquipments();
        }

        return Boolean(response);
    };

    return (
        <AdminEquipmentsTemplate
            isLoading={isLoading}
            saveEquipment={saveEquipment}
            deleteEquipment={deleteEquipment}
            equipments={equipments}
        />
    );
};

import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { Button } from "src/components/atoms/button";
import { InputField } from "src/components/molecules/input-field";
import { LoginLayout } from "src/components/templates/layout/login-layout";
import { Typography } from "src/components/atoms/typography";

const FrameBase = styled.form`
    min-width: 400px;
    max-width: 40vw;

    display: grid;
    row-gap: 5vh;
    column-gap: 2vw;
    justify-self: center;
    justify-items: center;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "description description"
        "form form"
        "back-button send-button";

    @media only screen and (max-width: 768px) {
        width: 95%;
        min-width: unset;
        max-width: unset;
    }
`;

const Description = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "400",
})`
    grid-area: description;
    text-align: center;
    user-select: none;
`;

const EmailField = styled(InputField).attrs({
    label: "E-mail",
    box: { light: true },
    placeholder: "Insira o e-mail de cadastro",
})`
    width: 100%;
`;

const EmailCodeField = styled(InputField).attrs({
    label: "Código enviado ao e-mail",
    box: { light: true },
    placeholder: "Insira o código enviado por e-mail",
})`
    width: 100%;
`;

const NewPasswordField = styled(InputField).attrs({
    label: "Nova senha",
    type: "password",
    box: { light: true },
    placeholder: "Insira a nova senha",
})`
    width: 100%;
`;

const ButtonBack = styled(Button).attrs({ round: false, color: "secondary" })`
    grid-area: back-button;
    width: 100%;
`;

const ButtonSend = styled(Button).attrs({ round: false })`
    grid-area: send-button;
    width: 100%;
`;

const FrameForm = styled.div`
    grid-area: form;

    width: 100%;

    display: grid;
    row-gap: 1rem;
`;

interface ForgotPasswordTemplateProps {
    goBack: () => void;
    sendForgotPasswordEmail: (email: string) => Promise<boolean>;
    setNewPassword: (code: string, password: string) => Promise<boolean>;
    redirectOnFinish: () => void;
}

export const ForgotPasswordTemplate: React.FC<ForgotPasswordTemplateProps> = (
    props
) => {
    const {
        control,
        formState,
        handleSubmit,
        errors,
        setError,
        reset,
    } = useForm();
    const { isSubmitting } = formState;

    const [step, setStep] = React.useState(0);

    const onSubmitEmail = async (values: any) => {
        const response = await props.sendForgotPasswordEmail(values.email);

        if (!response) {
            setError("email", {
                type: "manual",
                message: "E-mail não encontrado",
            });
            return;
        }

        setStep(1);
        reset();
    };

    const onSubmitPassword = async (values: any) => {
        const response = await props.setNewPassword(
            values.code,
            values.password
        );

        if (!response) {
            setError("code", {
                type: "manual",
                message: "Código inválido",
            });
            return;
        }

        setStep(2);
        setTimeout(() => props.redirectOnFinish(), 5000);
    };

    const getTitle = () => {
        if (step === 0) {
            return "Primeiro, precisamos enviar um código para o seu e-mail cadastrado";
        } else if (step === 1) {
            return "Verifique seu e-mail e copie o código recebido abaixo, assim como uma nova senha";
        } else {
            return "Sucesso! Você será direcionado ao login";
        }
    };

    const getForm = () => {
        if (step === 0) {
            return (
                <FrameForm>
                    <Controller
                        as={
                            <EmailField
                                error={{
                                    isInvalid: !!errors.email,
                                    message: errors.email?.message,
                                }}
                            />
                        }
                        rules={{ required: true }}
                        control={control}
                        defaultValue={""}
                        name="email"
                    />
                </FrameForm>
            );
        } else if (step === 1) {
            return (
                <FrameForm>
                    <Controller
                        as={
                            <EmailCodeField
                                error={{
                                    isInvalid: !!errors.code,
                                    message: errors.code?.message,
                                }}
                            />
                        }
                        rules={{ required: true }}
                        control={control}
                        defaultValue={""}
                        name="code"
                    />
                    <Controller
                        as={
                            <NewPasswordField
                                error={{
                                    isInvalid: !!errors.password,
                                    message: errors.password?.message,
                                }}
                            />
                        }
                        rules={{ required: true }}
                        control={control}
                        defaultValue={""}
                        name="password"
                    />
                </FrameForm>
            );
        } else {
            return <div />;
        }
    };

    return (
        <LoginLayout goToLink={props.goBack} linkLabel="Voltar ao Login">
            <FrameBase
                onSubmit={handleSubmit(
                    step === 0 ? onSubmitEmail : onSubmitPassword
                )}
            >
                <Description>{getTitle()}</Description>

                {getForm()}
                {!isSubmitting && step === 1 && (
                    <ButtonBack onClick={() => setStep(0)}>Retornar</ButtonBack>
                )}
                {step <= 1 && (
                    <ButtonSend type="submit" loading={isSubmitting}>
                        Recuperar senha
                    </ButtonSend>
                )}
            </FrameBase>
        </LoginLayout>
    );
};

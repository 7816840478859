import React from "react";
import styled from "styled-components";
import { times } from "lodash";
import { UseFormMethods, Controller } from "react-hook-form";
import { ExerciseBaseCard } from "src/components/structures/home-workout-series/exercise-base-card";
import { Divider } from "src/components/structures/home-workout-series/divider";
import { ExerciseResultsBase } from "src/components/structures/home-workout-series/exercise-results-base";
import { InputField } from "src/components/molecules/input-field";
import { getWorkoutExerciseDescription } from "src/entities/workout-series/utils";
import { Exercise } from "src/entities/exercise";
import { MorrerRepeticoes } from "src/entities/workout-series";

const BaseSection = styled.div`
    display: grid;
    row-gap: 3vh;
`;

const TotalSeriesField = styled(InputField).attrs({
    label: "Séries Totais",
    type: "number",
})`
    width: 100%;
`;

const TotalRepsField = styled(InputField).attrs({
    label: "Repetições Totais",
    type: "number",
})`
    width: 100%;
`;

const SectionArea = styled.div`
    display: grid;
    row-gap: 1vh;
`;

interface FrameMorrerRepeticoesProps {
    workoutSeries: MorrerRepeticoes;
    control: UseFormMethods["control"];
    exhibitExercise: (exercise: Exercise) => void;
}

export const FrameMorrerRepeticoes: React.FC<FrameMorrerRepeticoesProps> = (
    props
) => {
    return (
        <BaseSection>
            {times(props.workoutSeries?.rounds ?? 1, (index) => {
                const section = props.workoutSeries.sections[0];

                return (
                    <SectionArea key={index}>
                        {section.exercises.map((exercise, index) => (
                            <ExerciseBaseCard
                                key={index}
                                title={exercise.exercise.name}
                                description={getWorkoutExerciseDescription(
                                    exercise,
                                    {
                                        start:
                                            props.workoutSeries.initialReps ??
                                            0,
                                        increment:
                                            props.workoutSeries.incrementReps ??
                                            0,
                                    },
                                    {
                                        breakTime:
                                            props.workoutSeries.sections[0]
                                                ?.breakTime,
                                        max: props.workoutSeries.rounds,
                                    }
                                )}
                                onClick={() =>
                                    props.exhibitExercise(exercise.exercise)
                                }
                            />
                        ))}
                    </SectionArea>
                );
            })}

            <Divider />
            <ExerciseResultsBase control={props.control}>
                <Controller
                    as={<TotalSeriesField />}
                    control={props.control}
                    defaultValue={`${props.workoutSeries?.rounds ?? 5}`}
                    name="rounds"
                />
                <Controller
                    as={<TotalRepsField />}
                    control={props.control}
                    defaultValue={`${props.workoutSeries?.rounds ?? 5}`}
                    name="reps"
                />
            </ExerciseResultsBase>
        </BaseSection>
    );
};

import React from "react";
import styled from "styled-components";
import { theme } from "src/style/theme";
import { Typography } from "src/components/atoms/typography";
import { useHistory } from "react-router-dom";

const Background = styled.div`
    position: relative;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100vw;
    padding: 1vh 10vw;
    display: grid;
    align-items: center;
    justify-content: stretch;
    justify-items: center;
    height: ${theme.appBar.landinghHeight};
`;

const MenuToggle = styled.img.attrs({ src: "/assets/mdi_menu_icon.png" })`
    position: absolute;
    height: 40%;
    left: 5vw;
`;

const Logo = styled.img.attrs({ src: "/assets/logo_white.png" })`
    height: 90%;
    max-height: 2rem;
`;

interface StyledSideMenuProps {
    menuOpen: boolean;
}

const BaseMenu = styled.div<StyledSideMenuProps>`
    height: 92vh;
    background: #2b2b2c;
    width: ${(props) => (props.menuOpen ? "100vw" : "0")};
    padding: 10vh 0;
    padding-left: ${(props) => (props.menuOpen ? "3.5vw" : "0")};

    position: absolute;
    top: ${theme.appBar.landinghHeight};

    display: grid;
    row-gap: 4vh;
    justify-items: flex-start;
    align-content: flex-start;
    grid-template-rows: auto auto auto auto auto auto 1fr;

    transition: width 1s, padding-left 1s;

    overflow: hidden;

    z-index: 999;
`;

interface IconProps {
    url: string;
}

const Icon = styled.div<IconProps>`
    background-image: ${(props) => `url(${props.url})`};
    background-repeat: no-repeat;
    background-size: contain;
    width: max(1.3vw, 20px);
    height: max(1.3vw, 20px);
`;

const MenuItem = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: max(1.3vw, 20px);
    align-items: center;
    column-gap: 3vw;
    cursor: pointer;

    &.last {
        align-self: flex-end;
    }
`;

const MenuTitle = styled(Typography).attrs({
    variant: "h3",
    color: "white",
})`
    text-transform: uppercase;
`;

export const HeaderAdmin: React.FC = () => {
    const history = useHistory();

    const [menuOpen, setMenuOpen] = React.useState(false);

    return (
        <>
            <Background>
                <MenuToggle onClick={() => setMenuOpen(!menuOpen)} />
                <Logo onClick={() => history.push("/admin")} />
            </Background>
            <BaseMenu menuOpen={menuOpen}>
                <MenuItem onClick={() => history.push("/admin/clientes")}>
                    <Icon url="/assets/admin-side-menu-icons/mdi_person.svg" />
                    {menuOpen && <MenuTitle>Usuários</MenuTitle>}
                </MenuItem>
                <MenuItem onClick={() => history.push("/admin/condominios")}>
                    <Icon url="/assets/admin-side-menu-icons/mdi_home.svg" />
                    {menuOpen && <MenuTitle>CONDOMÍNIOS</MenuTitle>}
                </MenuItem>
                <MenuItem onClick={() => history.push("/admin/exercicios")}>
                    <Icon url="/assets/admin-side-menu-icons/mdi_gym.svg" />
                    {menuOpen && <MenuTitle>EXERCÍCIOS</MenuTitle>}
                </MenuItem>
                <MenuItem onClick={() => history.push("/admin/treinos")}>
                    <Icon url="/assets/admin-side-menu-icons/mdi_alarm_on.svg" />
                    {menuOpen && <MenuTitle>TREINOS</MenuTitle>}
                </MenuItem>
                <MenuItem onClick={() => history.push("/admin/equipamentos")}>
                    <Icon url="/assets/admin-side-menu-icons/mdi_watch.svg" />
                    {menuOpen && <MenuTitle>EQUIPAMENTOS</MenuTitle>}
                </MenuItem>
                <MenuItem onClick={() => history.push("/admin/agenda")}>
                    <Icon url="/assets/admin-side-menu-icons/mdi_calendar.svg" />
                    {menuOpen && <MenuTitle>AGENDA</MenuTitle>}
                </MenuItem>
                <MenuItem onClick={() => history.push("/admin/resultados")}>
                    <Icon url="/assets/admin-side-menu-icons/mdi_results.svg" />
                    {menuOpen && <MenuTitle>RESULTADOS</MenuTitle>}
                </MenuItem>
                <MenuItem
                    className="last"
                    onClick={() => history.push("/logout")}
                >
                    <MenuTitle>Sair</MenuTitle>
                </MenuItem>
            </BaseMenu>
        </>
    );
};

import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "@material-ui/core";
import { SideMenuCustomer } from "src/components/structures/home-layout/side-menu-customer";
import { HeaderCustomer } from "src/components/structures/home-layout/header-customer";
import { LoadingPage } from "src/components/structures/shared/loading-page";

const BaseStructure = styled.form`
    width: 100vw;
    height: 100vh; /* Fallback para não-suporte a Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background-color: white;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        grid-template-areas:
            "header"
            "content"
            "footer";
    }
`;

const Content = styled.div`
    display: grid;
    align-content: flex-start;
    padding: 5vh 5vw;
    overflow-y: auto;

    @media only screen and (max-width: 768px) {
        padding: 2vh 5vw;
    }
`;

const Footer = styled.div`
    background-color: #262626;
    padding: 2vh 2vw;
`;

interface HomeLayoutProps {
    isLoading?: boolean;
    footer?: React.ReactNode;
    onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
}

export const HomeLayout: React.FC<HomeLayoutProps> = (props) => {
    const isMobile = useMediaQuery("(max-width:768px)");

    return (
        <BaseStructure onSubmit={props.onSubmit}>
            {isMobile ? <HeaderCustomer /> : <SideMenuCustomer />}

            {props.isLoading ? (
                <LoadingPage />
            ) : (
                <Content>{props.children}</Content>
            )}

            {props.footer && <Footer>{props.footer}</Footer>}
        </BaseStructure>
    );
};

import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { useMediaQuery } from "@material-ui/core";
import { WorkoutResult } from "src/entities/workout-result";
import { Typography } from "src/components/atoms/typography";
import { getWorkoutTypeFormated } from "src/entities/workout-series/utils";
import { WorkoutSeriesResultCard } from "src/components/structures/admin-results/workout-series-result-card";
import { Workout } from "src/entities/workout";
import { LoadingPage } from "src/components/structures/shared/loading-page";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        background: "none",
        width: "80%",
        height: "90%",
    },
    overlay: {
        backgroundColor: "rgb(0, 0, 0, 0.3)",
        zIndex: 999,
    },
};

const customStylesMobile = {
    ...customStyles,
    content: {
        ...customStyles.content,
        width: "95vw",
        height: "95vh",
        padding: 0,
    },
};

const ModalContent = styled.div`
    height: 100%;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 4vh 3vw;
    border-radius: 1rem;
    overflow-y: auto;
`;

const Content = styled.div`
    display: grid;
    justify-items: center;
    align-content: flex-start;
`;

const CloseButton = styled.i.attrs({
    className: "fas fa-times",
})`
    position: absolute;
    top: 5vh;
    right: 3vw;
    cursor: pointer;
    z-index: 99;
    opacity: 70%;

    @media only screen and (max-width: 768px) {
        top: 4vh;
        right: 7vw;
        font-size: 2rem;
    }
`;

const Title = styled(Typography).attrs({
    variant: "h4",
})``;

const Subtitle = styled(Typography).attrs({
    variant: "h4",
    fontWeight: "400",
})``;

const CardsArea = styled.div`
    margin-top: 2vh;
    width: 100%;

    display: grid;
    row-gap: 2vh;
`;

interface ModalResultProps {
    isOpen: boolean;
    result: WorkoutResult;
    onClose: () => void;
    getWorkout: (id: string) => Promise<Workout | null>;
}

export const ModalResult: React.FC<ModalResultProps> = (props) => {
    Modal.setAppElement("#root");

    const isMobile = useMediaQuery("(max-width:768px)");

    const [isLoading, setIsLoading] = React.useState(true);
    const [workout, setWorkout] = React.useState<Workout | null>(null);

    React.useEffect(() => {
        const retrieveWorkout = async () => {
            const response = await props.getWorkout(props.result.workout.id);

            setWorkout(response);
            setIsLoading(false);
        };

        retrieveWorkout();
    });

    const getTitle = () => getWorkoutTypeFormated(props.result.workout.type);

    const getSubtitle = () => props.result.workout.name;

    return (
        <Modal
            style={isMobile ? customStylesMobile : customStyles}
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
        >
            <ModalContent>
                <CloseButton onClick={() => props.onClose()} />
                {isLoading ? (
                    <LoadingPage />
                ) : (
                    <Content>
                        <Title>{getTitle()}</Title>
                        <Subtitle>{getSubtitle()}</Subtitle>
                        <CardsArea>
                            {props.result.series.map((series) => {
                                const workoutSeries = workout?.series.find(
                                    (workoutSeries) =>
                                        workoutSeries.id === series.seriesId
                                );

                                return workoutSeries ? (
                                    <WorkoutSeriesResultCard
                                        key={series.seriesId}
                                        series={workoutSeries}
                                        seriesResult={series}
                                    />
                                ) : (
                                    <div />
                                );
                            })}
                        </CardsArea>
                    </Content>
                )}
            </ModalContent>
        </Modal>
    );
};

import { FetchClient } from "src/infrastructure/http-client/fetch-client";
import { ScheduledWorkout } from "src/entities/scheduled-workout";
import { CreateScheduledWorkoutRequest } from "src/entities/requests/create-scheduled-workout";
import dayjs from "dayjs";
import { UpdateScheduledWorkoutRequest } from "src/entities/requests/update-scheduled-workout";

const fetchClient = new FetchClient();

export const awsScheduledWorkoutsCreate = async (
    scheduledWorkoutRequest: CreateScheduledWorkoutRequest[]
): Promise<ScheduledWorkout[]> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/scheduled-workout`;

        const response = await fetchClient.post<ScheduledWorkout[]>(
            url,
            scheduledWorkoutRequest
        );

        return response ?? [];
    } catch (error) {
        console.log("error while creating scheduledWorkout", error);

        return [];
    }
};

export const awsScheduledWorkoutUpdate = async (
    scheduledWorkoutId: string,
    condoId: string,
    timestamp: string,
    updateData: UpdateScheduledWorkoutRequest
): Promise<ScheduledWorkout | null> => {
    try {
        const params = `scheduleId=${scheduledWorkoutId}&condoId=${condoId}&timestamp=${timestamp}`;

        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/scheduled-workout?${params}`;

        const response = await fetchClient.patch<ScheduledWorkout>(
            url,
            updateData
        );

        return response;
    } catch (error) {
        console.log("error while updating scheduledWorkout", error);

        return null;
    }
};

export const awsScheduledWorkoutGet = async (
    scheduledWorkoutCode: string
): Promise<ScheduledWorkout | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/scheduled-workout?scheduleId=${scheduledWorkoutCode}`;

        const response = await fetchClient.get<ScheduledWorkout>(url);

        return response;
    } catch (error) {
        console.log("error while getting ScheduledWorkout", error);

        return null;
    }
};

export const awsScheduledWorkoutDelete = async (
    scheduledWorkoutCode: string
): Promise<boolean> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/scheduled-workout?scheduleId=${scheduledWorkoutCode}`;

        await fetchClient.delete(url);

        return true;
    } catch (error) {
        console.log("error while deleting ScheduledWorkout", error);

        return false;
    }
};

export const awsScheduledWorkoutQuery = async (
    date: { startDate: Date; endDate?: Date },
    condoId?: string
): Promise<ScheduledWorkout[]> => {
    try {
        const startDate = dayjs(date.startDate).format("YYYY-MM-DD");
        const endDate = dayjs(date.endDate).format("YYYY-MM-DD");

        let url = `${process.env.REACT_APP_TREINADO_API_HOST}/scheduled-workouts?startDate=${startDate}`;

        if (date.endDate) {
            url += `&endDate=${endDate}`;
        }

        if (condoId) {
            url += `&condoId=${condoId}`;
        }

        const response = await fetchClient.get<ScheduledWorkout[]>(url);

        return response ?? [];
    } catch (error) {
        console.log("error while getting scheduledWorkouts", error);

        return [];
    }
};

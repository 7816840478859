import React from "react";
import styled from "styled-components";
import { theme } from "src/style/theme";

interface HeaderProps {
    fontWeight: string;
    color: string;
    cursor?: "pointer";
}

const Header1 = styled.h1<HeaderProps>`
    font-size: 2.2rem;
    margin: 0;
    font-weight: ${(props) => props.fontWeight};
    color: ${(props) => props.color};
    ${(props) => (props.cursor ? `cursor: pointer;` : ``)}
`;

const Header2 = styled.h2<HeaderProps>`
    font-size: 1.9rem;
    margin: 0;
    font-weight: ${(props) => props.fontWeight};
    color: ${(props) => props.color};
    ${(props) => (props.cursor ? `cursor: pointer;` : ``)}
`;

const Header3 = styled.h3<HeaderProps>`
    font-size: 1.6rem;
    margin: 0;
    font-weight: ${(props) => props.fontWeight};
    color: ${(props) => props.color};
    ${(props) => (props.cursor ? `cursor: pointer;` : ``)}
`;

const Header4 = styled.h4<HeaderProps>`
    font-size: 1.3rem;
    margin: 0;
    font-weight: ${(props) => props.fontWeight};
    color: ${(props) => props.color};
    ${(props) => (props.cursor ? `cursor: pointer;` : ``)}
`;

const Header5 = styled.h5<HeaderProps>`
    font-size: 1rem;
    margin: 0;
    font-weight: ${(props) => props.fontWeight};
    color: ${(props) => props.color};
    ${(props) => (props.cursor ? `cursor: pointer;` : ``)}
`;

interface TypographyProps {
    variant?: "h1" | "h2" | "h3" | "h4" | "h5";
    fontWeight?: "300" | "400" | "700" | "800";
    color?: "default" | "primary" | "secondary" | "white";
    className?: string;
    style?: React.CSSProperties;
    children: any;
    onClick?: () => void;
}

export const Typography: React.FC<TypographyProps> = (props) => {
    const fontWeight = props.fontWeight ?? "700";
    let color;

    switch (props.color) {
        case "primary":
            color = theme.colors.primary.light;
            break;
        case "secondary":
            color = theme.colors.secondary.light;
            break;
        case "white":
            color = "white";
            break;
        default:
            color = "black";
    }

    switch (props.variant) {
        case "h1":
            return (
                <Header1
                    color={color}
                    className={props.className}
                    fontWeight={fontWeight}
                    style={props.style}
                    onClick={props.onClick}
                    cursor={props.onClick ? "pointer" : undefined}
                >
                    {props.children}
                </Header1>
            );
        case "h2":
            return (
                <Header2
                    color={color}
                    className={props.className}
                    fontWeight={fontWeight}
                    style={props.style}
                    onClick={props.onClick}
                    cursor={props.onClick ? "pointer" : undefined}
                >
                    {props.children}
                </Header2>
            );
        case "h3":
            return (
                <Header3
                    color={color}
                    className={props.className}
                    fontWeight={fontWeight}
                    style={props.style}
                    onClick={props.onClick}
                    cursor={props.onClick ? "pointer" : undefined}
                >
                    {props.children}
                </Header3>
            );
        case "h4":
            return (
                <Header4
                    color={color}
                    className={props.className}
                    fontWeight={fontWeight}
                    style={props.style}
                    onClick={props.onClick}
                    cursor={props.onClick ? "pointer" : undefined}
                >
                    {props.children}
                </Header4>
            );
        case "h5":
            return (
                <Header5
                    color={color}
                    className={props.className}
                    fontWeight={fontWeight}
                    style={props.style}
                    onClick={props.onClick}
                    cursor={props.onClick ? "pointer" : undefined}
                >
                    {props.children}
                </Header5>
            );
        default:
            return (
                <Header1
                    color={color}
                    className={props.className}
                    fontWeight={fontWeight}
                    onClick={props.onClick}
                    cursor={props.onClick ? "pointer" : undefined}
                >
                    {props.children}
                </Header1>
            );
    }
};

import styled from "styled-components";

export const SignupBaseFrame = styled.form`
    justify-self: center;
    display: grid;
    justify-items: center;
    row-gap: 5vh;
    min-width: 400px;
    max-width: 40vw;

    @media only screen and (max-width: 768px) {
        width: 95%;
        min-width: unset;
        max-width: unset;
    }
`;

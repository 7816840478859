import React from "react";
import { LoginLayout } from "src/components/templates/layout/login-layout";
import { Customer } from "src/entities/customer";
import { SignupCondoCode } from "src/components/structures/signup/signup-condo-code";
import { SignupBasicInfo } from "src/components/structures/signup/signup-basic-info";
import { SignupLocationInfo } from "src/components/structures/signup/signup-location-info";
import { SignupPassword } from "src/components/structures/signup/signup-password";
import { SignupConfirmEmail } from "src/components/structures/signup/signup-confirm-email";
import { SignupFinish } from "src/components/structures/signup/signup-finish";
import { SignupHealthChecks } from "src/components/structures/signup/signup-health-checks";
import { SignupWarning } from "src/components/structures/signup/signup-warning";
import { Condo } from "src/entities/condo";
import { LoadingPage } from "src/components/structures/shared/loading-page";

export type SignupInitialStep = "EMAIL" | "PARQ" | undefined;

interface SignupTemplateProps {
    isLoading: boolean;
    initialState: SignupInitialStep;
    selectedCondo?: Condo;
    goToLogin: () => void;
    redirectOnFinish: () => void;
    updateSelectedCondo: (condoId: string) => Promise<boolean>;
    updateCustomerInfo: (
        customerData: Partial<Customer>,
        password?: string
    ) => Promise<boolean>;
    confirmEmail: (code: string) => Promise<boolean>;
    acceptHealthCareWarning: () => Promise<boolean>;
}

export const SignupTemplate: React.FC<SignupTemplateProps> = (props) => {
    const [acitveStep, setActiveStep] = React.useState(0);

    React.useEffect(() => {
        console.log("estado inicial", props.initialState);

        if (props.initialState === "EMAIL") {
            setActiveStep(4);
        } else if (props.initialState === "PARQ") {
            setActiveStep(5);
        }
    }, [props.initialState]);

    const getStepContent = () => {
        switch (acitveStep) {
            case 0:
                return (
                    <SignupCondoCode
                        goToNextPage={() => setActiveStep(1)}
                        selectCondo={props.updateSelectedCondo}
                    />
                );
            case 1:
                return (
                    <SignupBasicInfo
                        condo={props.selectedCondo}
                        goToNextPage={() => setActiveStep(2)}
                        updateInfo={props.updateCustomerInfo}
                    />
                );
            case 2:
                return (
                    <SignupLocationInfo
                        condo={props.selectedCondo}
                        goToNextPage={() => setActiveStep(3)}
                        updateInfo={props.updateCustomerInfo}
                    />
                );
            case 3:
                return (
                    <SignupPassword
                        goToNextPage={() => setActiveStep(4)}
                        updatePassword={(password) =>
                            props.updateCustomerInfo({}, password)
                        }
                    />
                );
            case 4:
                return (
                    <SignupConfirmEmail
                        goToNextPage={() => setActiveStep(5)}
                        confirmEmail={props.confirmEmail}
                    />
                );
            case 5:
                return (
                    <SignupHealthChecks
                        goToWarningPage={() => setActiveStep(6)}
                        goToNextPage={() => setActiveStep(7)}
                        updateInfo={(parq) =>
                            props.updateCustomerInfo({ parq })
                        }
                    />
                );
            case 6:
                return (
                    <SignupWarning
                        goToNextPage={() => setActiveStep(7)}
                        goToLogin={props.goToLogin}
                        updateInfo={props.acceptHealthCareWarning}
                    />
                );
            case 7:
                return (
                    <SignupFinish redirectOnFinish={props.redirectOnFinish} />
                );
        }
    };

    return (
        <LoginLayout linkLabel="Login" goToLink={props.goToLogin}>
            {props.isLoading ? <LoadingPage /> : getStepContent()}
        </LoginLayout>
    );
};

import React from "react";
import styled from "styled-components";
import { UseFormMethods, Controller } from "react-hook-form";
import {
    SelectField,
    SelectFieldOption,
} from "src/components/molecules/select-field";
import { LoadBase } from "src/entities/workout-series";

const LoadType = styled(SelectField).attrs({
    label: "Carga",
})`
    grid-area: load-type;
`;

interface LoadTypeFieldProps {
    defaultValue?: LoadBase;
    control: UseFormMethods["control"];
}

export const LoadTypeField: React.FC<LoadTypeFieldProps> = (props) => {
    const options: SelectFieldOption[] = [
        {
            label: "Sem Carga Definida",
            value: "sem_carga_definida",
        },
        {
            label: "Carga Igual, Maior ou Menor",
            value: "igual_maior_menor",
        },
        {
            label: "Percentual da Carga Máxima",
            value: "percentual_carga_maxima",
        },
        {
            label: "Percentual do Peso Corporal",
            value: "percentual_peso_corporal",
        },
        {
            label: "Carga Definida por Sexo",
            value: "carga_por_sexo",
        },
    ];

    return (
        <Controller
            as={<LoadType options={options} />}
            rules={{ required: true }}
            control={props.control}
            defaultValue={props.defaultValue?.type ?? "sem_carga_definida"}
            name="loadType"
        />
    );
};

import React from "react";
import styled from "styled-components";
import { UseFormMethods, Controller } from "react-hook-form";
import { ExerciseBaseCard } from "src/components/structures/home-workout-series/exercise-base-card";
import { Divider } from "src/components/structures/home-workout-series/divider";
import { ExerciseResultsBase } from "src/components/structures/home-workout-series/exercise-results-base";
import { InputField } from "src/components/molecules/input-field";
import { times } from "lodash";
import { RodadasMenorTempoFixas } from "src/entities/workout-series";
import { Typography } from "src/components/atoms/typography";
import { getWorkoutExerciseDescription } from "src/entities/workout-series/utils";
import dayjs from "dayjs";
import { Exercise } from "src/entities/exercise";

const BaseSection = styled.div`
    display: grid;
    row-gap: 3vh;
`;

const RepsTotalField = styled(InputField).attrs({
    label: "Tempo (s)",
    type: "number",
})`
    width: 100%;
`;

const SectionArea = styled.div`
    display: grid;
    row-gap: 1vh;
`;

const SectionTitle = styled(Typography).attrs({ variant: "h5" })``;

interface FrameRodadasMenorTempoFixasProps {
    workoutSeries: RodadasMenorTempoFixas;
    control: UseFormMethods["control"];
    exhibitExercise: (exercise: Exercise) => void;
}

export const FrameRodadasMenorTempoFixas: React.FC<FrameRodadasMenorTempoFixasProps> = (
    props
) => {
    return (
        <BaseSection>
            {times(props.workoutSeries?.rounds ?? 1, (index) => {
                const section = props.workoutSeries.sections[0];
                const breakTime = dayjs(section.breakTime).format("HH:mm:ss");

                return (
                    <SectionArea>
                        <SectionTitle>{`SÉRIE ${
                            index + 1
                        } (Descanso ${breakTime})`}</SectionTitle>
                        {section.exercises.map((exercise, index) => (
                            <ExerciseBaseCard
                                title={exercise.exercise.name}
                                description={getWorkoutExerciseDescription(
                                    exercise
                                )}
                                onClick={() =>
                                    props.exhibitExercise(exercise.exercise)
                                }
                            />
                        ))}
                    </SectionArea>
                );
            })}

            <Divider />
            <ExerciseResultsBase control={props.control}>
                <Controller
                    as={<RepsTotalField />}
                    control={props.control}
                    defaultValue={"5"}
                    name="reps"
                />
            </ExerciseResultsBase>
        </BaseSection>
    );
};

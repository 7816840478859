import React from "react";
import styled from "styled-components";
import { UseFormMethods } from "react-hook-form";
import { ExerciseBaseCard } from "src/components/structures/home-workout-series/exercise-base-card";
import { Divider } from "src/components/structures/home-workout-series/divider";
import { ExerciseResultsBase } from "src/components/structures/home-workout-series/exercise-results-base";
import { Exercise } from "src/entities/exercise";
import { Intervalado } from "src/entities/workout-series";
import {
    getWorkoutSeriesDescription,
    getWorkoutSeriesTitleFormated,
} from "src/entities/workout-series/utils";

const BaseSection = styled.div`
    display: grid;
    row-gap: 3vh;
`;

interface FrameIntervaladoProps {
    workoutSeries: Intervalado;
    control: UseFormMethods["control"];
    watch: UseFormMethods["watch"];
    exhibitExercise: (exercise: Exercise) => void;
}

export const FrameIntervalado: React.FC<FrameIntervaladoProps> = (props) => {
    return (
        <BaseSection>
            <ExerciseBaseCard
                title={getWorkoutSeriesTitleFormated(props.workoutSeries)}
                description={getWorkoutSeriesDescription(props.workoutSeries)}
                onClick={() =>
                    props.exhibitExercise(props.workoutSeries.exercise)
                }
            />
            <Divider />
            <ExerciseResultsBase control={props.control} />
        </BaseSection>
    );
};

import React from "react";
import styled from "styled-components";
import { ScheduledWorkout } from "src/entities/scheduled-workout";
import {
    getWorkoutTypeColor,
    getWorkoutTypeFormated,
} from "src/entities/workout-series/utils";
import { Typography } from "src/components/atoms/typography";
import IntlMessageFormat from "intl-messageformat";

interface ScheduledCardBaseProps {
    backgroundColor: string;
}

const ScheduledCardBase = styled.div<ScheduledCardBaseProps>`
    padding: 5vh 2vw;

    background-color: ${(props) => props.backgroundColor};

    display: grid;
    justify-items: center;
    row-gap: 2vh;

    cursor: pointer;

    @media only screen and (min-width: 768px) {
        padding: 2vh 1vw;
    }
`;

const Title = styled(Typography).attrs({
    variant: "h4",
})`
    text-align: center;

    @media only screen and (min-width: 768px) {
        font-size: 0.7rem;
    }
`;

const Description = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})`
    text-align: center;

    @media only screen and (min-width: 768px) {
        font-size: 0.7rem;
    }
`;

const ExerciseQuantity = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})`
    @media only screen and (min-width: 768px) {
        font-size: 0.7rem;
    }
`;

interface ScheduledCardProps {
    scheduledWorkout?: ScheduledWorkout;
    onClick?: (scheduledWorkout: ScheduledWorkout) => void;
}

export const ScheduledCard: React.FC<ScheduledCardProps> = (props) => {
    const getQuantityText = () => {
        return new IntlMessageFormat(
            `{exercisesNumber, plural,
                =0 {Nenhuma séries}
                =1 {Uma série}
                other {# séries}
              }`,
            "pt-BR"
        ).format({
            exercisesNumber: props.scheduledWorkout?.series.length ?? 0,
        });
    };

    return (
        <ScheduledCardBase
            backgroundColor={getWorkoutTypeColor(props.scheduledWorkout?.type)}
            onClick={() =>
                props.scheduledWorkout
                    ? props.onClick?.(props.scheduledWorkout)
                    : undefined
            }
        >
            <Title>
                {props.scheduledWorkout
                    ? getWorkoutTypeFormated(props.scheduledWorkout.type)
                    : "Sem Registros"}
            </Title>
            {props.scheduledWorkout && (
                <Description>{props.scheduledWorkout.description}</Description>
            )}
            {props.scheduledWorkout && (
                <ExerciseQuantity>{getQuantityText()}</ExerciseQuantity>
            )}
        </ScheduledCardBase>
    );
};

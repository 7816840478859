import React from "react";
import styled from "styled-components";
import IntlMessageFormat from "intl-messageformat";
import { HomeLayout } from "src/components/templates/layout/home-layout";
import { Typography } from "src/components/atoms/typography";
import { WorkoutSeriesShowcaseCard } from "src/components/structures/shared/workout-series-showcase-card";
import { getWorkoutTypeFormated } from "src/entities/workout-series/utils";
import { Button } from "src/components/atoms/button";
import { WorkoutSeries } from "src/entities/workout-series";
import { WorkoutResult } from "src/entities/workout-result";
import { Workout } from "src/entities/workout";

const WorkoutType = styled(Typography).attrs({ variant: "h5" })`
    color: #68d2ff;

    @media only screen and (max-width: 768px) {
        text-align: center;
    }
`;

const WorkoutTitle = styled(Typography).attrs({ variant: "h4" })`
    margin: 1vh 0;

    @media only screen and (max-width: 768px) {
        text-align: center;
    }
`;

const WorkoutQuantity = styled(Typography).attrs({ variant: "h5" })`
    margin: 2vh 0;
`;

const WorkoutDescription = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})`
    margin-bottom: 2vh;

    @media only screen and (max-width: 768px) {
        text-align: center;
    }
`;

const WorkoutQuantityArea = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.5vw;

    @media only screen and (max-width: 768px) {
        justify-content: center;
    }
`;

const WorkoutQuantityIcon = styled.img.attrs({ src: "/assets/gym_black.png" })`
    width: 21px;
    height: 21px;
`;

const WorkoutSeriesCardArea = styled.div`
    display: grid;
    row-gap: 2vh;
`;

const WorkoutFooterBase = styled.div`
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2vw;
`;

const CancelButton = styled(Button).attrs({
    color: "background",
    round: false,
})`
    padding: 1vh 2vw;
    width: 100%;
`;

const StartButton = styled(Button).attrs({
    round: false,
})`
    padding: 1vh 2vw;
    width: 100%;
`;

interface WorkoutFooterProps {
    goBack: () => void;
    startWorkout: () => void;
}

const WorkoutFooter: React.FC<WorkoutFooterProps> = (props) => {
    return (
        <WorkoutFooterBase>
            <CancelButton onClick={() => props.goBack()}>Voltar</CancelButton>
            <StartButton onClick={() => props.startWorkout()}>
                Iniciar
            </StartButton>
        </WorkoutFooterBase>
    );
};

interface HomeWorkoutTemplateProps {
    isLoading: boolean;
    result?: WorkoutResult;
    workout?: Workout;
    goToWorkoutSeries: (index: number) => void;
    goBackToWorkouts: () => void;
}

export const HomeWorkoutTemplate: React.FC<HomeWorkoutTemplateProps> = (
    props
) => {
    const [sortedItems, setSortedItems] = React.useState<WorkoutSeries[]>([]);

    const getQuantityText = () => {
        return new IntlMessageFormat(
            `{exercisesNumber, plural,
                =0 {Nenhum exercício}
                =1 {Um exercício}
                other {# Exercícios}
              }`,
            "pt-BR"
        ).format({ exercisesNumber: props.workout?.series.length });
    };

    const getSeriesStatus = (workoutSeries: WorkoutSeries): boolean => {
        return (
            props.result?.series.find(
                (series) => series.seriesId === workoutSeries.id
            ) !== undefined
        );
    };

    const startWorkout = () => {
        if (props.workout?.series[0]) {
            props.goToWorkoutSeries(0);
        }
    };

    React.useEffect(() => {
        const availableSeries = [...(props.workout?.series ?? [])];

        availableSeries.sort((a, b) => {
            if (a.createdAt && b.createdAt) {
                return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                );
            }

            return 0;
        });

        setSortedItems(availableSeries);
    }, [props.workout]);

    return (
        <HomeLayout
            isLoading={props.isLoading}
            footer={
                <WorkoutFooter
                    goBack={props.goBackToWorkouts}
                    startWorkout={startWorkout}
                />
            }
        >
            <WorkoutType>
                {getWorkoutTypeFormated(
                    props.workout?.type ?? "condicionamento_fisico"
                )}
            </WorkoutType>
            <WorkoutTitle>{props.workout?.name}</WorkoutTitle>
            <WorkoutQuantityArea>
                <WorkoutQuantityIcon />
                <WorkoutQuantity>{getQuantityText()}</WorkoutQuantity>
            </WorkoutQuantityArea>
            <WorkoutDescription>
                {props.workout?.description}
            </WorkoutDescription>
            <WorkoutSeriesCardArea>
                {sortedItems.map((workoutSeries, index) => (
                    <WorkoutSeriesShowcaseCard
                        key={workoutSeries.id}
                        workoutSeries={workoutSeries}
                        done={getSeriesStatus(workoutSeries)}
                        goToWorkoutSeries={() => props.goToWorkoutSeries(index)}
                    />
                ))}
            </WorkoutSeriesCardArea>
        </HomeLayout>
    );
};

import React from "react";
import { ExerciseBasicInfo } from "src/components/structures/admin-edit-exercise/exercise-basic-info";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import { AdminEditLayout } from "src/components/templates/layout/admin-edit-layout";
import { Exercise } from "src/entities/exercise";

interface AdminEditExerciseTemplateProps {
    isLoading: boolean;
    exercise?: Exercise;
    onCancel: () => void;
    onSave: (exercise: Exercise) => Promise<boolean>;
}

export const AdminEditExerciseTemplate: React.FC<AdminEditExerciseTemplateProps> = (
    props
) => {
    const [activeStep, setActiveStep] = React.useState(0);

    const getCurrentStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <ExerciseBasicInfo
                        exercise={props.exercise}
                        onCancel={props.onCancel}
                        onSave={props.onSave}
                    />
                );
        }
    };

    return (
        <AdminEditLayout
            title={
                Boolean(props.exercise)
                    ? "Editar Exercício"
                    : "Cadastro de Exercícios"
            }
            steps={[
                {
                    icon: "/assets/admin-step-menu/mdi_paste.png",
                },
            ]}
            stepClick={setActiveStep}
            activeStep={activeStep}
        >
            {props.isLoading ? <LoadingPage /> : getCurrentStepContent()}
        </AdminEditLayout>
    );
};

import dayjs from "dayjs";
import { FetchClient } from "src/infrastructure/http-client/fetch-client";
import { WorkoutEntity } from "src/entities/workout-entity";
import { SaveScheduledWorkoutEntityRequest } from "src/entities/requests/save-scheduled-workout-entity";

const fetchClient = new FetchClient();

export const awsScheduledWorkoutEntitySave = async (
    workoutEntity: SaveScheduledWorkoutEntityRequest
): Promise<WorkoutEntity | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/scheduled-workout-entity`;

        const response = await fetchClient.post<WorkoutEntity>(
            url,
            workoutEntity
        );

        return response;
    } catch (error) {
        console.log("error while creating workout entity", error);

        return null;
    }
};

export const awsScheduledWorkoutEntityGet = async (
    workoutId: string,
    entity: {
        id: string;
        type: "series";
    }
): Promise<WorkoutEntity | null> => {
    try {
        const params = `workoutId=${workoutId}&entityId=${entity.id}&entityType=${entity.type}`;
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/scheduled-workout-entity?${params}`;

        const response = await fetchClient.get<WorkoutEntity>(url);

        return response;
    } catch (error) {
        console.log("error while creating workout entity", error);

        return null;
    }
};

export const awsScheduledWorkoutEntityDelete = async (
    workoutId: string,
    condoId: string,
    date: Date,
    entity: {
        id: string;
        type: "series";
    }
): Promise<boolean> => {
    try {
        const timestamp = dayjs(date).format("YYYY-MM-DD");

        const params = `workoutId=${workoutId}&condoId=${condoId}&timestamp=${timestamp}&entityId=${entity.id}&entityType=${entity.type}`;

        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/scheduled-workout-entity?${params}`;

        await fetchClient.delete(url);

        return true;
    } catch (error) {
        console.log("error while deleting workout entity", error);

        return false;
    }
};

import dayjs from "dayjs";
import React from "react";
import { Button } from "src/components/atoms/button";
import { Typography } from "src/components/atoms/typography";
import styled from "styled-components";
import { useTimer } from "use-timer";

const FooterBase = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    align-content: center;
`;

const TimerArea = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding: 0 10vw;
    align-self: flex-start;
    margin-bottom: 1vh;
`;

const ButtonArea = styled.div`
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2vw;

    grid-template-areas: "cancel done";
`;

const CancelButton = styled(Button).attrs({
    color: "background",
    round: false,
})`
    grid-area: cancel;

    padding: 1vh 2vw;
    width: 100%;
`;

const StartButton = styled(Button).attrs({
    round: false,
})`
    grid-area: done;

    padding: 1vh 2vw;
    width: 100%;
`;

const ResetIcon = styled.img.attrs({
    src: "/assets/timer_reset.png",
})`
    width: 40px;
`;

const PauseIcon = styled.img.attrs({
    src: "/assets/timer_pause.png",
})`
    width: 40px;
`;

const TimerValue = styled(Typography).attrs({
    variant: "h2",
    color: "white",
})`
    text-align: center;
`;

interface HomeWorkoutSeriesFooterProps {
    isSubmitting: boolean;
    goBackToWorkout: () => void;
}

export const HomeWorkoutSeriesFooter: React.FC<HomeWorkoutSeriesFooterProps> = (
    props
) => {
    const { time, pause, reset, start, isRunning } = useTimer({
        initialTime: 0,
        timerType: "INCREMENTAL",
    });

    return (
        <FooterBase>
            <TimerArea>
                <ResetIcon onClick={() => reset()} />
                <TimerValue>
                    {dayjs(0)
                        .set("m", 0)
                        .set("s", 0)
                        .add(time, "s")
                        .format("mm:ss")}
                </TimerValue>
                <PauseIcon onClick={() => (isRunning ? pause() : start())} />
            </TimerArea>
            <ButtonArea>
                {!props.isSubmitting && (
                    <CancelButton onClick={() => props.goBackToWorkout()}>
                        Voltar
                    </CancelButton>
                )}
                <StartButton loading={props.isSubmitting} type="submit">
                    Realizado
                </StartButton>
            </ButtonArea>
        </FooterBase>
    );
};

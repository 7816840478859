import dayjs from "dayjs";
import IntlMessageFormat from "intl-messageformat";
import { WorkoutType } from "src/entities/workout";
import {
    LoadBase,
    WorkoutSeriesType,
    WorkoutSeries,
    RoundsBase,
    WorkoutExercise,
    WorkoutExercisesSection,
    WorkoutMultipleSeries,
    SeriesXRepeticao,
    IntervaladoMultiplo,
} from "src/entities/workout-series";

const getRoundsMessage = (series: number) =>
    new IntlMessageFormat(
        `{seriesNumber, plural,
    =0 {Nenhuma série}
    =1 {Uma série}
    other {# séries}
  }`,
        "pt-BR"
    ).format({ seriesNumber: series });

const getBreakMessage = (breakTime?: Date) => {
    if (!!breakTime) {
        return `Descanso: ${dayjs(breakTime).format("HH:mm:ss")}`;
    }
    return "Sem Descanso";
};

export const getWorkoutTypeColor = (workoutType?: WorkoutType) => {
    switch (workoutType) {
        case "condicionamento_fisico":
            return "#68D3FF";
        case "fortalecimento_muscular":
            return "#FFF6A3";
        case "personal_trainer":
            return "#FFA3A3";
        case "terceira_idade":
            return "#85FF8A";
        default:
            return "#F5F5F5";
    }
};

export const getWorkoutTypeFormated = (workoutType: WorkoutType): string => {
    switch (workoutType) {
        case "condicionamento_fisico":
            return "Condicionamento Físico";
        case "terceira_idade":
            return "Terceira Idade";
        case "fortalecimento_muscular":
            return "Fortalecimento Muscular";
        case "personal_trainer":
            return "Personal Trainer";
    }
};

export const getWorkoutSeriesLoadFormated = (load: LoadBase): string => {
    switch (load.type) {
        case "igual_maior_menor":
            return "";
        case "sem_carga_definida":
            return "Sem carga definida";
        case "percentual_carga_maxima":
            return `Percentual carga máxima: ${load.value}%`;
        case "percentual_peso_corporal":
            return `Percentual peso corporal: ${load.value}%`;
        case "carga_por_sexo":
            return `Carga Masc.: ${load.maleValue}Kg / Carga Fem.: ${load.femaleValue}Kg`;
    }
};

export const getWorkoutSeriesTypeFormated = (
    type: WorkoutSeriesType
): string => {
    switch (type) {
        case "maximo_carga":
            return "Máximo de Carga";
        case "maximo_repeticoes":
            return "Máximo de Repetições";
        case "morrer_repeticoes":
            return "Morrer por Repetições";
        case "morrer_carga":
            return "Morrer por Carga";
        case "morrer_repeticao":
            return "Morrer por Repetições";
        case "realizar_dentro_minuto":
            return "Realizar Dentro do Minuto";
        case "intervalado":
            return "Intervalado";
        case "repeticoes_tempo_determinado":
            return "Máximo de Repetições em Tempo Determinado";
        case "series_x_repeticao":
            return "Séries x Repetição";
        case "tempo":
            return "Por Tempo";
        case "rodadas_menor_tempo_fixas":
            return "Rodadas no Menor Tempo - Rep. Fixas";
        case "rodadas_menor_tempo_variadas":
            return "Rodadas no Menor Tempo - Rep. Variadas";
        case "realizar_dentro_minuto_multiplo":
            return "Realizar Dentro do Minuto";
        case "menor_tempo_possivel":
            return "Dentro do Menor Tempo Possível";
        case "maximo_repeticoes_x_tempo":
            return "Máximo de Repetições em X Tempo";
        case "maximo_rodadas_x_tempo":
            return "Máximo de Rodadas em X Tempo";
        case "intervalado_multiplo":
            return "Intervalado";
    }
};

export const getWorkoutExerciseDescription = (
    workoutExercise: WorkoutExercise,
    reps?: {
        start: number;
        increment: number;
    },
    rounds?: {
        breakTime?: Date;
        time?: Date;
        max?: number;
        total?: number;
    }
) => {
    let description: string[] = [];

    if (reps) {
        description.push(
            `${reps.start} Repetições + ${reps.increment} Repetições a cada rodada`
        );
    }

    if (rounds?.max) {
        const seriesMax = getRoundsMessage(rounds.max);
        description.push(`Máximo ${seriesMax}`);
    }

    if (rounds?.total && rounds?.time) {
        const seriesNumber = getRoundsMessage(rounds.total);
        description.push(
            `${seriesNumber} de ${dayjs(rounds.time).format("HH:mm:ss")}`
        );
    }

    if (rounds?.breakTime) {
        description.push(getBreakMessage(rounds.breakTime));
    }

    if (workoutExercise.goal.distance) {
        description.push(`${workoutExercise.goal.distance.join("/")} metros`);
    }

    if (workoutExercise.goal.height) {
        description.push(
            `${workoutExercise.goal.height.join("/")} centímetros`
        );
    }

    if (workoutExercise.goal.load) {
        description.push(`${workoutExercise.goal.load.join("/")} Kg`);
    }

    if (workoutExercise.goal.reps) {
        description.push(`${workoutExercise.goal.reps.join("/")} repetições`);
    }

    if (workoutExercise.goal.time) {
        description.push(`${workoutExercise.goal.time.join("/")} segundos`);
    }

    if (description.length === 0) {
        return "";
    }

    return `(${description.join(" / ")})`;
};

export const getWorkoutSeriesTitleFormated = (
    workoutSeries: WorkoutSeries,
    extended?: boolean
) => {
    if (workoutSeries?.style === "single") {
        return extended
            ? `${workoutSeries?.exercise.name} (${getWorkoutSeriesTypeFormated(
                  workoutSeries.type
              )})`
            : workoutSeries?.exercise.name;
    }

    if (extended) {
        return `EXERCÍCIO MÚLTIPLO (${getWorkoutSeriesTypeFormated(
            workoutSeries.type
        )})`;
    }

    return `EXERCÍCIO MÚLTIPLO`;
};

export const getWorkoutSeriesDescription = (
    workoutSeries: WorkoutSeries,
    index?: number
): string => {
    const getRepsMessage = (reps: number) =>
        new IntlMessageFormat(
            `{repsNumber, plural,
            =0 {Nenhuma repetição}
            =1 {Uma repetição}
            other {# repetições}
          }`,
            "pt-BR"
        ).format({ repsNumber: reps });

    const getMultipleRepsMessage = (rounds: RoundsBase[]) =>
        rounds.map((round) => round.reps).join(", ");

    const getIncrementRepsMessage = (rounds: RoundsBase[]) => {
        const startReps = rounds[0]?.reps;
        const incrementReps = (rounds[1]?.reps ?? 0) - (rounds[0]?.reps ?? 0);
        const roundTime = dayjs(rounds[0]?.roundTime).format("HH:mm:ss");

        return `${startReps} Repetições + ${incrementReps} Repetições a cada ${roundTime}`;
    };

    const getIncrementRepsLoadMessage = (rounds: RoundsBase[]) => {
        const startReps = rounds[0]?.reps ?? 0;
        const incrementLoad =
            (rounds[1]?.load.femaleValue ?? 0) -
            (rounds[0]?.load.femaleValue ?? 0);
        const roundTime = dayjs(rounds[0]?.roundTime).format("HH:mm:ss");

        return `${startReps} Repetições do peso inicial + ${incrementLoad} Kg a cada ${roundTime}`;
    };

    const getTotalTimeMessage = (totalTime: Date) => {
        return `Tempo total: ${dayjs(totalTime).format("HH:mm:ss")}`;
    };

    const getRoundTimeMessage = (roundTime?: Date) => {
        return roundTime ? `${dayjs(roundTime).format("HH:mm:ss")}` : "";
    };

    const getMultipleBreakMessage = (rounds: RoundsBase[]) =>
        `Descanso: ${rounds
            .map((round) => dayjs(round.breakTime).format("HH:mm:ss"))
            .join(", ")}`;

    const getLoadMessage = (load?: LoadBase) => {
        switch (load?.type) {
            case "carga_por_sexo":
                return `Carga Masc. ${load.maleValue}Kg / Carga Fem. ${load.femaleValue}Kg`;
            case "percentual_carga_maxima":
                return `Percentual Carga Máxima: ${load.value}%`;
            case "percentual_peso_corporal":
                return `Percentual Peso Corporal: ${load.value}%`;
            case "igual_maior_menor":
                if (load.value) {
                    return `Carga: ${load.value}Kg`;
                }
                if (load.higherLower === "higher") {
                    return `Carga Maior`;
                }
                return `Carga Menor`;
            case "sem_carga_definida":
                return `Sem Carga Definida`;
            default:
                return "";
        }
    };

    const getMultipleLoadMessage = (rounds?: RoundsBase[]) => {
        const loadType = rounds?.[0]?.load?.type;

        switch (loadType) {
            case "carga_por_sexo":
                return `Carga Masc. ${rounds
                    ?.map((round) => round.load?.maleValue)
                    .join(" / ")} Kg / Carga Fem. ${rounds
                    ?.map((round) => round.load?.femaleValue)
                    .join(" / ")} Kg`;
            case "percentual_carga_maxima":
                return `Percentual Carga Máxima: ${rounds
                    ?.map((round) => round.load?.value)
                    .join(" / ")}%`;
            case "percentual_peso_corporal":
                return `Percentual Peso Corporal: ${rounds
                    ?.map((round) => round.load?.value)
                    .join(" / ")}%`;
            case "igual_maior_menor":
                return `Percentual Peso Corporal: ${rounds
                    ?.map((round) => {
                        if (round.load.value) {
                            return `Carga: ${round.load.value}Kg`;
                        }
                        if (round.load.higherLower === "higher") {
                            return `Carga Maior`;
                        }
                        return `Carga Menor`;
                    })
                    .join(" / ")}%`;

            case "sem_carga_definida":
                return `Sem Carga Definida`;
            default:
                return "";
        }
    };

    const summarizeExercises = (exercises: WorkoutExercise[]) => {
        return exercises
            .map((exercise) => {
                return `${
                    exercise.exercise.name
                } ${getWorkoutExerciseDescription(exercise)}`;
            })
            .join("\n");
    };

    const summarizeSections = (
        sections: WorkoutExercisesSection[],
        skipBreakTime?: boolean
    ) => {
        return sections
            .map((section) => {
                const headers = [];

                if (section.rounds) {
                    headers.push(getRoundsMessage(section.rounds));
                }

                if (section.breakTime && skipBreakTime !== true) {
                    const breakTimeSeconds = getDateTimeInSeconds(
                        section.breakTime
                    );
                    const breakTime =
                        breakTimeSeconds > 0 ? section.breakTime : undefined;

                    headers.push(getBreakMessage(breakTime));
                }

                if (headers.length > 0) {
                    return `${headers.join(" / ")}\n${summarizeExercises(
                        section.exercises
                    )}`;
                }

                return summarizeExercises(section.exercises);
            })
            .join("\n\n");
    };

    const summarizeMultipleWorkoutSeries = (
        workoutSeries: WorkoutMultipleSeries
    ) => {
        const headers = [];

        if (workoutSeries.rounds) {
            headers.push(getRoundsMessage(workoutSeries.rounds));
        }

        if (workoutSeries.totalTime) {
            headers.push(getTotalTimeMessage(workoutSeries.totalTime));
        }

        if (workoutSeries.initialReps) {
            headers.push(
                `Início: ${getRepsMessage(workoutSeries.initialReps)}`
            );
        }

        if (workoutSeries.incrementReps) {
            headers.push(
                `Incremento: ${getRepsMessage(workoutSeries.incrementReps)}`
            );
        }

        return `${headers.join(" / ")}\n\n${summarizeSections(
            workoutSeries.sections
        )}`;
    };

    const summarizeMultipleIntervaladoWorkoutSeries = (
        workoutSeries: IntervaladoMultiplo
    ) => {
        const roundsMessage = getRoundsMessage(workoutSeries.rounds ?? 0);
        const roundTime = getRoundTimeMessage(
            workoutSeries.totalTime ?? new Date(0)
        );
        const breakTime = getBreakMessage(
            workoutSeries.sections[0]?.breakTime ?? new Date(0)
        );

        return `${roundsMessage} de ${roundTime} / ${breakTime}\n\n${summarizeSections(
            workoutSeries.sections,
            true
        )}`;
    };

    const getSeriesXRepeticao = (
        workoutSeries: SeriesXRepeticao,
        index?: number
    ) => {
        if (index !== undefined) {
            return `${getRoundsMessage(
                workoutSeries.rounds[index].reps ?? 0
            )} / ${getLoadMessage(
                workoutSeries.rounds[index]?.load
            )} / ${getBreakMessage(workoutSeries.rounds[index]?.breakTime)}`;
        }

        return `${getRoundsMessage(
            workoutSeries.rounds.length
        )} de ${getMultipleRepsMessage(
            workoutSeries.rounds
        )} repetições \n ${getMultipleBreakMessage(
            workoutSeries.rounds
        )} \n ${getMultipleLoadMessage(workoutSeries.rounds)}`;
    };

    switch (workoutSeries.type) {
        case "maximo_carga":
            return `${getRoundsMessage(
                workoutSeries.rounds.length
            )} / ${getRepsMessage(
                workoutSeries.rounds[0]?.reps ?? 0
            )} / ${getBreakMessage(workoutSeries.rounds[0]?.breakTime)}`;
        case "maximo_repeticoes":
            return `${getRoundsMessage(
                workoutSeries.rounds.length
            )} / ${getLoadMessage(
                workoutSeries.rounds[0]?.load
            )} / ${getBreakMessage(workoutSeries.rounds[0]?.breakTime)}`;
        case "series_x_repeticao":
            return getSeriesXRepeticao(workoutSeries, index);
        case "morrer_carga":
            return `${getIncrementRepsLoadMessage(workoutSeries.rounds)} / ${
                workoutSeries.rounds.length
            } Máximas / ${getLoadMessage(workoutSeries.rounds[0]?.load)}`;
        case "morrer_repeticao":
            return `${getIncrementRepsMessage(workoutSeries.rounds)} / ${
                workoutSeries.rounds.length
            } Máximas / ${getLoadMessage(workoutSeries.rounds[0]?.load)}`;
        case "realizar_dentro_minuto":
            return `Realizar ${getRepsMessage(
                workoutSeries.rounds[0]?.reps ?? 0
            )} a cada minuto / ${getRoundsMessage(
                workoutSeries.rounds.length
            )} / ${getLoadMessage(workoutSeries.rounds[0]?.load)}`;
        case "tempo":
            return `Realizar ${getRepsMessage(
                workoutSeries.rounds[0]?.reps ?? 0
            )} no menor tempo possível / ${getLoadMessage(
                workoutSeries.rounds[0]?.load
            )}`;
        case "repeticoes_tempo_determinado":
            return `${getRoundsMessage(
                workoutSeries.rounds.length
            )} de ${getRoundTimeMessage(
                workoutSeries.rounds[0]?.roundTime
            )} / ${getBreakMessage(
                workoutSeries.rounds[0]?.breakTime
            )} / ${getLoadMessage(workoutSeries.rounds[0]?.load)}`;
        case "intervalado":
            return `${getRoundsMessage(
                workoutSeries.rounds.length
            )} de ${getRoundTimeMessage(
                workoutSeries.rounds[0]?.roundTime
            )} / ${getBreakMessage(
                workoutSeries.rounds[0]?.breakTime
            )} / ${getLoadMessage(workoutSeries.rounds[0]?.load)}`;
        case "rodadas_menor_tempo_fixas":
        case "rodadas_menor_tempo_variadas":
        case "menor_tempo_possivel":
        case "realizar_dentro_minuto_multiplo":
        case "maximo_repeticoes_x_tempo":
        case "maximo_rodadas_x_tempo":
        case "morrer_repeticoes":
            return `${summarizeMultipleWorkoutSeries(workoutSeries)}`;
        case "intervalado_multiplo":
            return `${summarizeMultipleIntervaladoWorkoutSeries(
                workoutSeries
            )}`;
    }
};

export const getWorkoutSeriesTypeDescriptionText = (
    type: WorkoutSeriesType
): string => {
    switch (type) {
        case "maximo_repeticoes":
            return "Você deverá realizar o máximo de repetições possíveis sem pausa.";
        case "maximo_carga":
            return "Nesse exercício você deverá realizar as repetições com o máximo de carga que conseguir.";
        case "series_x_repeticao":
            return "Nesse exercício você deverá realizar a quantidade de séries e repetições conforme indicado.";
        case "morrer_repeticao":
        case "morrer_repeticoes":
            return "Você deverá realizar a quantidade de repetições dentro do tempo estipulado, aumentando a quantidade a cada rodada. O treino finaliza quando você não conseguir finalizar a quantidade prescrita dentro do tempo.";
        case "morrer_carga":
            return "Você deverá realizar a quantidade indicada com a maior carga possível.";
        case "realizar_dentro_minuto":
            return "Nesse exercício você deverá realizar a quantidade de repetições dentro do minuto.";
        case "realizar_dentro_minuto_multiplo":
            return "Nesse exercício você deverá realizar a quantidade de repetições dentro do minuto.";
        case "tempo":
            return "Você deverá realizar os exercícios no menor tempo possível.";
        case "repeticoes_tempo_determinado":
            return "Nesse exercício você deverá realizar a maior quantidade de repetições no tempo determinado.";
        case "rodadas_menor_tempo_fixas":
            return "Nesse exercício você deverá realizar a sequência no menor tempo possível.";
        case "rodadas_menor_tempo_variadas":
            return "Nesse exercício as repetições entre séries são diferentes e você deverá realizar a sequência no menor tempo possível.";
        case "menor_tempo_possivel":
            return "Nesse exercício você deverá realizar a sequência no menor tempo possível.";
        case "maximo_repeticoes_x_tempo":
            return "Você deverá realizar o máximo de repetições para cada exercício com base no tempo informado";
        case "maximo_rodadas_x_tempo":
            return "Você deverá realizar o máximo da sequência abaixo no tempo estimado.";
        case "intervalado":
            return "Realizar o máximo de repetições dento do tempo estimado, descansar e realizar novamente na quantidade de séries informadas.";
        case "intervalado_multiplo":
            return "Realizar o máximo de repetições dento do tempo estimado, descansar e realizar novamente na quantidade de séries informadas.";
    }
};

export const getDateTimeInSeconds = (dateTime: Date) => {
    const baseDate = dayjs(dateTime);

    return baseDate.hour() * 3600 + baseDate.minute() * 60 + baseDate.second();
};

import React from "react";
import noScroll from "no-scroll";
import { ModalContact } from "src/components/structures/landing-page/modal-contact";
import { SectionAboutUs } from "src/components/structures/landing-page/section-about-us";
import { SectionIntro } from "src/components/structures/landing-page/section-intro";
import { SectionOwners } from "src/components/structures/landing-page/section-owners";
import { SectionWorkouts } from "src/components/structures/landing-page/section-workouts";
import { LandingPageLayout } from "src/components/templates/layout/landing-page";
import { SectionTestemonials } from "../structures/landing-page/section-testemonials";
import {
    ScreenAlert,
    ScreenAlertProps,
} from "src/components/structures/shared/screen-alert";

interface LandingPageTemplate {
    goToLogin: () => void;
}

export const LandingPageTemplate: React.FC<LandingPageTemplate> = (props) => {
    const [modalContactOpen, setModalContactOpen] = React.useState(false);
    const [alertProps, setAlertProps] = React.useState<
        ScreenAlertProps | undefined
    >(undefined);

    React.useEffect(() => {
        if (modalContactOpen) {
            noScroll.on();
        } else {
            noScroll.off();
        }
    }, [modalContactOpen]);

    return (
        <LandingPageLayout
            openContactForm={() => setModalContactOpen(true)}
            goToLogin={props.goToLogin}
        >
            <SectionIntro />
            <SectionAboutUs openContactForm={() => setModalContactOpen(true)} />
            <SectionWorkouts
                openContactForm={() => setModalContactOpen(true)}
            />
            <SectionOwners openContactForm={() => setModalContactOpen(true)} />
            <SectionTestemonials
                openContactForm={() => setModalContactOpen(true)}
            />
            <ModalContact
                isOpen={modalContactOpen}
                onClose={(result) => {
                    if (result) {
                        setAlertProps({
                            handleClose: () => setAlertProps(undefined),
                            open: true,
                            message: "Mensagem enviada com sucesso!",
                            severity: "success",
                        });
                    }

                    setModalContactOpen(false);
                }}
                onSubmit={() => {}}
            />
            {alertProps && <ScreenAlert {...alertProps} />}
        </LandingPageLayout>
    );
};

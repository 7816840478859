import { FetchClient } from "src/infrastructure/http-client/fetch-client";
import { ViaCepResponse } from "./via-cep-response";

let client: FetchClient;
export const viaCepRequest = async (
    zipCode: string
): Promise<ViaCepResponse | null> => {
    try {
        if (!client) {
            client = new FetchClient();
        }

        const result = await client.get<ViaCepResponse>(
            `https://viacep.com.br/ws/${zipCode}/json`
        );

        return result;
    } catch {
        return null;
    }
};

import React from "react";
import Loader from "react-loader-spinner";

interface LoadingPageProps {
    size?: "sm" | "md" | "lg" | "xl";
}

export const LoadingPage: React.FC<LoadingPageProps> = (props) => {
    const getSize = () => {
        switch (props.size) {
            case "sm":
                return 20;
            case "md":
                return 50;
            case "lg":
                return 70;
            case "xl":
                return 100;
            default:
                return 100;
        }
    };

    return <Loader type="TailSpin" color="#000" height={getSize()} />;
};

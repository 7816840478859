import React, { useRef, useState } from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";
import {
    getBackgroundColor,
    getIsInputOnErrorState,
    getTextColor,
    InputBase,
    InputBaseError,
    InputBaseProps,
} from "../atoms/input-base";

interface InputRowProps {
    box: {
        backgroundColor: string;
        dense: boolean;
        noShadow: boolean;
        onError: boolean;
    };
    text: {
        color: string;
        transform: "uppercase" | "lowercase" | "capitalize" | "none";
        align: "center" | "left" | "right";
    };
}

const InputRow = styled.div<InputRowProps>`
    display: flex;
    align-items: center;
    justify-content: stretch;
    background-color: ${(props) => props.box.backgroundColor};
    padding: ${(props) => (props.box.dense ? "1vh 0.5vw" : "2vh 1.5vw")};
    box-shadow: ${(props) => {
        if (props.box.noShadow) {
            return "";
        }

        if (props.box.onError) {
            return `0 0 0 2px red`;
        }

        return "0px 3px 6px #00000029";
    }};
    height: 100%;
    border-radius: 7px;
    transition: box-shadow 0.5s;

    input {
        border: none;
        font-weight: 400;
        width: 100%;
        color: ${(props) => props.text.color};
        font-size: ${(props) => (props.box.dense ? "0.8rem" : "1.0rem")};
        background-color: ${(props) => props.box.backgroundColor};
        text-transform: ${(props) => props.text.transform};
        text-align: ${(props) => props.text.align ?? "left"};

        ::placeholder {
            font-weight: 100;
            opacity: 70%;
        }

        :focus {
            outline: none;
        }
    }
`;

const InputIcon = styled.i`
    opacity: 50%;
    cursor: pointer;
`;

interface ShowPasswordProps {
    showPassword: boolean;
    onClick: () => void;
}

const ShowPasswordIcon: React.FC<ShowPasswordProps> = (
    props: ShowPasswordProps
) => {
    return (
        <div
            onClick={() => {
                props.onClick();
            }}
        >
            {props.showPassword ? (
                <InputIcon className="fas fa-eye-slash"></InputIcon>
            ) : (
                <InputIcon className="fas fa-eye"></InputIcon>
            )}
        </div>
    );
};

const SearchIcon: React.FC = () => {
    return <InputIcon className="fas fa-search"></InputIcon>;
};

interface InputFieldProps extends Omit<InputBaseProps, "inputRef"> {
    type?: "text" | "email" | "password" | "number" | "search" | "tel" | "date";
    validations?: {
        mask?: string | (string | RegExp)[];
        min?: string;
        max?: string;
    };
}

export const InputField: React.FC<InputFieldProps> = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<InputBaseError | undefined>();
    const inputEl = useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        setError(props.error);
    }, [props.error]);

    const getFieldType = () => {
        if (props.type === "password") {
            return showPassword ? "text" : "password";
        }

        return props.type;
    };

    const inputRowProps: InputRowProps = {
        box: {
            dense: props.box?.dense ?? false,
            noShadow: props.box?.noShadow ?? false,
            backgroundColor: getBackgroundColor(props),
            onError: getIsInputOnErrorState(props),
        },
        text: {
            align: props.text?.align ?? "center",
            transform: props.text?.transform ?? "none",
            color: getTextColor(),
        },
    };

    return (
        <InputBase {...props} inputRef={inputEl} error={error}>
            <InputRow {...inputRowProps}>
                <InputMask
                    required={props.required}
                    type={getFieldType()}
                    mask={props.validations?.mask ?? ""}
                    placeholder={props.placeholder}
                    onChange={(event) => {
                        setError(undefined);

                        let value = event.currentTarget.value;

                        value = props.validations?.mask
                            ? value.replace(/[^a-zA-Z0-9]+/g, "")
                            : value;

                        props.onChange?.(value);
                    }}
                    onBlur={() => props.onValueComplete?.()}
                    onKeyPress={props.onKeyPress}
                    onKeyDown={props.onKeyDown}
                    onKeyUp={props.onKeyUp}
                    value={props.value}
                    disabled={props.disabled}
                    min={props.validations?.min}
                    max={props.validations?.max}
                    name={props.name}
                />
                {props.type === "password" && (
                    <ShowPasswordIcon
                        showPassword={showPassword}
                        onClick={() => setShowPassword(!showPassword)}
                    />
                )}
                {props.type === "search" && <SearchIcon />}
            </InputRow>
        </InputBase>
    );
};

import { FetchClient } from "src/infrastructure/http-client/fetch-client";
import { Condo } from "src/entities/condo";
import { Customer } from "src/entities/customer";

const fetchClient = new FetchClient();

export const awsCondoCreate = async (condo: Condo): Promise<Condo | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/condo`;

        const response = await fetchClient.post<Condo>(url, {
            ...condo,
        });

        return response;
    } catch (error) {
        console.log("error while creating condo", error);

        return null;
    }
};

export const awsCondoUpdate = async (
    condoCode: string,
    updateData: Partial<Condo>
): Promise<Condo | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/condo?id=${condoCode}`;

        const response = await fetchClient.patch<Condo>(url, {
            ...updateData,
        });

        return response;
    } catch (error) {
        console.log("error while updating condo", error);

        return null;
    }
};

export const awsCondoGet = async (condoCode: string): Promise<Condo | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/condo?id=${condoCode}`;

        const response = await fetchClient.get<Condo>(url);

        return response;
    } catch (error) {
        console.log("error while getting Condo", error);

        return null;
    }
};

export const awsCondoDelete = async (condoCode: string): Promise<boolean> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/condo?id=${condoCode}`;

        await fetchClient.delete(url);

        return true;
    } catch (error) {
        console.log("error while deleting Condo", error);

        return false;
    }
};

export const awsCondoGetAll = async (): Promise<Condo[]> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/condos`;

        const response = await fetchClient.get<Condo[]>(url);

        return response ?? [];
    } catch (error) {
        console.log("error while getting condos", error);

        return [];
    }
};

export const awsCondoGetAllByCustomer = async (
    customer: Customer
): Promise<Condo[]> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/condos?customer=${customer.document}`;

        const response = await fetchClient.get<Condo[]>(url);

        return response ?? [];
    } catch (error) {
        console.log("error while getting condos", error);

        return [];
    }
};

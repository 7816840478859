import React, { ReactNode } from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { theme, ColorType } from "src/style/theme";

const primaryColorSet = [
    theme.colors.primary.light,
    theme.colors.secondary.dark,
];
const secondaryColorSet = [
    theme.colors.secondary.light,
    theme.colors.background.light,
];
const backgroundColorSet = [
    theme.colors.background.light,
    theme.colors.secondary.dark,
];

interface BasicButtonProps {
    box: {
        color: string;
        noShadow: boolean;
        noPadding: boolean;
        round: boolean;
    };
    text: {
        color: string;
        bold: boolean;
        align: "right" | "left" | "center";
    };
}

const BasicButton = styled.button<BasicButtonProps>`
    font-size: 1.1rem;
    text-transform: uppercase;
    border-radius: ${(props) => (props.box.round ? "25px" : "10px")};
    cursor: pointer;
    box-shadow: ${(props) =>
        props.box.noShadow ? "" : "0px 3px 6px #00000029"};
    padding: ${(props) => {
        const paddingVertical = props.box.noPadding ? "0" : "2.5vh";
        const paddingHorizontal = props.box.noPadding ? "0" : "3vw";

        return `${paddingVertical} ${paddingHorizontal}`;
    }};
    font-weight: ${(props) => (props.text.bold ? "700" : "400")};
    text-align: ${(props) => props.text.align};
    vertical-align: middle;
    width: max-content;
    min-height: 8vh;
`;

const ContainedButton = styled(BasicButton)`
    border: none;
    color: ${(props) => props.text.color};
    background-color: ${(props) => props.box.color};
`;

interface ButtonProps {
    bold?: boolean;
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    noPadding?: boolean;
    noShadow?: boolean;
    round?: boolean;
    color?: ColorType;
    type?: "submit" | "button" | "reset";
    textAlign?: "right" | "left" | "center";
    style?: React.CSSProperties;
    onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button: React.FC<ButtonProps> = (props) => {
    const getBoxColor = () => {
        if (props.color === "background") {
            return backgroundColorSet[0];
        }

        if (props.color === "secondary") {
            return secondaryColorSet[0];
        }

        return primaryColorSet[0];
    };

    const getTextColor = () => {
        if (props.color === "background") {
            return backgroundColorSet[1];
        }

        if (props.color === "secondary") {
            return secondaryColorSet[1];
        }

        return primaryColorSet[1];
    };

    const boxColor = getBoxColor();
    const textColor = getTextColor();

    const basicButtonProps: BasicButtonProps = {
        box: {
            color: boxColor,
            noPadding: props.noPadding ?? props.loading ?? false,
            noShadow: props.noShadow ?? false,
            round: props.round ?? true,
        },
        text: {
            color: textColor,
            align: props.textAlign ?? "center",
            bold: props.bold ?? true,
        },
    };

    const newProps: any = { ...props };

    delete newProps.loading;

    return (
        <ContainedButton
            {...newProps}
            {...basicButtonProps}
            type={props.type ?? "button"}
            onClick={() => {
                if (!props.loading) {
                    props.onClick?.();
                }
            }}
        >
            {props.loading ? (
                <Loader type="Bars" color="#fff" height={30} />
            ) : (
                props.children
            )}
        </ContainedButton>
    );
};

import React from "react";
import styled from "styled-components";
import { InputField } from "src/components/molecules/input-field";
import { AdminLayout } from "src/components/templates/layout/admin-layout";
import { EquipmentCard } from "src/components/structures/admin-equipments/equipment-card";
import { Button } from "src/components/atoms/button";
import { EditEquipmentModal } from "src/components/structures/admin-equipments/edit-equipment-modal";
import { Equipment } from "src/entities/equipment";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import { ConfirmationModal } from "src/components/structures/shared/confirmation-modal";

const SearchBox = styled(InputField).attrs({
    type: "search",
    text: {
        align: "left",
    },
    placeholder: "Pesquise aqui",
})`
    width: 30vw;
    margin-top: 5vh;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Content = styled.div`
    margin: 5vh 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 2vh;
    column-gap: 2vw;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const ButtonAdd = styled(Button).attrs({
    round: false,
})`
    justify-self: flex-end;
`;

interface AdminEquipmentsTemplateProps {
    isLoading: boolean;
    equipments: Equipment[];
    saveEquipment: (equipment: Equipment) => Promise<boolean>;
    deleteEquipment: (equipment: Equipment) => Promise<boolean>;
}

export const AdminEquipmentsTemplate: React.FC<AdminEquipmentsTemplateProps> = (
    props
) => {
    const [equipmentToEdit, setEquipmentToEdit] = React.useState<
        Equipment | undefined
    >(undefined);
    const [equipmentToDelete, setEquipmentToDelete] = React.useState<
        Equipment | undefined
    >(undefined);
    const [filteredEquipments, setFilteredEquipments] = React.useState<
        Equipment[]
    >([]);
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [filterStr, setFilterStr] = React.useState("");

    const closeEditModal = () => {
        setEditModalOpen(false);
        setEquipmentToEdit(undefined);
    };

    const openEditModal = (equipment?: Equipment) => {
        setEditModalOpen(true);
        setEquipmentToEdit(equipment);
    };

    const closeDeleteModal = () => {
        setEquipmentToDelete(undefined);
        setDeleteModalOpen(false);
    };

    const openDeleteModal = (equipment?: Equipment) => {
        setEquipmentToDelete(equipment);
        setDeleteModalOpen(true);
    };

    React.useEffect(() => {
        const filteredResult = props.equipments.filter((equip) =>
            equip.name.toLowerCase().includes(filterStr.toLowerCase())
        );

        setFilteredEquipments(filteredResult);
    }, [filterStr, props.equipments]);

    return (
        <AdminLayout title="Gerenciamento de Equipamentos">
            <SearchBox onChange={setFilterStr} value={filterStr} />
            {props.isLoading ? (
                <LoadingPage />
            ) : (
                <Content>
                    {filteredEquipments.map((equipment, index) => (
                        <EquipmentCard
                            key={index}
                            title={equipment.name}
                            onDeleteClick={() => openDeleteModal(equipment)}
                            onEditClick={() => openEditModal(equipment)}
                        />
                    ))}
                </Content>
            )}
            <ButtonAdd onClick={() => setEditModalOpen(true)}>
                Novo Equipamento
            </ButtonAdd>
            <EditEquipmentModal
                equipment={equipmentToEdit}
                isOpen={editModalOpen}
                onClose={() => closeEditModal()}
                onSave={props.saveEquipment}
            />
            {equipmentToDelete && (
                <ConfirmationModal
                    isOpen={deleteModalOpen}
                    onClose={() => closeDeleteModal()}
                    onConfirm={() => props.deleteEquipment(equipmentToDelete)}
                />
            )}
        </AdminLayout>
    );
};

import React, { useState, CSSProperties } from "react";
import MaterialTable, { Column, Localization, Action } from "material-table";
import styled from "styled-components";

const StyledMaterialTable = styled(MaterialTable)``;

export interface TableAction<T> {
    icon: string;
    tooltip: string;
    onClick: (rowData: T) => void;
}

export interface TableProps<T> {
    data: object[];
    header: TableHeader<T>[];
    title?: string;
    style?: CSSProperties;
    export?: boolean;
    onClick?: (rowData: T | undefined) => void;
    actions?: TableAction<T>[];
    defaultPage?: number;
}

type TableDataType =
    | "boolean"
    | "time"
    | "numeric"
    | "date"
    | "datetime"
    | "currency"
    | undefined;

export interface TableCustomAction {
    title: string;
    onClick?: () => {};
}

export interface TableHeader<T> {
    title: string;
    field?: keyof T;
    type?: TableDataType;
    width?: number;
    render?: (rowData: T) => void;
    customFilterAndSearch?: (term: string, rowData: T) => boolean;
}

export const Table: <T extends any>(
    props: TableProps<T>
) => React.ReactElement<TableProps<T>> = (props) => {
    const [filteringActive, setFiltering] = useState(false);

    const localization: Localization = {
        body: {
            emptyDataSourceMessage: "Nenhum registro para exibir",
            addTooltip: "Adicionar",
            deleteTooltip: "Apagar",
            editTooltip: "Editar",
            filterRow: {
                filterTooltip: "Filtrar",
            },
            editRow: {
                deleteText: "Você tem certeza que deseja apagar esse registro?",
                cancelTooltip: "Cancelar",
                saveTooltip: "Salvar",
            },
        },
        grouping: {
            placeholder: "Arraste colunas...",
        },
        header: {
            actions: "Ações",
        },
        pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
            previousAriaLabel: "Página Anterior",
            previousTooltip: "Página Anterior",
            nextAriaLabel: "Próxima Página",
            nextTooltip: "Próxima Página",
            lastAriaLabel: "Última Página",
            lastTooltip: "Última Página",
        },
        toolbar: {
            addRemoveColumns: "Adicionar ou remover colunas",
            exportTitle: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            exportAriaLabel: "Exportar",
            nRowsSelected: "{0} registro(s) selecionados",
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar",
            showColumnsTitle: "Mostrar Colunas",
            showColumnsAriaLabel: "Mostrar Colunas",
        },
    };

    const actions: (Action<object> | ((rowData: object) => Action<object>))[] =
        props.actions?.map((action) => (rowData) => {
            return {
                icon: action.icon,
                tooltip: action.tooltip,
                isFreeAction: false,
                onClick: (_, rowData: any) => action.onClick(rowData),
            };
        }) ?? [];

    actions.push({
        icon: "filter_list",
        tooltip: "Filtrar lista",
        isFreeAction: true,
        onClick: (_e: any) => setFiltering(!filteringActive),
    });

    return (
        <StyledMaterialTable
            style={props.style}
            options={{
                exportButton: props.export,
                filtering: filteringActive,
                actionsColumnIndex: -1,
                showTitle: !!props.title,
                pageSize: props.defaultPage ?? 5,
                pageSizeOptions: [5, 10, 20, 50],
                rowStyle: {
                    fontSize: "0.8rem",
                },
            }}
            columns={props.header as Column<object>[]}
            data={props.data}
            title={props.title}
            actions={actions}
            localization={localization}
            onRowClick={(_e, rowData) => props.onClick?.(rowData as any)}
        />
    );
};

import React from "react";
import Modal from "react-modal";
import { Controller, useForm } from "react-hook-form";
import { Button } from "src/components/atoms/button";
import { InputField } from "src/components/molecules/input-field";
import {
    SelectField,
    SelectFieldOption,
} from "src/components/molecules/select-field";
import { Typography } from "src/components/atoms/typography";
import styled from "styled-components";
import { CondoEquipment, Equipment } from "src/entities/equipment";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        background: "none",
        width: "90%",
        maxWidth: "600px",
    },
    overlay: {
        backgroundColor: "rgb(0, 0, 0, 0.3)",
    },
};

const ModalContent = styled.form`
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 8vh 5vw;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "title title title title"
        "name name name quantity"
        "back-button back-button save-button save-button";
    align-items: center;
    row-gap: 3vh;
    column-gap: 1vw;
`;

const Title = styled(Typography).attrs({
    variant: "h2",
})`
    grid-area: title;
`;

const NameField = styled(SelectField).attrs({
    label: "Nome do equipamento",
    text: { align: "left" },
})`
    grid-area: name;
`;

const QuantityField = styled(InputField).attrs({
    label: "Quantidade",
})`
    grid-area: quantity;
`;

const ButtonBack = styled(Button).attrs({ color: "background", round: false })`
    grid-area: back-button;
    width: 100%;
`;

const ButtonSave = styled(Button).attrs({ round: false })`
    grid-area: save-button;
    width: 100%;
`;

interface CondoEquipmentModalProps {
    equipment?: CondoEquipment;
    equipments: Equipment[];
    isOpen: boolean;
    onClose: () => void;
    onSave: (equipment: CondoEquipment) => void;
}

export const CondoEquipmentModal: React.FC<CondoEquipmentModalProps> = (
    props
) => {
    const { control, formState, handleSubmit } = useForm();

    const { isSubmitting } = formState;

    Modal.setAppElement("#root");

    const getEquipmentsToSelect = (): SelectFieldOption[] => {
        return props.equipments.map((equipment) => ({
            label: equipment.name,
            value: equipment.id ?? "",
        }));
    };

    const getInitialSelectValue = (): string => {
        if (props.equipment?.id) {
            return props.equipment.id;
        }

        return getEquipmentsToSelect()[0].value;
    };

    const onSubmit = async (values: any) => {
        const equipmentId: string = values.name;

        const selectedEquipment =
            props.equipments.find((equip) => equip.id === equipmentId) ??
            props.equipments[0];

        await props.onSave({
            ...selectedEquipment,
            notes: values.quantity,
        });

        props.onClose();
    };

    return (
        <Modal
            style={customStyles}
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <ModalContent onSubmit={handleSubmit(onSubmit)}>
                <Title>Vincular Equipamento</Title>
                <Controller
                    as={<NameField options={getEquipmentsToSelect()} />}
                    rules={{ required: true }}
                    control={control}
                    defaultValue={getInitialSelectValue()}
                    name="name"
                />
                <Controller
                    as={<QuantityField />}
                    rules={{ required: true }}
                    control={control}
                    defaultValue={props.equipment?.notes ?? ""}
                    name="quantity"
                />

                {isSubmitting === false && (
                    <ButtonBack onClick={() => props.onClose()}>
                        Cancelar
                    </ButtonBack>
                )}
                <ButtonSave loading={isSubmitting} type="submit">
                    Salvar
                </ButtonSave>
            </ModalContent>
        </Modal>
    );
};

import React from "react";
import Select from "react-select";
import {
    getBackgroundColor,
    getIsInputOnErrorState,
    getTextColor,
    InputBase,
    InputBaseProps,
} from "src/components/atoms/input-base";
import styled from "styled-components";

interface StyledSelectProps {
    box: {
        backgroundColor: string;
        dense: boolean;
        noShadow: boolean;
        onError: boolean;
    };
    text: {
        color: string;
        transform: "uppercase" | "lowercase" | "capitalize" | "none";
        align: "center" | "left" | "right";
    };
}

const StyledSelect = styled(Select).attrs({})<StyledSelectProps>`
    [class*="control"] {
        border: none;
        box-shadow: none;

        background-color: ${(props) => props.box.backgroundColor};
        padding: ${(props) => (props.box.dense ? "1vh 0.5vw" : "1vh 1.5vw")};
        box-shadow: ${(props) => {
            if (props.box.noShadow) {
                return "";
            }

            if (props.box.onError) {
                return `0 0 0 2px red`;
            }

            return "0px 3px 6px #00000029";
        }};
        border-radius: 7px;
        height: 6vh;
    }
`;

export interface AutoCompleteFieldOption {
    label: string;
    value: string;
}
interface AutoCompleteFieldProps extends Omit<InputBaseProps, "inputRef"> {
    options: AutoCompleteFieldOption[];
    defaultValue?: string;
}

export const AutoCompleteField: React.FC<AutoCompleteFieldProps> = (props) => {
    const selectEl = React.useRef<any>(null);

    const styledSelectProps: StyledSelectProps = {
        box: {
            dense: props.box?.dense ?? false,
            noShadow: props.box?.noShadow ?? false,
            backgroundColor: getBackgroundColor(props),
            onError: getIsInputOnErrorState(props),
        },
        text: {
            align: props.text?.align ?? "center",
            transform: props.text?.transform ?? "none",
            color: getTextColor(),
        },
    };

    const getValue = () => {
        if (Boolean(props.value)) {
            return (
                props.options.find((option) => option.value === props.value)
                    ?.label ?? ""
            );
        }

        return "";
    };

    return (
        <InputBase {...props} inputRef={selectEl}>
            <StyledSelect
                {...styledSelectProps}
                options={props.options}
                value={{ label: getValue() }}
                onChange={(newOption: AutoCompleteFieldOption) => {
                    props.onChange?.(newOption.value);
                }}
                ref={selectEl}
            />
        </InputBase>
    );
};

import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { times } from "lodash";
import { UseFormMethods, Controller } from "react-hook-form";
import { ExerciseBaseCard } from "src/components/structures/home-workout-series/exercise-base-card";
import { Divider } from "src/components/structures/home-workout-series/divider";
import { ExerciseResultsBase } from "src/components/structures/home-workout-series/exercise-results-base";
import { InputField } from "src/components/molecules/input-field";
import { getWorkoutExerciseDescription } from "src/entities/workout-series/utils";
import { Exercise } from "src/entities/exercise";
import { Typography } from "src/components/atoms/typography";
import { MaximoRodadasXTempo } from "src/entities/workout-series";

const BaseSection = styled.div`
    display: grid;
    row-gap: 3vh;
`;

const ResultsArea = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2vw;
`;

const ExercisesResultArea = styled.div`
    display: grid;
    grid-auto-rows: 1fr;
    row-gap: 1vh;
`;

const RepsTotalField = styled(InputField).attrs({
    label: "Séries Realizadas",
    type: "number",
})`
    width: 100%;
`;

const ExerciseTotalField = styled(InputField).attrs({
    type: "number",
})`
    width: 100%;
`;

const SectionArea = styled.div`
    display: grid;
    row-gap: 1vh;
`;

const SectionTitle = styled(Typography).attrs({ variant: "h5" })``;

interface FrameMaximoRodadasXTempoProps {
    workoutSeries: MaximoRodadasXTempo;
    control: UseFormMethods["control"];
    exhibitExercise: (exercise: Exercise) => void;
}

export const FrameMaximoRodadasXTempo: React.FC<FrameMaximoRodadasXTempoProps> = (
    props
) => {
    return (
        <BaseSection>
            {times(props.workoutSeries?.rounds ?? 1, (index) => {
                const section = props.workoutSeries.sections[0];
                const totalTime = dayjs(props.workoutSeries.totalTime).format(
                    "HH:mm:ss"
                );

                return (
                    <SectionArea key={index}>
                        <SectionTitle>{`SÉRIE ${
                            index + 1
                        } - Tempo do Exercício: ${totalTime})`}</SectionTitle>
                        {section.exercises.map((exercise, exerciseIndex) => (
                            <ExerciseBaseCard
                                key={exerciseIndex}
                                title={exercise.exercise.name}
                                description={getWorkoutExerciseDescription(
                                    exercise
                                )}
                                onClick={() =>
                                    props.exhibitExercise(exercise.exercise)
                                }
                            />
                        ))}
                    </SectionArea>
                );
            })}

            <Divider />
            <ExerciseResultsBase control={props.control}>
                <ResultsArea>
                    <Controller
                        as={<RepsTotalField />}
                        control={props.control}
                        defaultValue={"5"}
                        name="reps"
                    />
                    <ExercisesResultArea>
                        {times(
                            props.workoutSeries.sections[0]?.exercises.length ??
                                0,
                            (index) => (
                                <Controller
                                    key={index}
                                    as={
                                        <ExerciseTotalField
                                            label={`Reps. Exerc. ${index + 1}`}
                                        />
                                    }
                                    control={props.control}
                                    defaultValue={"5"}
                                    name={`reps_${index}`}
                                />
                            )
                        )}
                    </ExercisesResultArea>
                </ResultsArea>
            </ExerciseResultsBase>
        </BaseSection>
    );
};

import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { AdminEditCondoTemplate } from "src/components/templates/admin-edit-condo-template";
import { Condo } from "src/entities/condo";
import {
    awsCondoCreate,
    awsCondoGet,
    awsCondoUpdate,
} from "src/infrastructure/aws/aws-condo";
import { CondoEquipment, Equipment } from "src/entities/equipment";
import { awsEquipmentGetAll } from "src/infrastructure/aws/aws-equipment";
import {
    awsCondoEntityCreate,
    awsCondoEntityDelete,
} from "src/infrastructure/aws/aws-condo-entity";

export const AdminEditCondoPage: React.FC = () => {
    const history = useHistory();
    const condoId = queryString.parse(useLocation().search)?.id ?? "";

    const [reloadCondo, setReloadCondo] = React.useState<boolean>(true);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [condo, setCondo] = React.useState<Condo | undefined>(undefined);
    const [equipments, setEquipments] = React.useState<Equipment[]>([]);

    const redirectCondosPage = () => {
        history.push("/admin/condominios");
    };

    const onCreateCondo = async (condo: Condo): Promise<boolean> => {
        const response = await awsCondoCreate(condo);

        setCondo(response ?? undefined);

        return Boolean(response);
    };

    const onUpdateCondo = async (
        id: string,
        updateData: Partial<Condo>
    ): Promise<boolean> => {
        const response = await awsCondoUpdate(id, updateData);

        return Boolean(response);
    };

    const onAddEquipment = async (newEquipment: CondoEquipment) => {
        const response = await awsCondoEntityCreate({
            condoId: condo?.id ?? "",
            entity: {
                id: newEquipment.id ?? "",
                type: "equipment",
            },
        });

        return Boolean(response);
    };

    const onDeleteEquipment = async (newEquipment: CondoEquipment) => {
        return await awsCondoEntityDelete({
            condoId: condo?.id ?? "",
            entity: {
                id: newEquipment.id ?? "",
                type: "equipment",
            },
        });
    };

    React.useEffect(() => {
        const retrieveEquipments = async () => {
            const newEquipments = await awsEquipmentGetAll();

            setEquipments(newEquipments);
        };

        retrieveEquipments();
    }, []);

    React.useEffect(() => {
        const retrieveCondoData = async () => {
            const condoQs = Array.isArray(condoId) ? condoId[0] : condoId;

            const newCondo = await awsCondoGet(condoQs);

            setCondo(newCondo ?? undefined);
            setIsLoading(false);
            setReloadCondo(false);
        };

        if (!!condoId && reloadCondo) {
            retrieveCondoData();
        } else {
            setIsLoading(false);
        }
    }, [condoId, reloadCondo]);

    return (
        <AdminEditCondoTemplate
            isLoading={isLoading}
            condo={condo}
            equipments={equipments}
            redirectCondosPage={redirectCondosPage}
            onCreateCondo={onCreateCondo}
            onUpdateCondo={onUpdateCondo}
            onAddEquipment={onAddEquipment}
            onDeleteEquipment={onDeleteEquipment}
        />
    );
};

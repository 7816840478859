import React from "react";
import Avatar from "react-avatar";
import { Button } from "src/components/atoms/button";
import { Typography } from "src/components/atoms/typography";
import { theme } from "src/style/theme";
import styled from "styled-components";

const Section = styled.div`
    width: 100vw;
    padding: 10vh 10vw;
    display: grid;

    @media only screen and (max-width: 768px) {
        padding: 0 10vw;

        justify-content: center;
        justify-items: center;

        text-align: center;
    }
`;

const Title = styled(Typography).attrs({ variant: "h1" })`
    text-transform: uppercase;
    text-shadow: 2px -2px ${theme.colors.primary.light};
`;

const SecondTitle = styled(Typography).attrs({ variant: "h1" })`
    text-transform: uppercase;
    margin: 0 20vw;
    justify-self: center;
    text-align: center;
    margin-top: 10vh;

    span {
        color: ${theme.colors.primary.light};
    }

    @media only screen and (max-width: 768px) {
        margin: 0;
        margin-top: 5vh;
    }
`;

const Subtitle = styled(Typography).attrs({ variant: "h5", fontWeight: "400" })`
    margin: 0 20vw;
    justify-self: center;
    text-align: center;
    margin-top: 3vh;

    @media only screen and (max-width: 768px) {
        margin: 0;
        margin-top: 3vh;
    }
`;

const Divider = styled.div`
    margin: 3vh 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.7);
    width: 40vw;

    @media only screen and (max-width: 768px) {
        width: 90%;
    }
`;

const SignupButton = styled(Button)`
    justify-self: center;
    margin-top: 5vh;
`;

const TestemonialsRow = styled.div`
    margin: 5vh 0;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    justify-items: center;
    column-gap: 5vw;

    @media only screen and (max-width: 768px) {
        grid-auto-flow: row;
        grid-auto-rows: auto;
        row-gap: 5vh;
    }
`;

const TestemonialItem = styled.div`
    display: grid;
    row-gap: 3vh;
    justify-items: center;
    align-content: flex-start;
`;

const TestemonialPhoto = styled(Avatar).attrs({ size: "10vh", round: true })`
    box-shadow: 4px 3px 0px ${theme.colors.primary.light};
`;

const TestemonialName = styled(Typography).attrs({ variant: "h5" })`
    text-transform: uppercase;
`;

const TestemonialDescription = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})`
    text-align: center;
    line-height: 2rem;
`;

interface SectionTestemonialsProps {
    openContactForm: () => void;
}

export const SectionTestemonials: React.FC<SectionTestemonialsProps> = (
    props
) => {
    return (
        <Section>
            <Title>O que nossos usuários falam</Title>
            <Divider />
            <TestemonialsRow>
                <TestemonialItem>
                    <TestemonialPhoto
                        name="Ana Paula"
                        src="/assets/depoimentos/ana_paula.jpeg"
                    />
                    <TestemonialName>Ana Paula</TestemonialName>
                    <TestemonialDescription>
                        Trabalho maravilhoso, dedicação 100%. Com esse método de
                        treinamento eu criei o gosto pela atividade física e
                        consegui inlcuir uma rotina saudável na vida.
                    </TestemonialDescription>
                </TestemonialItem>
                <TestemonialItem>
                    <TestemonialPhoto
                        name="Rodrigo Rodrigues"
                        src="/assets/depoimentos/rodrigo.jpeg"
                    />
                    <TestemonialName>Rodrigo</TestemonialName>
                    <TestemonialDescription>
                        Os treinos hoje são peças fundamentais no que diz
                        respeito a minha saúde e bem estar, fatores que me
                        possibilitam estar em boa forma física e estar sempre
                        disposto, para ser um melhor pai, marido e profissional!
                    </TestemonialDescription>
                </TestemonialItem>
                <TestemonialItem>
                    <TestemonialPhoto
                        name="Eduarda Soares"
                        src="/assets/depoimentos/eduarda.jpeg"
                    />
                    <TestemonialName>Eduarda</TestemonialName>
                    <TestemonialDescription>
                        Eu não gostava muito de treinar e hoje em dia não
                        consigo ficar sem. Meu horário de treino é sagrado e
                        meus dias ficam com muito mais energia após o treino.
                    </TestemonialDescription>
                </TestemonialItem>
            </TestemonialsRow>
            <SecondTitle>
                E VOCÊ, VAI FICAR DE FORA DA <span>TREINADO?</span>
            </SecondTitle>
            <Subtitle>
                Entre em contato conosco agora e traga mais saúde para sua vida!
            </Subtitle>
            <SignupButton onClick={() => props.openContactForm()}>
                Entre em contato
            </SignupButton>
        </Section>
    );
};

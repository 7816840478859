import { FetchClient } from "src/infrastructure/http-client/fetch-client";
import { WorkoutEntity } from "src/entities/workout-entity";

const fetchClient = new FetchClient();

export const awsWorkoutEntityCreate = async (
    workoutEntity: WorkoutEntity
): Promise<WorkoutEntity | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/workout-entity`;

        const response = await fetchClient.post<WorkoutEntity>(
            url,
            workoutEntity
        );

        return response;
    } catch (error) {
        console.log("error while creating workout entity", error);

        return null;
    }
};

export const awsWorkoutEntityUpdate = async (
    workoutEntity: WorkoutEntity
): Promise<WorkoutEntity | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/workout-entity`;

        const response = await fetchClient.patch<WorkoutEntity>(
            url,
            workoutEntity
        );

        return response;
    } catch (error) {
        console.log("error while updating workout entity", error);

        return null;
    }
};

export const awsWorkoutEntityDelete = async (
    workoutId: string,
    entity: {
        id: string;
        type: "series";
    }
): Promise<boolean> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/workout-entity?workoutId=${workoutId}&entityId=${entity.id}&entityType=${entity.type}`;

        await fetchClient.delete(url);

        return true;
    } catch (error) {
        console.log("error while deleting workout entity", error);

        return false;
    }
};

import React from "react";
import styled from "styled-components";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { Typography } from "src/components/atoms/typography";
import { Workout, WorkoutType } from "src/entities/workout";
import { Button } from "src/components/atoms/button";
import { theme } from "src/style/theme";

const Content = styled.form`
    display: grid;
    row-gap: 3vh;
    column-gap: 2vw;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "title title"
        ". ."
        "audience audience"
        ". buttons";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "title"
            "audience"
            "buttons";
    }
`;

const Title = styled(Typography).attrs({ variant: "h3" })`
    grid-area: title;
`;

const AudienceArea = styled.div`
    grid-area: audience;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 2vw;
    row-gap: 2vh;

    @media only screen and (max-width: 768px) {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr;
    }
`;

interface AudienceButton {
    backgroundColor: string;
}

const AudienceButton = styled.div<AudienceButton>`
    background: ${theme.colors.background.dark};
    border-radius: 22px;
    display: grid;
    align-content: center;
    justify-items: center;
    padding: 10vh 2vw;
    font-weight: 700;
    user-select: none;
    text-align: center;

    &.selected {
        background-color: ${theme.colors.primary.light};
    }

    @media only screen and (max-width: 768px) {
        padding: 3vh 2vw;
    }

    transition: background-color 0.4s;
`;

const ButtonsArea = styled.div`
    grid-area: buttons;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-end;
    column-gap: 2vw;
    grid-template-areas: "cancel submit";

    @media only screen and (max-width: 768px) {
        justify-content: stretch;
    }
`;

const ButtonCancel = styled(Button).attrs({
    color: "background",
    round: false,
})`
    width: 100%;

    grid-area: cancel;
`;

const ButtonNext = styled(Button).attrs({ round: false })`
    width: 100%;

    grid-area: submit;
`;

interface WorkoutAudienceProps {
    workout?: Partial<Workout>;
    onCancel: () => void;
    onSave: (updateData: Partial<Workout>) => Promise<boolean>;
    goToNextFrame: () => void;
}

export const WorkoutAudience: React.FC<WorkoutAudienceProps> = (props) => {
    const { formState, handleSubmit } = useForm();

    const { isSubmitting } = formState;

    const [selected, setSelected] = React.useState<number | undefined>(
        undefined
    );

    React.useEffect(() => {
        let newType: number;

        switch (props.workout?.type) {
            case "condicionamento_fisico":
                newType = 0;
                break;
            case "terceira_idade":
                newType = 1;
                break;
            case "fortalecimento_muscular":
                newType = 2;
                break;
            case "personal_trainer":
                newType = 3;
                break;
            default:
                newType = 0;
        }

        setSelected(newType);
    }, [props.workout]);

    const onSubmit = async () => {
        let type: WorkoutType;

        switch (selected) {
            case 0:
                type = "condicionamento_fisico";
                break;
            case 1:
                type = "terceira_idade";
                break;
            case 2:
                type = "fortalecimento_muscular";
                break;
            case 3:
                type = "personal_trainer";
                break;
            default:
                type = "condicionamento_fisico";
        }

        const result = await props.onSave({
            type,
        });

        if (result) {
            props.goToNextFrame();
        }
    };

    return (
        <Content onSubmit={handleSubmit(onSubmit)}>
            <Title>Selecione o perfil do Treino</Title>
            <AudienceArea>
                <AudienceButton
                    backgroundColor="#68D3FF"
                    className={clsx({ selected: selected === 0 })}
                    onClick={() => setSelected(0)}
                >
                    Condicionamento Físico
                </AudienceButton>
                <AudienceButton
                    backgroundColor="#85FF8A"
                    className={clsx({ selected: selected === 1 })}
                    onClick={() => setSelected(1)}
                >
                    Terceira Idade
                </AudienceButton>
                <AudienceButton
                    backgroundColor="#FFA3A3"
                    className={clsx({ selected: selected === 2 })}
                    onClick={() => setSelected(2)}
                >
                    Fortalecimento Muscular
                </AudienceButton>
                <AudienceButton
                    backgroundColor="#FFF6A3"
                    className={clsx({ selected: selected === 3 })}
                    onClick={() => setSelected(3)}
                >
                    Personal Trainer
                </AudienceButton>
            </AudienceArea>
            <ButtonsArea>
                {!isSubmitting && (
                    <ButtonCancel onClick={() => props.onCancel()}>
                        Cancelar
                    </ButtonCancel>
                )}
                <ButtonNext loading={isSubmitting} type="submit">
                    Salvar
                </ButtonNext>
            </ButtonsArea>
        </Content>
    );
};

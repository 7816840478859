import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { Button } from "src/components/atoms/button";
import { InputField } from "src/components/molecules/input-field";
import { Typography } from "src/components/atoms/typography";
import { SignupBaseFrame } from "src/components/structures/signup/signup-base-frame";

const Title = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "400",
})`
    text-align: center;
    margin: 3vh 0;
`;

const Description = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "400",
})`
    text-align: right;
    margin: 3vh 0;
`;

const EmailCodeField = styled(InputField).attrs({
    label: "Código de ativação",
    placeholder: "Código enviado por e-mail",
    type: "password",
    box: { light: true },
    text: { align: "left" },
})`
    width: 100%;
`;

const ButtonContinue = styled(Button).attrs({ round: false })`
    justify-self: flex-end;
    width: 50%;
    margin-top: 2vh;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface SignupConfirmEmailProps {
    goToNextPage: () => void;
    confirmEmail: (code: string) => Promise<boolean>;
}

export const SignupConfirmEmail: React.FC<SignupConfirmEmailProps> = (
    props
) => {
    const { control, formState, handleSubmit, errors, setError } = useForm();
    const { isSubmitting } = formState;

    const onSubmit = async (values: any) => {
        const response = await props.confirmEmail(values.emailCode);

        if (!response) {
            setError("emailCode", {
                type: "manual",
                message: "Código inválido, por favor tente novamente",
            });

            return;
        }

        props.goToNextPage();
    };

    return (
        <SignupBaseFrame onSubmit={handleSubmit(onSubmit)}>
            <Title>
                Foi enviado para o e-mail cadastrado um código de ativação. Por
                favor, informe o código abaixo:
            </Title>
            <Controller
                as={
                    <EmailCodeField
                        error={{
                            isInvalid: !!errors.emailCode,
                            message: errors.emailCode?.message,
                        }}
                    />
                }
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="emailCode"
            />
            <Description>
                Ao clicar em avançar, você concorda com a{" "}
                <strong>Política e Termos</strong> do Treinado.
            </Description>
            <ButtonContinue loading={isSubmitting} type="submit">
                AVANÇAR
            </ButtonContinue>
        </SignupBaseFrame>
    );
};

import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Exercise } from "src/entities/exercise";
import { Typography } from "src/components/atoms/typography";
import { LoadingPage } from "src/components/structures/shared/loading-page";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        background: "none",
        width: "100%",
        height: "100%",
    },
    overlay: {
        backgroundColor: "rgb(0, 0, 0, 0.3)",
    },
};

const ModalContent = styled.form`
    height: 100%;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 4vh 3vw;
    border-radius: 1rem;
    overflow-y: auto;
`;

const CloseButton = styled.i.attrs({
    className: "fas fa-times",
})`
    position: absolute;
    top: 5vh;
    right: 3vw;
    cursor: pointer;
    z-index: 99;
    opacity: 70%;

    @media only screen and (max-width: 768px) {
        top: 4vh;
        right: 7vw;
        font-size: 2rem;
    }
`;

const Title = styled(Typography).attrs({
    variant: "h4",
})``;

const Subtitle = styled(Typography).attrs({
    variant: "h4",
})``;

const Description = styled(Typography).attrs({
    variant: "h4",
    fontWeight: "400",
})``;

const VideoPlayer = styled.iframe.attrs({
    id: "ytplayer",
    type: "text/html",
    frameborder: "0",
})`
    width: 100% !important;
    height: 50vw !important;
    border: none;
`;

const ExerciseContent = styled.div`
    overflow-y: auto;

    display: grid;
    row-gap: 2vh;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    align-content: flex-start;
`;

interface ModalExerciseProps {
    exerciseId: string;
    isOpen: boolean;
    getExercise: (exerciseId: string) => Promise<Exercise | null>;
    onClose: () => void;
}

export const ModalExercise: React.FC<ModalExerciseProps> = (props) => {
    Modal.setAppElement("#root");

    const [isLoading, setIsLoading] = React.useState(true);
    const [exercise, setExercise] = React.useState<Exercise | null>();

    React.useEffect(() => {
        const retrieveExercise = async () => {
            const newExercise = await props.getExercise(props.exerciseId);

            setExercise(newExercise);
            setIsLoading(false);
        };

        retrieveExercise();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.exerciseId]);

    const getVideoUrl = (exercise: Exercise) => {
        const videoId = exercise.videoUrl.split("v=");

        return `https://www.youtube.com/embed/${videoId.slice(-1)[0]}`;
    };

    const showPreparationNotes = () => {
        return (exercise?.preparationNotes?.length ?? 0) > 3;
    };

    const showExecutionNotes = () => {
        return (exercise?.executionNotes?.length ?? 0) > 3;
    };

    const showGeneralNotes = () => {
        return (exercise?.generalNotes?.length ?? 0) > 3;
    };

    const getModalWithExercise = () => {
        return exercise ? (
            <ExerciseContent>
                <Title>{exercise.name}</Title>
                <VideoPlayer src={getVideoUrl(exercise)} />
                {showPreparationNotes() && <Subtitle>Preparação</Subtitle>}
                {showPreparationNotes() && (
                    <Description>{exercise.preparationNotes}</Description>
                )}
                {showExecutionNotes() && <Subtitle>Execução</Subtitle>}
                {showExecutionNotes() && (
                    <Description>{exercise.executionNotes}</Description>
                )}
                {showGeneralNotes() && <Subtitle>Dicas</Subtitle>}
                {showGeneralNotes() && (
                    <Description>{exercise.generalNotes}</Description>
                )}
            </ExerciseContent>
        ) : (
            <div>Exercício não encontrado</div>
        );
    };

    return (
        <Modal
            style={customStyles}
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
        >
            <ModalContent>
                <CloseButton onClick={() => props.onClose()} />
                {isLoading ? <LoadingPage /> : getModalWithExercise()}
            </ModalContent>
        </Modal>
    );
};

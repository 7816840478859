import React from "react";
import styled from "styled-components";
import { WorkoutExercise } from "src/entities/workout-series";
import { Typography } from "src/components/atoms/typography";
import { getWorkoutExerciseDescription } from "src/entities/workout-series/utils";

const WorkoutExerciseCardBase = styled.div`
    background: #f5f5f5;
    border-radius: 6px;
    padding: 2vh 1vw;
    margin-bottom: 2vh;

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr auto auto;
    column-gap: 1vw;
`;

const WorkoutExerciseCardTitle = styled(Typography).attrs({
    variant: "h5",
})``;

const WorkoutExerciseCardDescription = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})``;

const WorkoutExerciseCardCopy = styled.img.attrs({
    src: "/assets/mdi_duplicate.png",
})`
    height: 25px;
    cursor: pointer;
`;

const WorkoutExerciseCardEdit = styled.img.attrs({
    src: "/assets/mdi_edit.png",
})`
    height: 25px;
    cursor: pointer;
`;

const WorkoutExerciseCardDelete = styled.img.attrs({
    src: "/assets/mdi_delete.png",
})`
    height: 25px;
    cursor: pointer;
`;

interface WorkoutExerciseCardProps {
    workoutExercise: WorkoutExercise;
    deleteExercise: () => void;
    editExercise: () => void;
    copyExercise: () => void;
    innerRef?: any;
}

export const WorkoutExerciseCard: React.FC<WorkoutExerciseCardProps> = (
    props
) => {
    return (
        <WorkoutExerciseCardBase ref={props.innerRef} {...props}>
            <WorkoutExerciseCardTitle>
                {props.workoutExercise.exercise.name}
            </WorkoutExerciseCardTitle>
            <WorkoutExerciseCardDescription>
                {getWorkoutExerciseDescription(props.workoutExercise)}
            </WorkoutExerciseCardDescription>
            <WorkoutExerciseCardCopy onClick={() => props.copyExercise()} />
            <WorkoutExerciseCardDelete onClick={() => props.deleteExercise()} />
            <WorkoutExerciseCardEdit onClick={() => props.editExercise()} />
        </WorkoutExerciseCardBase>
    );
};

import React from "react";
import styled from "styled-components";
import { Typography } from "src/components/atoms/typography";
import { theme } from "src/style/theme";

const Background = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    z-index: 1;

    background: #000 url("/assets/background_intro.png") no-repeat;
    background-size: cover;

    display: grid;
    row-gap: 2rem;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
        "link"
        "logo"
        "content"
        "footer";

    padding: ${theme.padding.vertical.normal} ${theme.padding.horizontal.dense};
    overflow-y: hidden;

    ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: -1;
    }

    @media only screen and (max-width: 768px) {
        overflow-y: auto;
        height: fit-content;
        min-height: 100vh;
        padding: ${() => {
            const vertical = theme.padding.vertical.dense;
            const horizontal = theme.padding.horizontal.dense;

            return `${vertical} ${horizontal}`;
        }};
    }
`;

const Content = styled.div`
    grid-area: content;
    padding: 2vh 0;

    display: grid;
    justify-items: center;
    align-content: flex-start;
    width: 100%;
    overflow-y: auto;

    @media only screen and (max-width: 768px) {
        overflow-y: none;
        padding-bottom: 5vh;
    }
`;

const Footer = styled.div`
    grid-area: footer;

    color: white;
    display: grid;
    grid-auto-flow: column;
    width: 100%;
    justify-items: center;

    @media only screen and (max-width: 768px) {
        grid-auto-flow: row;
        row-gap: 2vh;
    }
`;

const Logo = styled.img.attrs({ src: "/assets/logo_white.png" })`
    width: 15rem;
    grid-area: logo;
    margin-bottom: 5vh;
`;

const SignupLink = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "400",
})`
    grid-area: link;

    justify-self: flex-end;
    margin-right: 20vw;
    cursor: pointer;
    user-select: none;

    @media only screen and (max-width: 768px) {
        margin-right: 10vw;
        margin-bottom: 5vh;
    }
`;

interface LoginLayoutProps {
    goToLink: () => void;
    linkLabel: string;
}

export const LoginLayout: React.FC<LoginLayoutProps> = (props) => {
    return (
        <Background>
            <SignupLink onClick={() => props.goToLink()}>
                {props.linkLabel}
            </SignupLink>
            <Logo />
            <Content>{props.children}</Content>
            <Footer>
                <Typography variant="h5" color="white" fontWeight="400">
                    Política e Termos de Uso
                </Typography>
                <Typography
                    variant="h5"
                    color="white"
                    fontWeight="400"
                    className="hide-mobile"
                >
                    Contato
                </Typography>
                <Typography
                    variant="h5"
                    color="white"
                    fontWeight="400"
                    className="hide-mobile"
                >
                    © Copyright 2020 Treinado. CNPJ 35.402.326/0001-20
                </Typography>
                <Typography
                    variant="h5"
                    color="white"
                    fontWeight="400"
                    className="mobile-only"
                >
                    © Copyright 2020 Treinado
                </Typography>
            </Footer>
        </Background>
    );
};

import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "src/style/theme";
import { Collapse } from "@material-ui/core";

interface InputBoxProps {
    label: {
        color: string;
    };
    box: {
        dense: boolean;
    };
}

const InputBox = styled.div<InputBoxProps>`
    border: none;
    height: min-content;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${(props) =>
        props.box.dense ? "auto 0.5vh auto auto" : "auto 0.7vh auto auto"};
    grid-template-areas:
        "label"
        "."
        "content"
        "error";
    min-height: 7vh;

    label {
        grid-area: label;
        color: ${(props) => props.label.color};
        font-size: ${(props) => (props.box.dense ? "0.6rem" : "0.8rem")};
        font-weight: 700;
    }

    .content {
        grid-area: content;
        width: 100%;
    }

    .input-error {
        grid-area: error;
        color: red;
        font-size: 0.8rem;
        margin-top: 1vh;
        font-weight: 700;
    }
`;

export interface InputBaseError {
    isInvalid?: boolean;
    message?: string;
}

export interface InputBaseProps {
    inputRef: React.RefObject<any>;
    label?: string;
    value?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    className?: string;
    required?: boolean;
    name?: string;
    placeholder?: string;
    box?: {
        dense?: boolean;
        noShadow?: boolean;
        transparent?: boolean;
        light?: boolean;
    };
    text?: {
        transform?: "uppercase" | "lowercase" | "capitalize" | "none";
        align?: "center" | "left" | "right";
    };
    error?: InputBaseError;
    onChange?: (value: string) => void;
    onKeyPress?: (event: any) => void;
    onKeyDown?: (event: any) => void;
    onKeyUp?: (event: any) => void;
    onValueComplete?: () => void;
}

export const getBackgroundColor = (props: Omit<InputBaseProps, "inputRef">) => {
    if (props.disabled) {
        return "#CCCCCC";
    }

    return theme.colors.background.light;
};

export const getIsInputOnErrorState = (
    props: Omit<InputBaseProps, "inputRef">
) => {
    return Boolean(props.error?.isInvalid) && props.disabled !== true;
};

export const getTextColor = () => {
    return theme.colors.secondary.dark;
};

export const InputBase: React.FC<InputBaseProps> = (props) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    React.useEffect(() => {
        if (props.error?.message) {
            setErrorMessage(props.error.message);
        }
    }, [props.error]);

    const setFocus = () => {
        props.inputRef.current?.focus();
    };

    return (
        <InputBox
            label={{
                color: props.box?.light
                    ? theme.colors.background.light
                    : theme.colors.secondary.dark,
            }}
            box={{
                dense: props.box?.dense ?? false,
            }}
            className={props.className}
            style={props.style}
            onClick={() => setFocus()}
        >
            <label>{props.label}</label>
            <div className="content">{props.children}</div>
            {errorMessage && (
                <div className="input-error">
                    <Collapse in={props.error?.isInvalid ?? false}>
                        {errorMessage}
                    </Collapse>
                </div>
            )}
        </InputBox>
    );
};

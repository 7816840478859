import React from "react";
import { useHistory } from "react-router-dom";
import { ForgotPasswordTemplate } from "src/components/templates/forgot-password-template";
import {
    awsAuthConfirmForgotPassword,
    awsAuthForgotPassword,
} from "src/infrastructure/aws/aws-amplify";

export const ForgotPasswordPage: React.FC = () => {
    const history = useHistory();

    const [email, setEmail] = React.useState("");

    const goBack = () => {
        history.push("/login");
    };

    const sendForgotPasswordEmail = async (email: string) => {
        setEmail(email);
        return await awsAuthForgotPassword(email);
    };

    const setNewPassword = async (code: string, newPassword: string) => {
        return await awsAuthConfirmForgotPassword(email, code, newPassword);
    };

    const redirectOnFinish = () => {
        history.push("/login");
    };

    return (
        <ForgotPasswordTemplate
            goBack={goBack}
            sendForgotPasswordEmail={sendForgotPasswordEmail}
            setNewPassword={setNewPassword}
            redirectOnFinish={redirectOnFinish}
        />
    );
};

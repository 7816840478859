import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Button } from "src/components/atoms/button";
import { Typography } from "src/components/atoms/typography";
import { Calendar } from "src/components/molecules/calendar";
import { InputField } from "src/components/molecules/input-field";
import { Condo } from "src/entities/condo";
import { Checkbox } from "src/components/atoms/checkbox";

const Content = styled.form`
    display: grid;
    row-gap: 3vh;
    column-gap: 2vw;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "title title"
        "calendar condos"
        "buttons buttons";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "title"
            "calendar"
            "condos"
            "buttons";
    }
`;

const CalendarArea = styled.div`
    grid-area: calendar;

    display: grid;
    row-gap: 2vh;
`;

const CondosArea = styled.div`
    grid-area: condos;

    display: grid;
    row-gap: 2vh;
    align-content: flex-start;
`;

const AvailableCondos = styled.div`
    display: grid;
    row-gap: 1vh;
    align-content: flex-start;
    height: 25vh;
    overflow-y: auto;
`;

const CondoOption = styled(Checkbox)``;

const SectionTitle = styled(Typography).attrs({ variant: "h5" })`
    color: #4d4d4d;
    text-transform: uppercase;
`;

const Title = styled(Typography).attrs({ variant: "h3" })`
    grid-area: title;
`;

const StyledCalendar = styled(Calendar).attrs({})`
    justify-self: center;
    width: fit-content;
`;

const SearchField = styled(InputField).attrs({
    type: "search",
    text: { align: "left" },
})``;

const ButtonsArea = styled.div`
    grid-area: buttons;

    display: grid;
    grid-auto-flow: column;
    column-gap: 2vw;
    justify-content: flex-end;
    justify-items: flex-end;
    grid-template-areas: "cancel-button schedule-button";

    @media only screen and (max-width: 768px) {
        grid-auto-flow: row;
        row-gap: 2vh;
        grid-auto-rows: 1fr;
        justify-content: stretch;
        grid-template-areas:
            "schedule-button"
            "cancel-button";
    }
`;

const ButtonCancel = styled(Button).attrs({
    color: "background",
    round: false,
})`
    grid-area: cancel-button;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const ButtonSchedule = styled(Button).attrs({ round: false })`
    grid-area: schedule-button;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface WorkoutSettingsProps {
    condos: Condo[];
    onCancel: () => void;
    onSchedule: (dates: Date[], condos: Condo[]) => Promise<void>;
}

export const WorkoutSettings: React.FC<WorkoutSettingsProps> = (props) => {
    const { formState, handleSubmit } = useForm();

    const { isSubmitting } = formState;

    const [filterTerms, setFilterTerms] = React.useState("");
    const [dates, setDates] = React.useState<Date[]>([]);
    const [condos, setCondos] = React.useState<Condo[]>([]);
    const [filteredCondos, setFilteredCondos] = React.useState<Condo[]>([]);

    React.useEffect(() => {
        const newFiltered = props.condos.filter((condo) =>
            condo.name.toLowerCase().includes(filterTerms.toLowerCase())
        );

        setFilteredCondos(newFiltered);
    }, [filterTerms, props.condos]);

    const toggleCondo = (condo: Condo) => {
        setCondos([...condos, condo]);
    };

    const onSubmit = async () => {
        await props.onSchedule(dates, condos);
    };

    return (
        <Content onSubmit={handleSubmit(onSubmit)}>
            <Title>Exercícios</Title>
            <CalendarArea>
                <SectionTitle>SELECIONE A DATA DESEJADA</SectionTitle>
                <StyledCalendar fetchSelectedDays={setDates} />
            </CalendarArea>
            <CondosArea>
                <SectionTitle>SELECIONE O(S) CONDOMÍNIO(S) </SectionTitle>
                <SearchField value={filterTerms} onChange={setFilterTerms} />
                <AvailableCondos>
                    {filteredCondos.map((condo, index) => (
                        <CondoOption
                            key={index}
                            onClick={() => toggleCondo(condo)}
                            label={condo.name}
                            defaultChecked={false}
                        />
                    ))}
                </AvailableCondos>
            </CondosArea>
            <ButtonsArea>
                {!isSubmitting && (
                    <ButtonCancel onClick={() => props.onCancel()}>
                        Cancelar
                    </ButtonCancel>
                )}
                <ButtonSchedule loading={isSubmitting} type="submit">
                    Agendar Treino
                </ButtonSchedule>
            </ButtonsArea>
        </Content>
    );
};

import React from "react";
import { Typography } from "src/components/atoms/typography";
import { Card } from "src/components/structures/admin-home/card";
import { ResultDisplay } from "src/components/structures/admin-home/result-display";
import { AdminLayout } from "src/components/templates/layout/admin-layout";
import { Customer } from "src/entities/customer";
import { DashboardData } from "src/entities/dashboard-data";
import styled from "styled-components";

const DashboardContent = styled.div`
    display: grid;
    row-gap: 2vh;
    grid-template-rows: auto 1fr 1fr;
    grid-template-columns: auto auto 5vw auto auto;
    grid-template-areas:
        "title title title title title"
        "add-workout workouts . schedule customers"
        "add-condo condos . add-exercise exercises";

    @media only screen and (max-width: 768px) {
        column-gap: 2vw;
        grid-template-rows: auto 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "title title"
            "add-workout workouts"
            "schedule customers"
            "add-condo condos"
            "add-exercise exercises";
    }
`;

const Title = styled(Typography).attrs({ variant: "h3" })`
    grid-area: title;
    margin: 5vh 0;
`;

interface AdminHomeTemplateProps {
    isLoading: boolean;
    customer?: Customer;
    dashboardData?: DashboardData;
    addNewCondo: () => void;
    addNewExercise: () => void;
    addNewWorkout: () => void;
    goToSchedulePage: () => void;
}

export const AdminHomeTemplate: React.FC<AdminHomeTemplateProps> = (props) => {
    return (
        <AdminLayout title="Dashboard">
            <DashboardContent>
                <Title>
                    Seja bem vindo, {`${props.customer?.name.split(" ")[0]}, `}o
                    que vamos fazer hoje?
                </Title>
                <Card
                    title="Criar novo treino"
                    icon="/assets/admin-home-icons/mdi_alarm_on.svg"
                    style={{ gridArea: "add-workout" }}
                    onClick={() => props.addNewWorkout()}
                />
                <ResultDisplay
                    isLoading={props.isLoading}
                    title="Usuários"
                    value={props.dashboardData?.customers.toFixed(0) ?? "--"}
                    style={{ gridArea: "customers" }}
                />
                <Card
                    title="Agenda de treino"
                    icon="/assets/admin-home-icons/mdi_calendar.svg"
                    style={{ gridArea: "schedule" }}
                    onClick={() => props.goToSchedulePage()}
                />
                <ResultDisplay
                    isLoading={props.isLoading}
                    title="Treinos"
                    value={props.dashboardData?.workouts.toFixed(0) ?? "--"}
                    style={{ gridArea: "workouts" }}
                />
                <Card
                    title="Criar novo Condomínio"
                    icon="/assets/admin-home-icons/mdi_home.svg"
                    style={{ gridArea: "add-condo" }}
                    onClick={() => props.addNewCondo()}
                />
                <ResultDisplay
                    isLoading={props.isLoading}
                    title="Condomínios"
                    value={props.dashboardData?.condos.toFixed(0) ?? "--"}
                    style={{ gridArea: "condos" }}
                />
                <Card
                    title="Criar novo exercício"
                    icon="/assets/admin-home-icons/mdi_gym.svg"
                    style={{ gridArea: "add-exercise" }}
                    onClick={() => props.addNewExercise()}
                />
                <ResultDisplay
                    isLoading={props.isLoading}
                    title="Exercícios"
                    value={props.dashboardData?.exercises.toFixed(0) ?? "--"}
                    style={{ gridArea: "exercises" }}
                />
            </DashboardContent>
        </AdminLayout>
    );
};

import React from "react";
import styled from "styled-components";
import { times } from "lodash";
import { UseFormMethods, Controller } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import { LoadTypeField } from "src/components/structures/admin-edit-workout/workout-exercises/shared-fields/load-type";
import { Typography } from "src/components/atoms/typography";
import { LoadType, SeriesXRepeticao } from "src/entities/workout-series";
import { LoadValueField } from "src/components/structures/admin-edit-workout/workout-exercises/shared-fields/load-value";
import { getDateTimeInSeconds } from "src/entities/workout-series/utils";

const FrameBase = styled.div`
    display: grid;
    grid-auto-rows: auto;
    column-gap: 1vw;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
        "rounds-number load-type . ."
        "props-title props-title props-title props-title"
        "series-props series-props series-props series-props";

    @media only screen and (max-width: 768px) {
        column-gap: 2vw;
        row-gap: 2vh;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "rounds-number load-type"
            "props-title props-title"
            "series-props series-props";
    }
`;

const RoundsNumber = styled(InputField).attrs({
    label: "Número de séries",
    type: "number",
})`
    grid-area: rounds-number;
`;

const PropsTitle = styled(Typography).attrs({
    variant: "h5",
})`
    grid-area: props-title;
    margin: 5vh 0 2vh;
    text-transform: uppercase;
    color: #4d4d4d;
`;

const SeriesPropsArea = styled.div`
    grid-area: series-props;
    padding-bottom: 5vh;
    display: grid;
    row-gap: 2vh;
`;

const SeriesPropsRow = styled.div`
    display: grid;
    column-gap: 1vw;
    grid-auto-columns: 1fr;
    grid-template-areas: "rounds reps break load-value load-value";

    @media only screen and (max-width: 768px) {
        column-gap: 3vw;
        row-gap: 2vh;
        grid-auto-columns: 1fr 1fr;
        grid-template-areas:
            "rounds reps"
            "break ."
            "load-value load-value";
    }
`;

const SeriesRoundsNumber = styled(InputField).attrs({
    label: "Série",
    type: "number",
    disabled: true,
})`
    grid-area: rounds;
`;

const SeriesRepsNumber = styled(InputField).attrs({
    label: "Repetições",
    type: "number",
})`
    grid-area: reps;
`;

const SeriesBreakTime = styled(InputField).attrs({
    label: "Descanso (s)",
    type: "number",
})`
    grid-area: break;
`;

interface FrameSeriesRepeticaoEditProps {
    workoutSeries?: SeriesXRepeticao;
    control: UseFormMethods["control"];
    errors: UseFormMethods["errors"];
    watch: UseFormMethods["watch"];
}

export const FrameSeriesRepeticaoEdit: React.FC<FrameSeriesRepeticaoEditProps> = (
    props
) => {
    const loadType = React.useMemo<LoadType>(() => {
        if (props.watch("loadType")) {
            return props.watch("loadType");
        }

        if (props.workoutSeries?.rounds[0]?.load) {
            return props.workoutSeries?.rounds[0]?.load.type;
        }

        return "sem_carga_definida";
    }, [props]);

    const rounds = React.useMemo(() => {
        if (props.watch("rounds")) {
            return Number(props.watch("rounds"));
        }

        if (props.workoutSeries?.rounds) {
            return props.workoutSeries?.rounds.length;
        }

        return 0;
    }, [props]);

    return (
        <FrameBase>
            <Controller
                as={
                    <RoundsNumber
                        error={{
                            isInvalid: props.errors.rounds,
                        }}
                    />
                }
                rules={{ required: true }}
                control={props.control}
                defaultValue={
                    props.workoutSeries ? props.workoutSeries.rounds.length : 1
                }
                name="rounds"
            />
            <LoadTypeField control={props.control} />
            <PropsTitle>PARÂMETROS DAS SÉRIES</PropsTitle>
            <SeriesPropsArea>
                {times(rounds, (index: number) => (
                    <SeriesPropsRow key={index}>
                        <Controller
                            as={<SeriesRoundsNumber />}
                            rules={{ required: true }}
                            control={props.control}
                            defaultValue={index + 1}
                            name={`seriesRounds${index}`}
                        />
                        <Controller
                            as={
                                <SeriesRepsNumber
                                    error={{
                                        isInvalid:
                                            props.errors[`seriesReps${index}`],
                                    }}
                                />
                            }
                            rules={{ required: true }}
                            control={props.control}
                            defaultValue={
                                !!props.workoutSeries?.rounds[index]?.reps
                                    ? props.workoutSeries?.rounds[index]?.reps
                                    : 5
                            }
                            name={`seriesReps${index}`}
                        />
                        <Controller
                            as={<SeriesBreakTime />}
                            control={props.control}
                            defaultValue={
                                !!props.workoutSeries?.rounds[index]?.breakTime
                                    ? getDateTimeInSeconds(
                                          props.workoutSeries?.rounds[index]
                                              ?.breakTime ?? new Date()
                                      )
                                    : 30
                            }
                            name={`seriesBreakTime${index}`}
                        />
                        {loadType !== "sem_carga_definida" && (
                            <LoadValueField
                                errors={props.errors}
                                watch={props.watch}
                                defaultValue={
                                    props.workoutSeries?.rounds[0]?.load
                                }
                                control={props.control}
                                loadType={loadType}
                            />
                        )}
                    </SeriesPropsRow>
                ))}
            </SeriesPropsArea>
        </FrameBase>
    );
};

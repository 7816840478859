import React, { useRef } from "react";
import styled from "styled-components";
import {
    getBackgroundColor,
    getIsInputOnErrorState,
    getTextColor,
    InputBase,
    InputBaseProps,
} from "../atoms/input-base";

interface InputRowProps {
    box: {
        backgroundColor: string;
        dense: boolean;
        noShadow: boolean;
        noResize?: boolean;
        onError: boolean;
    };
    text: {
        color: string;
        transform: "uppercase" | "lowercase" | "capitalize" | "none";
        align: "center" | "left" | "right";
    };
}

const InputRow = styled.div<InputRowProps>`
    display: flex;
    align-items: center;
    justify-content: "stretch";
    background-color: ${(props) => props.box.backgroundColor};
    padding: ${(props) => (props.box.dense ? "1vh 0.5vw" : "2vh 1.5vw")};
    border-radius: 7px;
    box-shadow: ${(props) => {
        if (props.box.noShadow) {
            return "";
        }

        if (props.box.onError) {
            return `0 0 0 2px red`;
        }

        return "0px 3px 6px #00000029";
    }};

    transition: box-shadow 0.5s;

    textarea {
        border: none;
        font-weight: 400;
        width: 100%;
        color: ${(props) => props.text.color};
        font-size: ${(props) => (props.box.dense ? "0.8rem" : "1.0rem")};
        background-color: ${(props) => props.box.backgroundColor};
        text-transform: ${(props) => props.text.transform};
        text-align: ${(props) => props.text.align ?? "left"};
        resize: ${(props) => (props.box.noResize ? "unset" : "vertical")};
        min-height: 7vh;
        white-space: pre-wrap;
        font-family: "Raleway", sans-serif !important;

        ::placeholder {
            opacity: 70%;
        }

        :focus {
            outline: none;
        }
    }
`;

interface TextAreaFieldProps extends Omit<InputBaseProps, "inputRef"> {
    rows?: number;
    maxlength?: number;
    noResize?: boolean;
}

export const TextAreaField: React.FC<TextAreaFieldProps> = (props) => {
    const textAreaEl = useRef<HTMLTextAreaElement>(null);

    const inputRowProps: InputRowProps = {
        box: {
            dense: props.box?.dense ?? false,
            noShadow: props.box?.noShadow ?? false,
            noResize: props.noResize,
            backgroundColor: getBackgroundColor(props),
            onError: getIsInputOnErrorState(props),
        },
        text: {
            align: props.text?.align ?? "left",
            transform: props.text?.transform ?? "none",
            color: getTextColor(),
        },
    };

    return (
        <InputBase {...props} inputRef={textAreaEl}>
            <InputRow {...inputRowProps}>
                <textarea
                    value={props.value}
                    onChange={(event) =>
                        props.onChange?.(event.currentTarget.value)
                    }
                    maxLength={props.maxlength}
                    rows={props.rows ?? 5}
                    ref={textAreaEl}
                    placeholder={props.placeholder}
                />
            </InputRow>
        </InputBase>
    );
};

import React from "react";
import styled from "styled-components";
import { Typography } from "src/components/atoms/typography";
import { theme } from "src/style/theme";
import { Button } from "src/components/atoms/button";

const Background = styled.div`
    position: relative;
    width: 100vw;
    min-height: 56vw;
    z-index: 1;
    padding: 0 40vw 0 ${theme.padding.horizontal.normal};

    background-image: url("/assets/background_intro.png");
    background-repeat: no-repeat;
    background-size: cover;

    display: grid;
    align-content: center;
    justify-content: flex-start;
    row-gap: 2vh;

    ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: -1;
    }

    @media only screen and (max-width: 768px) {
        background-position-x: center;
        padding: 10vh ${theme.padding.horizontal.normal};
        justify-items: center;
        text-align: center;
    }
`;

const Title = styled(Typography)`
    color: #f5f5f5;

    span {
        color: ${theme.colors.primary.light};
    }
`;

const Subtitle = styled(Typography).attrs({ variant: "h5", fontWeight: "400" })`
    color: #f5f5f5;
    margin-bottom: 3vh;
`;

const SignupButton = styled(Button)`
    padding: 2vh 5vw;
`;

export const SectionIntro = () => {
    return (
        <Background>
            <Title>
                TREINE NO SEU CONDOMÍNIO COM A <span>TREINADO</span>
            </Title>
            <Subtitle>
                Tenha acesso a métodos de treinamento exclusivos para a academia
                de condomínio com acompanhamento profissional.
            </Subtitle>
            <SignupButton>Venha conhecer</SignupButton>
        </Background>
    );
};

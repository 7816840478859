import React from "react";
import styled from "styled-components";
import { UseFormMethods, Controller } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import { LoadTypeField } from "src/components/structures/admin-edit-workout/workout-exercises/shared-fields/load-type";
import { Typography } from "src/components/atoms/typography";
import { LoadValueField } from "src/components/structures/admin-edit-workout/workout-exercises/shared-fields/load-value";
import {
    LoadType,
    RepeticoesTempoDeterminado,
} from "src/entities/workout-series";
import { getDateTimeInSeconds } from "src/entities/workout-series/utils";

const FrameBase = styled.div`
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1vw;
    grid-template-areas:
        " rounds round-time break-time load-type"
        "props-title props-title props-title props-title"
        "load-value load-value . .";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        column-gap: 2vw;
        row-gap: 2vh;
        grid-template-areas:
            "rounds round-time"
            "break-time load-type"
            "props-title props-title"
            "load-value load-value";
    }
`;

const RoundTime = styled(InputField).attrs({
    label: "Tempo Série (s)",
    type: "number",
    validations: { min: "0" },
})`
    grid-area: round-time;
`;

const Rounds = styled(InputField).attrs({
    label: "Séries",
    type: "number",
    validations: { min: "0" },
})`
    grid-area: rounds;
`;

const BreakTime = styled(InputField).attrs({
    label: "Descanso (s)",
    type: "number",
    validations: { min: "0" },
})`
    grid-area: break-time;
`;

const PropsTitle = styled(Typography).attrs({
    variant: "h5",
})`
    grid-area: props-title;
    margin: 5vh 0 2vh;
    text-transform: uppercase;
    color: #4d4d4d;
`;

interface FrameRepeticoesTempoDeterminadoEditProps {
    workoutSeries?: RepeticoesTempoDeterminado;
    control: UseFormMethods["control"];
    errors: UseFormMethods["errors"];
    watch: UseFormMethods["watch"];
}

export const FrameRepeticoesTempoDeterminadoEdit: React.FC<FrameRepeticoesTempoDeterminadoEditProps> = (
    props
) => {
    const loadType = React.useMemo<LoadType>(() => {
        if (props.watch("loadType")) {
            return props.watch("loadType");
        }

        if (props.workoutSeries?.rounds[0]?.load) {
            return props.workoutSeries?.rounds[0]?.load.type;
        }

        return "sem_carga_definida";
    }, [props]);

    const getDefaultBreakTime = () => {
        return props.workoutSeries?.rounds[0]?.breakTime
            ? getDateTimeInSeconds(props.workoutSeries?.rounds[0]?.breakTime)
            : 30;
    };

    const getDefaultRoundTime = () => {
        return props.workoutSeries?.rounds[0]?.roundTime
            ? getDateTimeInSeconds(props.workoutSeries?.rounds[0]?.roundTime)
            : 30;
    };

    return (
        <FrameBase>
            <Controller
                as={
                    <RoundTime
                        error={{
                            isInvalid: props.errors.roundTime,
                        }}
                    />
                }
                rules={{ required: true }}
                control={props.control}
                defaultValue={getDefaultRoundTime()}
                name="roundTime"
            />
            <Controller
                as={
                    <Rounds
                        error={{
                            isInvalid: props.errors.rounds,
                        }}
                    />
                }
                rules={{ required: true }}
                control={props.control}
                defaultValue={props.workoutSeries?.rounds.length ?? 5}
                name="rounds"
            />
            <Controller
                as={
                    <BreakTime
                        error={{
                            isInvalid: props.errors.breakTime,
                        }}
                    />
                }
                control={props.control}
                defaultValue={getDefaultBreakTime()}
                name="breakTime"
            />
            <LoadTypeField
                control={props.control}
                defaultValue={props.workoutSeries?.rounds[0]?.load}
            />
            {loadType !== "sem_carga_definida" && (
                <PropsTitle>PARÂMETROS DAS SÉRIES</PropsTitle>
            )}
            {loadType !== "sem_carga_definida" && (
                <LoadValueField
                    errors={props.errors}
                    control={props.control}
                    watch={props.watch}
                    loadType={loadType}
                    defaultValue={props.workoutSeries?.rounds[0]?.load}
                />
            )}
        </FrameBase>
    );
};

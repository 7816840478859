import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Button } from "src/components/atoms/button";
import { Typography } from "src/components/atoms/typography";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        background: "none",
        width: "90%",
        maxWidth: "600px",
    },
    overlay: {
        backgroundColor: "rgb(0, 0, 0, 0.3)",
    },
};

const ModalContent = styled.div`
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 8vh 5vw;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "title title"
        "back-button save-button";
    align-items: center;
    row-gap: 3vh;
    column-gap: 3vw;
`;

const Title = styled(Typography).attrs({
    variant: "h2",
})`
    grid-area: title;
`;

const ButtonBack = styled(Button).attrs({ color: "background", round: false })`
    grid-area: back-button;
    width: 100%;
`;

const ButtonSave = styled(Button).attrs({ round: false })`
    grid-area: save-button;
    width: 100%;
`;

interface ConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => Promise<boolean>;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
    Modal.setAppElement("#root");

    const [isLoading, setIsLoading] = React.useState(false);

    const onConfirmClick = async () => {
        setIsLoading(true);

        const response = await props.onConfirm();

        setIsLoading(false);

        if (response) {
            props.onClose();
        }
    };

    return (
        <Modal
            style={customStyles}
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <ModalContent>
                <Title>Deseja mesmo continuar?</Title>
                {isLoading === false && (
                    <ButtonBack
                        loading={isLoading}
                        onClick={() => props.onClose()}
                    >
                        Cancelar
                    </ButtonBack>
                )}
                <ButtonSave
                    loading={isLoading}
                    onClick={() => onConfirmClick()}
                >
                    Salvar
                </ButtonSave>
            </ModalContent>
        </Modal>
    );
};

import React from "react";
import styled from "styled-components";
import { Typography } from "src/components/atoms/typography";

interface BackgroundProps {
    hasTitle: boolean;
}

const Background = styled.div<BackgroundProps>`
    width: 100%;
    background-color: #4f4f4f;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1rem 1rem;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-template-rows: 3rem;
    justify-content: flex-start;
    justify-items: flex-start;
    column-gap: 3rem;

    @media only screen and (max-width: 768px) {
        width: 90vw;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        column-gap: 1rem;
        align-items: center;
    }
`;

const StepIcon = styled.img`
    height: 100%;

    @media only screen and (max-width: 768px) {
        height: unset;
        width: 100%;
    }
`;

const Title = styled(Typography).attrs({ variant: "h3", color: "white" })`
    justify-self: flex-end;
    width: 100%;
    text-align: right;
`;

export interface StepProps {
    icon: string;
}

interface StepMenuProps {
    title?: string;
    steps: StepProps[];
    activeStep: number;
    stepClick?: (step: number) => void;
}

export const StepMenu: React.FC<StepMenuProps> = (props) => {
    return (
        <Background hasTitle={Boolean(props.title)}>
            {props.steps.map((step, index) => (
                <StepIcon
                    key={index}
                    src={
                        props.activeStep === index
                            ? `${step.icon.replace(".png", "_green.png")}`
                            : step.icon
                    }
                    onClick={() => props.stepClick?.(index)}
                />
            ))}
            {Boolean(props.title) && (
                <Title className="hide-mobile">{props.title}</Title>
            )}
        </Background>
    );
};

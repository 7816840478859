import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { times } from "lodash";
import { UseFormMethods, Controller } from "react-hook-form";
import { ExerciseBaseCard } from "src/components/structures/home-workout-series/exercise-base-card";
import { Divider } from "src/components/structures/home-workout-series/divider";
import { ExerciseResultsBase } from "src/components/structures/home-workout-series/exercise-results-base";
import { InputField } from "src/components/molecules/input-field";
import { getWorkoutExerciseDescription } from "src/entities/workout-series/utils";
import { Exercise } from "src/entities/exercise";
import { MaximoRepeticoesXTempo } from "src/entities/workout-series";
import { Typography } from "src/components/atoms/typography";

const BaseSection = styled.div`
    display: grid;
    row-gap: 3vh;
`;

const ExerciseResult = styled(InputField).attrs({
    label: "Repetições",
    type: "number",
})`
    width: 50%;
`;

const SectionArea = styled.div`
    display: grid;
    row-gap: 1vh;
`;

const ExerciseArea = styled.div`
    display: grid;
    row-gap: 1vh;
`;

const SectionTitle = styled(Typography).attrs({ variant: "h5" })``;

interface FrameMaximoRepeticoesXTempoProps {
    workoutSeries: MaximoRepeticoesXTempo;
    control: UseFormMethods["control"];
    exhibitExercise: (exercise: Exercise) => void;
}

export const FrameMaximoRepeticoesXTempo: React.FC<FrameMaximoRepeticoesXTempoProps> = (
    props
) => {
    return (
        <BaseSection>
            {times(props.workoutSeries?.rounds ?? 1, (index) => {
                const section = props.workoutSeries.sections[0];
                const totalTime = dayjs(props.workoutSeries.totalTime).format(
                    "HH:mm:ss"
                );

                return (
                    <SectionArea key={index}>
                        <SectionTitle>{`SÉRIE ${
                            index + 1
                        } - Tempo do Exercício: ${totalTime})`}</SectionTitle>
                        {section.exercises.map((exercise, exerciseIndex) => (
                            <ExerciseArea>
                                <ExerciseBaseCard
                                    key={exerciseIndex}
                                    title={exercise.exercise.name}
                                    description={getWorkoutExerciseDescription(
                                        exercise
                                    )}
                                    onClick={() =>
                                        props.exhibitExercise(exercise.exercise)
                                    }
                                />
                                <Controller
                                    as={<ExerciseResult />}
                                    control={props.control}
                                    defaultValue={`${
                                        props.workoutSeries?.rounds ?? 5
                                    }`}
                                    name={`reps_${exerciseIndex}`}
                                />
                            </ExerciseArea>
                        ))}
                    </SectionArea>
                );
            })}

            <Divider />
            <ExerciseResultsBase control={props.control} />
        </BaseSection>
    );
};

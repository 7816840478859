import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import { Typography } from "src/components/atoms/typography";
import { Exercise } from "src/entities/exercise";
import { TextAreaField } from "src/components/molecules/textarea-field";
import { Button } from "src/components/atoms/button";

const Content = styled.form`
    display: grid;
    row-gap: 3vh;
    column-gap: 2vw;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "title title title"
        ". . ."
        "name url url"
        "preparation execution notes"
        ". buttons buttons";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "title"
            "name"
            "url"
            "preparation"
            "execution"
            "notes"
            "buttons";
    }
`;

const Title = styled(Typography).attrs({ variant: "h3" })`
    grid-area: title;
`;

const NameField = styled(InputField).attrs({
    label: "Nome do Exercício",
})`
    grid-area: name;
`;

const UrlField = styled(InputField).attrs({ label: "URL do Youtube" })`
    grid-area: url;
`;

const PreparationField = styled(TextAreaField).attrs({
    label: "Preparação",
})`
    grid-area: preparation;
`;

const ExecutiontionField = styled(TextAreaField).attrs({
    label: "Execução",
})`
    grid-area: execution;
`;

const NotesField = styled(TextAreaField).attrs({
    label: "Dicas",
})`
    grid-area: notes;
`;

const ButtonsArea = styled.div`
    grid-area: buttons;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-end;
    column-gap: 2vw;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        row-gap: 2vh;
    }
`;

const ButtonCancel = styled(Button).attrs({
    color: "background",
    round: false,
})`
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const ButtonNext = styled(Button).attrs({ round: false })`
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface ExerciseBasicInfoProps {
    exercise?: Exercise;
    onCancel: () => void;
    onSave: (exercise: Exercise) => Promise<boolean>;
}

export const ExerciseBasicInfo: React.FC<ExerciseBasicInfoProps> = (props) => {
    const { control, formState, handleSubmit } = useForm();

    const { isSubmitting } = formState;

    const onSubmit = async (values: any) => {
        const exercise: Exercise = {
            ...values,
            id: props.exercise?.id,
        };

        const response = await props.onSave(exercise);

        if (response) {
            props.onCancel();
        }
    };

    return (
        <Content onSubmit={handleSubmit(onSubmit)}>
            <Title>Informações do Exercício</Title>
            <Controller
                as={<NameField />}
                rules={{ required: true }}
                control={control}
                defaultValue={props.exercise?.name ?? ""}
                name="name"
            />
            <Controller
                as={<UrlField />}
                control={control}
                defaultValue={props.exercise?.videoUrl ?? ""}
                name="videoUrl"
            />
            <Controller
                as={<PreparationField />}
                control={control}
                defaultValue={props.exercise?.preparationNotes ?? ""}
                name="preparationNotes"
            />
            <Controller
                as={<ExecutiontionField />}
                control={control}
                defaultValue={props.exercise?.executionNotes ?? ""}
                name="executionNotes"
            />
            <Controller
                as={<NotesField />}
                control={control}
                defaultValue={props.exercise?.generalNotes ?? ""}
                name="generalNotes"
            />
            <ButtonsArea>
                {isSubmitting === false && (
                    <ButtonCancel onClick={() => props.onCancel()}>
                        Cancelar
                    </ButtonCancel>
                )}
                <ButtonNext type="submit" loading={isSubmitting}>
                    Salvar
                </ButtonNext>
            </ButtonsArea>
        </Content>
    );
};

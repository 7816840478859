import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { Typography } from "src/components/atoms/typography";
import { HeaderAdmin } from "src/components/structures/admin-layout/header-admin";
import { SideMenuAdmin } from "src/components/structures/admin-layout/side-menu-admin";
import {
    StepMenu,
    StepProps,
} from "src/components/structures/admin-layout/step-menu";
import styled from "styled-components";

const BaseStructure = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: white;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "header"
            "content";
    }
`;

const Title = styled(Typography).attrs({ variant: "h3" })``;

const Content = styled.div`
    padding: 10vh 5vw;
    display: grid;
    row-gap: 5vh;
    align-content: flex-start;
    justify-content: stretch;

    @media only screen and (max-width: 768px) {
        width: 100vw;
    }
`;

interface AdminEditLayoutProps {
    title: string;
    stepTitle?: string;
    steps: StepProps[];
    activeStep: number;
    stepClick?: (step: number) => void;
}

export const AdminEditLayout: React.FC<AdminEditLayoutProps> = (props) => {
    const isMobile = useMediaQuery("(max-width:768px)");

    return (
        <BaseStructure>
            {isMobile ? <HeaderAdmin /> : <SideMenuAdmin />}

            <Content>
                <Title>{props.title}</Title>
                <StepMenu
                    activeStep={props.activeStep}
                    stepClick={props.stepClick}
                    title={props.stepTitle}
                    steps={props.steps}
                />
                {props.children}
            </Content>
        </BaseStructure>
    );
};

import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import { Typography } from "src/components/atoms/typography";
import { Condo } from "src/entities/condo";
import { Button } from "src/components/atoms/button";
import { viaCepRequest } from "src/infrastructure/viaCep/via-cep-request";

const Content = styled.form`
    width: 100%;
    display: grid;
    row-gap: 3vh;
    column-gap: 2vw;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "title title title"
        ". . ."
        "id name user-limit"
        ". . ."
        "address-title address-title address-title"
        "zip-code . . "
        "street number neighborhood"
        "city state ."
        ". buttons buttons";

    @media only screen and (max-width: 768px) {
        grid-auto-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "title title"
            "id user-limit"
            "name name"
            "address-title address-title"
            "zip-code ."
            "street street"
            "number neighborhood"
            "city state"
            "buttons buttons";
    }
`;

const Title = styled(Typography).attrs({ variant: "h3" })`
    grid-area: title;
`;

const IdField = styled(InputField).attrs({
    label: "Código do Condomínio",
    placeholder: "Insira um código para o condomínio",
    text: { align: "left" },
})`
    grid-area: id;
`;

const NameField = styled(InputField).attrs({
    label: "Nome do Condomínio",
    placeholder: "Insira um nome para o condomínio",
    text: { align: "left" },
})`
    grid-area: name;
`;

const UserLimitField = styled(InputField).attrs({
    label: "Número Limite de Usuários",
    type: "number",
    text: { align: "left" },
})`
    grid-area: user-limit;
`;

const AddressZipCodeField = styled(InputField).attrs({
    label: "CEP",
    type: "tel",
    validations: { mask: "99999-999" },
    text: { align: "left" },
})`
    grid-area: zip-code;
`;

const AddressStreetField = styled(InputField).attrs({
    label: "Rua",
    text: { align: "left" },
})`
    grid-area: street;
`;

const AddressNumberField = styled(InputField).attrs({
    label: "Número",
    type: "number",
    text: { align: "left" },
})`
    grid-area: number;
`;

const AddressNeighboorhoodField = styled(InputField).attrs({
    label: "Bairro",
    text: { align: "left" },
})`
    grid-area: neighborhood;
`;

const AddressCityField = styled(InputField).attrs({
    label: "Cidade",
    text: { align: "left" },
})`
    grid-area: city;
`;

const AddressStateField = styled(InputField).attrs({
    label: "Estado",
    text: { align: "left" },
})`
    grid-area: state;
`;

const AddressTitle = styled(Typography).attrs({ variant: "h4" })`
    grid-area: address-title;
`;

const ButtonsArea = styled.div`
    grid-area: buttons;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-end;
    column-gap: 2vw;

    @media only screen and (max-width: 768px) {
        justify-content: stretch;
    }
`;

const ButtonCancel = styled(Button).attrs({
    color: "background",
    round: false,
})`
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const ButtonNext = styled(Button).attrs({ round: false })`
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface BasicInfoFrameProps {
    condo?: Condo;
    onCancel: () => void;
    onSave: (condo: Condo) => Promise<boolean>;
    nextFrame: () => void;
}

export const BasicInfoFrame: React.FC<BasicInfoFrameProps> = (props) => {
    const {
        control,
        formState,
        handleSubmit,
        getValues,
        setValue,
        reset,
    } = useForm();

    const { isSubmitting } = formState;

    const searchZipCode = async () => {
        const zipCode = getValues("address.zipCode");

        let zipCodeData;

        if (zipCode) {
            zipCodeData = await viaCepRequest(zipCode);
        }

        if (zipCodeData) {
            setValue("address.street", zipCodeData.logradouro);
            setValue("address.region", zipCodeData.bairro);
            setValue("address.state", zipCodeData.uf);
            setValue("address.city", zipCodeData.localidade);
        } else {
            reset({
                address: { street: "", region: "", state: "", city: "" },
            });
        }
    };

    const submitForm = async (values: any) => {
        const condoId = values.id.replaceAll(" ", "");

        const newCondo: Condo = {
            ...values,
            id: condoId,
        };

        const response = await props.onSave(newCondo);

        if (response) {
            props.nextFrame();
        }
    };

    return (
        <Content onSubmit={handleSubmit(submitForm)}>
            <Title>Informações Iniciais</Title>
            <Controller
                as={<IdField disabled={Boolean(props.condo)} />}
                rules={{ required: true }}
                control={control}
                defaultValue={props.condo?.id ?? ""}
                name="id"
            />
            <Controller
                as={<NameField />}
                rules={{ required: true }}
                control={control}
                defaultValue={props.condo?.name ?? ""}
                name="name"
            />
            <Controller
                as={<UserLimitField />}
                rules={{ required: true }}
                control={control}
                defaultValue={props.condo?.userLimit ?? 10}
                name="userLimit"
            />
            <AddressTitle>ENDEREÇO DO CONDOMÍNIO</AddressTitle>
            <Controller
                as={
                    <AddressZipCodeField
                        onValueComplete={() => searchZipCode()}
                    />
                }
                control={control}
                defaultValue={props.condo?.address?.zipCode ?? ""}
                name="address.zipCode"
            />
            <Controller
                as={<AddressStreetField />}
                control={control}
                defaultValue={props.condo?.address?.street ?? ""}
                name="address.street"
            />
            <Controller
                as={<AddressNumberField />}
                control={control}
                defaultValue={props.condo?.address?.number ?? ""}
                name="address.number"
            />
            <Controller
                as={<AddressNeighboorhoodField />}
                control={control}
                defaultValue={props.condo?.address?.region ?? ""}
                name="address.region"
            />
            <Controller
                as={<AddressCityField />}
                control={control}
                defaultValue={props.condo?.address?.city ?? ""}
                name="address.city"
            />
            <Controller
                as={<AddressStateField />}
                control={control}
                defaultValue={props.condo?.address?.state ?? ""}
                name="address.state"
            />
            <ButtonsArea>
                {isSubmitting === false && (
                    <ButtonCancel onClick={() => props.onCancel()}>
                        Cancelar
                    </ButtonCancel>
                )}
                <ButtonNext type="submit" loading={isSubmitting}>
                    Salvar
                </ButtonNext>
            </ButtonsArea>
        </Content>
    );
};

import React from "react";
import dayjs from "dayjs";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { AdminEditWorkoutTemplate } from "src/components/templates/admin-edit-workout-template";
import { Condo } from "src/entities/condo";
import { Exercise } from "src/entities/exercise";
import {
    WorkoutMultipleSeriesType,
    WorkoutSeries,
    WorkoutSingleSeriesType,
} from "src/entities/workout-series";
import { awsCondoGetAll } from "src/infrastructure/aws/aws-condo";
import { awsExerciseGetAll } from "src/infrastructure/aws/aws-exercise";
import {
    awsScheduledWorkoutGet,
    awsScheduledWorkoutUpdate,
} from "src/infrastructure/aws/aws-scheduled-workout";
import { ScheduledWorkout } from "src/entities/scheduled-workout";
import { UpdateScheduledWorkoutRequest } from "src/entities/requests/update-scheduled-workout";
import {
    awsScheduledWorkoutEntitySave,
    awsScheduledWorkoutEntityDelete,
} from "src/infrastructure/aws/aws-scheduled-workout-entity";
import { SaveScheduledWorkoutEntityRequest } from "src/entities/requests/save-scheduled-workout-entity";

export const AdminScheduledWorkoutPage: React.FC = () => {
    const history = useHistory();
    const schduledWorkoutId = queryString.parse(useLocation().search)?.id ?? "";

    const [workout, setWorkout] = React.useState<
        ScheduledWorkout | undefined
    >();
    const [isLoading, setIsLoading] = React.useState(true);
    const [reloadData, setReloadData] = React.useState(true);
    const [condos, setCondos] = React.useState<Condo[]>([]);
    const [availableExercises, setAvailableExercises] = React.useState<
        Exercise[]
    >([]);

    const singleWorkoutTypes: WorkoutSingleSeriesType[] = [
        "maximo_repeticoes",
        "maximo_carga",
        "series_x_repeticao",
        "morrer_repeticao",
        "morrer_carga",
        "realizar_dentro_minuto",
        "intervalado",
        "tempo",
        "repeticoes_tempo_determinado",
    ];

    const multipleWorkoutTypes: WorkoutMultipleSeriesType[] = [
        "rodadas_menor_tempo_fixas",
        "rodadas_menor_tempo_variadas",
        "menor_tempo_possivel",
        "maximo_repeticoes_x_tempo",
        "maximo_rodadas_x_tempo",
        "realizar_dentro_minuto_multiplo",
        "morrer_repeticoes",
        "intervalado_multiplo",
    ];

    React.useEffect(() => {
        const retrieveData = async () => {
            const scheduledWorkoutQs = Array.isArray(schduledWorkoutId)
                ? schduledWorkoutId[0]
                : schduledWorkoutId;

            const newCondos = await awsCondoGetAll();
            const newExercises = await awsExerciseGetAll();
            const newWorkout = scheduledWorkoutQs
                ? await awsScheduledWorkoutGet(scheduledWorkoutQs)
                : undefined;

            setCondos(newCondos);
            setAvailableExercises(newExercises);
            setWorkout(newWorkout ?? undefined);
            setIsLoading(false);
            setReloadData(false);
        };

        retrieveData();
    }, [reloadData, schduledWorkoutId]);

    const redirectWorkoutsPage = () => {
        history.push("/admin/agenda");
    };

    const saveWorkout = async (
        updateData: Partial<ScheduledWorkout>
    ): Promise<boolean> => {
        if (!workout) {
            return false;
        }

        const newData: UpdateScheduledWorkoutRequest = {
            ...workout,
            ...updateData,
        };

        const updateResponse = await awsScheduledWorkoutUpdate(
            workout.id,
            workout.condoId,
            workout.date,
            newData
        );

        if (!updateResponse) {
            return false;
        }

        setWorkout({ ...updateResponse, series: workout.series });

        return true;
    };

    const addWorkoutSeries = async (
        workoutSeries: WorkoutSeries
    ): Promise<boolean> => {
        if (!workout) {
            return false;
        }

        setIsLoading(true);

        const workoutEntity: SaveScheduledWorkoutEntityRequest = {
            workoutId: workout.id,
            condoId: workout.condoId,
            date: workout.date,
            entity: {
                id: workoutSeries.id,
                type: "series",
                data: workoutSeries,
            },
        };

        const response = await awsScheduledWorkoutEntitySave(workoutEntity);

        if (response) {
            setIsLoading(true);
            setReloadData(true);
        } else {
            setIsLoading(false);
        }

        return Boolean(response);
    };

    const removeWorkoutSeries = async (
        workoutSeries: WorkoutSeries
    ): Promise<boolean> => {
        if (!workout) {
            return false;
        }

        setIsLoading(true);

        const response = await awsScheduledWorkoutEntityDelete(
            workout.id,
            workout.condoId,
            dayjs(workout.date).toDate(),
            {
                id: workoutSeries.id ?? "",
                type: "series",
            }
        );

        if (response) {
            setReloadData(true);
        } else {
            setIsLoading(false);
        }

        return response;
    };

    return (
        <AdminEditWorkoutTemplate
            title="Editar Agendamento"
            scheduledDate={workout?.date}
            condos={condos}
            workout={workout}
            isLoading={isLoading}
            availableExercises={availableExercises}
            singleWorkoutTypes={singleWorkoutTypes}
            multipleWorkoutTypes={multipleWorkoutTypes}
            redirectWorkoutsPage={redirectWorkoutsPage}
            saveWorkout={saveWorkout}
            addWorkoutSeries={addWorkoutSeries}
            removeWorkoutSeries={removeWorkoutSeries}
        />
    );
};

import Amplify, { Auth } from "aws-amplify";
import dayjs from "dayjs";
import { Customer } from "src/entities/customer";
import {
    awsCustomerGet,
    awsCustomerUpdate,
} from "src/infrastructure/aws/aws-customer";

const amplifyConfig = {
    aws_cognito_region: process.env.REACT_APP_AWS_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_WEB_CLIENT_ID,
};

Amplify.configure(amplifyConfig);

let currentUser: Customer | null;

export const awsAuthGetCurrentAccessToken = async () => {
    const currentSession = await Auth.currentSession();

    return currentSession.getAccessToken().getJwtToken();
};

export const awsAuthGetCurrentIdToken = async () => {
    const currentSession = await Auth.currentSession();

    return currentSession.getIdToken().getJwtToken();
};

export type SignInResult =
    | "SUCCESS"
    | "ERROR"
    | "MISSING_EMAIL_VERIFY"
    | "FORCE_RESET_PASSWORD";

export const awsAuthSignIn = async (
    userName: string,
    password: string
): Promise<SignInResult> => {
    try {
        currentUser = null;

        const result = await Auth.signIn(userName, password);

        if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
            return "FORCE_RESET_PASSWORD";
        }

        return "SUCCESS";
    } catch (error) {
        console.log("erro no signin", error);

        if (error.code === "UserNotConfirmedException") {
            return "MISSING_EMAIL_VERIFY";
        }

        return "ERROR";
    }
};

export const awsAuthForgotPassword = async (
    userName: string
): Promise<boolean> => {
    try {
        await Auth.forgotPassword(userName);

        return true;
    } catch (error) {
        console.log("erro ao iniciar forgot password", error);
        return false;
    }
};

export const awsAuthConfirmForgotPassword = async (
    userName: string,
    code: string,
    password: string
): Promise<boolean> => {
    try {
        await Auth.forgotPasswordSubmit(userName, code, password);

        return true;
    } catch (error) {
        console.log("erro ao iniciar forgot password", error);
        return false;
    }
};

export const awsAuthSignOut = async (): Promise<void> => {
    try {
        await Auth.signOut({ global: true });

        currentUser = null;
    } catch (error) {
        console.log("erro no logout", error);
    }
};

export const awsAuthSwitchPassword = async (
    oldPassword: string,
    newPassword: string
): Promise<boolean> => {
    try {
        const result = await Auth.currentAuthenticatedUser();

        await Auth.changePassword(result, oldPassword, newPassword);

        return true;
    } catch (error) {
        console.log("erro ao trocar a senha", error);
        return false;
    }
};

export const awsAuthForceSwitchPassword = async (
    username: string,
    oldPassword: string,
    newPassword: string
): Promise<boolean> => {
    try {
        const resultSignIn = await Auth.signIn(username, oldPassword);

        const { requiredAttributes } = resultSignIn.challengeParam;

        await Auth.completeNewPassword(
            resultSignIn,
            newPassword,
            requiredAttributes
        );

        return true;
    } catch (error) {
        console.log("erro ao trocar a senha", error);
        return false;
    }
};

type SignupResult = "CREATED" | "ERROR" | "ALREADY_EXISTS";

export const awsAuthSignUp = async (
    entity: Customer,
    password: string
): Promise<SignupResult> => {
    try {
        await Auth.signUp({
            username: entity.email,
            password,
            attributes: {
                email: entity.email,
                name: entity.name,
                "custom:document": entity.document,
            },
        });

        return "CREATED";
    } catch (err) {
        console.log("erro no signup", err);

        if (err.code === "UsernameExistsException") {
            return "ALREADY_EXISTS";
        }

        return "ERROR";
    }
};

export const awsAuthGetCurrentUser = async (): Promise<Customer | null> => {
    const reloadUser = async (): Promise<Customer | null> => {
        const authUser = await Auth.currentAuthenticatedUser();

        if (!authUser) {
            console.log("User não existe no Cognito");
            return null;
        }

        const userAttrs = authUser.attributes;

        const userGroups =
            authUser?.signInUserSession?.accessToken?.payload["cognito:groups"];
        const isAdmin = !!userGroups?.find(
            (group: string) => group === "Administrators"
        );

        const dbUser = await awsCustomerGet(userAttrs["custom:document"]);

        if (!dbUser) {
            console.log("User não existe no DB");
            return null;
        }

        await awsCustomerUpdate(dbUser.document, {
            lastAccess: dayjs().toISOString(),
            type: isAdmin ? "ADMIN" : "CUSTOMER",
        });

        return {
            ...dbUser,
            type: isAdmin ? "ADMIN" : "CUSTOMER",
        };
    };

    try {
        const userInactive = currentUser?.status !== "ACTIVE";

        if (!currentUser || userInactive) {
            currentUser = await reloadUser();
        }

        return currentUser;
    } catch (err) {
        console.log("nenhum user logado", err);
        return null;
    }
};

export const awsAuthSendVerificationEmail = async (
    username: string
): Promise<void> => {
    try {
        await Auth.resendSignUp(username);
    } catch (err) {
        console.log("erro em reenviar o email");
    }
};

export const awsAuthVerifyEmail = async (
    email: string,
    code: string
): Promise<boolean> => {
    try {
        await Auth.confirmSignUp(email, code);

        return true;
    } catch (err) {
        console.log("erro em confirmar o signup", err);
        return false;
    }
};

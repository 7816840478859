import React from "react";
import { useHistory } from "react-router-dom";
import { LandingPageTemplate } from "src/components/templates/landing-page-template";

export const LandingPage: React.FC = () => {
    const history = useHistory();

    const goToLogin = () => {
        history.push("/login");
    };

    return <LandingPageTemplate goToLogin={goToLogin} />;
};

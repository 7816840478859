import React from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "src/components/atoms/typography";
import styled, { css } from "styled-components";

interface StyledSideMenuProps {
    menuOpen: boolean;
}

const BaseMenu = styled.div<StyledSideMenuProps>`
    min-height: 100vh;
    width: ${(props) => (props.menuOpen ? "20vw" : "8vw")};
    background: #2b2b2c;
    display: grid;
    justify-items: flex-start;
    align-content: flex-start;
    row-gap: 4vh;
    padding-top: 25vh;
    padding-left: 3.5vw;
`;

interface IconProps {
    url: string;
}

const Logo = styled.div<StyledSideMenuProps>`
    background-image: ${(props) =>
        props.menuOpen
            ? css`url("/assets/logo_white.png")`
            : css`url("/assets/admin-side-menu-icons/logo.svg")`};
    background-repeat: no-repeat;
    background-size: contain;
    width: ${(props) => (props.menuOpen ? css`80%` : css`max(0.8vw, 15px)`)};
    height: max(6vw, 100px);
    cursor: pointer;
    margin-bottom: 3vh; ;
`;

const Icon = styled.div<IconProps>`
    background-image: ${(props) => `url(${props.url})`};
    background-repeat: no-repeat;
    background-size: contain;
    width: max(1.3vw, 20px);
    height: max(1.3vw, 20px);
`;

const ToggleButton = styled.div<StyledSideMenuProps>`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    font-weight: 700;
    font-size: 1.5rem;
    position: absolute;
    left: ${(props) => (props.menuOpen ? "20vw" : "8vw")};
    top: 10vh;
    margin-left: -11px;
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #2b2b2c;
    cursor: pointer;
`;

const OpenMenuIcon = styled.div`
    background-image: url("/assets/side_menu_icon.svg");
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    background-size: contain;
    background-position-x: center;
`;

const CloseMenuIcon = styled.div`
    background-image: url("/assets/side_menu_icon_2.svg");
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    background-size: contain;
    background-position-x: center;
`;

const MenuItem = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: max(1.3vw, 20px);
    align-items: center;
    column-gap: 1vw;
    cursor: pointer;
`;

const MenuTitle = styled(Typography).attrs({
    variant: "h4",
    color: "white",
})`
    text-transform: uppercase;
`;

export const SideMenuAdmin: React.FC = () => {
    const history = useHistory();

    const [menuOpen, setMenuOpen] = React.useState(false);

    return (
        <BaseMenu menuOpen={menuOpen}>
            <ToggleButton menuOpen={menuOpen}>
                {menuOpen ? (
                    <CloseMenuIcon onClick={() => setMenuOpen(false)} />
                ) : (
                    <OpenMenuIcon onClick={() => setMenuOpen(true)} />
                )}
            </ToggleButton>
            <Logo menuOpen={menuOpen} onClick={() => history.push("/admin")} />
            <MenuItem onClick={() => history.push("/admin/clientes")}>
                <Icon url="/assets/admin-side-menu-icons/mdi_person.svg" />
                {menuOpen && <MenuTitle>Usuários</MenuTitle>}
            </MenuItem>
            <MenuItem onClick={() => history.push("/admin/condominios")}>
                <Icon url="/assets/admin-side-menu-icons/mdi_home.svg" />
                {menuOpen && <MenuTitle>CONDOMÍNIOS</MenuTitle>}
            </MenuItem>
            <MenuItem onClick={() => history.push("/admin/exercicios")}>
                <Icon url="/assets/admin-side-menu-icons/mdi_gym.svg" />
                {menuOpen && <MenuTitle>EXERCÍCIOS</MenuTitle>}
            </MenuItem>
            <MenuItem onClick={() => history.push("/admin/treinos")}>
                <Icon url="/assets/admin-side-menu-icons/mdi_alarm_on.svg" />
                {menuOpen && <MenuTitle>TREINOS</MenuTitle>}
            </MenuItem>
            <MenuItem onClick={() => history.push("/admin/equipamentos")}>
                <Icon url="/assets/admin-side-menu-icons/mdi_watch.svg" />
                {menuOpen && <MenuTitle>EQUIPAMENTOS</MenuTitle>}
            </MenuItem>
            <MenuItem onClick={() => history.push("/admin/agenda")}>
                <Icon url="/assets/admin-side-menu-icons/mdi_calendar.svg" />
                {menuOpen && <MenuTitle>AGENDA</MenuTitle>}
            </MenuItem>
            <MenuItem onClick={() => history.push("/admin/resultados")}>
                <Icon url="/assets/admin-side-menu-icons/mdi_results.svg" />
                {menuOpen && <MenuTitle>RESULTADOS</MenuTitle>}
            </MenuItem>
        </BaseMenu>
    );
};

import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import {
    SelectField,
    SelectFieldOption,
} from "src/components/molecules/select-field";
import { Typography } from "src/components/atoms/typography";
import { Exercise } from "src/entities/exercise";
import {
    MaximoRepeticoesXTempo,
    MaximoRodadasXTempo,
    MenorTempoPossivel,
    MorrerRepeticoes,
    RealizarDentroMinutoMultiplo,
    RodadasMenorTempoFixas,
    RodadasMenorTempoVariadas,
    IntervaladoMultiplo,
    WorkoutExercise,
    WorkoutExercisesSection,
    WorkoutMultipleSeries,
    WorkoutMultipleSeriesType,
    WorkoutSeries,
} from "src/entities/workout-series";
import { getWorkoutSeriesTypeFormated } from "src/entities/workout-series/utils";
import { ModalNewExercise } from "src/components/structures/admin-edit-workout/workout-exercises/modal-new-exercise";
import { FrameRodadasMenorTempoFixas } from "./multiple/frame-rodadas-menor-tempo-fixas";
import { FrameRodadasMenorTempoVariadas } from "./multiple/frame-rodadas-menor-tempo-variadas";
import { FrameMenorTempoPossivel } from "./multiple/frame-menor-tempo-possivel";
import { FrameRealizarDentroMinutoMultiplo } from "./multiple/frame-realizar-dentro-minuto";
import { FrameMaximoRepeticoesXTempo } from "./multiple/frame-maximo-repeticoes-x-tempo";
import { FrameMaximoRodadasXTempo } from "src/components/structures/admin-edit-workout/workout-exercises/multiple/frame-maximo-rodadas-x-tempo";
import { FrameMorrerRepeticoes } from "src/components/structures/admin-edit-workout/workout-exercises/multiple/frame-morrer-repeticoes";
import { FrameIntervaladoMultiplo } from "src/components/structures/admin-edit-workout/workout-exercises/multiple/frame-intervalado-multiplo";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        background: "none",
        width: "90%",
        height: "90%",
    },
    overlay: {
        backgroundColor: "rgb(0, 0, 0, 0.3)",
    },
};

const ModalContent = styled.div`
    height: 100%;
    overflow-y: auto;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 8vh 5vw;
    border-radius: 10px;

    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "title title"
        "style style"
        "content content"
        "button button";
    align-content: flex-start;
    row-gap: 3vh;
    column-gap: 1vw;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "title"
            "style"
            "content"
            "button";
    }
`;

const Title = styled(Typography).attrs({
    variant: "h2",
})`
    grid-area: title;
`;

const SelectWorkoutTypeField = styled(SelectField).attrs({
    label: "Selecione o Estilo de Treino",
    text: { align: "left" },
})`
    grid-area: style;
`;

const WorkoutSeriesContent = styled.div`
    grid-area: content;
`;

const CloseButton = styled.i.attrs({
    className: "fas fa-times",
})`
    position: absolute;
    top: 5vh;
    right: 2.5vw;
    cursor: pointer;
    z-index: 99;
    opacity: 70%;

    @media only screen and (max-width: 768px) {
        top: 6vh;
        right: 5vw;
        font-size: 2rem;
    }
`;

interface ModalNewMultipleExercisesProps {
    workoutSeries?: WorkoutMultipleSeries;
    availableExercises: Exercise[];
    workoutTypes: WorkoutMultipleSeriesType[];
    isOpen: boolean;
    onClose: () => void;
    onSave: (workoutSeries: WorkoutSeries) => void;
}

interface EditExerciseModalProps {
    open: boolean;
    section: number;
    numberOfFields: number;
    index?: number;
}

export const ModalNewMultipleExercises: React.FC<ModalNewMultipleExercisesProps> = (
    props
) => {
    Modal.setAppElement("#root");

    const [
        workoutTypeSelected,
        setWorkoutTypeSelected,
    ] = React.useState<WorkoutMultipleSeriesType>();
    const [sections, setSections] = React.useState<WorkoutExercisesSection[]>(
        []
    );
    const [exerciseModalProps, setExerciseModalProps] = React.useState<
        EditExerciseModalProps | undefined
    >(undefined);

    React.useEffect(() => {
        if (props.workoutSeries) {
            setSections(props.workoutSeries.sections);
            setWorkoutTypeSelected(props.workoutSeries.type);
        } else {
            setSections([]);
            setWorkoutTypeSelected("rodadas_menor_tempo_fixas");
        }
    }, [props.workoutSeries]);

    const getWorkoutTypeOptions = (): SelectFieldOption[] =>
        props.workoutTypes.map((workoutType) => ({
            label: getWorkoutSeriesTypeFormated(workoutType),
            value: workoutType,
        }));

    const openEditExerciseModal = (
        section: number,
        numberOfFields: number,
        index?: number
    ) => {
        setExerciseModalProps({
            section,
            numberOfFields,
            index,
            open: true,
        });
    };

    const deleteExercise = (section: number, index: number) => {
        const newSections = [...sections];

        newSections[section].exercises.splice(index, 1);

        setSections(newSections);
    };

    const copyExercise = (section: number, index: number) => {
        const newSections = [...sections];
        const exerciseToCopy = { ...newSections[section].exercises[index] };

        newSections[section].exercises.push(exerciseToCopy);

        setSections(newSections);
    };

    const saveExercise = (workoutExercise: WorkoutExercise) => {
        if (!exerciseModalProps) {
            return;
        }

        const newSections = [...sections];

        const sectionId = exerciseModalProps.section;
        const exerciseId = exerciseModalProps.index;

        if (newSections[sectionId] === undefined) {
            newSections.push({ exercises: [] });
        }

        if (exerciseId !== undefined) {
            newSections[sectionId].exercises[exerciseId] = workoutExercise;
        } else {
            newSections[sectionId].exercises.push(workoutExercise);
        }

        setSections(newSections);
        setExerciseModalProps(undefined);
    };

    const reorderExercise = (oldIndex: number, newIndex: number) => {
        const newExercises = [...sections[0].exercises];

        [newExercises[oldIndex], newExercises[newIndex]] = [
            newExercises[newIndex],
            newExercises[oldIndex],
        ];

        const newSections = [...sections];
        newSections[0].exercises = newExercises;

        setSections(newSections);
    };

    const getWorkoutSeriesContent = (
        workoutType: WorkoutMultipleSeriesType
    ) => {
        switch (workoutType) {
            case "rodadas_menor_tempo_fixas":
                return (
                    <FrameRodadasMenorTempoFixas
                        workoutSeries={
                            props.workoutSeries as RodadasMenorTempoFixas
                        }
                        sections={sections}
                        addExercise={() => openEditExerciseModal(0, 1)}
                        editExercise={(index) =>
                            openEditExerciseModal(0, 1, index)
                        }
                        copyExercise={(index) => copyExercise(0, index)}
                        deleteExercise={(index) => deleteExercise(0, index)}
                        onSaveWorkoutSeries={props.onSave}
                        reorderExercise={reorderExercise}
                    />
                );
            case "rodadas_menor_tempo_variadas":
                return (
                    <FrameRodadasMenorTempoVariadas
                        workoutSeries={
                            props.workoutSeries as RodadasMenorTempoVariadas
                        }
                        sections={sections}
                        addExercise={(seriesNumber) =>
                            openEditExerciseModal(0, seriesNumber)
                        }
                        editExercise={(index, seriesNumber) =>
                            openEditExerciseModal(0, seriesNumber, index)
                        }
                        copyExercise={(index) => copyExercise(0, index)}
                        deleteExercise={(index) => deleteExercise(0, index)}
                        onSaveWorkoutSeries={props.onSave}
                        reorderExercise={reorderExercise}
                    />
                );
            case "menor_tempo_possivel":
                return (
                    <FrameMenorTempoPossivel
                        workoutSeries={
                            props.workoutSeries as MenorTempoPossivel
                        }
                        sections={sections}
                        addExercise={() => openEditExerciseModal(0, 1)}
                        editExercise={(index) =>
                            openEditExerciseModal(0, 1, index)
                        }
                        copyExercise={(index) => copyExercise(0, index)}
                        deleteExercise={(index) => deleteExercise(0, index)}
                        onSaveWorkoutSeries={props.onSave}
                        reorderExercise={reorderExercise}
                    />
                );
            case "realizar_dentro_minuto_multiplo":
                return (
                    <FrameRealizarDentroMinutoMultiplo
                        workoutSeries={
                            props.workoutSeries as RealizarDentroMinutoMultiplo
                        }
                        sections={sections}
                        addExercise={() => openEditExerciseModal(0, 1)}
                        editExercise={(index) =>
                            openEditExerciseModal(0, 1, index)
                        }
                        copyExercise={(index) => copyExercise(0, index)}
                        deleteExercise={(index) => deleteExercise(0, index)}
                        onSaveWorkoutSeries={props.onSave}
                        reorderExercise={reorderExercise}
                    />
                );
            case "maximo_repeticoes_x_tempo":
                return (
                    <FrameMaximoRepeticoesXTempo
                        workoutSeries={
                            props.workoutSeries as MaximoRepeticoesXTempo
                        }
                        sections={sections}
                        addExercise={() => openEditExerciseModal(0, 1)}
                        editExercise={(index) =>
                            openEditExerciseModal(0, 1, index)
                        }
                        copyExercise={(index) => copyExercise(0, index)}
                        deleteExercise={(index) => deleteExercise(0, index)}
                        onSaveWorkoutSeries={props.onSave}
                        reorderExercise={reorderExercise}
                    />
                );
            case "maximo_rodadas_x_tempo":
                return (
                    <FrameMaximoRodadasXTempo
                        workoutSeries={
                            props.workoutSeries as MaximoRodadasXTempo
                        }
                        sections={sections}
                        addExercise={() => openEditExerciseModal(0, 1)}
                        editExercise={(index) =>
                            openEditExerciseModal(0, 1, index)
                        }
                        copyExercise={(index) => copyExercise(0, index)}
                        deleteExercise={(index) => deleteExercise(0, index)}
                        onSaveWorkoutSeries={props.onSave}
                        reorderExercise={reorderExercise}
                    />
                );
            case "morrer_repeticoes":
                return (
                    <FrameMorrerRepeticoes
                        workoutSeries={props.workoutSeries as MorrerRepeticoes}
                        sections={sections}
                        addExercise={() => openEditExerciseModal(0, 1)}
                        editExercise={(index) =>
                            openEditExerciseModal(0, 1, index)
                        }
                        copyExercise={(index) => copyExercise(0, index)}
                        deleteExercise={(index) => deleteExercise(0, index)}
                        onSaveWorkoutSeries={props.onSave}
                        reorderExercise={reorderExercise}
                    />
                );
            case "intervalado_multiplo":
                return (
                    <FrameIntervaladoMultiplo
                        workoutSeries={
                            props.workoutSeries as IntervaladoMultiplo
                        }
                        sections={sections}
                        addExercise={() => openEditExerciseModal(0, 1)}
                        editExercise={(index) =>
                            openEditExerciseModal(0, 1, index)
                        }
                        copyExercise={(index) => copyExercise(0, index)}
                        deleteExercise={(index) => deleteExercise(0, index)}
                        onSaveWorkoutSeries={props.onSave}
                        reorderExercise={reorderExercise}
                    />
                );
            default:
                return <div />;
        }
    };

    return (
        <Modal
            style={customStyles}
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <ModalContent>
                <CloseButton onClick={() => props.onClose()} />
                <Title>Vincular Exercício ao Treino</Title>
                <SelectWorkoutTypeField
                    options={getWorkoutTypeOptions()}
                    value={workoutTypeSelected}
                    onChange={(value) =>
                        setWorkoutTypeSelected(
                            value as WorkoutMultipleSeriesType
                        )
                    }
                    defaultValue={
                        props.workoutSeries
                            ? props.workoutSeries.type
                            : props.workoutTypes[0]
                    }
                />
                <WorkoutSeriesContent>
                    {getWorkoutSeriesContent(
                        workoutTypeSelected ?? "rodadas_menor_tempo_fixas"
                    )}
                </WorkoutSeriesContent>
            </ModalContent>
            {exerciseModalProps && (
                <ModalNewExercise
                    workoutExercise={
                        exerciseModalProps.index !== undefined
                            ? sections[exerciseModalProps.section].exercises[
                                  exerciseModalProps.index
                              ]
                            : undefined
                    }
                    seriesNumber={exerciseModalProps.numberOfFields}
                    isOpen={exerciseModalProps.open}
                    availableExercises={props.availableExercises}
                    onClose={() => setExerciseModalProps(undefined)}
                    onSave={saveExercise}
                />
            )}
        </Modal>
    );
};

import React from "react";
import styled from "styled-components";
import { theme } from "src/style/theme";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core";

interface CheckboxProps {
    label?: string;
    checked?: boolean;
    defaultChecked?: boolean;
    onClick: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void;
    className?: string;
}

const StyledCheckbox = styled(MaterialCheckbox)`
    &.Mui-checked {
        color: ${theme.colors.primary.dark} !important;
    }
`;

export const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
    return (
        <FormControlLabel
            control={
                <StyledCheckbox
                    onChange={props.onClick}
                    defaultChecked={props.defaultChecked}
                    checked={props.checked}
                />
            }
            label={props.label}
            className={props.className}
        />
    );
};

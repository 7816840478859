import React from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { Button } from "src/components/atoms/button";
import { InputField } from "src/components/molecules/input-field";
import { Typography } from "src/components/atoms/typography";
import { Condo } from "src/entities/condo";
import { Customer } from "src/entities/customer";
import { SignupBaseFrame } from "src/components/structures/signup/signup-base-frame";

const Title = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "400",
})`
    text-align: center;
    margin: 3vh 0;
`;

const DateOfBirthField = styled(InputField).attrs({
    label: "Insira sua data de Nascimento",
    placeholder: "dd/mm/aaaa",
    box: { light: true },
    text: { align: "left" },
    validations: { mask: "99/99/9999" },
})`
    width: 100%;
`;

const ApartmentField = styled(InputField).attrs({
    label: "Número do Apartamento",
    placeholder: "Digite o número do Apartamento",
    box: { light: true },
    text: { align: "left" },
})`
    width: 100%;
`;

const ApartmentRegionField = styled(InputField).attrs({
    label: "Número do Bloco",
    placeholder: "Digite o número do Bloco",
    box: { light: true },
    text: { align: "left" },
})`
    width: 100%;
`;

const ButtonContinue = styled(Button).attrs({ round: false })`
    justify-self: flex-end;
    width: 50%;
    margin-top: 2vh;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface SignupLocationInfoProps {
    condo?: Condo;
    goToNextPage: () => void;
    updateInfo: (customerData: Partial<Customer>) => void;
}

export const SignupLocationInfo: React.FC<SignupLocationInfoProps> = (
    props
) => {
    const { control, formState, handleSubmit, errors } = useForm();
    const { isSubmitting } = formState;

    const onSubmit = async (values: any) => {
        const birthDate = dayjs(values.birthDate, "DDMMYYYY").toDate();

        props.updateInfo({ ...values, birthDate });
        props.goToNextPage();
    };

    return (
        <SignupBaseFrame onSubmit={handleSubmit(onSubmit)}>
            <Title>
                Identificamos o seu condomínio:{" "}
                <strong>{props.condo?.name}</strong>
                <br />
                Insira suas informações de cadastro:
            </Title>
            <Controller
                as={
                    <DateOfBirthField
                        error={{
                            isInvalid: !!errors.birthDate,
                            message: errors.birthDate?.message,
                        }}
                    />
                }
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="birthDate"
            />
            <Controller
                as={
                    <ApartmentField
                        error={{
                            isInvalid: !!errors.apartment,
                            message: errors.apartment?.message,
                        }}
                    />
                }
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="apartment"
            />
            <Controller
                as={
                    <ApartmentRegionField
                        error={{
                            isInvalid: !!errors.condoZone,
                            message: errors.condoZone?.message,
                        }}
                    />
                }
                control={control}
                defaultValue={""}
                name="condoZone"
            />
            <ButtonContinue loading={isSubmitting} type="submit">
                Avançar
            </ButtonContinue>
        </SignupBaseFrame>
    );
};

import React from "react";
import { WorkoutAudience } from "src/components/structures/admin-edit-workout/workout-audience";
import { WorkoutBasicInfo } from "src/components/structures/admin-edit-workout/workout-basic-info";
import { WorkoutExercises } from "src/components/structures/admin-edit-workout/workout-exercises";
import { WorkoutSettings } from "src/components/structures/admin-edit-workout/workout-settings";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import {
    ScreenAlert,
    ScreenAlertProps,
} from "src/components/structures/shared/screen-alert";
import { AdminEditLayout } from "src/components/templates/layout/admin-edit-layout";
import { Condo } from "src/entities/condo";
import { Exercise } from "src/entities/exercise";
import { ScheduledWorkout } from "src/entities/scheduled-workout";
import { Workout } from "src/entities/workout";
import {
    WorkoutMultipleSeriesType,
    WorkoutSeries,
    WorkoutSingleSeriesType,
} from "src/entities/workout-series";

interface AdminEditWorkoutTemplateProps {
    condos: Condo[];
    isLoading: boolean;
    title: string;
    workout?: Workout;
    scheduledDate?: string;
    availableExercises: Exercise[];
    singleWorkoutTypes: WorkoutSingleSeriesType[];
    multipleWorkoutTypes: WorkoutMultipleSeriesType[];
    redirectWorkoutsPage: () => void;
    saveWorkout: (workout: Partial<ScheduledWorkout>) => Promise<boolean>;
    scheduleWorkout?: (
        scheduledWorkout: Workout,
        dates: Date[],
        condosId: string[]
    ) => Promise<boolean>;
    addWorkoutSeries: (workoutSeries: WorkoutSeries) => Promise<boolean>;
    removeWorkoutSeries: (workoutSeries: WorkoutSeries) => Promise<boolean>;
}

export const AdminEditWorkoutTemplate: React.FC<AdminEditWorkoutTemplateProps> = (
    props
) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [alertProps, setAlertProps] = React.useState<
        ScreenAlertProps | undefined
    >(undefined);

    const fullSteps = [
        {
            icon: "/assets/admin-step-menu/mdi_paste.png",
        },
        {
            icon: "/assets/admin-step-menu/mdi_person.png",
        },
        {
            icon: "/assets/admin-step-menu/mdi_gym.png",
        },
        {
            icon: "/assets/admin-step-menu/mdi_store_mall_directory.png",
        },
    ];

    const workoutOnlySteps = fullSteps.slice(0, 3);

    const basicSteps = fullSteps.slice(0, 1);

    const getFrameSteps = () => {
        if (!!props.scheduleWorkout && !!props.workout) {
            return fullSteps;
        }
        if (!!props.workout) {
            return workoutOnlySteps;
        }

        return basicSteps;
    };

    const getWorkoutSeries = () => {
        const availableWorkoutSeries =
            props.workout?.series?.map((series) => series as WorkoutSeries) ??
            [];

        availableWorkoutSeries.sort((a, b) => {
            if (a.createdAt && b.createdAt) {
                return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                );
            }

            return 0;
        });

        return availableWorkoutSeries;
    };

    const goToNextFrame = () => {
        setActiveStep(activeStep + 1);
    };

    const scheduleWorkout = async (
        dates: Date[],
        condos: Condo[]
    ): Promise<void> => {
        if (!props.workout) {
            setAlertProps({
                handleClose: () => setAlertProps(undefined),
                open: true,
                message: "Erro interno ao agendar",
                severity: "error",
            });

            return;
        }

        const condosId = condos.map((condo) => condo.id);

        const response = await props.scheduleWorkout?.(
            props.workout,
            dates,
            condosId
        );

        setAlertProps({
            handleClose: () => setAlertProps(undefined),
            open: true,
            message: response ? "Sucesso ao agendar!" : "Erro ao agendar!",
            severity: response ? "success" : "error",
        });

        if (response) {
            props.redirectWorkoutsPage();
        }
    };

    const getCurrentStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <WorkoutBasicInfo
                        workout={props.workout}
                        scheduledDate={props.scheduledDate}
                        onCancel={() => props.redirectWorkoutsPage()}
                        onSave={props.saveWorkout}
                        goToNextFrame={goToNextFrame}
                    />
                );
            case 1:
                return (
                    <WorkoutAudience
                        workout={props.workout}
                        onCancel={() => props.redirectWorkoutsPage()}
                        onSave={props.saveWorkout}
                        goToNextFrame={goToNextFrame}
                    />
                );
            case 2:
                return (
                    <WorkoutExercises
                        workoutSeries={getWorkoutSeries()}
                        availableExercises={props.availableExercises}
                        singleWorkoutTypes={props.singleWorkoutTypes}
                        multipleWorkoutTypes={props.multipleWorkoutTypes}
                        onCancel={() => props.redirectWorkoutsPage()}
                        addWorkoutSeries={props.addWorkoutSeries}
                        removeWorkoutSeries={props.removeWorkoutSeries}
                        goToNextFrame={
                            props.scheduleWorkout ? goToNextFrame : undefined
                        }
                    />
                );
            case 3:
                return (
                    <WorkoutSettings
                        condos={props.condos}
                        onCancel={() => props.redirectWorkoutsPage()}
                        onSchedule={scheduleWorkout}
                    />
                );
        }
    };

    return (
        <AdminEditLayout
            title={props.title}
            steps={getFrameSteps()}
            stepClick={setActiveStep}
            activeStep={activeStep}
        >
            {props.isLoading ? <LoadingPage /> : getCurrentStepContent()}
            {alertProps && <ScreenAlert {...alertProps} />}
        </AdminEditLayout>
    );
};

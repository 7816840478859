import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import { TextAreaField } from "src/components/molecules/textarea-field";
import { Typography } from "src/components/atoms/typography";
import { Condo } from "src/entities/condo";
import { Button } from "src/components/atoms/button";

const Content = styled.form`
    display: grid;
    row-gap: 3vh;
    column-gap: 2vw;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "title title title"
        ". . ."
        "contract-type contract-value ."
        "contract-due-date contract-payment ."
        "notes notes notes"
        ". buttons buttons";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "title"
            "contract-type"
            "contract-value"
            "contract-due-date"
            "contract-payment"
            "notes"
            "buttons";
    }
`;

const Title = styled(Typography).attrs({ variant: "h3" })`
    grid-area: title;
`;

const ContractTypeField = styled(InputField).attrs({
    label: "Tipo de contrato",
    placeholder: "Tipo de contrato",
})`
    grid-area: contract-type;
`;

const ContractValueField = styled(InputField).attrs({
    label: "Valor do Contrato",
    placeholder: "Informe o valor do contrato",
    type: "number",
})`
    grid-area: contract-value;
`;

const ContractDueDateField = styled(InputField).attrs({
    label: "Data de Vencimento da Mensalidade",
    placeholder: "Informe a data de Vencimento da Mensalidade",
    type: "tel",
})`
    grid-area: contract-due-date;
`;

const ContractPaymentField = styled(InputField).attrs({
    label: "Forma de Pagamento",
    placeholder: "Informe a Forma de Pagamento",
})`
    grid-area: contract-payment;
`;

const NotesField = styled(TextAreaField).attrs({
    label: "Observações Fiscais",
    placeholder: "Digite as observações",
})`
    grid-area: notes;
`;

const ButtonsArea = styled.div`
    grid-area: buttons;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-end;
    column-gap: 2vw;

    @media only screen and (max-width: 768px) {
        justify-content: stretch;
    }
`;

const ButtonCancel = styled(Button).attrs({
    color: "background",
    round: false,
})`
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const ButtonNext = styled(Button).attrs({ round: false })`
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface FinancialInfoFrameProps {
    condo?: Condo;
    onCancel: () => void;
    onSave: (id: string, condo: Partial<Condo>) => Promise<boolean>;
    nextFrame: () => void;
}

export const FinancialInfoFrame: React.FC<FinancialInfoFrameProps> = (
    props
) => {
    const { control, formState, handleSubmit } = useForm();

    const { isSubmitting } = formState;

    const submitForm = async (values: any) => {
        const dataToUpdate: Partial<Condo> = {
            ...values,
        };

        const response = await props.onSave(
            props.condo?.id ?? "",
            dataToUpdate
        );

        if (response) {
            props.nextFrame();
        }
    };

    return (
        <Content onSubmit={handleSubmit(submitForm)}>
            <Title>Informações Financeiras</Title>
            <Controller
                as={<ContractTypeField />}
                control={control}
                defaultValue={props.condo?.financialInfo?.contractType ?? ""}
                name="financialInfo.contractType"
            />
            <Controller
                as={<ContractValueField />}
                control={control}
                defaultValue={props.condo?.financialInfo?.value ?? ""}
                name="financialInfo.value"
            />
            <Controller
                as={<ContractDueDateField />}
                control={control}
                defaultValue={props.condo?.financialInfo?.dueDate ?? ""}
                name="financialInfo.dueDate"
            />
            <Controller
                as={<ContractPaymentField />}
                control={control}
                defaultValue={props.condo?.financialInfo?.paymentForm ?? ""}
                name="financialInfo.paymentForm"
            />
            <Controller
                as={<NotesField />}
                control={control}
                defaultValue={props.condo?.financialInfo?.notes ?? ""}
                name="financialInfo.notes"
            />
            <ButtonsArea>
                {isSubmitting === false && (
                    <ButtonCancel onClick={() => props.onCancel()}>
                        Cancelar
                    </ButtonCancel>
                )}
                <ButtonNext type="submit" loading={isSubmitting}>
                    Salvar
                </ButtonNext>
            </ButtonsArea>
        </Content>
    );
};

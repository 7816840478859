export type ColorType = "primary" | "secondary" | "background";

export const theme = {
    colors: {
        primary: {
            light: "#64FC2F",
            dark: "#6DCC02",
        },
        secondary: {
            light: "#808080",
            dark: "#4D4D4D",
        },
        background: {
            light: "#F5F5F5",
            dark: "#E5E5E5",
        },
    },
    padding: {
        horizontal: {
            dense: "5vw",
            normal: "10vw",
        },
        vertical: {
            normal: "10vh",
            dense: "5vh",
        },
    },
    appBar: {
        landinghHeight: "8vh",
        appHeight: "8vh",
        profileHeight: "6vh",
    },
    shadow: "0px 3px 6px #00000029",
};

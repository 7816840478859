import React from "react";
import styled from "styled-components";
import { Typography } from "src/components/atoms/typography";
import { SignupBaseFrame } from "src/components/structures/signup/signup-base-frame";

const FinishImage = styled.img.attrs({ src: "/assets/signup_end.png" })`
    width: 30%;
`;

const Title = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "400",
})`
    text-align: center;
    margin: 3vh 0;
`;

interface SignupFinishProps {
    redirectOnFinish: () => void;
}

export const SignupFinish: React.FC<SignupFinishProps> = (props) => {
    React.useEffect(() => {
        setTimeout(() => props.redirectOnFinish(), 5000);
    });

    return (
        <SignupBaseFrame>
            <FinishImage />
            <Title>
                Seu cadastro foi finalizado com sucesso!
                <br />
                Agora você pode desfrutar de todos os nossos serviços!
                <br />
                <br />
                Em instantes você será redirecionado a página de login.
            </Title>
        </SignupBaseFrame>
    );
};

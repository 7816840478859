import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { times } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { Typography } from "src/components/atoms/typography";
import { Exercise } from "src/entities/exercise";
import { WorkoutExercise } from "src/entities/workout-series";
import { InputField } from "src/components/molecules/input-field";
import { Checkbox } from "src/components/atoms/checkbox";
import { Button } from "src/components/atoms/button";
import {
    AutoCompleteField,
    AutoCompleteFieldOption,
} from "src/components/molecules/auto-complete-field";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        background: "none",
        width: "90%",
        height: "90%",
        zIndex: 999,
    },
    overlay: {
        backgroundColor: "rgb(0, 0, 0, 0.3)",
    },
};

const ModalContent = styled.form`
    height: 100%;
    overflow-y: auto;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 8vh 5vw;
    border-radius: 10px;

    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "title title title"
        "exercise exercise ."
        "subtitle subtitle subtitle"
        "content content content"
        "series series series"
        "buttons buttons buttons";
    align-content: flex-start;
    row-gap: 3vh;
    column-gap: 1vw;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "title"
            "exercise"
            "rounds-number"
            "subtitle"
            "content"
            "series"
            "buttons";
    }
`;

const Title = styled(Typography).attrs({
    variant: "h2",
})`
    grid-area: title;
`;

const CloseButton = styled.i.attrs({
    className: "fas fa-times",
})`
    position: absolute;
    top: 5vh;
    right: 2.5vw;
    cursor: pointer;
    z-index: 99;
    opacity: 70%;

    @media only screen and (max-width: 768px) {
        top: 6vh;
        right: 5vw;
        font-size: 2rem;
    }
`;

const SelectExerciseField = styled(AutoCompleteField).attrs({
    label: "Selecione o Exercício",
})`
    grid-area: exercise;
`;

const Subtitle = styled(Typography).attrs({
    variant: "h5",
})`
    grid-area: subtitle;
    text-transform: uppercase;
    color: #4d4d4d;
`;

const ButtonsArea = styled.div`
    margin-bottom: 5vh;

    grid-area: buttons;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-end;
    column-gap: 2vw;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        row-gap: 2vh;
    }
`;

const ButtonCancel = styled(Button).attrs({
    color: "background",
    round: false,
})`
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const ButtonSave = styled(Button).attrs({ round: false })`
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Content = styled.div`
    grid-area: content;

    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    column-gap: 1vw;
    row-gap: 2vh;
`;

const ExerciseGoalBase = styled.div`
    display: grid;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    grid-auto-rows: auto;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        "title title"
        "toggle values";
`;

const ExerciseGoalTitle = styled(Typography).attrs({
    variant: "h5",
})`
    grid-area: title;
`;

const ExerciseGoalToggle = styled(Checkbox)`
    grid-area: toggle;
    margin-right: 0 !important;
`;

const ExerciseGoalValueArea = styled.div`
    grid-area: values;

    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    column-gap: 1vw;

    @media only screen and (max-width: 768px) {
        grid-auto-flow: row;
        grid-template-columns: auto auto auto auto;
    }
`;

const ExerciseGoalValue = styled(InputField).attrs({
    type: "number",
    validations: { min: "0" },
})``;

interface ExerciseGoalProps {
    title: string;
    active: boolean;
    value: number[];
    seriesNumber: number;
    setActive: () => void;
    setValue: (index: number, value: number) => void;
}

const ExerciseGoal: React.FC<ExerciseGoalProps> = (props) => {
    return (
        <ExerciseGoalBase>
            <ExerciseGoalTitle>{props.title}</ExerciseGoalTitle>
            <ExerciseGoalToggle
                onClick={() => props.setActive()}
                checked={props.active}
            />
            <ExerciseGoalValueArea>
                {times(props.seriesNumber, (index: number) => (
                    <ExerciseGoalValue
                        key={index}
                        disabled={!props.active}
                        onChange={(value) =>
                            props.setValue(index, Number(value))
                        }
                        value={(props.value[index] ?? 0).toFixed(0)}
                    />
                ))}
            </ExerciseGoalValueArea>
        </ExerciseGoalBase>
    );
};

interface ModalNewExerciseProps {
    workoutExercise?: WorkoutExercise;
    seriesNumber?: number;
    availableExercises: Exercise[];
    isOpen: boolean;
    onClose: () => void;
    onSave: (workoutExercise: WorkoutExercise) => void;
}

export const ModalNewExercise: React.FC<ModalNewExerciseProps> = (props) => {
    Modal.setAppElement("#root");

    const { control, handleSubmit, reset, formState } = useForm();
    const { isSubmitting } = formState;

    const [repsActive, setRepsActive] = React.useState(false);
    const [repsValue, setRepsValue] = React.useState<number[]>([]);
    const [distanceActive, setDistanceActive] = React.useState(false);
    const [distanceValue, setDistanceValue] = React.useState<number[]>([]);
    const [timeActive, setTimeActive] = React.useState(false);
    const [timeValue, setTimeValue] = React.useState<number[]>([]);
    const [loadActive, setLoadActive] = React.useState(false);
    const [loadValue, setLoadValue] = React.useState<number[]>([]);
    const [heightActive, setHeightActive] = React.useState(false);
    const [heightValue, setHeightValue] = React.useState<number[]>([]);

    const setLocalValue = (
        index: number,
        value: number,
        type: "reps" | "distance" | "time" | "load" | "height"
    ) => {
        let baseValues: number[] = [];
        let baseUpdateMethod: (newArray: number[]) => void;

        switch (type) {
            case "reps":
                baseValues = repsValue;
                baseUpdateMethod = setRepsValue;
                break;
            case "distance":
                baseValues = distanceValue;
                baseUpdateMethod = setDistanceValue;
                break;
            case "time":
                baseValues = timeValue;
                baseUpdateMethod = setTimeValue;
                break;
            case "load":
                baseValues = loadValue;
                baseUpdateMethod = setLoadValue;
                break;
            case "height":
                baseValues = heightValue;
                baseUpdateMethod = setHeightValue;
                break;
        }

        const newValue = [...baseValues];
        newValue[index] = value;

        baseUpdateMethod?.(newValue);
    };

    React.useEffect(() => {
        setRepsActive(props.workoutExercise?.goal.reps !== undefined ?? false);
        setRepsValue(props.workoutExercise?.goal.reps ?? []);
        setDistanceActive(
            props.workoutExercise?.goal.distance !== undefined ?? false
        );
        setDistanceValue(props.workoutExercise?.goal.distance ?? []);
        setTimeActive(props.workoutExercise?.goal.time !== undefined ?? false);
        setTimeValue(props.workoutExercise?.goal.time ?? []);
        setLoadActive(props.workoutExercise?.goal.load !== undefined ?? false);
        setLoadValue(props.workoutExercise?.goal.load ?? []);
        setHeightActive(
            props.workoutExercise?.goal.height !== undefined ?? false
        );
        setHeightValue(props.workoutExercise?.goal.height ?? []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    const availableExercises = React.useMemo<AutoCompleteFieldOption[]>(
        () =>
            props.availableExercises
                .map((exercise) => ({
                    label: exercise.name,
                    value: exercise.id,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [props.availableExercises]
    );

    const onSubmit = async (values: any) => {
        const selectedId = values.exercise ?? props.availableExercises[0].id;

        const exercise = props.availableExercises.find(
            (exercise) => exercise.id === selectedId
        );

        if (!exercise) {
            console.log("Exercício não selecionado");
            return;
        }

        const goal = {
            reps: repsActive ? repsValue : undefined,
            load: loadActive ? loadValue : undefined,
            height: heightActive ? heightValue : undefined,
            time: timeActive ? timeValue : undefined,
            distance: distanceActive ? distanceValue : undefined,
        };

        props.onSave({
            exercise,
            goal,
        });

        reset();

        props.onClose();
    };

    return (
        <Modal
            style={customStyles}
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <ModalContent onSubmit={handleSubmit(onSubmit)}>
                <CloseButton onClick={() => props.onClose()} />
                <Title>
                    {"Vincular Exercício ao Treino > Adicionar Exercício"}
                </Title>
                <Controller
                    as={<SelectExerciseField options={availableExercises} />}
                    control={control}
                    defaultValue={
                        props.workoutExercise?.exercise.id ??
                        availableExercises[0]?.value
                    }
                    name="exercise"
                />
                <Subtitle>PARÂMETROS DO EXERCÍCIO</Subtitle>
                <Content>
                    <ExerciseGoal
                        title="Repetições"
                        active={repsActive}
                        setActive={() => setRepsActive(!repsActive)}
                        setValue={(index, value) =>
                            setLocalValue(index, value, "reps")
                        }
                        value={repsValue}
                        seriesNumber={props.seriesNumber ?? 1}
                    />
                    <ExerciseGoal
                        title="Tempo (s)"
                        active={timeActive}
                        setActive={() => setTimeActive(!timeActive)}
                        setValue={(index, value) =>
                            setLocalValue(index, value, "time")
                        }
                        value={timeValue}
                        seriesNumber={props.seriesNumber ?? 1}
                    />
                    <ExerciseGoal
                        title="Distância (m)"
                        active={distanceActive}
                        setActive={() => setDistanceActive(!distanceActive)}
                        setValue={(index, value) =>
                            setLocalValue(index, value, "distance")
                        }
                        value={distanceValue}
                        seriesNumber={props.seriesNumber ?? 1}
                    />
                    <ExerciseGoal
                        title="Carga (Kg)"
                        active={loadActive}
                        setActive={() => setLoadActive(!loadActive)}
                        setValue={(index, value) =>
                            setLocalValue(index, value, "load")
                        }
                        value={loadValue}
                        seriesNumber={props.seriesNumber ?? 1}
                    />
                    <ExerciseGoal
                        title="Altura (cm)"
                        active={heightActive}
                        setActive={() => setHeightActive(!heightActive)}
                        setValue={(index, value) =>
                            setLocalValue(index, value, "height")
                        }
                        value={heightValue}
                        seriesNumber={props.seriesNumber ?? 1}
                    />
                </Content>
                <ButtonsArea>
                    <ButtonCancel onClick={() => props.onClose()}>
                        Cancelar
                    </ButtonCancel>
                    <ButtonSave loading={isSubmitting} type="submit">
                        Adicionar
                    </ButtonSave>
                </ButtonsArea>
            </ModalContent>
        </Modal>
    );
};

import React from "react";
import styled from "styled-components";
import { UseFormMethods, Controller } from "react-hook-form";
import { ExerciseBaseCard } from "src/components/structures/home-workout-series/exercise-base-card";
import { Divider } from "src/components/structures/home-workout-series/divider";
import { ExerciseResultsBase } from "src/components/structures/home-workout-series/exercise-results-base";
import { InputField } from "src/components/molecules/input-field";
import {
    getWorkoutSeriesTitleFormated,
    getWorkoutSeriesDescription,
} from "src/entities/workout-series/utils";
import { RepeticoesTempoDeterminado } from "src/entities/workout-series";
import { Exercise } from "src/entities/exercise";

const BaseSection = styled.div`
    display: grid;
    row-gap: 3vh;
`;

const RepsTotalField = styled(InputField).attrs({
    label: "Carga Utilizada (Kg)",
    type: "number",
})`
    width: 100%;
`;

interface FrameRepeticoesTempoDeterminadoProps {
    workoutSeries: RepeticoesTempoDeterminado;
    control: UseFormMethods["control"];
    exhibitExercise: (exercise: Exercise) => void;
}

export const FrameRepeticoesTempoDeterminado: React.FC<FrameRepeticoesTempoDeterminadoProps> = (
    props
) => {
    return (
        <BaseSection>
            <ExerciseBaseCard
                title={getWorkoutSeriesTitleFormated(props.workoutSeries)}
                description={getWorkoutSeriesDescription(props.workoutSeries)}
                onClick={() =>
                    props.exhibitExercise(props.workoutSeries.exercise)
                }
            />
            <Divider />
            <ExerciseResultsBase control={props.control}>
                <Controller
                    as={<RepsTotalField />}
                    control={props.control}
                    defaultValue={"5"}
                    name="reps"
                />
            </ExerciseResultsBase>
        </BaseSection>
    );
};

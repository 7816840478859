import React from "react";
import styled from "styled-components";
import { AppBar } from "src/components/structures/landing-page/app-bar";
import { SectionFooter } from "src/components/structures/landing-page/section-footer";

const LandingPageBase = styled.div`
    width: 100vw;
    min-height: 100vh;
`;

interface LandingPageLayoutProps {
    goToLogin: () => void;
    openContactForm: () => void;
}

export const LandingPageLayout: React.FC<LandingPageLayoutProps> = (props) => {
    return (
        <LandingPageBase>
            <AppBar
                openContactForm={props.openContactForm}
                goToLogin={props.goToLogin}
            />
            {props.children}
            <SectionFooter />
        </LandingPageBase>
    );
};

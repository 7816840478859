import dayjs from "dayjs";
import React from "react";
import {
    AdminResultsTemplate,
    ResultsFilterOptions,
} from "src/components/templates/admin-results-template";
import { Condo } from "src/entities/condo";
import { WorkoutResult } from "src/entities/workout-result";
import { awsCondoGetAll } from "src/infrastructure/aws/aws-condo";
import {
    awsScheduledWorkoutGet,
    awsScheduledWorkoutQuery,
} from "src/infrastructure/aws/aws-scheduled-workout";

export const AdminResultsPage: React.FC = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [reload, setReload] = React.useState(false);
    const [condos, setCondos] = React.useState<Condo[]>([]);
    const [results, setResults] = React.useState<WorkoutResult[]>([]);
    const [filterOptions, setFilterOptions] = React.useState<
        ResultsFilterOptions
    >({
        startDate: dayjs().add(-4, "d"),
        endDate: dayjs(),
        selectedCondo: undefined,
    });

    const retrieveResults = async () => {
        const workouts = await awsScheduledWorkoutQuery(
            {
                startDate: filterOptions.startDate.toDate(),
                endDate: filterOptions.endDate?.toDate(),
            },
            filterOptions.selectedCondo
        );

        let newResults: WorkoutResult[] = [];

        workouts.forEach((workout) => {
            newResults = [...newResults, ...workout.results];
        });

        setIsLoading(false);
        setReload(false);
        setResults(newResults);
    };

    const retrieveCondos = async () => {
        const newCondos = await awsCondoGetAll();

        setCondos(newCondos);
    };

    React.useEffect(() => {
        const setInitialData = async () => {
            await retrieveResults();
            await retrieveCondos();
        };

        setInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (reload) {
            setIsLoading(true);
            retrieveResults();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    const getWorkout = async (id: string) => {
        return await awsScheduledWorkoutGet(id);
    };

    const reloadResults = (newOptions: ResultsFilterOptions) => {
        setFilterOptions(newOptions);
        setReload(true);
    };

    return (
        <AdminResultsTemplate
            results={results}
            condos={condos}
            isLoading={isLoading}
            defaultValues={filterOptions}
            getWorkout={getWorkout}
            reloadResults={reloadResults}
        />
    );
};

import React from "react";
import styled from "styled-components";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import {
    Table,
    TableAction,
    TableHeader,
} from "src/components/structures/shared/table";
import { AdminLayout } from "src/components/templates/layout/admin-layout";
import { Customer } from "src/entities/customer";
import dayjs from "dayjs";
import { useMediaQuery } from "@material-ui/core";

const Content = styled.div`
    padding: 5vh 0;
`;

interface AdminCustomersTemplateProps {
    customers: Customer[];
    isLoading: boolean;
    toggleCustomerActive: (customer: Customer) => void;
    getParq: (customer: Customer) => void;
}

export const AdminCustomersTemplate: React.FC<AdminCustomersTemplateProps> = (
    props
) => {
    const isMobile = useMediaQuery("(max-width:768px)");

    const getCustomerStatus = (customer: Customer) => {
        if (customer.type === "ADMIN") {
            return "ADMIN";
        }
        if (customer.status === "ACTIVE") {
            return "ACTIVE";
        }
        return "INACTIVE";
    };

    const tableHeadersMobile: TableHeader<Customer>[] = [
        {
            title: "Nome",
            render: (customer: Customer) =>
                `${customer.name} (${getCustomerStatus(customer)})`,
        },
    ];

    const tableHeaders: TableHeader<Customer>[] = [
        {
            title: "Nome",
            field: "name",
        },
        {
            title: "Último Acesso",
            field: "lastAccess",
            render: (customer: Customer) =>
                dayjs(customer.lastAccess).format("DD/MM/YYYY HH:mm:ss"),
        },
        {
            title: "E-mail",
            field: "email",
        },
        {
            title: "Ap/Bloco",
            render: (customer: Customer) =>
                customer.condoZone
                    ? `${customer.apartment}/${customer.condoZone}`
                    : customer.apartment,
        },
        {
            title: "Status",
            render: (customer: Customer) => getCustomerStatus(customer),
        },
    ];

    const tableActions: TableAction<Customer>[] = [
        {
            icon: "block",
            tooltip: "Tornar Ativo/Inativo",
            onClick: (customer) => props.toggleCustomerActive(customer),
        },
        {
            icon: "picture_as_pdf",
            tooltip: "PAR-Q",
            onClick: (customer) => props.getParq(customer),
        },
    ];

    return (
        <AdminLayout title="Gerenciamento de Usuários">
            {props.isLoading ? (
                <LoadingPage />
            ) : (
                <Content>
                    <Table
                        data={props.customers}
                        header={isMobile ? tableHeadersMobile : tableHeaders}
                        actions={tableActions}
                    />
                </Content>
            )}
        </AdminLayout>
    );
};

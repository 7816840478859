import React from "react";
// import queryString from "query-string";
import { useHistory, useParams } from "react-router-dom";
import { HomeWorkoutSeriesTemplate } from "src/components/templates/home-workout-series";
import { WorkoutSeries } from "src/entities/workout-series";
import { awsScheduledWorkoutEntitySave } from "src/infrastructure/aws/aws-scheduled-workout-entity";
import { awsExerciseGet } from "src/infrastructure/aws/aws-exercise";
import {
    WorkoutResult,
    WorkoutSeriesResult,
} from "src/entities/workout-result";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import { SaveScheduledWorkoutEntityRequest } from "src/entities/requests/save-scheduled-workout-entity";
import { Condo } from "src/entities/condo";
import { awsScheduledWorkoutGet } from "src/infrastructure/aws/aws-scheduled-workout";
import { awsAuthGetCurrentUser } from "src/infrastructure/aws/aws-amplify";
import { awsCondoGetAllByCustomer } from "src/infrastructure/aws/aws-condo";
import { ScheduledWorkout } from "src/entities/scheduled-workout";
import { Customer } from "src/entities/customer";
import dayjs from "dayjs";

interface RouteParams {
    treinoId: string;
    exercicioId: string;
}

export const HomeWorkoutSeriesPage: React.FC = () => {
    const history = useHistory();
    const routeParams = useParams<RouteParams>();

    const [customer, setCustomer] = React.useState<Customer | null>(null);
    const [condo, setCondo] = React.useState<Condo | null>(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [workout, setWorkout] = React.useState<ScheduledWorkout | null>(null);
    const [workoutSeries, setWorkoutSeries] = React.useState<
        WorkoutSeries | undefined
    >(undefined);

    React.useEffect(() => {
        const retrieveWorkout = async () => {
            const newWorkout = await awsScheduledWorkoutGet(
                routeParams.treinoId
            );

            const selectedSeries = newWorkout?.series.find(
                (series) => series.id === routeParams.exercicioId
            );

            setWorkout(newWorkout);
            setWorkoutSeries(selectedSeries);
            setIsLoading(false);
        };

        retrieveWorkout();
    }, [routeParams]);

    React.useEffect(() => {
        const retrieveCustomerInfo = async () => {
            const currentUser = await awsAuthGetCurrentUser();

            if (!currentUser) {
                console.log("Não há usuário logado");
                return [];
            }

            const condos = await awsCondoGetAllByCustomer(currentUser);

            if (condos.length === 0) {
                console.log("Não há condomínio para o usuário logado");
                return [];
            }

            setCustomer(currentUser);
            setCondo(condos[0]);
        };

        retrieveCustomerInfo();
    }, []);

    const goToWorkoutPage = () => {
        history.push(`/home/treino/${routeParams.treinoId}`);
    };

    const submitSeriesResults = async (
        seriesResult: WorkoutSeriesResult
    ): Promise<boolean> => {
        if (!condo || !workout || !customer) {
            console.log("Faltando informações", condo, workout, customer);
            return false;
        }

        const workoutResult: WorkoutResult = {
            condo: {
                id: condo.id,
                name: condo.name,
            },
            customer: {
                document: customer.document,
                name: customer.name,
            },
            series: [seriesResult],
            workout: {
                id: workout.id,
                name: workout.name,
                type: workout.type,
                date: workout.date,
            },
            createdAt: dayjs().toISOString(),
            updatedAt: dayjs().toISOString(),
        };

        const request: SaveScheduledWorkoutEntityRequest = {
            workoutId: workout.id,
            condoId: condo.id,
            date: workout.date,
            entity: {
                id: customer.document,
                type: "result",
                data: workoutResult,
            },
        };

        const response = await awsScheduledWorkoutEntitySave(request);

        if (response) {
            goToWorkoutPage();
        }

        return true;
    };

    const getExercise = async (exerciseId: string) => {
        return await awsExerciseGet(exerciseId);
    };

    const getContent = () => {
        return workoutSeries ? (
            <HomeWorkoutSeriesTemplate
                isLoading={isLoading}
                workoutSeries={workoutSeries}
                goToWorkoutPage={goToWorkoutPage}
                getExercise={getExercise}
                submitSeriesResults={submitSeriesResults}
            />
        ) : (
            <div>Treino não encontrado</div>
        );
    };

    return isLoading ? <LoadingPage /> : getContent();
};

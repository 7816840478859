import React from "react";
import { useHistory } from "react-router-dom";
import { AdminHomeTemplate } from "src/components/templates/admin-home-template";
import { Customer } from "src/entities/customer";
import { DashboardData } from "src/entities/dashboard-data";
import { awsAuthGetCurrentUser } from "src/infrastructure/aws/aws-amplify";
import { awsDashboardGet } from "src/infrastructure/aws/aws-dashboard";

export const AdminHomePage: React.FC = () => {
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(true);
    const [dashboardData, setDashboardData] = React.useState<
        DashboardData | undefined
    >();

    const [customer, setCustomer] = React.useState<Customer>();

    React.useEffect(() => {
        const retrieveCustomer = async () => {
            const newCustomer = await awsAuthGetCurrentUser();

            setCustomer(newCustomer ?? undefined);
        };

        retrieveCustomer();
    }, []);

    React.useEffect(() => {
        const retrieveDashboardData = async () => {
            const newData = await awsDashboardGet();

            setDashboardData(newData ?? undefined);
            setIsLoading(false);
        };

        retrieveDashboardData();
    }, []);

    const addNewCondo = () => {
        history.push("/admin/condominio");
    };

    const addNewExercise = () => {
        history.push("/admin/exercicio");
    };

    const addNewWorkout = () => {
        history.push("/admin/treino");
    };

    const goToSchedulePage = () => {
        history.push("/admin/agenda");
    };

    return (
        <AdminHomeTemplate
            isLoading={isLoading}
            customer={customer}
            dashboardData={dashboardData}
            addNewCondo={addNewCondo}
            addNewExercise={addNewExercise}
            goToSchedulePage={goToSchedulePage}
            addNewWorkout={addNewWorkout}
        />
    );
};

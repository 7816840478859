import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import dayjs from "dayjs";
import { times } from "lodash";
import { Controller, useForm } from "react-hook-form";
import {
    SelectField,
    SelectFieldOption,
} from "src/components/molecules/select-field";
import { Typography } from "src/components/atoms/typography";
import { Exercise } from "src/entities/exercise";
import {
    Intervalado,
    MaximoCarga,
    MaximoRepeticoes,
    MorrerCarga,
    MorrerRepeticao,
    RealizarDentroMinuto,
    RepeticoesTempoDeterminado,
    RoundsBase,
    SeriesXRepeticao,
    Tempo,
    WorkoutSeries,
    WorkoutSingleSeries,
    WorkoutSingleSeriesType,
} from "src/entities/workout-series";
import { Button } from "src/components/atoms/button";
import { getWorkoutSeriesTypeFormated } from "src/entities/workout-series/utils";
import { FrameMaximoRepeticoesEdit } from "./single/frame-maximo-repeticoes";
import { FrameMaximoCargaEdit } from "./single/frame-maximo-carga";
import { FrameSeriesRepeticaoEdit } from "./single/frame-series-repeticao";
import { FrameMorrerRepeticaoEdit } from "./single/frame-morrer-repeticao";
import { FrameMorrerCargaEdit } from "./single/frame-morrer-carga";
import { FrameRealizarDentroMinutoEdit } from "./single/frame-realizar-dentro-minuto";
import { FrameIntervaladoEdit } from "./single/frame-intervalado";
import { FrameTempoEdit } from "./single/frame-tempo";
import { FrameRepeticoesTempoDeterminadoEdit } from "./single/frame-repeticoes-tempo-determinado";
import { AutoCompleteField } from "src/components/molecules/auto-complete-field";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        background: "none",
        width: "90%",
        height: "90%",
    },
    overlay: {
        backgroundColor: "rgb(0, 0, 0, 0.3)",
    },
};

const ModalContent = styled.form`
    height: 100%;
    overflow-y: auto;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 8vh 5vw;
    border-radius: 10px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-template-areas:
        "title title"
        "exercise style"
        "subtitle subtitle"
        "content content"
        ". save";
    align-content: flex-start;
    row-gap: 3vh;
    column-gap: 1vw;

    @media only screen and (max-width: 768px) {
        padding: 3vh 5vw;
        grid-template-columns: 1fr;
        grid-template-areas:
            "title"
            "exercise"
            "style"
            "subtitle"
            "content"
            "save";
    }
`;

const Title = styled(Typography).attrs({
    variant: "h2",
})`
    grid-area: title;
`;

const Subtitle = styled(Typography).attrs({
    variant: "h5",
})`
    grid-area: subtitle;
    text-transform: uppercase;
    color: #4d4d4d;
`;

const SelectExerciseField = styled(AutoCompleteField).attrs({
    label: "Selecione o Exercício",
})`
    grid-area: exercise;
`;

const SelectWorkoutTypeField = styled(SelectField).attrs({
    label: "Selecione o Estilo de Treino",
    text: { align: "left" },
})`
    grid-area: style;
`;

const WorkoutSeriesContent = styled.div`
    grid-area: content;
`;

const CloseButton = styled.i.attrs({
    className: "fas fa-times",
})`
    position: absolute;
    top: 5vh;
    right: 2.5vw;
    cursor: pointer;
    z-index: 99;
    opacity: 70%;

    @media only screen and (max-width: 768px) {
        top: 6vh;
        right: 5vw;
        font-size: 2rem;
    }
`;

const ButtonSave = styled(Button).attrs({ round: false })`
    grid-area: save;
    justify-self: flex-end;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface ModalNewSingleExerciseProps {
    workoutSeries?: WorkoutSingleSeries;
    availableExercises: Exercise[];
    workoutTypes: WorkoutSingleSeriesType[];
    isOpen: boolean;
    onClose: () => void;
    onSave: (workoutSeries: WorkoutSeries) => void;
}

export const ModalNewSingleExercise: React.FC<ModalNewSingleExerciseProps> = (
    props
) => {
    Modal.setAppElement("#root");

    const { control, handleSubmit, watch, formState, errors } = useForm();
    const { isSubmitting } = formState;

    const availableExercises = React.useMemo(
        () =>
            props.availableExercises
                .map((exercise) => ({
                    label: exercise.name,
                    value: exercise.id,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [props.availableExercises]
    );

    const getRounds = (values: any): RoundsBase[] => {
        const higherLowerValue =
            values.loadHigherLower === "higher" ||
            values.loadHigherLower === "lower"
                ? values.loadHigherLower
                : undefined;

        const workoutSeriesType: WorkoutSingleSeriesType = values.type;

        const convertTime = (seconds: string): Date | undefined =>
            parseFloat(seconds) > 0
                ? dayjs()
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .add(parseFloat(seconds), "s")
                      .toDate()
                : undefined;

        switch (workoutSeriesType) {
            case "maximo_repeticoes":
            case "maximo_carga":
                return times<RoundsBase>(values.rounds, () => ({
                    reps: values.reps,
                    breakTime: convertTime(values.breakTime),
                    load: {
                        type: values.loadType,
                        femaleValue: values.loadFemaleValue,
                        higherLower: higherLowerValue,
                        maleValue: values.loadMaleValue,
                        value: values.loadValue,
                    },
                }));
            case "series_x_repeticao":
                return times<RoundsBase>(values.rounds, (index: number) => {
                    const breakTime = values[`seriesBreakTime${index}`];

                    return {
                        reps: values[`seriesReps${index}`],
                        breakTime: convertTime(breakTime),
                        load: {
                            type: values.loadType,
                            femaleValue: values.loadFemaleValue,
                            higherLower: values.loadHigherLower,
                            maleValue: values.loadMaleValue,
                            value: values.loadValue,
                        },
                    };
                });
            case "morrer_repeticao":
                return times<RoundsBase>(values.maxRounds, (index: number) => {
                    return {
                        reps:
                            parseFloat(values.startReps) +
                            index * parseFloat(values.incrementReps),
                        roundTime: convertTime(values.roundTime),
                        load: {
                            type: "carga_por_sexo",
                            femaleValue: values.loadFemaleValue,
                            maleValue: values.loadMaleValue,
                        },
                    };
                });
            case "morrer_carga":
                return times<RoundsBase>(values.maxRounds, (index: number) => {
                    return {
                        reps: values.startReps,
                        roundTime: convertTime(values.roundTime),
                        load: {
                            type: "carga_por_sexo",
                            femaleValue:
                                parseFloat(values.loadFemaleValue) +
                                index * parseFloat(values.incrementLoad),
                            maleValue:
                                parseFloat(values.loadMaleValue) +
                                index * parseFloat(values.incrementLoad),
                        },
                    };
                });
            case "realizar_dentro_minuto":
                return times<RoundsBase>(values.totalRounds, () => {
                    return {
                        reps: values.roundReps,
                        load: {
                            type: values.loadType,
                            femaleValue: values.loadFemaleValue,
                            higherLower: values.loadHigherLower,
                            maleValue: values.loadMaleValue,
                            value: values.loadValue,
                        },
                    };
                });
            case "intervalado":
                return times<RoundsBase>(values.rounds, () => {
                    return {
                        roundTime: convertTime(values.roundTime),
                        breakTime: convertTime(values.breakTime),
                        load: {
                            type: values.loadType,
                            femaleValue: values.loadFemaleValue,
                            higherLower: values.loadHigherLower,
                            maleValue: values.loadMaleValue,
                            value: values.loadValue,
                        },
                    };
                });
            case "tempo":
                return [
                    {
                        reps: values.reps,
                        load: {
                            type: values.loadType,
                            femaleValue: values.loadFemaleValue,
                            higherLower: values.loadHigherLower,
                            maleValue: values.loadMaleValue,
                            value: values.loadValue,
                        },
                    },
                ];
            case "repeticoes_tempo_determinado":
                return times<RoundsBase>(values.rounds, () => {
                    return {
                        roundTime: convertTime(values.roundTime),
                        breakTime: convertTime(values.breakTime),
                        load: {
                            type: values.loadType,
                            femaleValue: values.loadFemaleValue,
                            higherLower: values.loadHigherLower,
                            maleValue: values.loadMaleValue,
                            value: values.loadValue,
                        },
                    };
                });

            default:
                return [];
        }
    };

    const onSubmit = async (values: any) => {
        const exercise = props.availableExercises.find(
            (exerc) => exerc.id === values.exercise
        );

        if (!exercise) {
            return;
        }

        const newWorkoutSeries: WorkoutSingleSeries = {
            exercise,
            id: props.workoutSeries?.id,
            type: values.type,
            style: "single",
            rounds: getRounds(values),
        };

        await props.onSave(newWorkoutSeries);
        props.onClose();
    };

    const getWorkoutTypeOptions = (): SelectFieldOption[] => {
        return props.workoutTypes.map((workoutType) => ({
            label: getWorkoutSeriesTypeFormated(workoutType),
            value: workoutType,
        }));
    };

    const getWorkoutSeriesContent = () => {
        let workoutType: WorkoutSingleSeriesType;

        if (watch("type")) {
            workoutType = watch("type");
        } else if (props.workoutSeries) {
            workoutType = props.workoutSeries.type;
        } else {
            workoutType = "maximo_repeticoes";
        }

        switch (workoutType) {
            case "maximo_repeticoes":
                return (
                    <FrameMaximoRepeticoesEdit
                        workoutSeries={props.workoutSeries as MaximoRepeticoes}
                        control={control}
                        errors={errors}
                        watch={watch}
                    />
                );
            case "maximo_carga":
                return (
                    <FrameMaximoCargaEdit
                        workoutSeries={props.workoutSeries as MaximoCarga}
                        control={control}
                        errors={errors}
                        watch={watch}
                    />
                );
            case "series_x_repeticao":
                return (
                    <FrameSeriesRepeticaoEdit
                        workoutSeries={props.workoutSeries as SeriesXRepeticao}
                        control={control}
                        errors={errors}
                        watch={watch}
                    />
                );
            case "morrer_repeticao":
                return (
                    <FrameMorrerRepeticaoEdit
                        workoutSeries={props.workoutSeries as MorrerRepeticao}
                        control={control}
                        errors={errors}
                        watch={watch}
                    />
                );
            case "morrer_carga":
                return (
                    <FrameMorrerCargaEdit
                        workoutSeries={props.workoutSeries as MorrerCarga}
                        control={control}
                        errors={errors}
                        watch={watch}
                    />
                );
            case "realizar_dentro_minuto":
                return (
                    <FrameRealizarDentroMinutoEdit
                        workoutSeries={
                            props.workoutSeries as RealizarDentroMinuto
                        }
                        control={control}
                        errors={errors}
                        watch={watch}
                    />
                );
            case "intervalado":
                return (
                    <FrameIntervaladoEdit
                        control={control}
                        errors={errors}
                        watch={watch}
                        workoutSeries={props.workoutSeries as Intervalado}
                    />
                );
            case "tempo":
                return (
                    <FrameTempoEdit
                        control={control}
                        errors={errors}
                        watch={watch}
                        workoutSeries={props.workoutSeries as Tempo}
                    />
                );
            case "repeticoes_tempo_determinado":
                return (
                    <FrameRepeticoesTempoDeterminadoEdit
                        workoutSeries={
                            props.workoutSeries as RepeticoesTempoDeterminado
                        }
                        control={control}
                        errors={errors}
                        watch={watch}
                    />
                );
            default:
                return <div />;
        }
    };

    return (
        <Modal
            style={customStyles}
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <ModalContent onSubmit={handleSubmit(onSubmit)}>
                <CloseButton onClick={() => props.onClose()} />
                <Title>Vincular Exercício ao Treino</Title>
                <Controller
                    as={
                        <SelectExerciseField
                            options={availableExercises}
                            error={{
                                isInvalid: errors.exercise,
                                message: errors.exercise?.message,
                            }}
                        />
                    }
                    rules={{ required: true }}
                    control={control}
                    defaultValue={
                        props.workoutSeries
                            ? props.workoutSeries.exercise.id
                            : availableExercises[0].value
                    }
                    name="exercise"
                />
                <Controller
                    as={
                        <SelectWorkoutTypeField
                            options={getWorkoutTypeOptions()}
                            error={{
                                isInvalid: errors.type,
                                message: errors.type?.message,
                            }}
                        />
                    }
                    rules={{ required: true }}
                    control={control}
                    defaultValue={
                        props.workoutSeries
                            ? props.workoutSeries.type
                            : props.workoutTypes[0]
                    }
                    name="type"
                />
                <Subtitle>Parâmetros do Exercício</Subtitle>
                <WorkoutSeriesContent>
                    {getWorkoutSeriesContent()}
                </WorkoutSeriesContent>
                <ButtonSave loading={isSubmitting} type="submit">
                    {props.workoutSeries ? "Salvar" : "Adicionar"}
                </ButtonSave>
            </ModalContent>
        </Modal>
    );
};

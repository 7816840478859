import React, { useRef } from "react";
import styled from "styled-components";
import clsx from "clsx";
import {
    getBackgroundColor,
    getIsInputOnErrorState,
    getTextColor,
    InputBase,
    InputBaseProps,
} from "src/components/atoms/input-base";

interface StyledBorderProps {
    box: {
        backgroundColor: string;
        dense: boolean;
        noShadow: boolean;
        onError: boolean;
    };
    text: {
        color: string;
        transform: "uppercase" | "lowercase" | "capitalize" | "none";
        align: "center" | "left" | "right";
    };
}

const InputRow = styled.div<StyledBorderProps>`
    display: flex;
    align-items: center;
    justify-content: "stretch";
    background-color: ${(props) => props.box.backgroundColor};
    padding: ${(props) => (props.box.dense ? "1vh 0.5vw" : "2vh 1.5vw")};
    box-shadow: ${(props) => {
        if (props.box.noShadow) {
            return "";
        }

        if (props.box.onError) {
            return `0 0 0 2px red`;
        }

        return "0px 3px 6px #00000029";
    }};
    border-radius: 7px;
    height: 6vh;
    transition: box-shadow 0.5s;

    select {
        appearance: none;
        border: none;
        font-weight: 100;
        width: 100%;
        color: ${(props) => props.text.color};
        font-size: ${(props) => (props.box.dense ? "0.8rem" : "1.0rem")};
        background-color: ${(props) => props.box.backgroundColor};
        text-transform: ${(props) => props.text.transform};
        text-align: ${(props) => props.text.align ?? "left"};

        &.placeholder {
            opacity: 30%;
        }

        :focus {
            outline: none;
        }
    }

    i {
        grid-area: icon;
        color: black;
        font-size: 1.2rem;
    }
`;

export interface SelectFieldOption {
    label: string;
    value: string;
}

interface SelectFieldProps extends Omit<InputBaseProps, "inputRef"> {
    options: SelectFieldOption[];
    defaultValue?: string;
}

export const SelectField: React.FC<SelectFieldProps> = (props) => {
    const inputEl = useRef<HTMLSelectElement>(null);

    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;

        if (value === "default") {
            inputEl.current?.classList.add("placeholder");
        } else {
            inputEl.current?.classList.remove("placeholder");
        }

        props.onChange?.(value);
    };

    const classes = clsx(props.placeholder && "placeholder");

    const inputRowProps: StyledBorderProps = {
        box: {
            dense: props.box?.dense ?? false,
            noShadow: props.box?.noShadow ?? false,
            backgroundColor: getBackgroundColor(props),
            onError: getIsInputOnErrorState(props),
        },
        text: {
            align: props.text?.align ?? "center",
            transform: props.text?.transform ?? "none",
            color: getTextColor(),
        },
    };

    return (
        <InputBase {...props} inputRef={inputEl}>
            <InputRow {...inputRowProps}>
                <select
                    className={classes}
                    onChange={onChange}
                    ref={inputEl}
                    value={props.value}
                    defaultValue={props.defaultValue ?? "default"}
                >
                    {props.placeholder && (
                        <option value="default" disabled hidden>
                            {props.placeholder}
                        </option>
                    )}

                    {props.options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                <i className="fas fa-angle-down"></i>
            </InputRow>
        </InputBase>
    );
};

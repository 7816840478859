import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useMediaQuery } from "@material-ui/core";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import { Table, TableHeader } from "src/components/structures/shared/table";
import { AdminLayout } from "src/components/templates/layout/admin-layout";
import { WorkoutResult } from "src/entities/workout-result";
import { getWorkoutTypeFormated } from "src/entities/workout-series/utils";
import { ModalResult } from "src/components/structures/admin-results/modal-result";
import { Workout } from "src/entities/workout";
import { Controller, useForm } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import {
    SelectField,
    SelectFieldOption,
} from "src/components/molecules/select-field";
import { Condo } from "src/entities/condo";
import { Button } from "src/components/atoms/button";

dayjs.extend(customParseFormat);

const Content = styled.div`
    padding: 5vh 0;
    display: grid;
    row-gap: 2vh;
`;

const CondoSelector = styled(SelectField).attrs({
    label: "Condomínio",
    placeholder: "Selecione um condomínio",
})`
    grid-area: condo;
`;

const StartDateSelector = styled(InputField).attrs({
    label: "Data Inicial",
    type: "date",
})`
    grid-area: start-date;
`;

const EndDateSelector = styled(InputField).attrs({
    label: "Data Final",
    type: "date",
})`
    grid-area: end-date;
`;

const FilterButton = styled(Button).attrs({ round: false })`
    grid-area: filter-button;
    padding: 2vh 3vw;
    min-height: unset;
    height: fit-content;
    align-self: end;

    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 3vh;
    }
`;

const FormData = styled.form`
    margin-top: 2vh;

    display: grid;
    column-gap: 1vw;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "condo start-date end-date filter-button .";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        row-gap: 1.5vh;
        grid-template-areas:
            "condo"
            "start-date"
            "end-date"
            "filter-button";
    }
`;

export interface ResultsFilterOptions {
    startDate: dayjs.Dayjs;
    endDate?: dayjs.Dayjs;
    selectedCondo?: string;
}

interface AdminResultsTemplateProps {
    isLoading: boolean;
    condos: Condo[];
    results: WorkoutResult[];
    defaultValues: ResultsFilterOptions;
    getWorkout: (id: string) => Promise<Workout | null>;
    reloadResults: (filterOptions: ResultsFilterOptions) => void;
}

export const AdminResultsTemplate: React.FC<AdminResultsTemplateProps> = (
    props
) => {
    const isMobile = useMediaQuery("(max-width:768px)");

    const { control, handleSubmit, formState, setError, errors } = useForm();
    const { isSubmitting } = formState;

    const [selectedWorkoutResult, setSelectedWorkoutResult] = React.useState<
        WorkoutResult | undefined
    >();

    const tableHeaders: TableHeader<WorkoutResult>[] = [
        {
            title: "Usuário",
            render: (result) => result.customer?.name ?? "---",
            customFilterAndSearch: (term, result) =>
                result.customer?.name
                    .toLowerCase()
                    .includes(term.toLowerCase()),
        },
        {
            title: "Data Treino",
            render: (result) => dayjs(result.workout.date).format("DD/MM/YYYY"),
            width: 12,
        },
        {
            title: "Data Realizado",
            render: (result) => dayjs(result.updatedAt).format("DD/MM/YYYY"),
            width: 12,
        },
        {
            title: "Treino (nome)",
            render: (result) => result.workout?.name ?? "",
            customFilterAndSearch: (term, result) =>
                result.workout?.name.toLowerCase().includes(term.toLowerCase()),
        },
        {
            title: "Treino (tipo)",
            width: 14,
            render: (result) => getWorkoutTypeFormated(result.workout?.type),
            customFilterAndSearch: (term, result) =>
                result.workout?.type.toLowerCase().includes(term.toLowerCase()),
        },
        {
            title: "Condomínio",
            render: (result) => result.condo?.name ?? "---",
        },
    ];

    const tableHeadersMobile: TableHeader<WorkoutResult>[] = [
        {
            title: "Usuário",
            render: (result) => result.customer.name,
        },
        {
            title: "Data Realizado",
            render: (result) => dayjs(result.workout.date).format("DD/MM/YYYY"),
        },
    ];

    const getCondoOptions = (): SelectFieldOption[] => {
        return props.condos.map((condo) => ({
            label: condo.name,
            value: condo.id,
        }));
    };

    const filterItems = (values: any) => {
        const dateFormats = ["DD/MM/YYYY", "DDMMYYYY", "YYYY-MM-DD"];

        const newStartDate = dayjs(values.startDate, dateFormats).isValid()
            ? dayjs(values.startDate, dateFormats)
            : undefined;
        const newEndDate = dayjs(values.endDate, dateFormats).isValid()
            ? dayjs(values.endDate, dateFormats)
            : undefined;

        if (!newStartDate) {
            setError("startDate", {
                type: "manual",
                message: "Data inválida!",
            });
            return;
        }

        if (newEndDate && newEndDate.diff(newStartDate, "day") > 5) {
            setError("endDate", {
                type: "manual",
                message: "Máx: 5 dias",
            });
            return;
        }

        props.reloadResults({
            startDate: newStartDate,
            endDate: newEndDate,
            selectedCondo: values.condoId,
        });
    };

    return (
        <AdminLayout title="Resultados dos Treinos">
            <FormData onSubmit={handleSubmit(filterItems)}>
                <Controller
                    as={<CondoSelector options={getCondoOptions()} />}
                    control={control}
                    name="condoId"
                />
                <Controller
                    as={
                        <StartDateSelector
                            error={{
                                isInvalid: !!errors.startDate,
                                message: errors.startDate?.message,
                            }}
                        />
                    }
                    rules={{ required: true }}
                    control={control}
                    defaultValue={props.defaultValues.startDate.format(
                        "YYYY-MM-DD"
                    )}
                    name="startDate"
                />
                <Controller
                    as={
                        <EndDateSelector
                            error={{
                                isInvalid: !!errors.endDate,
                                message: errors.endDate?.message,
                            }}
                        />
                    }
                    control={control}
                    defaultValue={props.defaultValues.endDate?.format(
                        "YYYY-MM-DD"
                    )}
                    name="endDate"
                />
                <FilterButton loading={isSubmitting} type="submit">
                    Filtrar
                </FilterButton>
            </FormData>
            {isSubmitting || props.isLoading ? (
                <LoadingPage />
            ) : (
                <Content>
                    <Table
                        data={props.results}
                        header={isMobile ? tableHeadersMobile : tableHeaders}
                        onClick={setSelectedWorkoutResult}
                    />
                    {selectedWorkoutResult && (
                        <ModalResult
                            isOpen={Boolean(selectedWorkoutResult)}
                            result={selectedWorkoutResult}
                            getWorkout={props.getWorkout}
                            onClose={() => setSelectedWorkoutResult(undefined)}
                        />
                    )}
                </Content>
            )}
        </AdminLayout>
    );
};

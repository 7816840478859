import React from "react";
import styled from "styled-components";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from "react-beautiful-dnd";
import { WorkoutExerciseCard } from "src/components/structures/admin-edit-workout/workout-exercises/shared-fields/workout-exercise-card";
import { WorkoutExercise } from "src/entities/workout-series";

const ExerciseArea = styled.div`
    grid-area: exercises;
`;

interface ExerciseEditableListProps {
    exercises: WorkoutExercise[];
    reorderExercise: (oldIndex: number, newIndex: number) => void;
    deleteExercise: (index: number) => void;
    editExercise: (index: number) => void;
    copyExercise: (index: number) => void;
}

export const ExerciseEditableList: React.FC<ExerciseEditableListProps> = (
    props
) => {
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        props.reorderExercise(result.source.index, result.destination.index);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="exercises-list">
                {(provided) => (
                    <ExerciseArea
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {props.exercises.map((exercise, index) => {
                            return (
                                <Draggable
                                    key={exercise.exercise.id}
                                    draggableId={exercise.exercise.id}
                                    index={index}
                                >
                                    {(provided2) => (
                                        <WorkoutExerciseCard
                                            workoutExercise={exercise}
                                            copyExercise={() =>
                                                props.copyExercise(index)
                                            }
                                            deleteExercise={() =>
                                                props.deleteExercise(index)
                                            }
                                            editExercise={() =>
                                                props.editExercise(index)
                                            }
                                            innerRef={provided2.innerRef}
                                            {...provided2.draggableProps}
                                            {...provided2.dragHandleProps}
                                        />
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </ExerciseArea>
                )}
            </Droppable>
        </DragDropContext>
    );
};

import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { Button } from "src/components/atoms/button";
import { Typography } from "src/components/atoms/typography";
import { SignupBaseFrame } from "src/components/structures/signup/signup-base-frame";
import { Parq } from "src/entities/customer";

const Title = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "400",
})`
    text-align: center;
    margin: 3vh 0;
`;

const Item = styled.div`
    display: grid;
    column-gap: 2vw;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "title title"
        "button-yes button-no";
`;

const ItemTitle = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "400",
})`
    grid-area: title;
    text-align: center;
    margin: 3vh 0;
`;

const ButtonYes = styled(Button).attrs({ round: false })`
    width: 100%;
    grid-area: button-yes;
`;

const ButtonNo = styled(Button).attrs({ round: false })`
    width: 100%;
    grid-area: button-no;
`;

const ItemArea = styled.div`
    height: 45vh;
    overflow-y: auto;
    display: grid;
`;

const ButtonContinue = styled(Button).attrs({ round: false })`
    justify-self: flex-end;
    width: 50%;
    margin-top: 5vh;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface SignupHealthChecksProps {
    goToNextPage: () => void;
    goToWarningPage: () => void;
    updateInfo: (parq: Parq) => Promise<boolean>;
}

export const SignupHealthChecks: React.FC<SignupHealthChecksProps> = (
    props
) => {
    const { control, formState, handleSubmit } = useForm({
        mode: "onChange",
    });
    const { isSubmitting } = formState;

    const onSubmit = async (values: any) => {
        const parquetValues: Record<string, boolean> = {};

        let showWarningPage = false;

        Object.entries(values).forEach(([key, value]) => {
            const parsedValue = value === 1;

            if (parsedValue === true) {
                showWarningPage = true;
            }

            parquetValues[key] = parsedValue;
        });

        const response = await props.updateInfo(
            (parquetValues as unknown) as Parq
        );

        if (!response) {
            return;
        }

        if (showWarningPage) {
            props.goToWarningPage();
        } else {
            props.goToNextPage();
        }
    };

    const healthCheckItems = {
        problemaCoracao:
            "Algum médico já disse que você possui algum problema de coração e que só deveria realizar atividade física supervisionado por profissionais de saúde?",
        dorPeitoGeral:
            "Você sente dores no peito quando pratica atividade física?",
        dorPeitoUltimoMes:
            "No último mês, você sentiu dores no peito quando praticou atividade física?",
        desequilibrio:
            "Você apresenta desequilíbrio devido à tontura e/ ou perda de consciência?",
        problemaOsseo:
            "Você possui algum problema ósseo ou articular que poderia ser piorado pela atividade física?",
        medicacaoPressao:
            "Você toma atualmente algum medicamento para pressão arterial e/ou problema de coração?",
        outroMotivo:
            "Sabe de alguma outra razão pela qual você não deve praticar atividade física?",
    };

    return (
        <SignupBaseFrame onSubmit={handleSubmit(onSubmit)}>
            <Title>
                Estamos quase finalizando seu cadastro! Precisamos que você
                responda algumas perguntas em relação a sua saúde.
            </Title>
            <ItemArea>
                {Object.entries(healthCheckItems).map(([key, value], index) => (
                    <Item key={index}>
                        <ItemTitle>{value}</ItemTitle>
                        <Controller
                            render={({ onChange, value }) => (
                                <>
                                    <ButtonYes
                                        color={
                                            value === 1
                                                ? "primary"
                                                : "background"
                                        }
                                        onClick={() => onChange(1)}
                                    >
                                        Sim
                                    </ButtonYes>
                                    <ButtonNo
                                        color={
                                            value === 2
                                                ? "primary"
                                                : "background"
                                        }
                                        onClick={() => onChange(2)}
                                    >
                                        Não
                                    </ButtonNo>
                                </>
                            )}
                            rules={{ required: true, min: 1 }}
                            control={control}
                            defaultValue={0}
                            name={key}
                        />
                    </Item>
                ))}
                <ButtonContinue loading={isSubmitting} type="submit">
                    AVANÇAR
                </ButtonContinue>
            </ItemArea>
        </SignupBaseFrame>
    );
};

import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import noScroll from "no-scroll";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Controller, useForm } from "react-hook-form";
import {
    SelectField,
    SelectFieldOption,
} from "src/components/molecules/select-field";
import { AdminLayout } from "src/components/templates/layout/admin-layout";
import { InputField } from "src/components/molecules/input-field";
import {
    WorkoutScheduleCalendar,
    WorkoutSheduleCalendarItem,
} from "src/components/structures/shared/workout-schedule-calendar";
import { Condo } from "src/entities/condo";
import { ScheduledWorkout } from "src/entities/scheduled-workout";
import { Button } from "src/components/atoms/button";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import { ModalSchedule } from "src/components/structures/admin-schedule/modal-schedule";
import { useMediaQuery } from "@material-ui/core";
import { WorkoutSeries } from "src/entities/workout-series";
import {
    ScreenAlert,
    ScreenAlertProps,
} from "src/components/structures/shared/screen-alert";

dayjs.extend(customParseFormat);

const Content = styled.form`
    margin: 2vh 0;

    display: grid;
    column-gap: 2vw;
    row-gap: 2vh;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: stretch;
    grid-template-rows: auto auto;
    grid-template-areas:
        "condo condo start-date end-date filter-button"
        "calendar calendar calendar calendar calendar";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "condo"
            "start-date"
            "filter-button"
            "calendar";
    }
`;

const CondoSelector = styled(SelectField).attrs({
    label: "Condomínio",
    placeholder: "Selecione um condomínio",
})`
    grid-area: condo;
`;

const StartDateSelector = styled(InputField).attrs({
    label: "Data Inicial",
    type: "date",
})`
    grid-area: start-date;
`;

const EndDateSelector = styled(InputField).attrs({
    label: "Data Final",
    type: "date",
})`
    grid-area: end-date;
`;

const FilterButton = styled(Button).attrs({ round: false })`
    grid-area: filter-button;
    height: 50%;
    align-self: end;
    width: 100%;
    padding: 0;

    @media only screen and (max-width: 768px) {
        margin-bottom: 3vh;
    }
`;

const LoadingArea = styled.div`
    grid-area: calendar;
`;

export interface FilterOptions {
    startDate: dayjs.Dayjs;
    endDate?: dayjs.Dayjs;
    selectedCondo?: string;
}

interface AdminScheduleTemplateProps {
    isLoading: boolean;
    condos: Condo[];
    calendarItems: WorkoutSheduleCalendarItem[];
    defaultOptions: FilterOptions;
    reloadCalendarItems: (filterOptions?: FilterOptions) => void;
    editScheduledWorkout: (scheduledWorkout: ScheduledWorkout) => void;
    deleteScheduledWorkout: (
        scheduledWorkout: ScheduledWorkout
    ) => Promise<boolean>;
}

export const AdminScheduleTemplate: React.FC<AdminScheduleTemplateProps> = (
    props
) => {
    const isMobile = useMediaQuery("(max-width:768px)", { noSsr: true });

    const { control, handleSubmit, setError, errors } = useForm();

    const [isLoading, setIsLoading] = React.useState(true);
    const [
        selectedScheduledWorkout,
        setSelectedScheduledWorkout,
    ] = React.useState<ScheduledWorkout | undefined>(undefined);
    const [alertProps, setAlertProps] = React.useState<
        ScreenAlertProps | undefined
    >(undefined);

    React.useEffect(() => {
        if (selectedScheduledWorkout) {
            noScroll.on();
        } else {
            noScroll.off();
        }
    }, [selectedScheduledWorkout]);

    React.useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    const getCondoOptions = (): SelectFieldOption[] => {
        return props.condos.map((condo) => ({
            label: condo.name,
            value: condo.id,
        }));
    };

    const filterItems = (values: any) => {
        const dateFormats = ["DD/MM/YYYY", "DDMMYYYY", "YYYY-MM-DD"];

        const newStartDate = dayjs(values.startDate, dateFormats).isValid()
            ? dayjs(values.startDate, dateFormats)
            : undefined;
        const newEndDate =
            dayjs(values.endDate, dateFormats).isValid() && !isMobile
                ? dayjs(values.endDate, dateFormats)
                : undefined;

        if (!newStartDate) {
            setError("startDate", {
                type: "manual",
                message: "Data inválida!",
            });
            return;
        }

        if (newEndDate && newEndDate.diff(newStartDate, "day") > 5) {
            setError("endDate", {
                type: "manual",
                message: "Máx: 5 dias",
            });
            return;
        }

        props.reloadCalendarItems({
            startDate: newStartDate,
            endDate: newEndDate,
            selectedCondo: values.selectedCondo,
        });
    };

    const deleteScheduledWorkout = async () => {
        if (!selectedScheduledWorkout) {
            return;
        }

        setIsLoading(true);
        setSelectedScheduledWorkout(undefined);

        const response = await props.deleteScheduledWorkout(
            selectedScheduledWorkout
        );

        setAlertProps({
            handleClose: () => setAlertProps(undefined),
            open: true,
            message: response ? "Sucesso ao apagar!" : "Erro ao apagar!",
            severity: response ? "success" : "error",
        });

        props.reloadCalendarItems();
    };

    const editScheduledWorkout = async () => {
        if (!selectedScheduledWorkout) {
            return;
        }

        props.editScheduledWorkout(selectedScheduledWorkout);
    };

    const pickScheduledWorkout = (scheduledWorkout: ScheduledWorkout) => {
        const sortedScheduledWorkout: ScheduledWorkout = {
            ...scheduledWorkout,
        };

        const availableWorkoutSeries =
            sortedScheduledWorkout?.series?.map(
                (series) => series as WorkoutSeries
            ) ?? [];

        availableWorkoutSeries.sort((a, b) => {
            if (a.createdAt && b.createdAt) {
                return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                );
            }

            return 0;
        });

        setSelectedScheduledWorkout({
            ...sortedScheduledWorkout,
            series: availableWorkoutSeries,
        });
    };

    return (
        <AdminLayout title="Agenda de treinos">
            <Content onSubmit={handleSubmit(filterItems)}>
                <Controller
                    as={<CondoSelector options={getCondoOptions()} />}
                    control={control}
                    name="selectedCondo"
                    defaultValue={props.defaultOptions.selectedCondo}
                />
                <Controller
                    as={
                        <StartDateSelector
                            error={{
                                isInvalid: !!errors.startDate,
                                message: errors.startDate?.message,
                            }}
                        />
                    }
                    rules={{ required: true }}
                    control={control}
                    defaultValue={props.defaultOptions.startDate.format(
                        "YYYY-MM-DD"
                    )}
                    name="startDate"
                />
                <Controller
                    as={
                        <EndDateSelector
                            className="hide-mobile"
                            error={{
                                isInvalid: !!errors.endDate,
                                message: errors.endDate?.message,
                            }}
                        />
                    }
                    control={control}
                    defaultValue={props.defaultOptions.endDate?.format(
                        "YYYY-MM-DD"
                    )}
                    name="endDate"
                />
                <FilterButton loading={isLoading} type="submit">
                    Filtrar
                </FilterButton>
                {isLoading ? (
                    <LoadingArea>
                        <LoadingPage />
                    </LoadingArea>
                ) : (
                    <WorkoutScheduleCalendar
                        items={props.calendarItems}
                        isLoading={isLoading}
                        onSelectScheduledWorkout={(
                            scheduledWorkout: ScheduledWorkout
                        ) => pickScheduledWorkout(scheduledWorkout)}
                    />
                )}
            </Content>
            {selectedScheduledWorkout && (
                <ModalSchedule
                    isOpen={Boolean(selectedScheduledWorkout)}
                    scheduleWorkout={selectedScheduledWorkout}
                    onClose={() => setSelectedScheduledWorkout(undefined)}
                    onEdit={editScheduledWorkout}
                    onDelete={deleteScheduledWorkout}
                />
            )}
            {alertProps && <ScreenAlert {...alertProps} />}
        </AdminLayout>
    );
};

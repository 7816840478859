import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { Typography } from "src/components/atoms/typography";
import { InputField } from "src/components/molecules/input-field";
import { TextAreaField } from "src/components/molecules/textarea-field";
import { Button } from "src/components/atoms/button";
import { useMediaQuery } from "@material-ui/core";
import { awsEmailContact } from "src/infrastructure/aws/aws-email";
import {
    ScreenAlert,
    ScreenAlertProps,
} from "src/components/structures/shared/screen-alert";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        background: "none",
        width: "80%",
        height: "90%",
        zIndex: 100,
    },
    overlay: {
        backgroundColor: "rgb(0, 0, 0, 0.3)",
        zIndex: 99,
    },
};

const customStylesMobile = {
    ...customStyles,
    content: {
        ...customStyles.content,
        width: "100vw",
        height: "100vh",
        padding: 0,
    },
};

const CloseButton = styled.i.attrs({
    className: "fas fa-times",
})`
    position: absolute;
    top: 5vh;
    right: 2.5vw;
    cursor: pointer;
    z-index: 99;
    opacity: 70%;

    @media only screen and (max-width: 768px) {
        top: 2vh;
        right: 4vw;
        font-size: 2rem;
    }
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: linear-gradient(90deg, transparent 15%, #fff 15%);
    padding: 7vh 3vw;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-areas: "contact-info contact-form";
    column-gap: 3vw;

    @media only screen and (max-width: 768px) {
        background: #fff;
        grid-template-columns: 1fr;
        grid-template-areas: "contact-form";
    }
`;

const ContactInfoSection = styled.div`
    grid-area: contact-info;
    background-color: #4e4e4e;
    box-shadow: 10px 10px 0px #87fe00;
    width: 100%;
    height: 100%;

    display: grid;
    row-gap: 2vh;
    align-content: center;
    justify-content: center;
    justify-items: center;
    grid-auto-rows: auto;
    grid-template-areas:
        "logo"
        "title"
        "contact"
        "social-media";
`;

const Logo = styled.img.attrs({ src: "/assets/logo_white.png" })`
    grid-area: logo;
    width: 50%;
`;

const ContactTitle = styled(Typography).attrs({
    variant: "h4",
    color: "white",
})`
    grid-area: title;
    text-align: center;
`;

const ContactInfo = styled(Typography).attrs({
    variant: "h4",
    fontWeight: "400",
    color: "white",
})`
    grid-area: contact;
    text-align: center;
`;

const SocialMediaRow = styled.div`
    grid-area: social-media;
    width: 50%;

    display: grid;
    column-gap: 2vw;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
`;

const InstagramIcon = styled.img.attrs({ src: "/assets/instagram_icon.png" })`
    width: 100%;
    cursor: pointer;
`;

const FacebookIcon = styled.img.attrs({ src: "/assets/facebook_icon.png" })`
    width: 100%;
    cursor: pointer;
`;

const WhatsappIcon = styled.img.attrs({ src: "/assets/whatsapp_icon.png" })`
    width: 100%;
    cursor: pointer;
`;

const ContactFormSection = styled.form`
    grid-area: contact-form;
    height: 100%;
    overflow-y: auto;
    padding: 0 2px;

    display: grid;
    grid-auto-rows: auto;
    align-content: flex-start;
    row-gap: 2vh;
    grid-template-areas:
        "title"
        "name"
        "email"
        "subject"
        "message"
        "send-button";
`;

const Title = styled(Typography).attrs({ variant: "h4" })`
    grid-area: title;
    color: #4e4e4e;
`;

const NameField = styled(InputField).attrs({
    label: "Nome",
    placeholder: "Digite seu nome",
    text: { align: "left" },
})`
    grid-area: name;
`;

const EmailField = styled(InputField).attrs({
    label: "E-mail",
    placeholder: "Digite seu e-mail",
    text: { align: "left" },
})`
    grid-area: email;
`;

const SubjectField = styled(InputField).attrs({
    label: "Assunto",
    placeholder: "Digite o assunto",
    text: { align: "left" },
})`
    grid-area: subject;
`;

const MessageField = styled(TextAreaField).attrs({
    label: "Mensagem",
    placeholder: "Digite sua mensagem",
})`
    grid-area: message;
`;

const SendButton = styled(Button).attrs({})`
    grid-area: send-button;

    justify-self: flex-end;
    margin-bottom: 1vh;
`;

interface ModalContactProps {
    isOpen: boolean;
    onClose: (status?: boolean) => void;
    onSubmit: () => void;
}

export const ModalContact: React.FC<ModalContactProps> = (props) => {
    Modal.setAppElement("#root");

    const isMobile = useMediaQuery("(max-width:768px)");

    const { control, handleSubmit, errors, formState } = useForm();
    const { isSubmitting } = formState;

    const [alertProps, setAlertProps] = React.useState<
        ScreenAlertProps | undefined
    >(undefined);

    const onSubmit = async (values: any) => {
        const response = await awsEmailContact(values);

        if (!response) {
            setAlertProps({
                handleClose: () => setAlertProps(undefined),
                open: true,
                message:
                    "Erro ao enviar, por favor tente novamente mais tarde!",
                severity: "error",
            });
            return;
        }

        props.onClose(true);
    };

    return (
        <Modal
            style={isMobile ? customStylesMobile : customStyles}
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
        >
            <Content>
                <CloseButton onClick={() => props.onClose()} />
                <ContactInfoSection className="hide-mobile">
                    <Logo />
                    <ContactTitle>
                        Se preferir, use nossos canais de atendimento
                    </ContactTitle>
                    <ContactInfo>
                        {"(041) 99916-3589"}
                        <br />
                        {"email@exemplo.com"}
                    </ContactInfo>
                    <SocialMediaRow>
                        <a
                            href={"https://instagram.com/usetreinado"}
                            style={{ display: "grid", justifyItems: "center" }}
                        >
                            <InstagramIcon />
                        </a>
                        <a
                            href={"https://facebook.com/usetreinado"}
                            style={{ display: "grid", justifyItems: "center" }}
                        >
                            <FacebookIcon />
                        </a>
                        <a
                            href={"https://wa.me/+5541999163589"}
                            style={{ display: "grid", justifyItems: "center" }}
                        >
                            <WhatsappIcon />
                        </a>
                    </SocialMediaRow>
                </ContactInfoSection>
                <ContactFormSection onSubmit={handleSubmit(onSubmit)}>
                    <Title>ENTRE EM CONTATO CONOSCO</Title>
                    <Controller
                        as={<NameField error={{ isInvalid: errors.name }} />}
                        rules={{ required: true }}
                        control={control}
                        defaultValue={""}
                        name="name"
                    />
                    <Controller
                        as={<EmailField error={{ isInvalid: errors.email }} />}
                        rules={{ required: true }}
                        control={control}
                        defaultValue={""}
                        name="email"
                    />
                    <Controller
                        as={
                            <SubjectField
                                error={{ isInvalid: errors.contactReason }}
                            />
                        }
                        rules={{ required: true }}
                        control={control}
                        defaultValue={""}
                        name="contactReason"
                    />
                    <Controller
                        as={
                            <MessageField
                                error={{ isInvalid: errors.description }}
                            />
                        }
                        rules={{ required: true }}
                        control={control}
                        defaultValue={""}
                        name="description"
                    />
                    <SendButton type="submit" loading={isSubmitting}>
                        Enviar
                    </SendButton>
                </ContactFormSection>
            </Content>
            {alertProps && <ScreenAlert {...alertProps} />}
        </Modal>
    );
};

import React from "react";
import styled from "styled-components";
import { UseFormMethods, Controller } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import { MaximoCarga } from "src/entities/workout-series";
import { getDateTimeInSeconds } from "src/entities/workout-series/utils";

const FrameBase = styled.div`
    display: grid;
    grid-auto-rows: auto;
    column-gap: 1vw;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
        "rounds-number round-reps break-time load-type"
        "props-title props-title props-title props-title"
        "load-value load-value . .";

    @media only screen and (max-width: 768px) {
        column-gap: 2vw;
        row-gap: 2vh;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "rounds-number round-reps"
            "break-time load-type"
            "props-title props-title"
            "load-value load-value";
    }
`;

const RoundsNumber = styled(InputField).attrs({
    label: "Número de séries",
    type: "number",
    validations: { min: "0" },
})`
    grid-area: rounds-number;
`;

const RoundReps = styled(InputField).attrs({
    label: "Nº Repetições/Série",
    type: "number",
    validations: { min: "0" },
})`
    grid-area: round-reps;
`;

const BreakTime = styled(InputField).attrs({
    label: "Descanso (s)",
    type: "number",
    validations: { min: "0" },
})`
    grid-area: break-time;
`;

interface FrameMaximoCargaEditProps {
    workoutSeries?: MaximoCarga;
    control: UseFormMethods["control"];
    errors: UseFormMethods["errors"];
    watch: UseFormMethods["watch"];
}

export const FrameMaximoCargaEdit: React.FC<FrameMaximoCargaEditProps> = (
    props
) => {
    const getDefaultBreakTime = () => {
        return props.workoutSeries?.rounds[0]?.breakTime
            ? getDateTimeInSeconds(props.workoutSeries?.rounds[0]?.breakTime)
            : 30;
    };

    return (
        <FrameBase>
            <Controller
                as={
                    <RoundsNumber
                        error={{
                            isInvalid: props.errors.rounds,
                        }}
                    />
                }
                rules={{ required: true }}
                control={props.control}
                defaultValue={props.workoutSeries?.rounds.length ?? 1}
                name="rounds"
            />
            <Controller
                as={
                    <RoundReps
                        error={{
                            isInvalid: props.errors.reps,
                        }}
                    />
                }
                rules={{ required: true }}
                control={props.control}
                defaultValue={props.workoutSeries?.rounds[0]?.reps ?? 1}
                name="reps"
            />
            <Controller
                as={
                    <BreakTime
                        error={{
                            isInvalid: props.errors.breakTime,
                        }}
                    />
                }
                control={props.control}
                defaultValue={getDefaultBreakTime()}
                name="breakTime"
            />
        </FrameBase>
    );
};

import React from "react";
import { BasicInfoFrame } from "src/components/structures/admin-edit-condo/basic-info-frame";
import { ContactInfoFrame } from "src/components/structures/admin-edit-condo/contact-info-frame";
import { EquipmentsInfoFrame } from "src/components/structures/admin-edit-condo/equipments-info-frame";
import { FinancialInfoFrame } from "src/components/structures/admin-edit-condo/financial-info-frame";
import { CustomersInfoFrame } from "src/components/structures/admin-edit-condo/customers-info-frame";
import { AdminEditLayout } from "src/components/templates/layout/admin-edit-layout";
import { Condo } from "src/entities/condo";
import { LoadingPage } from "src/components/structures/shared/loading-page";
import { CondoEquipment, Equipment } from "src/entities/equipment";

interface AdminEditCondoTemplateProps {
    isLoading: boolean;
    condo?: Condo;
    equipments: Equipment[];
    redirectCondosPage: () => void;
    onCreateCondo: (condo: Condo) => Promise<boolean>;
    onUpdateCondo: (id: string, condo: Partial<Condo>) => Promise<boolean>;
    onAddEquipment: (equipment: CondoEquipment) => Promise<boolean>;
    onDeleteEquipment: (equipment: CondoEquipment) => Promise<boolean>;
}

export const AdminEditCondoTemplate: React.FC<AdminEditCondoTemplateProps> = (
    props
) => {
    const [activeStep, setActiveStep] = React.useState(0);

    const getCurrentStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <BasicInfoFrame
                        condo={props.condo}
                        onCancel={props.redirectCondosPage}
                        onSave={props.onCreateCondo}
                        nextFrame={() => setActiveStep(activeStep + 1)}
                    />
                );
            case 1:
                return (
                    <ContactInfoFrame
                        condo={props.condo}
                        onCancel={props.redirectCondosPage}
                        onSave={props.onUpdateCondo}
                        nextFrame={() => setActiveStep(activeStep + 1)}
                    />
                );
            case 2:
                return (
                    <FinancialInfoFrame
                        condo={props.condo}
                        onCancel={props.redirectCondosPage}
                        onSave={props.onUpdateCondo}
                        nextFrame={() => setActiveStep(activeStep + 1)}
                    />
                );
            case 3:
                return (
                    <EquipmentsInfoFrame
                        availableEquipments={props.equipments}
                        condoEquipments={props.condo?.equipments ?? []}
                        onCancel={props.redirectCondosPage}
                        onAddEquipment={props.onAddEquipment}
                        onDeleteEquipment={props.onDeleteEquipment}
                    />
                );
            default:
                return (
                    <CustomersInfoFrame
                        customers={props.condo?.customers ?? []}
                    />
                );
        }
    };

    return (
        <AdminEditLayout
            title={
                Boolean(props.condo)
                    ? "Editar Condomínio"
                    : "Cadastro de Novo Condomínio"
            }
            steps={[
                {
                    icon: "/assets/admin-step-menu/mdi_paste.png",
                },
                {
                    icon: "/assets/admin-step-menu/mdi_email.png",
                },
                {
                    icon: "/assets/admin-step-menu/mdi_local_atm.png",
                },
                {
                    icon:
                        "/assets/admin-step-menu/mdi_store_mall_directory.png",
                },
                {
                    icon: "/assets/admin-step-menu/mdi_people.png",
                },
            ]}
            stepClick={props.condo ? setActiveStep : undefined}
            activeStep={activeStep}
            stepTitle={props.condo?.name}
        >
            {props.isLoading ? <LoadingPage /> : getCurrentStepContent()}
        </AdminEditLayout>
    );
};

import React, { CSSProperties } from "react";
import { Typography } from "src/components/atoms/typography";
import { theme } from "src/style/theme";
import styled from "styled-components";

const CardBase = styled.div`
    padding: 3vh 3vw;
    max-width: 300px;
    background: ${theme.colors.background.dark};
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;

    cursor: pointer;
    user-select: none;

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-items: center;
    justify-content: center;
`;

interface CardIconProps {
    image: string;
}

const CardIcon = styled.div<CardIconProps>`
    background: ${(props) => `url(${props.image})`} no-repeat;
    background-size: contain;
    width: 4vw;
    height: 4vw;

    @media only screen and (max-width: 768px) {
        width: 10vw;
        height: 10vw;
    }
`;

const CardContent = styled(Typography).attrs({
    variant: "h4",
})`
    text-align: center;
    text-transform: uppercase;
`;

interface CardProps {
    title: string;
    icon: string;
    style: CSSProperties;
    onClick?: () => void;
}

export const Card: React.FC<CardProps> = (props) => {
    return (
        <CardBase style={props.style} onClick={props.onClick}>
            <CardIcon image={props.icon} />
            <CardContent>{props.title}</CardContent>
        </CardBase>
    );
};

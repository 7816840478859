import React from "react";
import styled from "styled-components";
// import { theme } from "src/style/theme";
import { WorkoutSeries } from "src/entities/workout-series";
import { Typography } from "src/components/atoms/typography";
import {
    getWorkoutSeriesDescription,
    getWorkoutSeriesTitleFormated,
    getWorkoutSeriesTypeFormated,
} from "src/entities/workout-series/utils";
import { theme } from "src/style/theme";

interface BaseCardProps {
    clickable: boolean;
}

const BaseCard = styled.div<BaseCardProps>`
    position: relative;
    width: 100%;
    background: #f5f5f5;
    border-radius: 6px;
    padding: 2vh 2vw;
    cursor: ${(props) => (props.clickable ? "pointer" : "unset")};

    display: grid;
    row-gap: 1vh;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-template-areas:
        "title"
        "type"
        "description";
`;

const Title = styled(Typography).attrs({
    variant: "h5",
})`
    text-transform: uppercase;
    grid-area: title;
    text-align: center;
`;

const Subtitle = styled(Typography).attrs({
    variant: "h5",
})`
    grid-area: type;
    text-align: center;
    font-size: 0.8rem;
`;

const Description = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})`
    grid-area: description;
    text-align: center;
    white-space: pre-line;
`;

const StatusIndicator = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;

    width: 12px;
    height: 12px;
    border-radius: 50%;

    background-color: ${theme.colors.primary.light};
`;

interface WorkoutSeriesCardProps {
    workoutSeries: WorkoutSeries;
    done?: boolean;
    goToWorkoutSeries?: (workoutSeries: WorkoutSeries) => void;
}

export const WorkoutSeriesShowcaseCard: React.FC<WorkoutSeriesCardProps> = (
    props
) => {
    return (
        <BaseCard
            clickable={Boolean(props.goToWorkoutSeries)}
            onClick={() => props.goToWorkoutSeries?.(props.workoutSeries)}
        >
            <Title>{getWorkoutSeriesTitleFormated(props.workoutSeries)}</Title>
            {props.done && <StatusIndicator />}
            <Subtitle>{`(${getWorkoutSeriesTypeFormated(
                props.workoutSeries.type
            )})`}</Subtitle>
            <Description>
                {getWorkoutSeriesDescription(props.workoutSeries)}
            </Description>
        </BaseCard>
    );
};

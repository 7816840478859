import React from "react";
import Avatar from "react-avatar";
import styled from "styled-components";
import { Typography } from "src/components/atoms/typography";
import { theme } from "src/style/theme";
import { Button } from "src/components/atoms/button";
import { useMediaQuery } from "@material-ui/core";

const Section = styled.div`
    width: 100vw;
    display: grid;
`;

const UpperBackground = styled.div`
    width: 100vw;
    height: 20vh;
    background: url("/assets/background_owners.svg");
    background-color: transparent;
    z-index: 10;
    opacity: 90%;
    position: relative;
    padding-top: 80px;
    transform: scaleX(-1);
`;

const InfBackground = styled.div`
    width: 100vw;
    height: 12vw;
    background: url("/assets/background_owners.svg");
    background-color: transparent;
    z-index: 10;
    opacity: 90%;
    position: relative;
    transform: scaleX(-1) scaleY(-1);

    @media only screen and (max-width: 768px) {
        min-height: 20vw;
    }
`;

const Content = styled.div`
    width: 100vw;
    background-color: black;
    z-index: 10;
    padding: 0 10vw;
    opacity: 90%;
    position: relative;

    display: grid;

    @media only screen and (max-width: 768px) {
        padding-bottom: 10vh;
    }
`;

const Title = styled(Typography).attrs({ variant: "h1", color: "white" })`
    text-transform: uppercase;
`;

const Divider = styled.div`
    margin: 3vh 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.7);
    width: 40vw;
`;

const PhotoRow = styled.div`
    margin-top: 10vh;

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    column-gap: 5vw;

    @media only screen and (max-width: 768px) {
        margin-top: 0;
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        row-gap: 5vh;
    }
`;

const PhotoItem = styled.div`
    display: grid;
    grid-template-rows: 40vh 45px auto;
    row-gap: 5vh;
    justify-items: center;

    @media only screen and (max-width: 768px) {
        grid-template-rows: 20vh auto;
    }
`;

const Photo = styled(Avatar).attrs({ round: true })`
    box-shadow: 4px 3px 0px ${theme.colors.primary.light};
`;

const Description = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
    color: "white",
})`
    text-align: center;

    strong {
        font-weight: 700;
    }
`;

const SignupButton = styled(Button)`
    justify-self: center;
    margin-top: 5vh;
`;

const ContactArea = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-content: center;
    column-gap: 3vw;
    height: 45px;
`;

const InstagramIcon = styled.img.attrs({
    alt: "instagram logo",
    src: "/assets/instagram_green.png",
})`
    width: 45px;
    height: 45px;
`;

const NameArea = styled(Typography).attrs({
    variant: "h4",
    color: "white",
})`
    text-align: center;
`;

interface SectionOwnersProps {
    openContactForm: () => void;
}

export const SectionOwners: React.FC<SectionOwnersProps> = (props) => {
    const isMobile = useMediaQuery("(max-width:768px)");

    return (
        <Section>
            <UpperBackground />
            <Content>
                <Title className="hide-mobile">Nossos Profissionais</Title>
                <Divider className="hide-mobile" />
                <PhotoRow>
                    <PhotoItem>
                        <Photo
                            src="/assets/renata.png"
                            size={isMobile ? "20vh" : "40vh"}
                        />
                        <ContactArea className="hide-mobile">
                            <InstagramIcon />
                            <NameArea>
                                EDUCADORA FÍSICA RENATINHA <br /> (CREF
                                025739-G/PR)
                            </NameArea>
                        </ContactArea>
                        <Description>
                            Formada pela UFPR e certificada pela Crossfit® no
                            qual atua na área desde 2015. Ministra aulas
                            presenciais como personal trainer e consultora
                            online personalizada. É co-fundadora do{" "}
                            <strong>Improve Mobility</strong> (programa diário
                            de alongamento), <strong>Projeto Carnaval</strong>{" "}
                            (programa pontual de treinos para emagrecimento e
                            tonificação da musculatura) e criadora do{" "}
                            <strong>#pullem19dias</strong> e
                            <strong>#toestobarem30dias</strong> programas de
                            treinos específicos para desenvolvimento de
                            movimentos específicos do Crossfit.
                        </Description>
                    </PhotoItem>
                    <PhotoItem>
                        <Photo
                            src="/assets/fernando.png"
                            size={isMobile ? "20vh" : "40vh"}
                        />
                        <ContactArea className="hide-mobile">
                            <InstagramIcon />
                            <NameArea>
                                EDUCADOR FÍSICA FERNADO <br /> (CREF
                                012150-G/PR)
                            </NameArea>
                        </ContactArea>
                        <Description>
                            Formado em pela Universidade Federal do Paraná
                            (UFPR), Pós Graduado em Atividades Aquáticas e
                            Treinamento Funcional. É qualificado como como{" "}
                            <strong>level 1, level 2 e Weight Lifting</strong>{" "}
                            pela Crossfit. Atua com treinamento funcional e
                            personal trainer desde 2007 e com Crossfit desde
                            2014. Atualmente trabalha com treinamento específico
                            individual e em grupos, treinamento para a terceira
                            idade e periodizações para os mais diversos
                            objetivos..
                        </Description>
                    </PhotoItem>
                </PhotoRow>
                <SignupButton onClick={() => props.openContactForm()}>
                    Faça parte agora!
                </SignupButton>
            </Content>
            <InfBackground />
        </Section>
    );
};

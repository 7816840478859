import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { AdminEditWorkoutTemplate } from "src/components/templates/admin-edit-workout-template";
import { Condo } from "src/entities/condo";
import { Exercise } from "src/entities/exercise";
import { Workout } from "src/entities/workout";
import {
    WorkoutMultipleSeriesType,
    WorkoutSeries,
    WorkoutSingleSeriesType,
} from "src/entities/workout-series";
import { awsCondoGetAll } from "src/infrastructure/aws/aws-condo";
import { awsExerciseGetAll } from "src/infrastructure/aws/aws-exercise";
import { awsScheduledWorkoutsCreate } from "src/infrastructure/aws/aws-scheduled-workout";
import {
    awsWorkoutCreate,
    awsWorkoutGet,
    awsWorkoutUpdate,
} from "src/infrastructure/aws/aws-workout";
import { CreateScheduledWorkoutRequest } from "src/entities/requests/create-scheduled-workout";
import {
    awsWorkoutEntityCreate,
    awsWorkoutEntityDelete,
    awsWorkoutEntityUpdate,
} from "src/infrastructure/aws/aws-workout-entity";
import { WorkoutEntity } from "src/entities/workout-entity";
import dayjs from "dayjs";

export const AdminEditWorkoutPage: React.FC = () => {
    const history = useHistory();
    const workoutId = queryString.parse(useLocation().search)?.id;

    const [workout, setWorkout] = React.useState<Workout | undefined>();
    const [isLoading, setIsLoading] = React.useState(true);
    const [reloadData, setReloadData] = React.useState(true);
    const [condos, setCondos] = React.useState<Condo[]>([]);
    const [availableExercises, setAvailableExercises] = React.useState<
        Exercise[]
    >([]);

    const singleWorkoutTypes: WorkoutSingleSeriesType[] = [
        "maximo_repeticoes",
        "maximo_carga",
        "series_x_repeticao",
        "morrer_repeticao",
        "morrer_carga",
        "realizar_dentro_minuto",
        "intervalado",
        "tempo",
        "repeticoes_tempo_determinado",
    ];

    const multipleWorkoutTypes: WorkoutMultipleSeriesType[] = [
        "rodadas_menor_tempo_fixas",
        "rodadas_menor_tempo_variadas",
        "menor_tempo_possivel",
        "maximo_repeticoes_x_tempo",
        "maximo_rodadas_x_tempo",
        "realizar_dentro_minuto_multiplo",
        "morrer_repeticoes",
        "intervalado_multiplo",
    ];

    React.useEffect(() => {
        const retrieveData = async () => {
            const workoutQs = Array.isArray(workoutId)
                ? workoutId[0]
                : workoutId;

            const newCondos = await awsCondoGetAll();
            const newExercises = await awsExerciseGetAll();

            let newWorkout: Workout | undefined = undefined;

            if (!!workoutQs) {
                newWorkout = (await awsWorkoutGet(workoutQs)) ?? undefined;
            } else if (workout !== undefined) {
                newWorkout = (await awsWorkoutGet(workout.id)) ?? undefined;
            }

            setCondos(newCondos);
            setAvailableExercises(newExercises);
            setWorkout(newWorkout ?? undefined);
            setIsLoading(false);
            setReloadData(false);
        };

        retrieveData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workoutId, reloadData]);

    const redirectWorkoutsPage = () => {
        history.push("/admin/treinos");
    };

    const saveWorkout = async (
        updateData: Partial<Workout>
    ): Promise<boolean> => {
        let response: boolean;

        if (workout) {
            const updateResponse = await awsWorkoutUpdate(
                workout.id,
                updateData
            );

            setWorkout(updateResponse ?? undefined);

            response = Boolean(updateResponse);
        } else {
            const newWorkout = {
                ...updateData,
                type: "condicionamento_fisico",
            };

            const createResponse = await awsWorkoutCreate(
                newWorkout as Workout
            );

            setWorkout(createResponse ?? undefined);

            response = Boolean(createResponse);
        }

        return response;
    };

    const scheduleWorkout = async (
        scheduledWorkout: Workout,
        dates: Date[],
        condosId: string[]
    ): Promise<boolean> => {
        const scheduledWorkouts: CreateScheduledWorkoutRequest[] = [];

        condosId.forEach((condoId) =>
            dates.forEach((date) =>
                scheduledWorkouts.push({
                    ...scheduledWorkout,
                    condoId,
                    date: dayjs(date).toISOString(),
                })
            )
        );

        const response = await awsScheduledWorkoutsCreate(scheduledWorkouts);

        return response.length > 0;
    };

    const addWorkoutSeries = async (
        workoutSeries: WorkoutSeries
    ): Promise<boolean> => {
        setIsLoading(true);

        const addMethod = workoutSeries.id
            ? awsWorkoutEntityUpdate
            : awsWorkoutEntityCreate;

        const workoutEntity: WorkoutEntity = {
            workoutId: workout?.id ?? "",
            entity: {
                id: workoutSeries.id,
                type: "series",
                data: workoutSeries,
            },
        };

        const response = await addMethod(workoutEntity);

        if (response) {
            setIsLoading(true);
            setReloadData(true);
        } else {
            setIsLoading(false);
        }

        return Boolean(response);
    };

    const removeWorkoutSeries = async (
        workoutSeries: WorkoutSeries
    ): Promise<boolean> => {
        setIsLoading(true);

        const response = await awsWorkoutEntityDelete(workout?.id ?? "", {
            id: workoutSeries.id ?? "",
            type: "series",
        });

        if (response) {
            setReloadData(true);
        } else {
            setIsLoading(false);
        }

        return response;
    };

    return (
        <AdminEditWorkoutTemplate
            title="Cadastro de Treino"
            condos={condos}
            workout={workout}
            isLoading={isLoading}
            availableExercises={availableExercises}
            singleWorkoutTypes={singleWorkoutTypes}
            multipleWorkoutTypes={multipleWorkoutTypes}
            redirectWorkoutsPage={redirectWorkoutsPage}
            scheduleWorkout={scheduleWorkout}
            saveWorkout={saveWorkout}
            addWorkoutSeries={addWorkoutSeries}
            removeWorkoutSeries={removeWorkoutSeries}
        />
    );
};

import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { Button } from "src/components/atoms/button";
import { InputField } from "src/components/molecules/input-field";
import { Typography } from "src/components/atoms/typography";
import { Condo } from "src/entities/condo";
import { Customer } from "src/entities/customer";
import { SignupBaseFrame } from "src/components/structures/signup/signup-base-frame";

const Title = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "400",
})`
    text-align: center;
    margin: 3vh 0;
`;

const NameField = styled(InputField).attrs({
    label: "Nome Completo",
    placeholder: "Digite seu nome completo",
    box: { light: true },
    text: { align: "left" },
})`
    width: 100%;
`;

const EmailField = styled(InputField).attrs({
    label: "E-mail",
    placeholder: "Digite seu e-mail",
    box: { light: true },
    text: { align: "left" },
})`
    width: 100%;
`;

const DocumentField = styled(InputField).attrs({
    label: "Insira seu CPF/CNPJ",
    placeholder: "CPF/CNPJ",
    box: { light: true },
    text: { align: "left" },
    validations: { mask: "999999999-99" },
})`
    width: 100%;
`;

const ButtonContinue = styled(Button).attrs({ round: false })`
    justify-self: flex-end;
    width: 50%;
    margin-top: 2vh;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface SignupBasicInfoProps {
    condo?: Condo;
    goToNextPage: () => void;
    updateInfo: (customerData: Partial<Customer>) => Promise<boolean>;
}

export const SignupBasicInfo: React.FC<SignupBasicInfoProps> = (props) => {
    const { control, formState, handleSubmit, errors, setError } = useForm();
    const { isSubmitting } = formState;

    const onSubmit = async (values: any) => {
        const email = values.email.replaceAll(" ", "");

        const response = await props.updateInfo({
            ...values,
            email,
        });

        if (!response) {
            setError("email", {
                type: "manual",
                message: "Já existe um cadastro para os dados informados",
            });
            setError("document", {
                type: "manual",
                message: "Já existe um cadastro para os dados informados",
            });
        } else {
            props.goToNextPage();
        }
    };

    return (
        <SignupBaseFrame onSubmit={handleSubmit(onSubmit)}>
            <Title>
                Identificamos o seu condomínio:{" "}
                <strong>{props.condo?.name}</strong>
                <br />
                Insira suas informações de cadastro:
            </Title>
            <Controller
                as={
                    <NameField
                        error={{
                            isInvalid: !!errors.name,
                            message: errors.name?.message,
                        }}
                    />
                }
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="name"
            />
            <Controller
                as={
                    <EmailField
                        error={{
                            isInvalid: !!errors.email,
                            message: errors.email?.message,
                        }}
                    />
                }
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="email"
            />
            <Controller
                as={
                    <DocumentField
                        error={{
                            isInvalid: !!errors.document,
                            message: errors.document?.message,
                        }}
                    />
                }
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="document"
            />
            <ButtonContinue loading={isSubmitting} type="submit">
                Avançar
            </ButtonContinue>
        </SignupBaseFrame>
    );
};

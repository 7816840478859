import React from "react";
import styled from "styled-components";
import { Button } from "src/components/atoms/button";
import { Typography } from "src/components/atoms/typography";
import { useForm } from "react-hook-form";

const SignupFrame = styled.form`
    width: 400px;
    justify-self: center;
    display: grid;
    justify-items: center;
    row-gap: 5vh;
    column-gap: 2vw;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
        "title title"
        "description description"
        "dont-agree agree";

    @media only screen and (max-width: 768px) {
        width: 95%;
        min-width: unset;
        max-width: unset;
    }
`;

const Title = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "700",
})`
    text-align: center;
    grid-area: title;
`;

const Description = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "400",
})`
    text-align: center;
    grid-area: description;
`;

const ButtonDisagree = styled(Button).attrs({
    round: false,
    color: "background",
})`
    grid-area: dont-agree;
    width: 100%;
`;

const ButtonAgree = styled(Button).attrs({ round: false })`
    grid-area: agree;
    width: 100%;
`;

interface SignupWarningProps {
    goToLogin: () => void;
    goToNextPage: () => void;
    updateInfo: () => Promise<boolean>;
}

export const SignupWarning: React.FC<SignupWarningProps> = (props) => {
    const { formState, handleSubmit } = useForm();
    const { isSubmitting } = formState;

    const onSubmit = async () => {
        const response = await props.updateInfo();

        if (!response) {
            return;
        }

        props.goToNextPage();
    };

    return (
        <SignupFrame onSubmit={handleSubmit(onSubmit)}>
            <Title>
                Termo de Responsabilidade para Prática de Atividade Física
            </Title>
            <Description>
                Notamos que você respondeu “SIM” para uma dos questões do
                “Questionário de Prontidão para Atividade Física” (PAR-Q).
                Recomendamos que antes de realizar qualquer atividade física
                consulte com o seu médico de preferência a possibilidade de
                realizá-las.
                <br />
                <br />
                Ao clicar em “CONCORDO” você assume a plena responsabilidade por
                qualquer atividade física praticada sem o atendimento a essa
                recomendação.
            </Description>
            <ButtonDisagree onClick={() => props.goToLogin()}>
                Não concordo
            </ButtonDisagree>
            <ButtonAgree loading={isSubmitting} type="submit">
                Concordo
            </ButtonAgree>
        </SignupFrame>
    );
};

import React from "react";
import dayjs from "dayjs";
import Cookies from "universal-cookie";
import { useMediaQuery } from "@material-ui/core";
import { times } from "lodash";
import { useHistory } from "react-router-dom";
import { WorkoutSheduleCalendarItem } from "src/components/structures/shared/workout-schedule-calendar";
import {
    AdminScheduleTemplate,
    FilterOptions,
} from "src/components/templates/admin-schedule-template";
import { Condo } from "src/entities/condo";
import { ScheduledWorkout } from "src/entities/scheduled-workout";
import { awsCondoGetAll } from "src/infrastructure/aws/aws-condo";
import {
    awsScheduledWorkoutDelete,
    awsScheduledWorkoutQuery,
} from "src/infrastructure/aws/aws-scheduled-workout";

const cookies = new Cookies();

export const AdminSchedulePage: React.FC = () => {
    const history = useHistory();
    const isMobile = useMediaQuery("(max-width:768px)", { noSsr: true });

    const [isLoading, setIsLoading] = React.useState(true);
    const [reload, setReload] = React.useState(true);
    const [condos, setCondos] = React.useState<Condo[]>([]);
    const [calendarItems, setCalendarItems] = React.useState<
        WorkoutSheduleCalendarItem[]
    >([]);
    const [filterOptions, setFilterOptions] = React.useState<FilterOptions>({
        startDate: cookies.get("scheduleOptions")
            ? dayjs(cookies.get("scheduleOptions").startDate)
            : dayjs(),
        endDate: isMobile
            ? undefined
            : cookies.get("scheduleOptions")
            ? dayjs(cookies.get("scheduleOptions").endDate)
            : dayjs().add(4, "d"),
        selectedCondo: cookies.get("scheduleOptions")
            ? cookies.get("scheduleOptions").selectedCondo
            : undefined,
    });

    React.useEffect(() => {
        const retrieveCondos = async () => {
            const newCondos = await awsCondoGetAll();

            setCondos(newCondos);
        };

        retrieveCondos();
    }, []);

    React.useEffect(() => {
        const retrieveSchedule = async () => {
            const result = await await awsScheduledWorkoutQuery(
                {
                    startDate: filterOptions.startDate.toDate(),
                    endDate: isMobile
                        ? undefined
                        : filterOptions.endDate?.toDate(),
                },
                filterOptions.selectedCondo
            );

            const newCalendarItems: WorkoutSheduleCalendarItem[] = [];

            const startDate = filterOptions.startDate;
            const endDate = filterOptions.endDate ?? filterOptions.startDate;
            const numberOfDays = endDate.diff(startDate, "day") + 1;
            const availableDates = times(numberOfDays, (index) =>
                dayjs(startDate).add(index, "day")
            );

            for (const date of availableDates) {
                const scheduledWorkouts = result.filter((item) =>
                    dayjs(item.date).isSame(date, "day")
                );

                newCalendarItems.push({
                    date: date.toDate(),
                    scheduledWorkouts,
                });
            }

            setCalendarItems(newCalendarItems);
            setIsLoading(false);
            setReload(false);
        };

        if (reload) {
            setIsLoading(true);
            retrieveSchedule();
        }
    }, [filterOptions, isMobile, reload]);

    const editScheduledWorkout = async (scheduledWorkout: ScheduledWorkout) => {
        history.push(`/admin/agenda/agendamento?id=${scheduledWorkout.id}`);
    };

    const deleteScheduledWorkout = async (
        scheduledWorkout: ScheduledWorkout
    ) => {
        return await awsScheduledWorkoutDelete(scheduledWorkout.id);
    };

    const reloadCalendarItems = (newFilterOptions?: FilterOptions) => {
        console.log(newFilterOptions);

        if (newFilterOptions) {
            setFilterOptions(newFilterOptions);
            cookies.set("scheduleOptions", newFilterOptions);
        }

        setReload(true);
    };

    return (
        <AdminScheduleTemplate
            isLoading={isLoading}
            condos={condos}
            calendarItems={calendarItems}
            defaultOptions={filterOptions}
            reloadCalendarItems={reloadCalendarItems}
            editScheduledWorkout={editScheduledWorkout}
            deleteScheduledWorkout={deleteScheduledWorkout}
        />
    );
};

import React from "react";
import styled from "styled-components";

const Background = styled.div`
    width: 100vw;
`;

const UpperBackground = styled.div`
    width: 100vw;
    background: url("/assets/background_footer.svg");
    background-color: transparent;
    z-index: 10;
    position: relative;
    padding-top: 80px;
`;

const Content = styled.div`
    min-height: 19vw;
    padding: 1vh 10vw;

    /* background-image: url("/assets/background_footer.svg"); */
    /* background-repeat: no-repeat; */
    background-color: rgba(0, 0, 0, 0.8);

    color: white;

    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "contact social-media logo"
        "copyright copyright copyright";
    column-gap: 1vw;
    row-gap: 2vh;
    justify-items: center;
    justify-self: flex-end;
    align-content: flex-end;
    align-items: center;

    @media only screen and (max-width: 768px) {
        padding-top: -5vh;
        grid-template-rows: auto auto auto auto;
        grid-template-columns: 1fr;
        grid-template-areas:
            "logo"
            "contact"
            "social-media"
            "copyright";
    }
`;

const ContactArea = styled.div`
    grid-area: contact;
    display: grid;
    grid-template-rows: auto auto auto;
    justify-items: center;
`;

const SocialMediaArea = styled.div`
    grid-area: social-media;
    display: grid;
    grid-template-rows: auto auto;
    justify-items: center;
    width: 70%;
`;

const LogoArea = styled.img.attrs({ src: "/assets/logo_white.png" })`
    grid-area: logo;
    width: 50%;
`;

const CopyrightArea = styled.div`
    grid-area: copyright;

    font-size: 0.8rem;

    display: grid;
    grid-template-rows: auto auto;
    justify-items: center;
`;

const SocialMediaRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
`;

const InstagramIcon = styled.img.attrs({ src: "/assets/instagram_icon.png" })`
    width: 50%;
    cursor: pointer;
`;

const FacebookIcon = styled.img.attrs({ src: "/assets/facebook_icon.png" })`
    width: 50%;
    cursor: pointer;
`;

const WhatsappIcon = styled.img.attrs({ src: "/assets/whatsapp_icon.png" })`
    width: 50%;
    cursor: pointer;
`;

export const SectionFooter: React.FC = () => {
    return (
        <Background>
            <UpperBackground />
            <Content>
                <ContactArea>
                    <div style={{ marginBottom: "1vh" }}>
                        Entre em contato conosco:
                    </div>
                    <div>(041) 99916-3589</div>
                    <div>email@exemplo.com</div>
                </ContactArea>
                <SocialMediaArea>
                    <div>Redes Sociais:</div>
                    <SocialMediaRow>
                        <a
                            href={"https://instagram.com/usetreinado"}
                            style={{ display: "grid", justifyItems: "center" }}
                        >
                            <InstagramIcon />
                        </a>
                        <a
                            href={"https://facebook.com/usetreinado"}
                            style={{ display: "grid", justifyItems: "center" }}
                        >
                            <FacebookIcon />
                        </a>
                        <a
                            href={"https://wa.me/+5541999163589"}
                            style={{ display: "grid", justifyItems: "center" }}
                        >
                            <WhatsappIcon />
                        </a>
                    </SocialMediaRow>
                </SocialMediaArea>
                <LogoArea />
                <CopyrightArea>
                    <div>
                        © Copyright 2020 Treinado. CNPJ 35.402.326/0001-20
                    </div>
                    <div>
                        Design por Luiz H. Skodowski; Desenvolvido por Josnei
                        Luis Olszewski Junior LTDA
                    </div>
                </CopyrightArea>
            </Content>
        </Background>
    );
};

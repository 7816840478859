import React from "react";
import { useHistory } from "react-router-dom";
import { LoginTemplate } from "src/components/templates/login-template";
import {
    awsAuthGetCurrentUser,
    awsAuthSignIn,
} from "src/infrastructure/aws/aws-amplify";

export const LoginPage: React.FC = () => {
    const history = useHistory();

    const login = async (
        username: string,
        password: string
    ): Promise<boolean> => {
        const result = await awsAuthSignIn(username, password);

        if (result === "MISSING_EMAIL_VERIFY") {
            history.push(`/cadastro?email=${username}&state=EMAIL`);
            return true;
        }

        if (result === "ERROR") {
            return false;
        }

        const currentCustomer = await awsAuthGetCurrentUser();

        if (!currentCustomer) {
            return false;
        }

        if (currentCustomer.parq === undefined) {
            history.push(`/cadastro?email=${username}&state=PARQ`);
            return true;
        }

        currentCustomer.type === "ADMIN"
            ? history.push("/admin")
            : history.push("/home");

        return true;
    };

    const goToSignup = () => {
        history.push("/cadastro");
    };

    const goToForgotPassword = () => {
        history.push("/esqueci-senha");
    };

    return (
        <LoginTemplate
            login={login}
            goToSignup={goToSignup}
            goToForgotPassword={goToForgotPassword}
        />
    );
};

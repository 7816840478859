import React from "react";
import styled from "styled-components";
import { Button } from "src/components/atoms/button";
import { InputField } from "src/components/molecules/input-field";
import { LoginLayout } from "src/components/templates/layout/login-layout";
import { Controller, useForm } from "react-hook-form";

const LoginFrame = styled.form`
    justify-self: center;
    display: grid;
    justify-items: center;
    row-gap: 2vh;
    min-width: 400px;
    max-width: 40vw;

    @media only screen and (max-width: 768px) {
        width: 95%;
        min-width: unset;
        max-width: unset;
    }
`;

const UsernameField = styled(InputField).attrs({
    label: "E-mail",
    box: { light: true },
})`
    width: 100%;
`;

const PasswordField = styled(InputField).attrs({
    label: "Senha",
    type: "password",
    box: { light: true },
})`
    width: 100%;
`;

const ForgotPassword = styled.a`
    text-decoration: none;
    color: white;
    font-size: 0.8rem;
    justify-self: flex-end;
    cursor: pointer;
`;

const ButtonLogin = styled(Button).attrs({ round: false })`
    justify-self: flex-end;
    width: 50%;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface LoginTemplateProps {
    goToSignup: () => void;
    goToForgotPassword: () => void;
    login: (username: string, password: string) => Promise<boolean>;
}

export const LoginTemplate: React.FC<LoginTemplateProps> = (props) => {
    const { control, formState, handleSubmit, setError, errors } = useForm();

    const { isSubmitting } = formState;

    const onSubmit = async (values: any) => {
        const response = await props.login(values.username, values.password);

        if (!response) {
            setError("username", {
                type: "manual",
                message:
                    "Usuário/senha não encontrados. Por favor, verifique e tente novamente",
            });
        }
    };

    return (
        <LoginLayout linkLabel="Registrar-se" goToLink={props.goToSignup}>
            <LoginFrame onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    as={
                        <UsernameField
                            error={{
                                isInvalid: errors.username,
                                message: errors.username?.message,
                            }}
                        />
                    }
                    rules={{ required: true }}
                    control={control}
                    defaultValue={""}
                    name="username"
                />
                <Controller
                    as={
                        <PasswordField
                            error={{
                                isInvalid: errors.password,
                                message: errors.password?.message,
                            }}
                        />
                    }
                    rules={{ required: true }}
                    control={control}
                    defaultValue={""}
                    name="password"
                />

                <ForgotPassword onClick={() => props.goToForgotPassword()}>
                    Esqueci minha senha
                </ForgotPassword>
                <ButtonLogin loading={isSubmitting} type="submit">
                    Login
                </ButtonLogin>
            </LoginFrame>
        </LoginLayout>
    );
};

import { FetchClient } from "src/infrastructure/http-client/fetch-client";
import { Workout } from "src/entities/workout";

const fetchClient = new FetchClient();

export const awsWorkoutCreate = async (
    workout: Workout
): Promise<Workout | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/workout`;

        const response = await fetchClient.post<Workout>(url, {
            ...workout,
        });

        return response;
    } catch (error) {
        console.log("error while creating workout", error);

        return null;
    }
};

export const awsWorkoutUpdate = async (
    workoutCode: string,
    updateData: Partial<Workout>
): Promise<Workout | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/workout?id=${workoutCode}`;

        const response = await fetchClient.patch<Workout>(url, {
            ...updateData,
        });

        return response;
    } catch (error) {
        console.log("error while updating workout", error);

        return null;
    }
};

export const awsWorkoutGet = async (
    workoutCode: string
): Promise<Workout | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/workout?id=${workoutCode}`;

        const response = await fetchClient.get<Workout>(url);

        return response;
    } catch (error) {
        console.log("error while getting Workout", error);

        return null;
    }
};

export const awsWorkoutDelete = async (
    workoutCode: string
): Promise<boolean> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/workout?id=${workoutCode}`;

        await fetchClient.delete(url);

        return true;
    } catch (error) {
        console.log("error while deleting Workout", error);

        return false;
    }
};

export const awsWorkoutGetAll = async (): Promise<Workout[]> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/workouts`;

        const response = await fetchClient.get<Workout[]>(url);

        return response ?? [];
    } catch (error) {
        console.log("error while getting workouts", error);

        return [];
    }
};

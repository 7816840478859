import React from "react";
import styled from "styled-components";
import { Typography } from "src/components/atoms/typography";

const BaseCard = styled.div`
    background: #f5f5f5;
    border-radius: 6px;
    padding: 2vh 2vw;
    cursor: pointer;

    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
`;

const Title = styled(Typography).attrs({
    variant: "h5",
})`
    text-transform: uppercase;
`;

const Description = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})`
    text-align: center;
`;

interface WorkoutSeriesCardProps {
    title: string;
    description: string;
    onClick?: () => void;
}

export const ExerciseBaseCard: React.FC<WorkoutSeriesCardProps> = (props) => {
    return (
        <BaseCard onClick={props.onClick}>
            <Title>{props.title}</Title>
            <Description>{props.description}</Description>
        </BaseCard>
    );
};

import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Typography } from "src/components/atoms/typography";
import { HomeLayout } from "src/components/templates/layout/home-layout";
import { WorkoutSeries } from "src/entities/workout-series";
import {
    getWorkoutSeriesTypeDescriptionText,
    getWorkoutSeriesTypeFormated,
} from "src/entities/workout-series/utils";
import { HomeWorkoutSeriesFooter } from "src/components/structures/home-layout/home-workout-series-footer";
import { FrameMaximoRepeticoes } from "src/components/structures/home-workout-series/single/frame-maximo-repeticoes";
import { FrameMaximoCarga } from "src/components/structures/home-workout-series/single/frame-maximo-carga";
import { FrameMorrerPorCarga } from "src/components/structures/home-workout-series/single/frame-morrer-por-carga";
import { FrameMorrerPorRepeticao } from "src/components/structures/home-workout-series/single/frame-morrer-por-repeticao";
import { FrameSeriesXRepeticao } from "src/components/structures/home-workout-series/single/frame-series-x-repeticao";
import { FrameRealizarDentroMinuto } from "src/components/structures/home-workout-series/single/frame-realizar-dentro-minuto";
import { FrameTempo } from "src/components/structures/home-workout-series/single/frame-tempo";
import { FrameRepeticoesTempoDeterminado } from "src/components/structures/home-workout-series/single/frame-maximo-repeticoes-tempo-determinado";
import { FrameRodadasMenorTempoFixas } from "src/components/structures/home-workout-series/multiple/frame-rodadas-menor-tempo-fixas";
import { FrameRealizarDentroMinutoMultiplo } from "src/components/structures/home-workout-series/multiple/frame-realizar-dentro-minuto-multiplo";
import { FrameRodadasMenorTempoPossivel } from "src/components/structures/home-workout-series/multiple/frame-rodadas-menor-tempo-possivel";
import { ModalExercise } from "src/components/structures/home-workout-series/modal-exercise";
import { Exercise } from "src/entities/exercise";
import { WorkoutSeriesResult } from "src/entities/workout-result";
import { FrameMorrerRepeticoes } from "src/components/structures/home-workout-series/multiple/frame-morrer-repeticoes";
import { FrameMaximoRepeticoesXTempo } from "src/components/structures/home-workout-series/multiple/frame-maximo-repeticoes-x-tempo";
import { FrameMaximoRodadasXTempo } from "src/components/structures/home-workout-series/multiple/frame-maximo-rodadas-x-tempo";
import { FrameIntervaladoMultiplo } from "src/components/structures/home-workout-series/multiple/frame-intervalado-multiplo";
import { FrameIntervalado } from "src/components/structures/home-workout-series/single/frame-intervalado";
import { FrameRodadasMenorTempoVariadas } from "src/components/structures/home-workout-series/multiple/frame-rodadas-menor-tempo-variadas";

const Header = styled.div`
    display: grid;
    justify-items: center;
`;

const GoBackLink = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})`
    justify-self: flex-start;
`;

const GymIcon = styled.img.attrs({ src: "/assets/gym_black.png" })`
    width: 92px;
`;

const WorkoutTypeDescription = styled.label`
    font-size: 1rem;
    margin-bottom: 2vh;
    text-align: center;
`;

interface HomeWorkoutSeriesTemplateProps {
    isLoading: boolean;
    workoutSeries: WorkoutSeries;
    goToWorkoutPage: () => void;
    submitSeriesResults: (
        seriesResult: WorkoutSeriesResult
    ) => Promise<boolean>;
    getExercise: (exerciseId: string) => Promise<Exercise | null>;
}

export const HomeWorkoutSeriesTemplate: React.FC<HomeWorkoutSeriesTemplateProps> = (
    props
) => {
    const { control, formState, handleSubmit, watch } = useForm();

    const { isSubmitting } = formState;

    const [exerciseToExhibit, setExerciseToExhibit] = React.useState<
        Exercise | undefined
    >(undefined);

    const exhibitExercise = (exercise: Exercise) => {
        setExerciseToExhibit(exercise);
    };

    const onSubmit = async (values: any) => {
        let reps: number | number[] | undefined = undefined;

        if (values.reps_0) {
            let index = 0;
            reps = [];

            while (values[`reps_${index}`]) {
                reps.push(values[`reps_${index}`]);

                index++;
            }
        } else {
            reps = values.reps;
        }

        const newWorkoutResult: WorkoutSeriesResult = {
            ...values,
            reps,
            seriesId: props.workoutSeries.id,
        };

        await props.submitSeriesResults(newWorkoutResult);
    };

    const getWorkoutSeriesExercises = () => {
        switch (props.workoutSeries?.type) {
            case "maximo_repeticoes":
                return (
                    <FrameMaximoRepeticoes
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "maximo_carga":
                return (
                    <FrameMaximoCarga
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "morrer_carga":
                return (
                    <FrameMorrerPorCarga
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "morrer_repeticao":
                return (
                    <FrameMorrerPorRepeticao
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "series_x_repeticao":
                return (
                    <FrameSeriesXRepeticao
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "realizar_dentro_minuto":
                return (
                    <FrameRealizarDentroMinuto
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "tempo":
                return (
                    <FrameTempo
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "repeticoes_tempo_determinado":
                return (
                    <FrameRepeticoesTempoDeterminado
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "rodadas_menor_tempo_fixas":
                return (
                    <FrameRodadasMenorTempoFixas
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "rodadas_menor_tempo_variadas":
                return (
                    <FrameRodadasMenorTempoVariadas
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "realizar_dentro_minuto_multiplo":
                return (
                    <FrameRealizarDentroMinutoMultiplo
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "menor_tempo_possivel":
                return (
                    <FrameRodadasMenorTempoPossivel
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "morrer_repeticoes":
                return (
                    <FrameMorrerRepeticoes
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "maximo_repeticoes_x_tempo":
                return (
                    <FrameMaximoRepeticoesXTempo
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "maximo_rodadas_x_tempo":
                return (
                    <FrameMaximoRodadasXTempo
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                    />
                );
            case "intervalado":
                return (
                    <FrameIntervalado
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                        watch={watch}
                    />
                );
            case "intervalado_multiplo":
                return (
                    <FrameIntervaladoMultiplo
                        workoutSeries={props.workoutSeries}
                        exhibitExercise={exhibitExercise}
                        control={control}
                        watch={watch}
                    />
                );
            default:
                return <div>()</div>;
        }
    };

    return (
        <HomeLayout
            onSubmit={handleSubmit(onSubmit)}
            isLoading={props.isLoading}
            footer={
                <HomeWorkoutSeriesFooter
                    goBackToWorkout={props.goToWorkoutPage}
                    isSubmitting={isSubmitting}
                />
            }
        >
            <Header>
                <GoBackLink onClick={() => props.goToWorkoutPage()}>
                    {"< Retornar ao Treino"}
                </GoBackLink>
                <GymIcon />
                <WorkoutTypeDescription>
                    <strong>
                        {`${getWorkoutSeriesTypeFormated(
                            props.workoutSeries.type
                        )} - `}
                    </strong>
                    {getWorkoutSeriesTypeDescriptionText(
                        props.workoutSeries.type
                    )}
                </WorkoutTypeDescription>
            </Header>
            {getWorkoutSeriesExercises()}
            {exerciseToExhibit && (
                <ModalExercise
                    exerciseId={exerciseToExhibit.id}
                    onClose={() => setExerciseToExhibit(undefined)}
                    isOpen={exerciseToExhibit !== undefined}
                    getExercise={props.getExercise}
                />
            )}
        </HomeLayout>
    );
};

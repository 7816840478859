import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { Button } from "src/components/atoms/button";
import { InputField } from "src/components/molecules/input-field";
import { Typography } from "src/components/atoms/typography";
import { SignupBaseFrame } from "src/components/structures/signup/signup-base-frame";

const Title = styled(Typography).attrs({
    variant: "h5",
    color: "white",
    fontWeight: "400",
})`
    text-align: center;
    margin: 3vh 0;
`;

const PasswordField = styled(InputField).attrs({
    label: "Senha",
    placeholder: "Insira sua senha",
    type: "password",
    box: { light: true },
    text: { align: "left" },
})`
    width: 100%;
`;

const ConfirmPasswordField = styled(InputField).attrs({
    label: "Repetir Senha",
    placeholder: "Digite novamente sua senha",
    type: "password",
    box: { light: true },
    text: { align: "left" },
})`
    width: 100%;
`;

const ButtonContinue = styled(Button).attrs({ round: false })`
    justify-self: flex-end;
    width: 50%;
    margin-top: 2vh;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface SignupPasswordProps {
    updatePassword: (password: string) => Promise<boolean>;
    goToNextPage: () => void;
}

export const SignupPassword: React.FC<SignupPasswordProps> = (props) => {
    const {
        control,
        formState,
        handleSubmit,
        errors,
        getValues,
        setError,
    } = useForm();
    const { isSubmitting } = formState;

    const onSubmit = async (values: any) => {
        const response = await props.updatePassword(values.password);

        if (response) {
            props.goToNextPage();
        } else {
            setError("password", {
                type: "manual",
                message: "Erro ao salvar a senha, por favor, tente novamente (",
            });
        }
    };

    return (
        <SignupBaseFrame onSubmit={handleSubmit(onSubmit)}>
            <Title>
                Insira sua senha.
                <br />
                Ela deve conter no mínimo 8 caracteres.
            </Title>
            <Controller
                as={
                    <PasswordField
                        error={{
                            isInvalid: !!errors.password,
                            message: errors.password?.message,
                        }}
                    />
                }
                defaultValue={""}
                rules={{
                    required: true,
                    minLength: {
                        value: 8,
                        message: "No mínimo 8 caracteres",
                    },
                }}
                control={control}
                name="password"
            />
            <Controller
                as={
                    <ConfirmPasswordField
                        error={{
                            isInvalid: !!errors.confirmPassword,
                            message: errors.confirmPassword?.message,
                        }}
                    />
                }
                defaultValue={""}
                rules={{
                    required: true,
                    validate: (value) =>
                        value === getValues("password") ||
                        "As senhas devem coincidir",
                }}
                control={control}
                name="confirmPassword"
            />

            <ButtonContinue loading={isSubmitting} type="submit">
                Avançar
            </ButtonContinue>
        </SignupBaseFrame>
    );
};

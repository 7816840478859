import React from "react";
import { useHistory } from "react-router-dom";
import { AdminWorkoutsTemplate } from "src/components/templates/admin-workouts-template";
import { Workout } from "src/entities/workout";
import {
    awsWorkoutDelete,
    awsWorkoutGetAll,
} from "src/infrastructure/aws/aws-workout";

export const AdminWorkoutsPage: React.FC = () => {
    const history = useHistory();

    const [reload, setReload] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [workouts, setWorkouts] = React.useState<Workout[]>([]);

    React.useEffect(() => {
        const retrieveWorkouts = async () => {
            setIsLoading(true);

            const newWorkouts = await awsWorkoutGetAll();

            setWorkouts(newWorkouts);
            setIsLoading(false);
            setReload(false);
        };

        if (reload) {
            retrieveWorkouts();
        }
    }, [reload]);

    const addNewWorkout = () => {
        history.push("/admin/treino");
    };

    const editWorkout = (workout?: Workout) => {
        history.push(`/admin/treino?id=${workout?.id}`);
    };

    const deleteWorkout = async (workout: Workout) => {
        setIsLoading(true);

        await awsWorkoutDelete(workout.id);

        setReload(true);
    };

    return (
        <AdminWorkoutsTemplate
            addNewWorkout={addNewWorkout}
            workouts={workouts}
            isLoading={isLoading}
            editWorkout={editWorkout}
            deleteWorkout={deleteWorkout}
        />
    );
};

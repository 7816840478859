import React from "react";
import styled from "styled-components";
import { Typography } from "src/components/atoms/typography";
import { WorkoutSeries } from "src/entities/workout-series";
import {
    getWorkoutSeriesTitleFormated,
    getWorkoutSeriesDescription,
} from "src/entities/workout-series/utils";

const WorkoutSeriesCardBase = styled.div`
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 4vh 2vw;

    display: grid;
    row-gap: 1vh;
    column-gap: 2vw;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    grid-template-areas:
        "title icons"
        "subtitle icons";
`;

const ExerciseTitle = styled(Typography).attrs({
    variant: "h4",
})`
    grid-area: title;
`;

const ExerciseSubtitle = styled(Typography).attrs({
    variant: "h5",
    fontWeight: "400",
})`
    grid-area: subtitle;
    white-space: pre-line;
`;

const ExerciceIconArea = styled.div`
    grid-area: icons;
    display: grid;
    grid-auto-flow: column;
    column-gap: 1vw;
    align-content: center;
`;

const EditIcon = styled.img.attrs({ src: "/assets/mdi_edit.png" })`
    height: 25px;
    cursor: pointer;
`;

const EraseIcon = styled.img.attrs({ src: "/assets/mdi_delete.png" })`
    height: 25px;
    cursor: pointer;
`;

const DuplicateIcon = styled.img.attrs({ src: "/assets/mdi_duplicate.png" })`
    height: 25px;
    cursor: pointer;
`;

interface WorkoutSeriesCardProps {
    workoutSeries: WorkoutSeries;
    onEdit: () => void;
    onErase: () => void;
    onDuplicate: () => void;
}

export const WorkoutSeriesCard: React.FC<WorkoutSeriesCardProps> = (props) => {
    return (
        <WorkoutSeriesCardBase>
            <ExerciseTitle>
                {getWorkoutSeriesTitleFormated(props.workoutSeries, true)}
            </ExerciseTitle>
            <ExerciseSubtitle>
                {getWorkoutSeriesDescription(props.workoutSeries)}
            </ExerciseSubtitle>
            <ExerciceIconArea>
                <EditIcon onClick={() => props.onEdit()} />
                <EraseIcon onClick={() => props.onErase()} />
                <DuplicateIcon onClick={() => props.onDuplicate()} />
            </ExerciceIconArea>
        </WorkoutSeriesCardBase>
    );
};

import { FetchClient } from "src/infrastructure/http-client/fetch-client";
import { Customer } from "src/entities/customer";

const fetchClient = new FetchClient();

export const awsCustomerCreate = async (
    customer: Customer,
    condoId: string
): Promise<Customer | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/customer`;

        const response = await fetchClient.post<Customer>(url, {
            ...customer,
            signupCode: condoId,
        });

        return response;
    } catch (error) {
        console.log("error while creating customer", error);

        return null;
    }
};

export const awsCustomerUpdate = async (
    customerCode: string,
    updateData: Partial<Customer>
): Promise<Customer | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/customer?document=${customerCode}`;

        const response = await fetchClient.patch<Customer>(url, {
            ...updateData,
        });

        return response;
    } catch (error) {
        console.log("error while updating customer", error);

        return null;
    }
};

export const awsCustomerGet = async (
    document: string
): Promise<Customer | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/customer?document=${document}`;

        const response = await fetchClient.get<Customer>(url);

        return response;
    } catch (error) {
        console.log("error while getting Customer", error);

        return null;
    }
};

export const awsCustomerGetByEmail = async (
    email: string
): Promise<Customer | null> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/customer?email=${email}`;

        const response = await fetchClient.get<Customer>(url);

        return response;
    } catch (error) {
        console.log("error while getting Customer", error);

        return null;
    }
};

export const awsCustomerDelete = async (
    customerCode: string
): Promise<boolean> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/customer?document=${customerCode}`;

        await fetchClient.delete(url);

        return true;
    } catch (error) {
        console.log("error while deleting Customer", error);

        return false;
    }
};

export const awsCustomerGetAll = async (): Promise<Customer[]> => {
    try {
        const url = `${process.env.REACT_APP_TREINADO_API_HOST}/customers`;

        const response = await fetchClient.get<Customer[]>(url);

        return response ?? [];
    } catch (error) {
        console.log("error while getting customers", error);

        return [];
    }
};

import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import { Typography } from "src/components/atoms/typography";
import {
    RealizarDentroMinutoMultiplo,
    WorkoutExercisesSection,
} from "src/entities/workout-series";
import { Button } from "src/components/atoms/button";
import { getDateTimeInSeconds } from "src/entities/workout-series/utils";
import { ExerciseEditableList } from "src/components/structures/admin-edit-workout/workout-exercises/multiple/exercise-editable-list";

const FrameBase = styled.form`
    padding-bottom: 5vh;

    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1vw;
    row-gap: 2vh;
    grid-template-areas:
        "rounds-number break-time . ."
        "exercises-title exercises-title exercises-title exercises-title"
        "exercises exercises exercises exercises"
        "add-exercise . . ."
        ". . . save";

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "rounds-number break-time"
            "exercises-title exercises-title"
            "exercises exercises"
            "add-exercise add-exercise"
            "save save";
    }
`;

const RoundsNumber = styled(InputField).attrs({
    label: "Nº de Séries",
    type: "number",
})`
    grid-area: rounds-number;
`;

const BreakTime = styled(InputField).attrs({
    label: "Descanso (s)",
    type: "number",
})`
    grid-area: break-time;
`;

const ExerciseTitle = styled(Typography).attrs({
    variant: "h5",
})`
    grid-area: exercises-title;
    text-transform: uppercase;
    color: #4d4d4d;
`;

const ButtonAddExercise = styled(Button).attrs({
    color: "background",
    round: false,
})`
    grid-area: add-exercise;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const ButtonSave = styled(Button).attrs({ round: false })`
    grid-area: save;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface FrameRealizarDentroMinutoMultiploProps {
    workoutSeries?: RealizarDentroMinutoMultiplo;
    sections: WorkoutExercisesSection[];
    addExercise: (numberOfFields: number) => void;
    editExercise: (index: number) => void;
    copyExercise: (index: number) => void;
    deleteExercise: (index: number) => void;
    reorderExercise: (oldIndex: number, newIndex: number) => void;
    onSaveWorkoutSeries: (workoutSeries: RealizarDentroMinutoMultiplo) => void;
}

export const FrameRealizarDentroMinutoMultiplo: React.FC<FrameRealizarDentroMinutoMultiploProps> = (
    props
) => {
    const { control, formState, handleSubmit, getValues, errors } = useForm();

    const { isSubmitting } = formState;

    const onSubmit = async (values: any) => {
        const breakTime = dayjs()
            .hour(0)
            .minute(0)
            .second(0)
            .add(values.breakTime, "s")
            .toDate();

        const totalTime = dayjs()
            .hour(0)
            .minute(0)
            .second(0)
            .add(60, "s")
            .toDate();

        const newWorkoutSeries: RealizarDentroMinutoMultiplo = {
            id: props.workoutSeries?.id,
            style: "multiple",
            type: "realizar_dentro_minuto_multiplo",
            rounds: values.rounds,
            totalTime,
            sections: [
                {
                    breakTime,
                    exercises: props.sections[0].exercises ?? [],
                },
            ],
        };

        props.onSaveWorkoutSeries(newWorkoutSeries);
    };

    const getDefaultBreakTime = () => {
        return props.workoutSeries?.sections[0]?.breakTime
            ? getDateTimeInSeconds(props.workoutSeries?.sections[0]?.breakTime)
            : 30;
    };

    return (
        <FrameBase onSubmit={handleSubmit(onSubmit)}>
            <Controller
                as={
                    <RoundsNumber
                        error={{
                            isInvalid: errors.rounds,
                        }}
                    />
                }
                rules={{ required: true }}
                control={control}
                defaultValue={props.workoutSeries?.rounds ?? 1}
                name="rounds"
            />
            <Controller
                as={
                    <BreakTime
                        error={{
                            isInvalid: errors.breakTime,
                        }}
                    />
                }
                control={control}
                defaultValue={getDefaultBreakTime()}
                name="breakTime"
            />
            <ExerciseTitle>Exercícios</ExerciseTitle>
            <ExerciseEditableList
                reorderExercise={props.reorderExercise}
                deleteExercise={props.deleteExercise}
                editExercise={props.editExercise}
                copyExercise={props.copyExercise}
                exercises={props.sections[0]?.exercises ?? []}
            />
            {!isSubmitting && (
                <ButtonAddExercise
                    type="button"
                    onClick={() => props.addExercise(getValues("rounds"))}
                >
                    Adicionar Exercício
                </ButtonAddExercise>
            )}
            <ButtonSave type="submit" loading={isSubmitting}>
                {props.workoutSeries ? "Salvar" : "Adicionar"}
            </ButtonSave>
        </FrameBase>
    );
};

import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { InputField } from "src/components/molecules/input-field";
import { Typography } from "src/components/atoms/typography";
import { Condo } from "src/entities/condo";
import { Button } from "src/components/atoms/button";
import {
    ScreenAlert,
    ScreenAlertProps,
} from "src/components/structures/shared/screen-alert";

const Content = styled.form`
    display: grid;
    row-gap: 3vh;
    column-gap: 2vw;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "title title title"
        ". . ."
        "name job-position phone"
        "email . ."
        ". buttons buttons";

    @media only screen and (max-width: 768px) {
        grid-auto-rows: auto;
        grid-template-columns: 1fr;
        grid-template-areas:
            "title"
            "name"
            "job-position"
            "phone"
            "email"
            "buttons";
    }
`;

const Title = styled(Typography).attrs({ variant: "h3" })`
    grid-area: title;
`;

const NameField = styled(InputField).attrs({
    label: "Nome Pessoa de Contato",
    placeholder: "Nome da pessoa de contato",
})`
    grid-area: name;
`;

const JobPositionField = styled(InputField).attrs({
    label: "Função",
    placeholder: "Função da pessoa de contato",
})`
    grid-area: job-position;
`;

const PhoneField = styled(InputField).attrs({
    label: "Telefone",
    type: "tel",
    placeholder: "Telefone da pessoa de contato",
})`
    grid-area: phone;
`;

const EmailField = styled(InputField).attrs({
    label: "E-mail",
    placeholder: "E-mail da pessoal de contato",
})`
    grid-area: email;
`;

const ButtonsArea = styled.div`
    grid-area: buttons;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-end;
    column-gap: 2vw;

    @media only screen and (max-width: 768px) {
        justify-content: stretch;
    }
`;

const ButtonCancel = styled(Button).attrs({
    color: "background",
    round: false,
})`
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const ButtonNext = styled(Button).attrs({ round: false })`
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

interface ContactInfoFrameProps {
    condo?: Condo;
    onCancel: () => void;
    onSave: (id: string, condo: Partial<Condo>) => Promise<boolean>;
    nextFrame: () => void;
}

export const ContactInfoFrame: React.FC<ContactInfoFrameProps> = (props) => {
    const { control, formState, handleSubmit } = useForm();
    const { isSubmitting } = formState;

    const [alertProps, setAlertProps] = React.useState<
        ScreenAlertProps | undefined
    >(undefined);

    const submitForm = async (values: any) => {
        const dataToUpdate: Partial<Condo> = {
            ...values,
        };

        const response = await props.onSave(
            props.condo?.id ?? "",
            dataToUpdate
        );

        if (response) {
            props.nextFrame();
        }

        setAlertProps({
            handleClose: () => setAlertProps(undefined),
            open: true,
            message: response ? "Sucesso ao salvar!" : "Erro ao salvar!",
            severity: response ? "success" : "error",
        });
    };

    return (
        <Content onSubmit={handleSubmit(submitForm)}>
            <Title>Informações de Contato</Title>
            <Controller
                as={<NameField />}
                control={control}
                defaultValue={props.condo?.contactInfo?.email ?? ""}
                name="contactInfo.name"
            />
            <Controller
                as={<JobPositionField />}
                control={control}
                defaultValue={props.condo?.contactInfo?.job ?? ""}
                name="contactInfo.job"
            />
            <Controller
                as={<PhoneField />}
                control={control}
                defaultValue={props.condo?.contactInfo?.phone ?? ""}
                name="contactInfo.phone"
            />
            <Controller
                as={<EmailField />}
                control={control}
                defaultValue={props.condo?.contactInfo?.email ?? ""}
                name="contactInfo.email"
            />
            <ButtonsArea>
                {isSubmitting === false && (
                    <ButtonCancel onClick={() => props.onCancel()}>
                        Cancelar
                    </ButtonCancel>
                )}
                <ButtonNext type="submit" loading={isSubmitting}>
                    Salvar
                </ButtonNext>
            </ButtonsArea>
            {alertProps && <ScreenAlert {...alertProps} />}
        </Content>
    );
};

import React from "react";
import styled from "styled-components";
import { UseFormMethods, Controller } from "react-hook-form";
import { LoadBase, LoadType } from "src/entities/workout-series";
import { InputField } from "src/components/molecules/input-field";
import {
    SelectField,
    SelectFieldOption,
} from "src/components/molecules/select-field";

const FrameBase = styled.div`
    grid-area: load-value;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1vw;

    @media only screen and (max-width: 768px) {
        column-gap: 2vw;
    }
`;

const CargaMaximaField = styled(InputField).attrs({
    label: "% Carga Máxima",
    type: "number",
    validations: { min: "0" },
})``;

const PesoCorporalField = styled(InputField).attrs({
    label: "% Peso Corporal",
    type: "number",
    validations: { min: "0" },
})``;

const CargaMasculinaField = styled(InputField).attrs({
    label: "Carga Masculina (Kg)",
    type: "number",
    validations: { min: "0" },
})``;

const CargaFemininaField = styled(InputField).attrs({
    label: "Carga Feminina (Kg)",
    type: "number",
    validations: { min: "0" },
})``;

const CargaMaiorMenorIgualSelector = styled(SelectField).attrs({
    label: "Tipo de Carga",
})``;

const CargaMaiorMenorIgualValue = styled(InputField).attrs({
    label: "Carga (Kg)",
    type: "number",
    validations: { min: "0" },
})``;

interface LoadValueFieldProps {
    defaultValue?: LoadBase;
    errors: UseFormMethods["errors"];
    control: UseFormMethods["control"];
    watch: UseFormMethods["watch"];
    loadType: LoadType;
}

export const LoadValueField: React.FC<LoadValueFieldProps> = (props) => {
    const cargaMaiorMenorIgualOptions: SelectFieldOption[] = [
        {
            label: "Carga Definida",
            value: "carga_definida",
        },
        {
            label: "Aumentar a Caga",
            value: "higher",
        },
        {
            label: "Diminuir a Caga",
            value: "lower",
        },
    ];

    return (
        <FrameBase>
            {props.loadType === "percentual_carga_maxima" && (
                <Controller
                    as={
                        <CargaMaximaField
                            error={{
                                isInvalid: props.errors.loadValue,
                            }}
                        />
                    }
                    rules={{ required: true }}
                    control={props.control}
                    defaultValue={props.defaultValue?.value ?? 50}
                    name="loadValue"
                />
            )}
            {props.loadType === "percentual_peso_corporal" && (
                <Controller
                    as={
                        <PesoCorporalField
                            error={{
                                isInvalid: props.errors.loadValue,
                            }}
                        />
                    }
                    rules={{ required: true }}
                    control={props.control}
                    defaultValue={props.defaultValue?.value ?? 50}
                    name="loadValue"
                />
            )}
            {props.loadType === "carga_por_sexo" && (
                <Controller
                    as={
                        <CargaMasculinaField
                            error={{
                                isInvalid: props.errors.loadMaleValue,
                            }}
                        />
                    }
                    rules={{ required: true }}
                    control={props.control}
                    defaultValue={props.defaultValue?.maleValue ?? 50}
                    name="loadMaleValue"
                />
            )}
            {props.loadType === "carga_por_sexo" && (
                <Controller
                    as={
                        <CargaFemininaField
                            error={{
                                isInvalid: props.errors.loadFemaleValue,
                            }}
                        />
                    }
                    rules={{ required: true }}
                    control={props.control}
                    defaultValue={props.defaultValue?.femaleValue ?? 50}
                    name="loadFemaleValue"
                />
            )}
            {props.loadType === "igual_maior_menor" && (
                <Controller
                    as={
                        <CargaMaiorMenorIgualSelector
                            options={cargaMaiorMenorIgualOptions}
                            error={{
                                isInvalid: props.errors.loadValue,
                            }}
                        />
                    }
                    rules={{ required: true }}
                    control={props.control}
                    defaultValue={props.defaultValue?.higherLower ?? 0}
                    name="loadHigherLower"
                />
            )}
            {props.loadType === "igual_maior_menor" &&
                props.watch("loadHigherLower") === "carga_definida" && (
                    <Controller
                        as={
                            <CargaMaiorMenorIgualValue
                                error={{
                                    isInvalid: props.errors.loadValue,
                                }}
                            />
                        }
                        rules={{ required: true }}
                        control={props.control}
                        defaultValue={props.defaultValue?.value ?? 0}
                        name="loadValue"
                    />
                )}
        </FrameBase>
    );
};
